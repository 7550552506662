/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FiX } from "react-icons/fi";

/* eslint-disable jsx-a11y/no-static-element-interactions */
export default function Modal({ open, setOpen, closable, hasHeader, title, content, buttons }) {
  return open ? (
    <>
      <div
        onClick={() => setOpen(false)}
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm mx-3"
      >
        <div
          className="relative my-6 mx-auto w-[48rem] max-w-md"
          onClick={(e) => e.stopPropagation()}
        >
          {/* ---- CONTENT ---- */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[95vh] overflow-hidden hover:overflow-auto">
            {hasHeader && (
              <div className="flex items-start justify-between px-5 pt-5 rounded-t">
                <h3 className="text-xl font-semibold">{title || "Modal Title"}</h3>
                {closable && (
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <FiX className="text-gray-400 hover:text-gray-500" size={25} />
                  </button>
                )}
              </div>
            )}

            <div className="">{content}</div>
          </div>
          <div>{buttons}</div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  ) : null;
}
