// eslint-disable-next-line import/no-extraneous-dependencies
import { fromJS } from "immutable";

import getPhotosRoutine, {
  removePhotoFolderRoutine,
  addPhotoFolderRoutine,
  addPhotoImageRoutine,
  getImagesRoutine,
  sortImagesRoutine,
  sortPhotoFoldersRoutine,
  removePhotoImageRoutine,
} from "./routines";

const initialState = fromJS({});

export default function photoReducer(state = initialState, action) {
  switch (action.type) {
    case getPhotosRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });
    case getPhotosRoutine.SUCCESS:
      return state.merge({
        photos: action.payload.sort((a, b) => a.order - b.order),
        photosLoading: false,
      });
    case getPhotosRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case removePhotoFolderRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });
    case removePhotoFolderRoutine.SUCCESS:
      return state.merge({
        photos: action.payload.sort((a, b) => a.order - b.order),
        photosLoading: false,
      });
    case removePhotoFolderRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case addPhotoFolderRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });

    case addPhotoFolderRoutine.SUCCESS:
      return state.merge({
        photos: action.payload.sort((a, b) => a.order - b.order),
        photosLoading: false,
      });
    case addPhotoFolderRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case addPhotoImageRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });
    case addPhotoImageRoutine.SUCCESS:
      return state.merge({
        photos: action.payload.sort((a, b) => a.order - b.order),
        photosLoading: false,
      });
    case addPhotoImageRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case sortPhotoFoldersRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });

    case sortPhotoFoldersRoutine.SUCCESS:
      return state.merge({
        photos: action.payload.sort((a, b) => a.order - b.order),
        photosLoading: false,
      });
    case sortPhotoFoldersRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case getImagesRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });

    case getImagesRoutine.SUCCESS:
      return state.merge({
        images: action.payload.sort((a, b) => a.order - b.order),
      });

    case getImagesRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case sortImagesRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });
    case sortImagesRoutine.SUCCESS:
      return state.merge({
        photosLoading: false,
        images: action.payload,
      });
    case sortImagesRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    case removePhotoImageRoutine.TRIGGER:
      return state.merge({
        photosLoading: true,
      });
    case removePhotoImageRoutine.SUCCESS:
      return state.merge({
        images: action.payload.sort((a, b) => a.order - b.order),
      });
    case removePhotoImageRoutine.FAILURE:
      return state.merge({
        photosLoading: false,
      });

    default:
      return state;
  }
}
