import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
// import data from "pages/viewer/builder/content/modules/photo-gallery/data";
import axios from "axios";
import { baseUrl } from "api";
import getPhotosRoutine, {
  addPhotoFolderRoutine,
  addPhotoImageRoutine,
  getImagesRoutine,
  removePhotoFolderRoutine,
  removePhotoImageRoutine,
  sortImagesRoutine,
  sortPhotoFoldersRoutine,
  updatePhotoFolderRoutine,
} from "./routines";

/**
 * ============================================================
 * @param {Get Services}
 */
function* getPhotos(action) {

  const { moduleId } = action.payload;

  try {
    // trigger request action
    yield put(getPhotosRoutine.request());
    const response = yield call(axios.get, `${baseUrl}/photo-gallery-folders?viewerId=${moduleId}`);

    yield put(getPhotosRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

function* foldersSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getPhotosRoutine.TRIGGER, getPhotos);
}

export default foldersSaga;

function* removePhotoFolder(action) {
  const { photos, id } = action.payload;

  const newFolders = photos.filter((folder) => folder.id !== id);
  try {
    // trigger request action
    yield put(removePhotoFolderRoutine.request());

    yield put(removePhotoFolderRoutine.success(newFolders));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* removePhotoFolderSaga() {
  yield takeLatest([removePhotoFolderRoutine.TRIGGER], removePhotoFolder);
}

function* addPhotoFolder(action) {
  const { photos, title } = action.payload;

  const newFolder = {
    title,
    images: [],
    id: photos.length + 2,
    thumbnail: `https://viewlio.b-cdn.net/gallery/photo/${title}.png`,
  };

  try {
    // trigger request action
    yield put(addPhotoFolderRoutine.request());

    yield put(addPhotoFolderRoutine.success([newFolder, ...photos]));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* addPhotoFolderSaga() {
  yield takeLatest([addPhotoFolderRoutine.TRIGGER], addPhotoFolder);
}

function* addPhotoImage(action) {
  const { photos, values } = action.payload;

  const search = photos.filter((obj) => obj.id === parseFloat(values.id));
  if (search.length > 0) {
    search[0].images.push({
      id: search[0].images.length + 2,
      image: `https://viewlio.b-cdn.net/gallery/photo/${values.uuid}.png`,
    });
  }
  try {
    // trigger request action
    yield put(addPhotoImageRoutine.request());

    yield put(addPhotoImageRoutine.success(photos));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* addPhotoImageSaga() {
  yield takeLatest([addPhotoImageRoutine.TRIGGER], addPhotoImage);
}

function* updatePhotoFolder(action) {
  const { photos, values } = action.payload;

  const search = photos.filter((obj) => obj.id === values.id);
  if (search.length > 0) {
    search[0].title = values.title;
  }
  try {
    // trigger request action
    yield put(updatePhotoFolderRoutine.request());

    yield put(updatePhotoFolderRoutine.success(photos));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* updatePhotoFolderSaga() {
  yield takeLatest([updatePhotoFolderRoutine.TRIGGER], updatePhotoFolder);
}

function* sortPhotoFolders(action) {
  const { photos, draggedFolderId, newTargetFolderId, moduleId } = action.payload;

  const folderPositions = photos.map((folder, i) => ({ id: folder.id, index: i }));
  const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);
  const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

  const sortedFolders = [...photos];
  sortedFolders.splice(draggedFolder.index, 1);
  sortedFolders.splice(newTargetFolder.index, 0, photos[draggedFolder.index]);

  for (let i = 0; i < sortedFolders.length; i += 1) {
    sortedFolders[i].order = i + 1;
  }



  const response = yield call(
    axios.post,
    `${baseUrl}/photo-gallery-folders/reorder/${moduleId}`,
    sortedFolders,
    {
      headers: {
        accept: "application/json",
        token: localStorage.getItem("$token"),
      },
    }
  );

  try {
    // trigger request action
    yield put(sortPhotoFoldersRoutine.request());

    yield put(sortPhotoFoldersRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* sortPhotoFoldersSaga() {
  yield takeLatest([sortPhotoFoldersRoutine.TRIGGER], sortPhotoFolders);
}

function* getImages(action) {
  const { photos, id } = action.payload;

  let selectedFolder = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < photos.length; i++) {
    if (photos[i].id === parseFloat(id)) {
      selectedFolder = photos[i].images;
    }
  }

  try {
    // trigger request action
    yield put(getImagesRoutine.request());

    yield put(getImagesRoutine.success(selectedFolder));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* getImagesSaga() {
  yield takeLatest([getImagesRoutine.TRIGGER], getImages);
}

function* sortImages(action) {
  const { photos, folderId, images, draggedFolderId, newTargetFolderId } = action.payload;

  const folderPositions = images.map((folder, i) => ({ id: folder, index: i }));
  const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);

  const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

  const sortedImages = [...images];
  sortedImages.splice(draggedFolder.index, 1);
  sortedImages.splice(newTargetFolder.index, 0, images[draggedFolder.index]);

  // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < photos.length; i++) {
  //   if (photos[i].id === parseFloat(folderId)) {
  //     photos[i].images = sortedImages;
  //   }
  // }

  try {
    // trigger request action
    yield put(sortImagesRoutine.request());

    yield put(sortImagesRoutine.success(sortedImages));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* sortImagesSaga() {
  yield takeLatest([sortImagesRoutine.TRIGGER], sortImages);
}

function* removePhotoImage(action) {
  const { photos, folderId, id } = action.payload;

  let newImages = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < photos.length; i++) {
    if (photos[i].id === parseFloat(folderId)) {
      // eslint-disable-next-line no-plusplus
      for (let p = 0; p < photos[i].images.length; p++) {
        if (photos[i].images[p].id === parseFloat(id)) {
          photos[i].images.splice(p, 1);
          newImages = photos[i].images;
        }
      }
    }
  }

  console.log("=========", newImages);
  try {
    // trigger request action
    yield put(removePhotoImageRoutine.request());

    yield put(removePhotoImageRoutine.success(newImages));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* removePhotoImageSaga() {
  yield takeLatest([removePhotoImageRoutine.TRIGGER], removePhotoImage);
}
