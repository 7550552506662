/* eslint-disable import/no-unresolved */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
// import data from "pages/viewer/builder/content/modules/floorPlan-gallery/data";
import axios from "axios";
import { baseUrl } from "api";
import getFloorPlansRoutine, {
  addFloorPlanFolderRoutine,
  addFloorPlanPlanRoutine,
  getPlansRoutine,
  removeFloorPlanFolderRoutine,
  removeFloorPlanPlanRoutine,
  sortPlansRoutine,
  sortFloorPlanFoldersRoutine,
  updateFloorPlanFolderRoutine,
} from "./routines";

/**
 * ============================================================
 * @param {Get Services}
 */
function* getFloorPlans(action) {
  const { moduleId } = action.payload;

  try {
    // trigger request action
    yield put(getFloorPlansRoutine.request());
    const response = yield call(axios.get, `${baseUrl}/floor-plans-folders?viewerId=${moduleId}`);

    yield put(getFloorPlansRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

function* foldersSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getFloorPlansRoutine.TRIGGER, getFloorPlans);
}

export default foldersSaga;

function* removeFloorPlanFolder(action) {
  const { floorPlans, id } = action.payload;

  const newFolders = floorPlans.filter((folder) => folder.id !== id);
  try {
    // trigger request action
    yield put(removeFloorPlanFolderRoutine.request());

    yield put(removeFloorPlanFolderRoutine.success(newFolders));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* removeFloorPlanFolderSaga() {
  yield takeLatest([removeFloorPlanFolderRoutine.TRIGGER], removeFloorPlanFolder);
}

function* addFloorPlanFolder(action) {
  const { floorPlans, title } = action.payload;

  const newFolder = {
    title,
    images: [],
    id: floorPlans.length + 2,
    thumbnail: `https://viewlio.b-cdn.net/gallery/floorPlan/${title}.png`,
  };

  try {
    // trigger request action
    yield put(addFloorPlanFolderRoutine.request());

    yield put(addFloorPlanFolderRoutine.success([newFolder, ...floorPlans]));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* addFloorPlanFolderSaga() {
  yield takeLatest([addFloorPlanFolderRoutine.TRIGGER], addFloorPlanFolder);
}

function* addFloorPlanPlan(action) {
  const { floorPlans, values } = action.payload;

  const search = floorPlans.filter((obj) => obj.id === parseFloat(values.id));
  if (search.length > 0) {
    search[0].images.push({
      id: search[0].images.length + 2,
      image: `https://viewlio.b-cdn.net/gallery/floorPlan/${values.uuid}.png`,
    });
  }
  try {
    // trigger request action
    yield put(addFloorPlanPlanRoutine.request());

    yield put(addFloorPlanPlanRoutine.success(floorPlans));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* addFloorPlanPlanSaga() {
  yield takeLatest([addFloorPlanPlanRoutine.TRIGGER], addFloorPlanPlan);
}

function* updateFloorPlanFolder(action) {
  const { floorPlans, values } = action.payload;

  const search = floorPlans.filter((obj) => obj.id === values.id);
  if (search.length > 0) {
    search[0].title = values.title;
  }
  try {
    // trigger request action
    yield put(updateFloorPlanFolderRoutine.request());

    yield put(updateFloorPlanFolderRoutine.success(floorPlans));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* updateFloorPlanFolderSaga() {
  yield takeLatest([updateFloorPlanFolderRoutine.TRIGGER], updateFloorPlanFolder);
}

function* sortFloorPlanFolders(action) {
  const { floorPlans, draggedFolderId, newTargetFolderId, moduleId } = action.payload;

  const folderPositions = floorPlans.map((folder, i) => ({ id: folder.id, index: i }));
  const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);
  const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

  const sortedFolders = [...floorPlans];
  sortedFolders.splice(draggedFolder.index, 1);
  sortedFolders.splice(newTargetFolder.index, 0, floorPlans[draggedFolder.index]);

  for (let i = 0; i < sortedFolders.length; i += 1) {
    sortedFolders[i].order = i + 1;
  }

  const response = yield call(
    axios.post,
    `${baseUrl}/floor-plans-folders/reorder/${moduleId}`,
    sortedFolders,
    {
      headers: {
        accept: "application/json",
        token: localStorage.getItem("$token"),
      },
    }
  );

  try {
    // trigger request action
    yield put(sortFloorPlanFoldersRoutine.request());

    yield put(sortFloorPlanFoldersRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* sortFloorPlanFoldersSaga() {
  yield takeLatest([sortFloorPlanFoldersRoutine.TRIGGER], sortFloorPlanFolders);
}

function* getPlans(action) {
  const { floorPlans, id } = action.payload;

  let selectedFolder = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < floorPlans.length; i++) {
    if (floorPlans[i].id === parseFloat(id)) {
      selectedFolder = floorPlans[i].images;
    }
  }

  try {
    // trigger request action
    yield put(getPlansRoutine.request());

    yield put(getPlansRoutine.success(selectedFolder));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* getPlansSaga() {
  yield takeLatest([getPlansRoutine.TRIGGER], getPlans);
}

function* sortPlans(action) {
  const { floorPlans, folderId, images, draggedFolderId, newTargetFolderId } = action.payload;

  const folderPositions = images.map((folder, i) => ({ id: folder, index: i }));
  const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);

  const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

  const sortedPlans = [...images];
  sortedPlans.splice(draggedFolder.index, 1);
  sortedPlans.splice(newTargetFolder.index, 0, images[draggedFolder.index]);

  // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < floorPlans.length; i++) {
  //   if (floorPlans[i].id === parseFloat(folderId)) {
  //     floorPlans[i].images = sortedPlans;
  //   }
  // }

  try {
    // trigger request action
    yield put(sortPlansRoutine.request());

    yield put(sortPlansRoutine.success(sortedPlans));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* sortPlansSaga() {
  yield takeLatest([sortPlansRoutine.TRIGGER], sortPlans);
}

function* removeFloorPlanPlan(action) {
  const { floorPlans, folderId, id } = action.payload;

  let newPlans = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < floorPlans.length; i++) {
    if (floorPlans[i].id === parseFloat(folderId)) {
      // eslint-disable-next-line no-plusplus
      for (let p = 0; p < floorPlans[i].images.length; p++) {
        if (floorPlans[i].images[p].id === parseFloat(id)) {
          floorPlans[i].images.splice(p, 1);
          newPlans = floorPlans[i].images;
        }
      }
    }
  }

  try {
    // trigger request action
    yield put(removeFloorPlanPlanRoutine.request());

    yield put(removeFloorPlanPlanRoutine.success(newPlans));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* removeFloorPlanPlanSaga() {
  yield takeLatest([removeFloorPlanPlanRoutine.TRIGGER], removeFloorPlanPlan);
}
