/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";

// eslint-disable-next-line import/no-extraneous-dependencies
import { loadStripe } from "@stripe/stripe-js";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Elements } from "@stripe/react-stripe-js";
// eslint-disable-next-line import/no-named-as-default-member
import keys from "data/keys";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(keys.pushibleKey);
// payment_intent_client_secret
// eslint-disable-next-line
function Billing({
  activeStep,
  handleBack,
  paymentStatus,
  setLoadingMessage,
  loading,
  error,
  totalAmount,
  viewerValues,
  viewer,
  plan,
  subscriptionRows,
  submitForm,
  submitValues,
  removeOneviewerLogo,
  setupExtras,
  customerDefaultPaymentMethod,
  loadingMessage,
  errorMessage,
  setErrorMessage,
  setIsLoading,
  isLoading,

  // setError,
  // setActiveStep,
}) {
  // const [clientSecret, setClientSecret] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   setIsLoading(true);
  //   fetch(`${baseUrl}/billing/create-payment-intent`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ totalAmount }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setIsLoading(false);
  //       setClientSecret(data.clientSecret);
  //     })
  //     .catch(async () => {
  //       await query.delete(endpoints.viewers.deleteOne({ id: viewer.id })).then(() => {});
  //       setError("Failed to initiate payment method. Please retry if error persist contact admin");
  //       setActiveStep(0);
  //     });
  // }, []);

  // const appearance = {
  //   theme: "stripe",
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Payment
      </MDTypography>

      <Elements stripe={stripePromise}>
        <CheckoutForm
          activeStep={activeStep}
          handleBack={handleBack}
          loadingMessage={loadingMessage}
          loading={loading}
          viewerError={error}
          viewerValues={viewerValues}
          paymentStatus={paymentStatus}
          setLoadingMessage={setLoadingMessage}
          viewer={viewer}
          // secret={clientSecret}
          totalAmount={totalAmount}
          submitForm={submitForm}
          submitValues={submitValues}
          plan={plan}
          subscriptionRows={subscriptionRows}
          removeOneviewerLogo={removeOneviewerLogo}
          setupExtras={setupExtras}
          customerDefaultPaymentMethod={customerDefaultPaymentMethod}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Elements>
      {/* {isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null} */}
    </MDBox>
  );
}

Billing.propTypes = {};

export default Billing;
