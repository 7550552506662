import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";
import { Link } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import React from "react";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const handleUpdatePassword = () => {
    if (newPassword !== confirmNewPassword) {
      setError("Passwords don't match");
      return;
    }
    if (newPassword === "" || confirmNewPassword === "" || currentPassword === "") {
      setError("Fields cannot be empty");
      return;
    }
    setLoading(true);
    setTimeout(() => {
      query
        .post(endpoints.users.changePassword(), {
          currentPassword,
          newPassword,
          repeatPassword: confirmNewPassword,
        })
        .then(() => {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setError();
          setSuccess(true);
          setTimeout(() => setSuccess(false), 3000);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data.message);
          } else {
            setError("Something went wrong");
          }
        });
      setLoading(false);
    }, 2000);
  };

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3} width="30%">
          <Grid item xs={12}>
            <MDTypography variant="body2" color="error">
              {error}
            </MDTypography>
            <MDTypography variant="body2" color="success">
              {success && "Password updated!"}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDInput
              className="passwordfieldsinmobile"
              fullWidth
              label="Current Password"
              inputProps={{ type: "password", autoComplete: "" }}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              className="passwordfieldsinmobile"
              fullWidth
              label="New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              className="passwordfieldsinmobile"
              fullWidth
              label="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <br />
        <MDBox display="flex" justifyContent="center" flexWrap="wrap">
          <center>
            <MDBox ml="auto">
              <MDButton
                color="dark"
                size="small"
                component={Link}
                disabled={loading}
                onClick={handleUpdatePassword}
              >
                <span className="text-white">{loading ? "Updating..." : "UPDATE"}</span>
              </MDButton>
            </MDBox>
          </center>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
