import * as Yup from "yup";
import form from "./form";

const {
  formField: { firstName, lastName, email, title, phoneNumber, linkedInProfile, locationName },
} = form;

const validations = [
  Yup.object().shape({
    [locationName.name]: Yup.string()
      .required(locationName.errorMsg)
      .matches(/^[aA-zZ0-9_/-]+$/, "Please use only text - no special characters!"),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string(),
    [lastName.name]: Yup.string(),
    [email.name]: Yup.string().email(email.invalidMsg),
    [title.name]: Yup.string(),
    [phoneNumber.name]: Yup.string(),
    [linkedInProfile.name]: Yup.string(),
  }),
];

export default validations;
