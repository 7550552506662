import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import PropTypes from "prop-types";

function Terms({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { title, companyName, companyWebsiteUrl } = formField;
  const {
    title: titleV,
    companyName: companyNameV,
    companyWebsiteUrl: companyWebsiteUrlV,
  } = values;

  return (
    <MDBox>
      <center>
        {" "}
        <MDTypography variant="h4" fontWeight="bold" color="text">
          Welcome to Viewlio!
        </MDTypography>
      </center>
      <MDBox mt={1.625}>
        <MDTypography variant="h6" color="text">
          No purchase is necessary to sign up and create an Account.{" "}
          <a
            href="https://www.viewlio.com/terms"
            target="_blank"
            style={{ color: "#051d3a" }}
            rel="noreferrer"
          >
            The 14-day free trial
          </a>{" "}
          The 14-day free trial applies to creating your first Gallery. A credit card will be
          required to start the free trial period for the Gallery you create, and if you do not
          cancel your subscription before the trial period is over, you will be billed automatically
          for the monthly or annual subscription you choose. You can easily cancel your subscription
          for any Gallery you&apos;ve created anytime, and also delete and completely remove your
          Account, all within your Account Dashboard.
        </MDTypography>
        <br />
        <MDTypography variant="h6" color="text">
          By submitting and creating an account, you accept the{" "}
          <a
            href="https://www.viewlio.com/terms"
            target="_blank"
            style={{ color: "#051d3a" }}
            rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and acknowledge reading the{" "}
          <a
            href="https://www.viewlio.com/privacy"
            target="_blank"
            style={{ color: "#051d3a" }}
            rel="noreferrer"
          >
            Privacy Policy.
          </a>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Terms.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Terms;
