/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Radio, RadioGroup } from "@mui/material";
import SwitchToggle from "components/switch";
import { subscriptionsData } from "data";

function Extras({
  removeOneviewerLogo,
  setRemoveOneviewerLogo,
  setupExtras,
  setSetupExtras,
  setPlan,
  plan,
}) {
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Subscription Plan
      </MDTypography>
      <MDBox>
        <div className="flex flex-col gap-y-2 justify-center">
          <div className="flex items-center justify-center gap-x-3">
            <h1 className="text-sm text-gray-500 mr-3">Monthly</h1>
            <SwitchToggle
              initialState
              checked={plan === "yearly"}
              setChecked={() => setPlan(plan === "monthly" ? "yearly" : "monthly")}
            />
            <h1 className="text-sm text-gray-500">Annually</h1>
          </div>
          <div className="text-center text-md">
            {plan === "monthly"
              ? `$${subscriptionsData.monthlyPlan.amount} Monthly`
              : `$${subscriptionsData.yearlyPlan.amount} Annually`}
          </div>
          {plan === "yearly" ? (
            <div className="text-sm text-center text-gray-500">
              If you pay annually, you receive 2 months free. A savings of $198/year!
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
        <div className="text-sm text-center text-gray-500">
          Billed automatically after 14-day free trial. Cancel anytime.
        </div>
      </MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Extras
      </MDTypography>
      <MDBox mt={1.625}>
        <div className="mb-7">
          <MDTypography variant="h6" fontWeight="" className="uppercase font-[600] text-[14px]">
            Setup support
          </MDTypography>
          <RadioGroup value={setupExtras} onChange={(e) => setSetupExtras(e.target.value)}>
            <div className="flex gap-x-2 items-center">
              <Radio value="none" />
              <h1 className="text-sm text-gray-500">None</h1>
            </div>
            <div className="flex gap-x-2 items-center">
              <Radio value="setupInstruction" />
              <h1 className="text-sm text-gray-500">
                Concierge 1-Hour Setup Walkthrough Instruction via Zoom + $
                {subscriptionsData.setupWalkthrough.amount}
              </h1>
            </div>
            <div className="flex gap-x-2 items-center">
              <Radio value="completeViewer" />
              <h1 className="text-sm text-gray-500">
                Concierge Complete Gallery Built for You + $
                {subscriptionsData.completeViewer.amount}
              </h1>
            </div>
          </RadioGroup>
          <h1 className="text-sm text-gray-500">
            If one of these options is selected, we will reach out to you to schedule a Zoom
            meeting.
            <br />
            <span className="text-sm">
              These extra services are not included in the 14-day free trial, and are immediately
              billed upon purchase. You can also choose to purchase these services at a later date.
            </span>
          </h1>
        </div>
        <div>
          <MDTypography variant="h6" fontWeight="" className="uppercase font-[600] text-[14px]">
            Logo Removal
          </MDTypography>
          <div className="flex gap-x-2 items-center">
            <SwitchToggle
              initialState
              checked={removeOneviewerLogo}
              setChecked={setRemoveOneviewerLogo}
            />
            <h1 className="text-sm text-gray-500">
              Remove Viewlio Logo from Gallery Menu + ${subscriptionsData.removeLogoMontlhy.amount}
              /month or ${subscriptionsData.removeLogoYearly.amount}/year (depending on your
              subscription)
            </h1>
          </div>
        </div>
      </MDBox>
    </MDBox>
  );
}

Extras.propTypes = {};

export default Extras;
