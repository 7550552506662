/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
import MDButton from "@core/components/MDButton";
import { Slider } from "@mui/material";
import ImageCropper from "components/image-cropper";
import Modal from "components/modals/modal";
import { filePickButtonMaxWidth } from "constants/variables";
import { useCallback, useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import getCroppedImg from "./cropImage";

function ProfileCropper({ profilePicture, setProfilePicture, text, serverImage }) {
  const [cropperModal, setCropperModal] = useState(false);
  const [filepickEvent, setFilepickEvent] = useState();
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image, setImage] = useState("");

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      setCroppedImage(croppedImage);
      setProfilePicture(croppedImage);
      setCropperModal(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  useEffect(() => {
    setCroppedImage(serverImage);
  }, [serverImage]);
  return (
    <div className="flex space-x-5 justify-center 2xl:w-1/4 mx-auto max-w-[300px] relative agentpicstyle">
      <Modal
        open={cropperModal}
        setOpen={() => {
          setCropperModal(true);
        }}
        content={
          <ImageCropper
            file={profilePicture}
            setFile={setProfilePicture}
            setCropperModal={setCropperModal}
            filepickEvent={filepickEvent}
            setFilepickEvent={setFilepickEvent}
            zoom={zoom}
            setImage={setImage}
            image={image}
            type="profile"
            setZoom={setZoom}
            setCroppedAreaPixels={setCroppedAreaPixels}
            style={{ borderRadius: "50%" }}
          />
        }
        buttons={
          <div style={{ padding: "12px", backgroundColor: "white" }}>
            <Slider
              defaultValue={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
            />
            <MDButton className="w-full mt-5" color="info" onClick={showCroppedImage}>
              Crop Image
            </MDButton>
            <MDButton
              className="w-full mt-5"
              color="light"
              onClick={() => {
                setCropperModal(false);
              }}
            >
              Cancel
            </MDButton>
          </div>
        }
      />

      {!croppedImage ? (
        <div className="flex justify-center items-center w-full specificwithd">
          <label
            htmlFor="imageProfileCropperProfile"
            className="flex flex-col justify-center items-center w-full h-10 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-200"
            style={{ maxWidth: filePickButtonMaxWidth }}
          >
            <div className="flex flex-col justify-center items-center">
              <p className="mb-0 text-sm text-gray-500 dark:text-gray-400 font-semibold">
                {croppedImage !== "" && croppedImage ? "" : "Upload Photo" || "Upload Photo"}
              </p>
            </div>

            <input
              id="imageProfileCropperProfile"
              onChange={(e) => {
                setFilepickEvent(e);
                setCropperModal(true);
              }}
              type="file"
              accept="image/*"
              hidden
            />
          </label>
        </div>
      ) : (
        ""
      )}
      {croppedImage !== "" && croppedImage && (
        <img style={{ borderRadius: "50%", maxheight: "140px" }} src={croppedImage} alt="Cropped" />
      )}
      {croppedImage !== "" && croppedImage ? (
        <label className="flex flex-col justify-center items-center w-fit h-10 rounded-lg bg-red-500 text-white hover:bg-red-600 cursor-pointer absolute bottom-2 right-2">
          <div className="flex flex-col justify-center items-center">
            <p className="mb-0 text-white text-lg font-semibold p-2 px-3">
              <FiTrash2
                onClick={() => {
                  setCroppedImage(null);
                }}
              />
            </p>
          </div>
        </label>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProfileCropper;
