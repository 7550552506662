import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { FormControl, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import countries from "data/countries";
import PropTypes from "prop-types";
import FormField from "../form-field";

function CompanyInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { title, companyName, companyWebsiteUrl } = formField;
  const {
    title: titleV,
    companyName: companyNameV,
    companyWebsiteUrl: companyWebsiteUrlV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1} mb={1} textAlign="center">
        <MDTypography variant="h5" color="text">
          Sign up for a free 14-day trial to create your first Gallery!
        </MDTypography>
        <MDTypography variant="h6" color="text">
          Cancel easily anytime with the simple click of a button.
        </MDTypography>
      </MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Company Info
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <FormField
              type={title.type}
              label={title.label}
              name={title.name}
              value={titleV}
              placeholder={title.placeholder}
              error={errors.title && touched.title}
              success={titleV.length > 0 && !errors.title}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormField
              type={companyName.type}
              label={companyName.label}
              name={companyName.name}
              value={companyNameV}
              placeholder={companyName.placeholder}
              error={errors.companyName && touched.companyName}
              success={companyNameV.length > 0 && !errors.companyName}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormField
              type={companyWebsiteUrl.type}
              label={companyWebsiteUrl.label}
              name={companyWebsiteUrl.name}
              value={companyWebsiteUrlV}
              placeholder={companyWebsiteUrl.placeholder}
              error={errors.companyWebsiteUrl && touched.companyWebsiteUrl}
              success={companyWebsiteUrlV.length > 0 && !errors.companyWebsiteUrl}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl
              variant="standard"
              style={{
                marginTop: "7px",
              }}
              fullWidth
            >
              <Select
                labelId="country-select-label"
                id="country-select"
                // value={selectedCountry}
                label="Select Country"
                native
                inputProps={{
                  name: "type",
                  id: "uncontrolled-native",
                }}
                style={{
                  height: "40px",
                  color: "#052D3A",
                }}
                onChange={(e) => {
                  // setCountry(e.target.value);
                }}
              >
                <option value="">Select Country</option>
                {countries.map((option) => (
                  <option value={option.name} key={option.name}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

CompanyInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CompanyInfo;
