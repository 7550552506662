/* eslint-disable import/no-unresolved */
import photosSaga, {
  removePhotoFolderSaga,
  addPhotoImageSaga,
  addPhotoFolderSaga,
  updatePhotoFolderSaga,
  sortPhotoFoldersSaga,
  getImagesSaga,
  sortImagesSaga,
  removePhotoImageSaga,
} from "utils/accessor/folders/photos/saga";

import floorPlansSaga, {
  removeFloorPlanFolderSaga,
  addFloorPlanPlanSaga,
  addFloorPlanFolderSaga,
  updateFloorPlanFolderSaga,
  sortFloorPlanFoldersSaga,
  getPlansSaga,
  sortPlansSaga,
  removeFloorPlanPlanSaga,
} from "utils/accessor/folders/floor-plans/saga";

import videosSaga, {
  removeVideoFolderSaga,
  addVideoFolderSaga,
  sortVideoFoldersSaga,
} from "utils/accessor/folders/videos/saga";

const sagas = [
  photosSaga,
  sortPhotoFoldersSaga,
  removePhotoFolderSaga,
  addPhotoImageSaga,
  addPhotoFolderSaga,
  addFloorPlanPlanSaga,
  addFloorPlanFolderSaga,
  updatePhotoFolderSaga,
  getImagesSaga,
  sortImagesSaga,
  removePhotoImageSaga,
  videosSaga,
  removeFloorPlanPlanSaga,
  floorPlansSaga,
  removeVideoFolderSaga,
  addVideoFolderSaga,
  sortVideoFoldersSaga,
  removeFloorPlanFolderSaga,
  updateFloorPlanFolderSaga,
  sortFloorPlanFoldersSaga,
  getPlansSaga,
  sortPlansSaga,
];
export default function registerSagaWithMiddleware(middleware) {
  sagas?.forEach((saga) => middleware.run(saga));
}
