import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { endpoints, query } from "api";
import { useEffect, useState } from "react";

function PlatformSettings({ user, setUser }) {
  const [submitsForm, setSubmitsForm] = useState(true);
  const [newsletter, setNewsletter] = useState(false);

  useEffect(() => {
    setSubmitsForm(user.emailMeWhenSomeoneSubmitsForm);
    setNewsletter(user.subscribedToNewsletter);
  }, [user]);

  const handleSubmitsForm = () => {
    setSubmitsForm(!submitsForm);
    query.patch(endpoints.users.updateOne({ id: user.id }), { emailMeWhenSomeoneSubmitsForm: !submitsForm })
      .then((res) => {
        setUser(res.data);
      });
  };

  const handleNewsletter = () => {
    setNewsletter(!newsletter);
    query.patch(endpoints.users.updateOne({ id: user.id }), { subscribedToNewsletter: !newsletter })
      .then((res) => {
        setUser(res.data);
      });
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </MDTypography>
      </MDBox>
      <MDBox pt={0} pb={2} px={2} lineHeight={1.25}>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={submitsForm} onChange={handleSubmitsForm} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when someone submits form
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={newsletter} onChange={handleNewsletter} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
