// @react-jvectormap components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Card, Table } from "@mui/material";
import AnalyticsDataTable from "components/analytics-datatable";
import NoAnalyticsData from "components/no-analytics-data";

function TimeAndBounceOnModule({ data, isLoading }) {
  return (
    <Card sx={{ width: "100%", height: "25.8rem" }}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={1} pt={1}>
          <MDBox style={{ color: "#051D3A" }}>
            <MDTypography variant="h6">Traffic Source</MDTypography>
          </MDBox>
        </MDBox>
        {/* <div style={{ maxHeight: "18rem", overflowY: "auto" }}> */}
        <div>
          <Table sx={{ minWidth: "32rem" }}>
            <MDBox component="thead">
              {data.length > 0 && !isLoading ? (
                <AnalyticsDataTable
                  theme="solarized"
                  columns={[
                    {
                      name: "Source",
                      selector: (row) => row.source,
                      sortable: true,
                    },
                    {
                      name: "Type",
                      selector: (row) => row.type,
                      sortable: true,
                    },
                    {
                      name: "Count",
                      selector: (row) => row.visits,
                      sortable: true,
                    },
                  ]}
                  data={data.map((item) => {
                    const parts = item?.sourceMedium?.split("/");

                    const source = parts[0];
                    const type = parts[1] || null;

                    return {
                      ...item,
                      source,
                      type,
                    };
                  })}
                />
              ) : (
                <NoAnalyticsData isLoading={isLoading} />
              )}
            </MDBox>
          </Table>
        </div>
      </MDBox>
    </Card>
  );
}

export default TimeAndBounceOnModule;
