/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Button, CircularProgress, Link, Modal } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { baseUrl, endpoints, query } from "api";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "routes/paths";

function BasicInfo() {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellPhone] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [loading, setLoading] = useState(false);

  const getUserDetails = () => {
    setIsLoading(false);
    query
      .get(`/users/${localStorage.getItem("$id")}`)
      .then((res) => {
        setUser(res.data);
        setfullName(res.data.fullName);
        setEmail(res.data.email);
        setTitle(res.data.title);
        setCellPhone(res.data.cellphone);
        setCompany(res.data.companyName);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data) {
          console.error("error fetching current user");
        } else {
          console.warn("refreshed in the middle of a request");
        }
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Card id="general" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Admin</MDTypography>
      </MDBox>
      {isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null}

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Full Name"
              value={fullName}
              onChange={(event) => {
                setfullName(event.target.value);
              }}
              // placeholder="Alec"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              // placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              value={email}
              // placeholder="example@email.com"
              inputProps={{ type: "email" }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              // placeholder="+40 735 631 620"
              // inputProps={{ type: "number" }}
              value={cellphone}
              onChange={(event) => {
                setCellPhone(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Company"
              value={company}
              onChange={(event) => {
                setCompany(event.target.value);
              }}
              // placeholder=""
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4.5} />
          <Grid item xs={4}>
            <MDBox
              ml="auto"
              mt={3}
              display="flex"
              justify-content="center"
              className="customgap these-buttons-temp-style-for-mobile"
            >
              <MDButton
                className="nowrapalways"
                onClick={openModal}
                color="error"
                size="medium"
                component={Link}
              >
                delete account
              </MDButton>
              <MDButton
                onClick={() => {
                  if (
                    fullName === "" ||
                    email === "" ||
                    cellphone === "" ||
                    title === "" ||
                    company === ""
                  ) {
                    error("Fields cannot be empty");
                    return;
                  }
                  setIsLoading(true);
                  query
                    .patch(`/users/${localStorage.getItem("$id")}`, {
                      fullName,
                      email,
                      cellphone,
                      title,
                      companyName: company,
                      // change user role
                      // role
                      // role: "superadmin"
                    })
                    .then((res) => {
                      success("Updated successfully");
                      setIsLoading(false);
                      getUserDetails();
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      error("Failed to update");
                    });
                }}
                color="dark"
                size="medium"
                component={Link}
              >
                update
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            bgcolor: "background.paper",
            // width: "40%",
            p: 4,
          }}
          className="transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <MDBox mb={1}>
            <MDTypography variant="h6">
              Are you sure? This will completely delete your Admin user account and all Galleries
              you have created unless you have transferred them all to another Admin! There is no
              way to retrieve a deleted Admin account and the associated Galleries if they are not
              assigned to a new Admin. Contact{" "}
              <MDTypography
                component={Link}
                href="https://www.viewlio.com/support"
                variant="a"
                color="info"
                fontWeight="medium"
                cursor="pointer"
                target="_blank"
              >
                Support
              </MDTypography>{" "}
              to have Galleries transferred to another Admin.
            </MDTypography>
          </MDBox>

          {error && (
            <MDBox mb={1}>
              <MDTypography variant="body1" color="error" style={{ fontSize: "15px" }}>
                {error}
              </MDTypography>
            </MDBox>
          )}

          <MDBox
            mt={2}
            display="flex"
            width="100%"
            className="w-full centeraddvideobutton"
            gap="8px"
          >
            <Button
              className="w-1/2 m-auto text-white"
              style={{ backgroundColor: "#245C84" }}
              variant="contained"
              color="secondary"
              onClick={closeModal}
              sx={{
                color: "#fff",
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-1/2 m-auto text-white"
              variant="contained"
              color="primary"
              style={{ backgroundColor: "red" }}
              onClick={() => {
                setLoading(true);
                query
                  .delete(`/users/${localStorage.getItem("$id")}`)
                  .then(() => {
                    query.get(`${endpoints.viewers.getMine()}`).then((res) => {
                      let count = 0;
                      for (let i = 0; i <= res.data.length; i++) {
                        try {
                          query.patch(endpoints.viewers.updateOne({ id: res.data[i].id }), {
                            isActive: false,
                            status: "9",
                          });

                          fetch(`${baseUrl}/billing/subscriptions/viewer/${res.data[i].id}`, {
                            method: "GET",
                            headers: { "Content-Type": "application/json" },
                          })
                            .then((reso) => reso.json())
                            .then((data) => {
                              fetch(
                                `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.paynentId}`,
                                {
                                  method: "GET",
                                  headers: { "Content-Type": "application/json" },
                                }
                              )
                                .then((resp) => resp.json())
                                .then(() => {})
                                .catch(() => {});
                              if (data?.subscriptions[0]?.removeLogoSubscriptionId) {
                                fetch(
                                  `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.removeLogoSubscriptionId}`,
                                  {
                                    method: "GET",
                                    headers: { "Content-Type": "application/json" },
                                  }
                                )
                                  .then((respo) => respo.json())
                                  .then(() => {})
                                  .catch(() => {});
                              }
                            })
                            .catch(() => {});
                          count += 1;
                        } catch (e) {
                        }
                      }
                      if (count === res.data.length) {
                        setLoading(false);
                        navigate(PATHS.signUp);
                      }
                    });
                  })
                  .catch((err) => {
                    setLoading(false);
                    error("Failed to delete your account");
                  });
              }}
              sx={{
                color: "#fff",
                backgroundColor: "red",
                marginRight: "8px",
              }}
              disabled={loading}
            >
              {loading ? "Deleting..." : "Delete"}
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
    </Card>
  );
}

export default BasicInfo;
