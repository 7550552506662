import { z } from "zod";

export const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  title: z.string(),
  email: z.string().email("Please enter a valid email address."),
  phone: z.string(),
  linkedIn: z.string(),
});
