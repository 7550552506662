// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";

// Authentication layout components

// Images
import bgImage from "@core/assets/images/bg-sign-in-basic.jpeg";
import MDInput from "@core/components/MDInput";
import { endpoints, query } from "api";
import GuestLayout from "layouts/guest-layout";
import { useState } from "react";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [sending, setSending] = useState(false);
  const sendCode = () => {
    setError();
    setSuccess();
    setSending(true);
    query
      .get(endpoints.users.forgotPassword({}, { email }))
      .then(() => {
        setSuccess(true);
        setSending(false);
      })
      .catch((err) => {
        setSuccess(false);
        setSending(false);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError("Unknown error occurred");
        }
      });
  };

  return (
    <GuestLayout image={bgImage} isSignIn>
      <Card sx={{ maxWidth: "400px", mx: "auto" }}>
        <MDBox
          borderRadius="lg"
          coloredShadow="info"
          bgColor="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} textAlign="center">
              <MDTypography variant="button" color="text">
                Enter your email address and we&apos;ll send you a verification code.
              </MDTypography>
            </MDBox>
            {error || success ? (
              <MDBox mb={2} textAlign="center">
                {error ? (
                  <MDTypography variant="button" color="error">
                    {error}
                  </MDTypography>
                ) : (
                  ""
                )}
                {success ? (
                  <MDTypography variant="button" color="success">
                    Check your email
                  </MDTypography>
                ) : (
                  ""
                )}
              </MDBox>
            ) : (
              ""
            )}
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1} className="flex items-center justify-center">
              <MDButton color="info" disabled={sending} onClick={sendCode}>
                {sending ? "Sending..." : "Send verification code"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Received code?{" "}
                <MDTypography
                  component={Link}
                  to="/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Reset password
                </MDTypography>
              </MDTypography>
              <br />
              <MDTypography variant="button" color="text">
                Dont have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign Up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </GuestLayout>
  );
}

export default ForgotPassword;
