/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";
import { CircularProgress, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { usePhotoGallery } from "contexts/photo-gallery";
import { useState } from "react";
import heic2any from "heic2any";
import { isHeicFile } from "utils/isHeicFile";
import { blobToFile } from "utils/convertHeic2Jpg";
import { useSnackbar } from "notistack";
import { isFileAllowed } from "utils/isFileAllowed";

export default function Header({ addFolder }) {
  const [isAddFolderVisible, setIsAddFolderVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [buttonText, setButtonText] = useState("create");
  const [submitted, setSubmitted] = useState(false);
  const [titleIsEmpty, setTitleIsEmpty] = useState(false);
  const [base64File, setBase64File] = useState(false);
  // const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const { creatingFolder: isLoading } = usePhotoGallery();
  // const [color, setColor] = useState("#000000");
  const { enqueueSnackbar } = useSnackbar();

  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });
  const handleSubmit = async () => {
    setSubmitted(true);
    if (title.trim() === "") {
      setTitleIsEmpty(true);
      return;
    }
    if (isFileAllowed(base64File)) {
      if (isHeicFile(base64File)) {
        setButtonText("Processing file...");
        await heic2any({
          blob: base64File,
          toType: "image/jpeg",
          quality: 0.7,
        }).then(
          (blob) => {
            const fileName = "viewlio.txt";
            const mimeType = "text/plain";
            const newFile = blobToFile(blob, fileName, mimeType);
            setIsAddFolderVisible(false);
            setButtonText("create");
            addFolder(title, newFile, {});
          },
          () => {
            error("Failed to process your file");
          }
        );
      } else {
        setIsAddFolderVisible(false);
        console.log("file is not");
        addFolder(title, base64File, {});
      }
    } else {
      error("File type not allowed");
    }
  };

  const handleTitleChange = (event) => {
    const value = event.target.value;

    if (submitted) setSubmitted(false);

    if (value.trim() !== "") setTitleIsEmpty(false);

    setTitle(value);
  };

  return (
    <Grid item xs={12} lg={12}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="centeraddvideobutton">
            <MDBox pl={1} className="flex gap-3">
              {isAddFolderVisible ? (
                <MDButton
                  onClick={() => {
                    setIsAddFolderVisible(false);
                  }}
                  color="info"
                  variant="outlined"
                >
                  Close
                </MDButton>
              ) : (
                <>
                  {/* <Tooltip title="Add Floor Plan">
                    <MDButton
                      onClick={() => {
                        if (isAddFolderVisible) {
                          setIsAddFolderVisible(false);
                        } else {
                          setIsAddFolderVisible(true);
                        }
                      }}
                      color="info"
                      variant="outlined"
                    >
                      {isAddFolderVisible ? "Close" : "Add Floor Plans"}
                    </MDButton>
                  </Tooltip> */}
                  <Tooltip title="Add Folder">
                    <MDButton
                      onClick={() => {
                        if (isAddFolderVisible) {
                          setIsAddFolderVisible(false);
                        } else {
                          setIsAddFolderVisible(true);
                        }
                      }}
                      color="info"
                      variant="outlined"
                    >
                      {isAddFolderVisible ? "Close" : "Add Folder"}
                    </MDButton>
                  </Tooltip>
                </>
              )}
              {isLoading ? (
                <>
                  <br /> <br /> <CircularProgress color="info" />
                </>
              ) : null}
            </MDBox>
          </Grid>
          {isAddFolderVisible ? (
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5">Create Folder </MDTypography>
                </MDBox>
                <MDBox component="form" pb={3} px={3}>
                  <Grid container p={1} spacing={3}>
                    <Grid item xs={12}>
                      <MDInput
                        error={titleIsEmpty && submitted}
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        label="Title"
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <MDBox mb={0.5} ml={-1.5} className="flex gap-x-2 justify-start">
                        <MDBox mt={0.5}>
                          <Switch
                            // checked={edits?.displayInLeftMenu}
                            // onChange={handleDisplayInLeftMenu}
                          />
                        </MDBox>
                        <MDBox width="80%" ml={0.5} className="flex flex-grow items-center">
                          <MDTypography variant="button" fontWeight="regular" color="text">
                            Show as Submenu?
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid> */}
                  </Grid>

                  {/* <Grid container p={2} spacing={3}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel component="legend">Thumbnail Settings</FormLabel>
                        <FormControlLabel label="Use Image Borders" control={<Switch />} />
                        <FormControlLabel
                          label="Set border color"
                          control={
                            <Switch
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setColorPickerVisible(true);
                                } else {
                                  setColorPickerVisible(false);
                                }
                              }}
                            />
                          }
                        />
                        {colorPickerVisible ? (
                          <ColorPicker
                            name="color"
                            defaultValue={color}
                            // value={this.state.color} - for controlled component
                            onChange={(_color) => {
                              setColor(_color);
                            }}
                          />
                        ) : null}
                        <FormControlLabel label="Set round corners" control={<Switch />} />
                      </FormGroup>
                    </Grid>
                  </Grid> */}
                  <Grid container p={2} spacing={3}>
                    <Grid item xs={12}>
                      {/* <MDDropzone options={{ addRemoveLinks: true }} label="Thumbnail" />
                       */}
                      <div className="flex flex-col gap-y-2">
                        <div className="text-sm">Upload a photo for the folder cover image.</div>
                        <div className="file-input-container">
                          <input
                            type="file"
                            name="submit"
                            id="fileInput"
                            accept=".heic, .png, .jpg, .jpeg"
                            className="text-sm md:text-md cheatcode"
                            onChange={(e) => {
                              setBase64File(e.target.files[0]);
                            }}
                          />
                          <label htmlFor="fileInput" className="custom-file-label">
                            {" "}
                            Upload File{" "}
                          </label>
                        </div>
                      </div>
                      {/* <FileBase64
                        type="file"
                        multiple={false}
                        onDone={(e) => 
                          setBase64File(e.base64.replace(";base64", `;name=${e.name};base64`))
                        }
                      /> */}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" justifyContent="flex-end" className="centeraddvideobutton">
                    <MDButton
                      onClick={handleSubmit}
                      color="info"
                      disabled={buttonText !== "create"}
                    >
                      {buttonText}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </MDBox>
    </Grid>
  );
}
