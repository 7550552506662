/* eslint-disable import/no-unresolved */
import profilePicture from "@core/assets/images/avatar.jpg";
import MDAvatar from "@core/components/MDAvatar";
import Dashboard from "pages/dashboard";
import ForgotPassword from "pages/forgot-password";
import ResetPassword from "pages/reset-password";
import SetPassword from "pages/set-password";
import SignIn from "pages/sign-in";
import SignUp from "pages/sign-up";
import SupportContactUs from "pages/support/contactUs";
import SupportFAQS from "pages/support/faqs";
import SupportTutorials from "pages/support/tutorials";
import UserProfile from "pages/user/profile-overview";
import UserSettings from "pages/user/settings";
import VerifyEmail from "pages/verify-email";
import Viewer from "pages/viewer";
import Viewers from "pages/viewers";
import NewViewer from "pages/viewers/new-viewer";
import SampleViewer from "pages/viewers/sample";
import { FiFilePlus, FiHome, FiList, FiLogOut, FiSettings, FiTool } from "react-icons/fi";
import PATHS from "routes/paths";

const routes = [
  {
    type: "collapse",
    name: () => "All Galleries",
    key: "all-galerries",
    route: PATHS.viewers,
    icon: () => <FiList className="stroke-1" size={25} />,
    component: <Viewers />,
    noCollapse: true,
    visibleOnSidebar: true,
  },
  { type: "divider", key: "divider-0", visibleOnSidebar: false },
  {
    type: "collapse",
    name: () => "Analytics",
    key: "dashboard",
    route: PATHS.dashboard,
    icon: () => <FiHome className="stroke-1" size={25} />,
    component: <Dashboard />,
    noCollapse: true,
    visibleOnSidebar: false,
  },
  { type: "divider", key: "divider-1", visibleOnSidebar: false },
  {
    type: "collapse",
    name: () => "Create Gallery",
    key: "create-viewer",
    route: PATHS.createViewer,
    icon: () => <FiFilePlus className="stroke-1" size={25} />,
    component: <NewViewer />,
    noCollapse: true,
    visibleOnSidebar: true,
  },
  { type: "divider", key: "divider-2", visibleOnSidebar: true },
  {
    name: () => "Account",
    type: "collapse",
    key: "profile-settings",
    route: PATHS.userSettings,
    component: <UserSettings />,
    icon: () => <FiSettings className="stroke-1" size={25} />,
    visibleOnSidebar: true,
    noCollapse: true,
  },
  // {
  //   name: () => "Billing",
  //   key: "user-billing",
  //   type: "collapse",
  //   route: PATHS.userBilling,
  //   component: <UserBilling />,
  //   visibleOnSidebar: true,
  //   icon: () => <MdPayment size={25} />,
  //   noCollapse: true,
  // },
  { type: "divider", key: "divider-3", visibleOnSidebar: true },
  {
    type: "collapse",
    name: () => "Support",
    key: "support-menu",
    icon: () => <FiTool className="stroke-1" size={25} />,
    visibleOnSidebar: true,
    collapse: [
      {
        name: () => "Tutorials",
        key: "tutorials",
        route: PATHS.supportTutorials,
        component: <SupportTutorials />,
        visibleOnSidebar: true,
      },
      {
        name: () => "FAQ's",
        key: "faqs",
        route: PATHS.supportFAQs,
        component: <SupportFAQS />,
        visibleOnSidebar: true,
      },
      {
        name: () => "Contact Us",
        key: "contact",
        route: "support",
        component: <SupportContactUs />,
        visibleOnSidebar: true,
      },
    ],
  },
  { type: "divider", key: "divider-4", visibleOnSidebar: true },
  {
    name: () => "Logout",
    key: "logout",
    type: "collapse",
    route: PATHS.signIn,
    component: <SignIn />,
    visibleOnSidebar: true,
    icon: () => <FiLogOut className="stroke-1" size={25} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: () => localStorage.getItem("$fullName"),
    key: "brooklyn-alice",
    icon: () => <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    visibleOnSidebar: false,
    collapse: [
      {
        name: () => "My Profile",
        key: "user-profile",
        route: PATHS.userProfile,
        component: <UserProfile />,
        visibleOnSidebar: true,
      },
      {
        name: () => "Logout",
        key: "logout",
        route: "/logout",
        component: <SignIn />,
      },
      {
        name: () => "Sign In",
        key: "sign-in",
        route: "/sign-in",
        component: <SignIn />,
      },
      {
        name: () => "Sign Up",
        key: "sign-up",
        route: "/sign-up",
        component: <SignUp />,
      },
      {
        name: () => "Set Password",
        key: "set-password",
        route: "/set-password",
        component: <SetPassword />,
      },
      {
        name: () => "Verify Email",
        key: "verify-email",
        route: "/verify-email",
        component: <VerifyEmail />,
      },
      {
        name: () => "Forgot Password",
        key: "forgot-password",
        route: "/forgot-password",
        component: <ForgotPassword />,
      },
      {
        name: () => "Reset Password",
        key: "reset-password",
        route: "/reset-password",
        component: <ResetPassword />,
      },
    ],
  },

  // Page Routes
  {
    key: "viewer",
    route: PATHS.viewer,
    // component: <ViewerBuilder />,
    component: <Viewer />,
  },

  {
    key: "sample-viewer",
    route: PATHS.sampleViewer,
    component: <SampleViewer />,
  },

  // Module Types
  // {
  //   key: "pdf-viewer",
  //   route: PATHS.pdfViewer,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "overview",
  //   route: PATHS.overview,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "instagram",
  //   route: PATHS.instagram,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "code-embed",
  //   route: PATHS.codeEmbed,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "photo-gallery",
  //   route: PATHS.photoGallery,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "photo-library",
  //   route: `${PATHS.photoLibrary}/:folderId`,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "video-gallery",
  //   route: PATHS.videoGallery,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "overview-module",
  //   route: PATHS.overviewModule,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "models",
  //   route: PATHS.models,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "staging-options",
  //   route: PATHS.stagingOptions,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "maps-and-directions",
  //   route: PATHS.mapsAndDirections,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "event-enquiries",
  //   route: PATHS.eventEnquiryForm,
  //   component: <ViewerBuilder />,
  // },
  // {
  //   key: "viewer-settings",
  //   route: PATHS.viewerSettings,
  //   component: <ViewerSettings />,
  // },
];

export default routes;
