/* eslint-disable import/no-unresolved */
import MDTypography from "@core/components/MDTypography";
import Card from "@mui/material/Card";
import { AgentInfoForm } from "./form";

function AgentInfo(props) {
  return (
    <Card id="agent-info" sx={{ overflow: "visible" }} className="rounded-lg">
      <div className="p-6">
        <MDTypography variant="h5">Agent</MDTypography>
      </div>
      <div className="p-6 pt-0">
        <AgentInfoForm {...props} />
      </div>
    </Card>
  );
}

export default AgentInfo;
