const formatUnixDate = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};
export default formatUnixDate;

export const processDates = (dates, returnType) => {
  if (returnType === "month") {
    return dates.map((date) => {
      const month = date.slice(4, 6);
      switch (month) {
        case "01":
          return "January";
        case "02":
          return "February";
        case "03":
          return "March";
        case "04":
          return "April";
        case "05":
          return "May";
        case "06":
          return "June";
        case "07":
          return "July";
        case "08":
          return "August";
        case "09":
          return "September";
        case "10":
          return "October";
        case "11":
          return "November";
        case "12":
          return "December";
        default:
          return "Invalid Month";
      }
    });
  }
  if (returnType === "day") {
    return dates.map((date) => {
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6, 8);
      return `${year}/${month}/${day}`;
    });
  }
  if (returnType === "year") {
    return dates.map((date) => date);
  }
  console.error("Invalid returnType. Use 'month' or 'day'.");
  return null;
};
