/* eslint-disable import/order */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-unresolved */
import BasicModal from "components/modals/modal";
import CardModal from "../card-modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import keys from "data/keys";

const stripePromise = loadStripe(keys.pushibleKey);

export default function EditCardModal({ open, setOpen, fetch, customer, getSubscriptionDetails }) {
  return (
    <Elements stripe={stripePromise}>
      <BasicModal
        open={open}
        setOpen={setOpen}
        content={
          <CardModal
            submitText="Update"
            hide={() => setOpen(false)}
            fetch={fetch}
            getSubscriptionDetails={getSubscriptionDetails}
            customer={customer}
          />
        }
        hasHeader
        closable
        title="Edit Card"
      />
    </Elements>
  );
}
