/* eslint-disable import/no-unresolved */
import form from "pages/viewers/new-viewer/schemas/form";
import * as Yup from "yup";

const {
  formField: { name, firstName, lastName, email, title, phoneNumber, linkedInProfile },
} = form;

const validationsWithoutPwd = [
  Yup.object().shape({
    [name.name]: Yup.string()
      .required(name.errorMsg)
      .matches(/^[ aA-zZ0-9_/-]+$/, "Please use only text - no special characters"),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string(),
    [lastName.name]: Yup.string(),
    [email.name]: Yup.string().email(email.invalidMsg),
    [title.name]: Yup.string(),
    [phoneNumber.name]: Yup.string(),
    [linkedInProfile.name]: Yup.string(),
  }),
];

export default validationsWithoutPwd;
