/* eslint-disable no-useless-return */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from "notistack";
// eslint-disable-next-line import/no-extraneous-dependencies
import MDButton from "@core/components/MDButton";
import { TextField } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { baseUrl, endpoints, query } from "api";
import axios from "axios";
import { useEffect, useState } from "react";

export default function CardModal({ customer, getSubscriptionDetails, hide }) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(customer?.email);
  const [name, setName] = useState(customer?.name);
  const [loadingMessage, setLoadingMessage] = useState("Saving card details.....");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const paymentElementOptions = {
    layout: "tabs",
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"SF-Pro-Rounded"',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setErrorMessage("");
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    // Create a payment method using the card element
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (paymentMethod?.id) {
      await axios
        .post(`${baseUrl}/billing/update-card/`, {
          name,
          customerId: customer.id,
          email,
          paymentMethod: paymentMethod.id,
          // expiringDate: string,
        })
        .then(async () => {
          query
            .patch(endpoints.users.updateOne({ id: localStorage.getItem("$id") }), {
              cardNumber: paymentMethod.id,
            })
            .then((res) => {});

          setIsLoading(false);
          success("Default card updated successfully");
          getSubscriptionDetails();
          hide();
        })
        .catch((err) => {
          setIsLoading(false);
          setLoadingMessage("Update")
          setErrorMessage(err.response.data.message);
        });
    } else {
      setIsLoading(false);
      error("Card details cannot be empty");
      return;
    }
  };
  return (
    <div style={{ padding: "20px" }}>
      {" "}
      <form id="payment-form" onSubmit={handleSubmit}>
        {elements ? (
          <>
            {" "}
            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-2">
              <div className="flex flex-col gap-y-1">
                <div className="text-sm text-gray-500">Name On Card</div>
                <TextField
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <div className="text-sm text-gray-500">Email Address</div>
                <TextField
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div style={{ marginTop: "12px" }} className="flex flex-col gap-y-1">
              <div className="text-sm text-gray-500">Card Details</div>
              <CardElement id="payment-element" options={paymentElementOptions} />
            </div>
          </>
        ) : null}

        {errorMessage && (
          <div id="error-message" style={{ color: "red" }}>
            <br /> {errorMessage}
          </div>
        )}
        <br />
        <center>
          <MDButton disabled={isLoading || !stripe || !elements} type="submit" color="dark">
            {isLoading ? loadingMessage : "Update"}
          </MDButton>
        </center>
      </form>
    </div>
  );
}
