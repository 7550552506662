/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Alert, Card, CircularProgress, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { ChevronLeft } from "akar-icons";
import { query } from "api";
import { useUrl } from "contexts/url";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import getFloorPlansRoutine, {
  addFloorPlanPlanRoutine,
  removeFloorPlanPlanRoutine,
  sortPlansRoutine,
} from "utils/accessor/folders/floor-plans/routines";
import AddFloorPlanForm from "../components/forms/AddPhotoForm";
import PlanCard from "../components/image-card";

function FloorPlanLibrary({
  photos,
  removeFloorPlanPlan,
  addFloorPlanPlan,
  sortPlans,
  getFloorPlans,
  floorPlansLoading,
}) {
  // const { folderId } = useParams();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const { getSearchParam } = useUrl();
  const folderId = getSearchParam("openFolder");
  const navigate = useNavigate();
  const { removeSearchParam } = useUrl();
  const { enqueueSnackbar } = useSnackbar();

  const [isVisible, setIsVisible] = useState(undefined);
  const [draggedFolderId, setDraggedFolderId] = useState(undefined);
  const [newTargetFolderId, setNewTargetFolderId] = useState(undefined);
  const moduleId = getSearchParam("moduleId");

  function useForceUpdate() {
    const [value1, setValue] = useState(0); // integer state
    // eslint-disable-next-line no-shadow
    return () => setValue((value1) => value1 + 1);
  }

  const forceUpdate = useForceUpdate();

  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  useEffect(() => {
    let selectedFolder = [];
    let selectedName = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < photos.length; i++) {
      if (photos[i].id === folderId) {
        selectedFolder = photos[i].files;
        selectedName = photos[i].name;
      }
    }

    setImages(selectedFolder);
    setName(selectedName);

    // eslint-disable-next-line react/prop-types
    // getPlans(photos, folderId);
  }, [photos]);

  const removePlan = (image) => {
    setIsLoading(true);
    query
      .patch(`/floor-plans-folders/removeimages/${folderId}`, {
        files: images?.filter((folder) => folder !== image),
        id: folderId,
      })
      .then((res) => {
        getFloorPlans(moduleId);
        success("Removed successfully");
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-line
        error(err?.response?.data?.message || "Could not update");
        setIsLoading(false);
      });
  };

  const handleDragStart = (id) => {
    setDraggedFolderId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (id) => {
    setNewTargetFolderId(id);
  };

  const handleDragEnd = () => {
    setDraggedFolderId(undefined);
    setNewTargetFolderId(undefined);
  };
  const addPlan = (values) => {
    addFloorPlanPlan(photos, values);
    getFloorPlans(moduleId);
    forceUpdate();
  };

  const sortFolders = () => {
    setIsLoading(true);
    if (!draggedFolderId || !newTargetFolderId) return;

    sortPlans(photos, folderId, images, draggedFolderId, newTargetFolderId);

    const folderPositions = images.map((folder, i) => ({ id: folder, index: i }));
    const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);

    const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

    const sortedPlans = [...images];
    sortedPlans.splice(draggedFolder.index, 1);
    sortedPlans.splice(newTargetFolder.index, 0, images[draggedFolder.index]);

    query
      .patch(`/floor-plans-folders/removeimages/${folderId}`, {
        files: sortedPlans,
        id: folderId,
      })
      .then((res) => {
        setIsLoading(false);

        getFloorPlans(moduleId);
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not get data");
      });

    forceUpdate();
  };

  const handleDrop = () => {
    sortFolders();
  };

  const getFloorPlansHere = () => {
    getFloorPlans(moduleId);
    forceUpdate();
  };

  return (
    <>
      <Card className="mb-4 rounded-lg">
        <MDBox>
          <MDBox width="98%" textAlign="center" mx="auto" className="my-3">
            <MDBox mb={1} className="flex items-center gap-x-2">
              <IconButton onClick={() => removeSearchParam("openFolder")}>
                <ChevronLeft />
              </IconButton>
              <MDTypography variant="h5" fontWeight="bold">
                {name}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <Card m={2} />
      <MDBox display="flex" justifyContent="flex-start" className="mx-4 mt-5">
        <Grid item xs={12} lg={12}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={1}>
                <MDBox pl={1}>
                  <Tooltip title="Add Plans">
                    <MDButton
                      onClick={() => {
                        if (isVisible) {
                          setIsVisible(false);
                        } else {
                          setIsVisible(true);
                        }
                      }}
                      color="info"
                      iconOnly
                    >
                      <Icon sx={{ fontWeight: "bold" }}>{isVisible ? "close" : "add"}</Icon>
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </Grid>
              {isVisible ? (
                <Grid item xs={12} p={2}>
                  <Card className="px-5">
                    <AddFloorPlanForm
                      getFloorPlans={getFloorPlansHere}
                      id={folderId}
                      cancel={setIsVisible}
                    />
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          </MDBox>
        </Grid>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-start" m={2} />
      {floorPlansLoading || isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null}

      {images.length === 0 ? (
        <Card m={2} sx={{ marginTop: "15px" }}>
          <Alert severity="error">No floor plan photos found</Alert>
        </Card>
      ) : null}
      <Grid container spacing={6}>
        {images.map((image) => (
          <PlanCard
            removePlan={removePlan}
            isLoading={isLoading}
            addPlan={addPlan}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            handleDrop={handleDrop}
            key={image}
            image={image}
            folderId={folderId}
          />
        ))}
      </Grid>
    </>
  );
}

FloorPlanLibrary.defaultProps = {
  photos: [],
};

FloorPlanLibrary.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  removeFloorPlanPlan: PropTypes.func.isRequired,
  addFloorPlanPlan: PropTypes.func.isRequired,
  sortPlans: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    photos: state.getIn(["floorPlans", "floorPlans"]),
    images: state.getIn(["floorPlans", "plans"]),
    floorPlansLoading: state.getIn(["floorPlans", "floorPlansLoading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeFloorPlanPlan: (photos, folderId, id) =>
      dispatch(
        removeFloorPlanPlanRoutine.trigger({
          photos,
          folderId,
          id,
        })
      ),
    addFloorPlanPlan: (photos, values) =>
      dispatch(
        addFloorPlanPlanRoutine.trigger({
          photos,
          values,
        })
      ),
    sortPlans: (floorPlans, folderId, images, draggedFolderId, newTargetFolderId, moduleId) =>
      dispatch(
        sortPlansRoutine.trigger({
          floorPlans,
          folderId,
          images,
          draggedFolderId,
          newTargetFolderId,
          moduleId,
        })
      ),
    getFloorPlans: (moduleId) => dispatch(getFloorPlansRoutine.trigger({ moduleId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanLibrary);
