/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import AgentProfilePicture from "components/profileCropper";
import SwitchToggle from "components/switch";
import PropTypes from "prop-types";
import FormField from "../form-field";

function AgentInfo({
  formData,
  profilePicture,
  setProfilePicture,
  showAgentInfo,
  setShowAgentInfo,
}) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, title, phoneNumber, website, email } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    title: titleV,
    email: emailV,
    phoneNumber: phoneNumberV,
    website: websiteV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Agent</MDTypography>
        <h1 className="text-sm text-gray-500">
          Any information entered here will appear at the bottom of your Gallery Menu in the Agent
          Section. Leave any field blank if you do not want anything to appear for that field.
        </h1>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              // placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              // placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={title.type}
              label={title.label}
              name={title.name}
              value={titleV}
              // placeholder={title.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              // placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phoneNumber.type}
              label={phoneNumber.label}
              name={phoneNumber.name}
              value={phoneNumberV}
              // placeholder={phoneNumber.placeholder}
              error={errors.phoneNumber && touched.phoneNumber}
              success={phoneNumberV.length > 0 && !errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={website.type}
              label={website.label}
              name={website.name}
              value={websiteV}
              // placeholder={website.placeholder}
              error={errors.website && touched.website}
              success={websiteV.length > 0 && !errors.website}
            />
          </Grid>
          <Grid item xs={12}>
            <AgentProfilePicture
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              serverImage={profilePicture}
            />
          </Grid>
          <MDBox
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
            className="flex-col gap-y-2 md:flex-row"
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <p
              style={{ display: "inline-flex", justifyContent: "left" }}
              className="text-sm text-gray-500 mt-4 font-bold"
            >
              {" "}
            </p>
            <div
              className="flex gap-x-2 items-center"
              style={{ display: "inline-flex", justifyContent: "right" }}
            >
              <SwitchToggle checked={!showAgentInfo} setChecked={setShowAgentInfo} />
              <h1 className="text-sm text-gray-500">Display in Menu?</h1>
            </div>
          </MDBox>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

AgentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AgentInfo;
