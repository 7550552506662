// eslint-disable-next-line
import { ReactSortable } from "react-sortablejs";

export default function Reorderable({
  items,
  setItems,
  children,
  ...props
}) {
  return (
    <ReactSortable
      {...(props || {})}
      list={items}
      setList={setItems}
      animation={200}
      ghostClass="bg-blue-200"
    >
      {children || "No items"}
    </ReactSortable>
  );
}