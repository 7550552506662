/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

// Create a context
const PageRerenderContext = createContext();

// Create a provider component
export function PageRerenderProvider({ children }) {
    const [rerenderCount, setRerenderCount] = useState(0);

    const forceRerender = () => {
      setRerenderCount(rerenderCount + 1);
    };
  
    const contextValue = {
      rerenderCount,
      forceRerender,
    };
  
  return (
    <PageRerenderContext.Provider value={contextValue}>
      {children}
    </PageRerenderContext.Provider>
  );
}

// Custom hook to access the context
export function usePageRerender() {
  return useContext(PageRerenderContext);
}
