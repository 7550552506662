/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-bind */
import MDTypography from "@core/components/MDTypography";
import { Button, Card, Skeleton } from "@mui/material";
import { useEffect } from "react";
import Nestable from "react-nestable";
import "react-nestable/dist/styles/index.css";

import MDButton from "@core/components/MDButton";
import {
  ArrowCycle,
  Calendar,
  CodepenFill,
  File,
  Globe,
  Grid,
  Image,
  Info,
  InstagramFill,
  Location,
  PanelSplit,
  Video,
} from "akar-icons";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import PanToolMenu from "./panTool";

function Sidenav({ useIcons, add }) {
  const { setActiveModule, modules, reordering, fetchingModules, fetchModules, reorderModules } =
    useViewer();
  const { setSearchParam, getSearchParam, setMultipleSearchParams } = useUrl();

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  useEffect(() => {
    const moduleIdParam = getSearchParam("moduleId");
    if (moduleIdParam) {
      setActiveModule(moduleIdParam);
    } else {
      setSearchParam("moduleId", modules.length ? modules[0]?.id : "");
    }
  }, [getSearchParam, setSearchParam, modules]);

  const isActive = (item) =>
    // if (item.children?.length) {
    //   return false;
    // }
    // if (item.isChild) {
    item.id === getSearchParam("moduleId");
  // }
  // return item.name === getSearchParam("builderTab");
  const renderItem = ({ item }) => {
    const size = 18;
    const route = item.menuTitle ? item.menuTitle.toLowerCase().replace(" ", "-") : item.type;
    const iconMappings = {
      "virtual-tour": <ArrowCycle className="iconPositionChangeSN" strokeWidth={2} size={size} />,
      "photo-gallery": <Image className="iconPositionChangeSN" size={size} />,
      "video-gallery": <Video strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      "3d-model": <CodepenFill strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      website: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      "virtual-staging": <Grid strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      "sales-deck": <File strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      "contact-form": <Calendar strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      instagram: <InstagramFill strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      overview: <Info strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      directions: <Location strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      custom: <Globe strokeWidth={2} className="iconPositionChangeSN" size={size} />,
      "floor-plans": <PanelSplit strokeWidth={2} className="iconPositionChangeSN" size={size} />,
    };

    const icon = iconMappings[route] || null;
    return (
      <Card
        sx={{
          borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
          border: isActive(item) ? "2px solid #4682B4" : "2px solid transparent",
        }}
        onClick={() => {
          if (item.modules && item.modules.length > 1) {
            // setExpandedTab((e) => (e === item.name ? null : item.name));
          } else {
            setMultipleSearchParams({
              builderTab: item.menuTitle
                ? item.menuTitle.toLowerCase().replace(" ", "-")
                : item.type,
              moduleId: item?.id,
            });
          }
        }}
        className="text-sm px-4 py-2 font-semibold shadow-md group h-[4rem] border-2 flex items-center justify-start"
      >
        <div className="flex items-center justify-start space-x-4 h-full w-full">
          {useIcons ? icon : ""}

          <div className="flex justify-between items-center w-full">
            <Button
              className="max-w-fit mx-auto md:mx-0 flex-grow"
              variant="text"
              disableRipple
              disableTouchRipple
              disableElevation
              sx={{
                textAlign: "left",
                padding: "0px",
                margin: "0px",
              }}
            >
              <MDTypography variant="h6" className="text-[.9rem]">
                {item.label}
              </MDTypography>
            </Button>
            <PanToolMenu />

            {/* <Actions item={item} add={item.isChild ? null : add} /> */}
          </div>
        </div>
      </Card>
    );
  };

  const loadingNestable = (
    <div className="rounded-md bg-white h-[65px] shadow-md flex gap-x-2 items-center p-3">
      <Skeleton className="w-[20px] rounded-full" />
      <Skeleton variant="rectangular" className="w-[180px] h-[20px] rounded-lg" />
    </div>
  );

  return (
    <>
      {fetchingModules || reordering ? (
        <div className="flex flex-col gap-y-2 w-full">
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
          {loadingNestable}
        </div>
      ) : (
        <Nestable
          onChange={({ items: newList }) => reorderModules(newList)}
          maxDepth={2}
          items={modules}
          renderItem={renderItem}
        />
      )}

      {!fetchingModules ? (
        <div className="flex items-center justify-center py-3 mt-3">
          <MDButton color="primary" onClick={() => add()}>
            Add New Module
          </MDButton>
        </div>
      ) : null}
    </>
  );
}

export default Sidenav;
