/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import { useViewer } from "contexts/viewer";
import BaseLayout from "layouts/base-layout";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ViewerAnalytics from "./analytics";
import ViewerBillingPage from "./billing";
import ViewerBuilderTab from "./builder";
import ViewerHeader from "./header";
import ViewerSettings from "./settings";

function Contained({ children }) {
  return (
    <Grid item xs={12}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </Grid>
  );
}

export default function Viewer() {
  const { viewer, setViewer } = useViewer();
  // eslint-disable-next-line camelcase
  const { name: viewerName, tab: currentTab } = useParams();
  const showMenuIconSwitch = useMemo(() => currentTab === "builder", [currentTab]);

  const getViewer = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName, isActive: true })).then((res) => {
      if (res.data.length) {
        localStorage.setItem("viewerId", res.data[0].id);
        setViewer(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getViewer();
  }, []);

  return (
    <BaseLayout pageName={viewer?.name || "Gallery Builder"}>
      <MDBox mt={0}>
        <div className="sm:flex sm:justify-between items-center mb-3">
          <ViewerHeader showMenuIconSwitch={showMenuIconSwitch} />
        </div>
        <Grid container spacing={3}>
          {(() => {
            switch (currentTab) {
              case "builder":
                return <ViewerBuilderTab />;
              case "settings":
                return (
                  <Contained>
                    <ViewerSettings />
                  </Contained>
                );
              case "analytics":
                return (
                  <Contained>
                    <ViewerAnalytics />
                    {/* <Charts /> */}
                  </Contained>
                );
              case "billing":
                return (
                  <Contained>
                    <ViewerBillingPage />
                  </Contained>
                );
              default:
                return (
                  <Contained>
                    <Alert severity="error">No such page</Alert>
                  </Contained>
                );
            }
          })()}
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}
