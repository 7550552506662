import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormField from "../form-field";

function UserInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { fullName, email, cellphone, password, repeatPassword } = formField;
  const {
    fullName: fullNameV,
    email: emailV,
    cellphone: phoneNumberV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1} mb={1} textAlign="center">
        <MDTypography variant="h5" color="text">
          Sign up for a free 14-day trial to create your first Gallery!
        </MDTypography>
        <MDTypography variant="h6" color="text">
          Cancel easily anytime with the simple click of a button.
        </MDTypography>
      </MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        User Info
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={fullName.type}
              label={fullName.label}
              name={fullName.name}
              value={fullNameV}
              // placeholder={fullName.placeholder}
              error={errors.fullName && touched.fullName}
              success={fullNameV.length > 0 && !errors.fullName}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              // placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={cellphone.type}
              label={cellphone.label}
              name={cellphone.name}
              value={phoneNumberV}
              // placeholder={cellphone.placeholder}
              error={errors.cellphone && touched.cellphone}
              success={phoneNumberV.length > 0 && !errors.cellphone}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              // placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              // placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
