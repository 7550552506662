import FontPicker from "font-picker-react";

function FontSelector({ font, setFont }) {
  return (
    <div className="text-sm relative">
      <FontPicker
        apiKey="AIzaSyDsKxukuex-qXUHCPko6iOzIlMgGlvSB_M"
        activeFontFamily={font}
        onChange={(nextFont) => setFont(nextFont.family)}
        className="bg-white"
      />
      {/* <p className="apply-font text-sm mt-2 absolute">This font will be applied to this form.</p> */}
    </div>
  );
}

export default FontSelector;
