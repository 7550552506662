function createEndpoint(template) {
  return (params, query) => {
    const url = Object.keys(params || {}).reduce((_url, key) => {
      const value = params[key];
      return _url.replaceAll(`:${key}`, value);
    }, template);

    const searchParams = new URLSearchParams(query || {});
    return `${url}?${searchParams.toString()}`;
  };
}

export const endpoints = {
  users: {
    register: "/users/register",
    updateOne: createEndpoint("/users/:id"),
    forgotPassword: createEndpoint("/users/forgot-password"),
    changePassword: createEndpoint("/users/change-password"),
    resetPassword: createEndpoint("/users/reset-password"),
    setPassword: createEndpoint("/users/set-password"),
    getUserByEmail: createEndpoint("/users/get-user-by-email"),
  },
  auth: {
    login: "/auth/login",
    user: "/auth/user",
  },
  cards: {
    create: createEndpoint("/cards"),
    updateOne: createEndpoint("/cards/:id"),
    deleteOne: createEndpoint("/cards/:id"),
    findOne: createEndpoint("/cards/:id"),
    getAll: createEndpoint("/cards"),
  },
  viewers: {
    create: createEndpoint("/viewers"),
    updateOne: createEndpoint("/viewers/:id"),
    deleteOne: createEndpoint("/viewers/:id"),
    findOne: createEndpoint("/viewers/:id"),
    getMine: createEndpoint("/viewers/mine"),
    getAll: createEndpoint("/viewers"),
    deleteUserViewers: createEndpoint("/viewers/user/delete/:id"),
    deactivateViewer: createEndpoint("/viewers/viewer/deactivate/:id"),
    reorderModules: createEndpoint("/viewers/:id/reorder-modules"),
    updateAgentProfilePicture: createEndpoint("/viewers/:id/agent-profile-picture"),
    updateLogo: createEndpoint("/viewers/:id/logo"),
  },
  modules: {
    create: createEndpoint("/viewer-modules"),
    updateOne: createEndpoint("/viewer-modules/:id"),
    updateFileOne: createEndpoint("/viewer-modules/delete/file/"),
    deleteOne: createEndpoint("/viewer-modules/:id"),
    findOne: createEndpoint("/viewer-modules/:id"),
    getMine: createEndpoint("/viewer-modules/mine"),
    getAll: createEndpoint("/viewer-modules"),
    updateSalesDeck: createEndpoint("/viewer-modules/:id/sales-deck"),
    updateLogo: createEndpoint("/viewer-modules/:id/logo"),
  },
  photoGalleryFolders: {
    create: createEndpoint("/photo-gallery-folders"),
    updateOne: createEndpoint("/photo-gallery-folders/:id"),
    deleteOne: createEndpoint("/photo-gallery-folders/:id"),
    findOne: createEndpoint("/photo-gallery-folders/:id"),
    getAll: createEndpoint("/photo-gallery-folders"),
    uploadThumbnail: createEndpoint("/photo-gallery-folders/:id/thumbnail"),
    uploadImage: createEndpoint("/photo-gallery-folders/:id/upload"),
  },
  videoGalleryFolders: {
    create: createEndpoint("/video-gallery-folders"),
    updateOne: createEndpoint("/video-gallery-folders/:id"),
    deleteOne: createEndpoint("/video-gallery-folders/:id"),
    findOne: createEndpoint("/video-gallery-folders/:id"),
    getAll: createEndpoint("/video-gallery-folders"),
    uploadThumbnail: createEndpoint("/video-gallery-folders/:id/thumbnail"),
    uploadImage: createEndpoint("/video-gallery-folders/:id/upload"),
  },
  floorPlansFolders: {
    create: createEndpoint("/floor-plans-folders"),
    updateOne: createEndpoint("/floor-plans-folders/:id"),
    deleteOne: createEndpoint("/floor-plans-folders/:id"),
    findOne: createEndpoint("/floor-plans-folders/:id"),
    getAll: createEndpoint("/floor-plans-folders"),
    uploadThumbnail: createEndpoint("/floor-plans-folders/:id/thumbnail"),
    uploadImage: createEndpoint("/floor-plans-folders/:id/upload"),
  },
};
