import form from "./form";

const {
  formField: {
    fullName,
    email,
    companyName,
    companyWebsiteUrl,
    title,
    cellphone,
    password,
    repeatPassword,
    nameOnCard,
    cardNumber,
    expirationDate,
    cvv,
    billingAddress,
  },
} = form;

const initialValues = {
  [fullName.name]: "",
  [email.name]: "",
  [title.name]: "",
  [companyName.name]: "",
  [companyWebsiteUrl.name]: "",
  [cellphone.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [nameOnCard.name]: "",
  [cardNumber.name]: "",
  [expirationDate.name]: "",
  [cvv.name]: "",
  [billingAddress.name]: "",
};

export default initialValues;
