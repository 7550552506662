/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";
import PropTypes from "prop-types";

function FormField({ label, name, ...rest }) {
  return (
    <MDBox mb={1.5}>
      <MDInput {...rest} name={name} autoComplete="new-password" variant="standard" label={label} fullWidth />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {/* <ErrorMessage name={name} /> */}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
