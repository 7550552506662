/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { useMaterialUIController } from "@core/context";
import Card from "@mui/material/Card";
import { FiBox, FiCalendar, FiMap, FiPackage } from "react-icons/fi";
import { Link } from "react-router-dom";
import PATHS from "routes/paths";

function Sidenav() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const sidenavItems = [
    { icon: <FiBox size={20} />, label: "Website", href: PATHS.website },
    { icon: <FiBox size={20} />, label: "3D Models", href: PATHS.models },
    { icon: <FiPackage size={20} />, label: "Staging Options", href: PATHS.stagingOptions },
    { icon: <FiMap size={20} />, label: "Maps & Directions", href: PATHS.mapsAndDirections },
    { icon: <FiCalendar size={20} />, label: "Event Inquiries", href: PATHS.eventEnquiryForm },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <Link to={href}>
          <MDTypography
            component="a"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
              borders: { borderRadius },
              functions: { pxToRem },
              palette: { light },
              transitions,
            }) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
            })}
          >
            <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
              {icon}
            </MDBox>
            {label}
          </MDTypography>
        </Link>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
