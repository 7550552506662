/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Alert, Card, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useEffect } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function Models({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    setEdits({
      threeDModelUrl: data?.threeDModelUrl,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
    });
  }, [data, setEdits]);

  const handleDisplayInLeftMenu = () => {
    const currentValue = edits?.displayInLeftMenu || false;
    setEdit("displayInLeftMenu", !currentValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Card className="p-6">
        <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
          3D MODEL
        </MDTypography>

        <div>
          <Grid display="flex">
            <Grid item xs={4}>
              <FormField
                label="Menu Title*"
                placeholder="eg. 3D Model"
                value={edits?.label || ""}
                onChange={(e) => setEdit("label", e.target.value)}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography variant="button" className="text-sm text-gray-500">
                    Display in Menu?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <FormField
            type="text"
            label="3D Model URL"
            onChange={(e) => setEdit("threeDModelUrl", e.target.value)}
            value={edits?.threeDModelUrl || ""}
            placeholder="Please enter the entire URL of the hosted 3D Model"
          />

          <Grid item xs={9}>
            <MDBox>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
          <br />
          <Grid className="centermodulebuttons">
            <Grid display="flex" justify-content="center" className="customgap">
              <DeleteModule id={data?.id} />
              <MDButton
                color="info"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto"
                onClick={save}
                disabled={loading}
              >
                {loading ? "Updating..." : "UPDATE"}
              </MDButton>
            </Grid>
          </Grid>
        </div>
      </Card>
      <br />
      <Card className="p-6 overflow-auto">
        <Alert
          severity="info"
          className="text-sm md:text-md"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div className="text-center">
            <h3 className="font-bold">Don&apos;t have a 3D Model?</h3>
            No problem! Let us create custom ones for you.
            <br />
            We provide professional 3D Model services globally. <br />
            <br />
            Click here to learn more and get started!
            <br />
            <a href="https://www.viewlio.com/3d-models/" target="_blank" rel="noreferrer">
              <MDButton
                size="medium"
                variant="outlined"
                className="mx-auto w-full sm:w-1/4 md:w-auto mt-2 specialcolor"
              >
                Learn more
              </MDButton>
            </a>
          </div>
        </Alert>
      </Card>
    </>
  );
}

export default function ModelsModule() {
  return <ModuleWrapper component={Models} />;
}
