// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";

// Authentication layout components

// Images
import bgImage from "@core/assets/images/bg-sign-in-basic.jpeg";
import MDInput from "@core/components/MDInput";
import { endpoints, query } from "api";
import GuestLayout from "layouts/guest-layout";
import { useState } from "react";

function ResetPassword() {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [sending, setSending] = useState(false);
  const [values, setValues] = useState({});

  const handleValueChange = (field, value) =>
    setValues((v) => ({
      ...v,
      [field]: value,
    }));

  const reset = () => {
    setError();
    setSuccess();
    setSending(true);
    query
      .post(endpoints.users.resetPassword({}), {
        ...values,
      })
      .then(() => {
        setSuccess(true);
        setSending(false);
      })
      .catch((err) => {
        setSuccess(false);
        setSending(false);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError("Unknown error occurred");
        }
      });
  };

  return (
    <GuestLayout image={bgImage} isSignIn>
      <Card sx={{ maxWidth: "400px", mx: "auto" }}>
        <MDBox
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} textAlign="center">
              <MDTypography variant="button" color="text">
                Enter your new password
              </MDTypography>
            </MDBox>
            {error || success ? (
              <MDBox mb={2} textAlign="center">
                {error ? (
                  <MDTypography variant="button" color="error">
                    {error}
                  </MDTypography>
                ) : (
                  ""
                )}
                {success ? (
                  <MDTypography variant="button" color="success">
                    Password changed. You can go and{" "}
                    <a
                      href="https://devadmin.viewlio.com/sign-in"
                      target="_blank"
                      style={{ color: "#398b08" }}
                      rel="noreferrer"
                    >
                      Sign In.
                    </a>{" "}
                  </MDTypography>
                ) : (
                  ""
                )}
              </MDBox>
            ) : (
              ""
            )}
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                readonly
                onChange={(e) => handleValueChange("email", e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Verification code"
                fullWidth
                onChange={(e) => handleValueChange("passwordResetCode", e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => handleValueChange("password", e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Repeat Password"
                fullWidth
                onChange={(e) => handleValueChange("repeatPassword", e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1} className="flex items-center justify-center">
              <MDButton color="info" onClick={reset} disabled={sending}>
                {sending ? "Resetting" : "Change password"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Dont have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign Up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </GuestLayout>
  );
}

export default ResetPassword;
