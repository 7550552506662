/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useUrl } from "contexts/url";
import getFloorPlansRoutine from "./routines";

function FloorPlans(props) {
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { getFloorPlans } = props;
    getFloorPlans(moduleId);
  }, [moduleId]);
  return null;
}

FloorPlans.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getFloorPlans: (moduleId) => dispatch(getFloorPlansRoutine.trigger({moduleId})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlans);
