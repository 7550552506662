/* eslint-disable react/prop-types */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

export default function ImageCard({
  image,
  removePlan,
  handleDragStart,
  handleDragEnter,
  handleDragOver,
  handleDragEnd,
  handleDrop,
  isLoading,
}) {
  return (
    <Grid item xs={3}>
      <Card
        draggable
        onDragStart={() => handleDragStart(image)}
        onDragEnter={() => handleDragEnter(image)}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDrop={handleDrop}
        className="hover:cursor-pointer"
      >
        <MDBox
          position="relative"
          borderRadius="lg"
          mt={-3}
          className="card-header"
          sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
        >
          <center>
            <MDBox
              component="img"
              src={image}
              borderRadius="lg"
              shadow="sm"
              height="180px"
              // width="100%"
              position="relative"
              zIndex={10}
              mb={2}
            />
          </center>
        </MDBox>
        <MDBox textAlign="center" mb={2}>
          <MDButton onClick={() => removePlan(image)} variant="outlined" color="dark" size="small">
            {isLoading ? "removing..." : "remove"}
          </MDButton>
        </MDBox>
      </Card>
    </Grid>
  );
}
ImageCard.defaultProps = {
  image: "",
};

ImageCard.propTypes = {
  image: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    imageCount: PropTypes.number,
    handleDragStart: PropTypes.func,
    updateFolder: PropTypes.func,
    handleDragOver: PropTypes.func,
    handleDragEnter: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleDrop: PropTypes.func,
  }),
};
