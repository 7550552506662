/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Alert, Card, CircularProgress, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { ChevronLeft } from "akar-icons";
import { query } from "api";
import { useUrl } from "contexts/url";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import getPhotosRoutine, {
  addPhotoImageRoutine,
  removePhotoImageRoutine,
  sortImagesRoutine,
} from "utils/accessor/folders/photos/routines";
import AddPhotoForm from "../components/forms/AddPhotoForm";
import ImageCard from "../components/image-card";

function PhotoLibrary({
  photos,
  removePhotoImage,
  addPhotoImage,
  sortImages,
  getPhotos,
  photosLoading,
}) {
  // const { folderId } = useParams();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const { getSearchParam } = useUrl();
  const folderId = getSearchParam("openFolder");
  const navigate = useNavigate();
  const { removeSearchParam } = useUrl();
  const { enqueueSnackbar } = useSnackbar();
  const [isVisible, setIsVisible] = useState(undefined);
  const [draggedFolderId, setDraggedFolderId] = useState(undefined);
  const [newTargetFolderId, setNewTargetFolderId] = useState(undefined);
  function useForceUpdate() {
    const [value1, setValue] = useState(0); // integer state
    // eslint-disable-next-line no-shadow
    return () => setValue((value1) => value1 + 1);
  }
  const moduleId = getSearchParam("moduleId");
  const forceUpdate = useForceUpdate();

  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  useEffect(() => {
    let selectedFolder = [];
    let selectedName = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < photos.length; i++) {
      if (photos[i].id === folderId) {
        selectedFolder = photos[i].files;
        selectedName = photos[i].name;
      }
    }

    setImages(selectedFolder);
    setName(selectedName);

    // eslint-disable-next-line react/prop-types
    // getImages(photos, folderId);
  }, [photos]);

  const removeImage = (image) => {
    setIsLoading(true);
    query
      .patch(`/photo-gallery-folders/removeimages/${folderId}`, {
        files: images?.filter((folder) => folder !== image),
        id: folderId,
      })
      .then((res) => {
        getPhotos(moduleId);
        success("Removed successfully");
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-line
        setIsLoading(false);

        error(err?.response?.data.message || "Could not update");
      });
  };

  const handleDragStart = (id) => {
    setDraggedFolderId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (id) => {
    setNewTargetFolderId(id);
  };

  const handleDragEnd = () => {
    setDraggedFolderId(undefined);
    setNewTargetFolderId(undefined);
  };
  const addImage = (values) => {
    addPhotoImage(photos, values);
    forceUpdate();
  };

  const sortFolders = () => {
    setIsLoading(true);
    if (!draggedFolderId || !newTargetFolderId) return;

    sortImages(photos, folderId, images, draggedFolderId, newTargetFolderId);

    const folderPositions = images.map((folder, i) => ({ id: folder, index: i }));
    const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);

    const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

    const sortedImages = [...images];
    sortedImages.splice(draggedFolder.index, 1);
    sortedImages.splice(newTargetFolder.index, 0, images[draggedFolder.index]);

    query
      .patch(`/photo-gallery-folders/removeimages/${folderId}`, {
        files: sortedImages,
        id: folderId,
      })
      .then((res) => {
        setIsLoading(false);

        getPhotos(moduleId);
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not get data");
      });

    forceUpdate();
  };

  const handleDrop = () => {
    sortFolders();
  };

  const getPhotosHere = () => {
    getPhotos(moduleId);
    forceUpdate();
  };

  return (
    <>
      <Card className="mb-4 rounded-lg">
        <MDBox>
          <MDBox width="98%" textAlign="center" mx="auto" className="my-3">
            <MDBox mb={1} className="flex items-center gap-x-2">
              <IconButton onClick={() => removeSearchParam("openFolder")}>
                <ChevronLeft />
              </IconButton>
              <MDTypography variant="h5" fontWeight="bold">
                {name}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <Card m={2} />
      <MDBox display="flex" justifyContent="flex-start" className="mx-4 mt-5">
        <Grid item xs={12} lg={12}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={1}>
                <MDBox pl={1}>
                  <Tooltip title="Add Images">
                    <MDButton
                      onClick={() => {
                        if (isVisible) {
                          setIsVisible(false);
                        } else {
                          setIsVisible(true);
                        }
                      }}
                      color="info"
                      iconOnly
                    >
                      <Icon sx={{ fontWeight: "bold" }}>{isVisible ? "close" : "add"}</Icon>
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </Grid>
              {isVisible ? (
                <Grid item xs={12} p={2}>
                  <Card className="px-5">
                    <AddPhotoForm
                      getPhotos={getPhotosHere}
                      id={folderId}
                      moduleId={moduleId}
                      cancel={setIsVisible}
                    />
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          </MDBox>
        </Grid>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-start" m={2} />
      {photosLoading || isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null}

      {images.length === 0 ? (
        <Card m={2} sx={{ marginTop: "15px" }}>
          <Alert severity="error">No photos found</Alert>
        </Card>
      ) : null}
      <div className="grid sm:grid-cols-3 xl:grid-cols-3 gap-5">
        {images.map((image) => (
          <ImageCard
            removeImage={removeImage}
            isLoading={isLoading}
            addImage={addImage}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            handleDrop={handleDrop}
            key={image}
            image={image}
            folderId={folderId}
          />
        ))}
      </div>
    </>
  );
}

PhotoLibrary.defaultProps = {
  photos: [],
};

PhotoLibrary.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  removePhotoImage: PropTypes.func.isRequired,
  addPhotoImage: PropTypes.func.isRequired,
  sortImages: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    photos: state.getIn(["photos", "photos"]),
    images: state.getIn(["photos", "images"]),
    photosLoading: state.getIn(["photos", "photosLoading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removePhotoImage: (photos, folderId, id) =>
      dispatch(
        removePhotoImageRoutine.trigger({
          photos,
          folderId,
          id,
        })
      ),
    addPhotoImage: (photos, values) =>
      dispatch(
        addPhotoImageRoutine.trigger({
          photos,
          values,
        })
      ),
    sortImages: (photos, folderId, images, draggedFolderId, newTargetFolderId) =>
      dispatch(
        sortImagesRoutine.trigger({
          photos,
          folderId,
          images,
          draggedFolderId,
          newTargetFolderId,
        })
      ),
    getPhotos: (moduleId) => dispatch(getPhotosRoutine.trigger({ moduleId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoLibrary);
