import FloorPlans from "./floor-plans";
import Photos from "./photos";
import Videos from "./videos";

function AccessorContainer() {
  return (
    <>
      <Photos />
      <Videos />
      <FloorPlans />
    </>
  );
}

export default AccessorContainer;
