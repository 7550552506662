/* eslint-disable import/no-unresolved */
import theme from "@core/assets/theme";
import themeDark from "@core/assets/theme-dark";
import themeDarkRTL from "@core/assets/theme-dark/theme-rtl";
import themeRTL from "@core/assets/theme/theme-rtl";
import MDBox from "@core/components/MDBox";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "@core/context";
import Configurator from "@core/examples/Configurator";
import Sidenav from "@core/examples/Sidenav";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import NotFoundPage from "pages/404";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "routes/routes";
import rtlPlugin from "stylis-plugin-rtl";
import Folders from "utils/accessor/folders";
import images from "utils/images";
import "./fonts.css";

/**
 * @TODO (@geniuschinez): Use rtkquery when interacting with the API.
 * - The current implementation is ...shaky, and definitely doesn't
 *   follow best practices.
 * */

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const DEFAULT_TIMER = 60*60;
  const [logoutTimer, setLogoutTimer] = useState(DEFAULT_TIMER); // 60 minutes in seconds

  // Function to reset the logout timer
  const resetTimer = () => {
    setLogoutTimer(DEFAULT_TIMER); // Reset the timer to 60 minutes when there's activity
  };

  // Effect to start the timer and listen for activity
  useEffect(() => {
    const countdown = setInterval(() => {
      setLogoutTimer((prevTime) => prevTime - 1);
    }, 1000); // Update the timer every second

    // Listen for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    // Cleanup the interval and remove event listeners when unmounting
    return () => {
      clearInterval(countdown);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, []);

  // Logout the user when the timer reaches 0
  useEffect(() => {
    if (logoutTimer === 0) {
      // Perform logout actions here, such as clearing the session or token
      // alert("You have been logged out due to inactivity.");
      // Redirect the user to the login page
      if (localStorage.getItem("$token")) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, [logoutTimer]);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const sidebarRoutes = routes
    .filter((route) => route.visibleOnSidebar)
    .map((route) => {
      if (route.collapse) {
        return {
          ...route,
          collapse: route.collapse.filter((coll) => coll.visibleOnSidebar),
        };
      }

      return route;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? images?.brandDark
                  : images?.brandWhite
              }
              brandName="Material Dashboard PRO"
              routes={sidebarRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Folders />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? images?.brandDark
                : images?.brandWhite
            }
            brandName="Material Dashboard PRO"
            routes={sidebarRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {/* <Route
          exact
          path={`${PATHS.photoLibrary}/:folderId`}
          element={<PhotoLibrary />}
          key="photolibrary"
        /> */}
        {getRoutes(routes)}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}
