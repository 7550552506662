/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Card, Grid, Switch } from "@mui/material";
import { endpoints, query } from "api";
import MuiColorPicker from "components/color-picker";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import LogoCropper from "components/logoCropper";
import { DataURIToBlob } from "helpers";
import { createColor } from "material-ui-color";
import { useCallback, useEffect, useState } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function Overview({
  data,
  saving: loading,
  save,
  edits,
  setEdit,
  setEdits,
  showSnackbar,
  moduleId,
}) {
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [fileUri, setFileUri] = useState("");
  const [file, setFile] = useState("");
  const [serverImage, setServerImage] = useState("");
  const [deleteImage, setDeleteImage] = useState("");
  const [colorOfBox, setColorOfBox] = useState("#ffffff");
  const [mapTitle, setFontColor] = useState("#ffffff");

  useEffect(() => {
    setServerImage(data?.logoUrl);
  }, data);

  const handleTextChange = (field, value) =>
    setEdits((v) => ({
      ...v,
      [field]: value,
    }));

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };
  const handleUseLogoBox = () => {
    const currentValue = edits?.useLogoBox || false;
    setEdit("useLogoBox", !currentValue);
  };

  const handleUseRoundedCorners = () => {
    const currentValue = edits?.useRoundedCorners || false;
    setEdit("useRoundedCorners", !currentValue);
  };

  useEffect(() => {
    setEdits({
      overviewHeading: data?.overviewHeading,
      overviewSubheading: data?.overviewSubheading,
      overviewContent: data?.overviewContent,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
      useLogoBox: data?.useLogoBox || false,
      colorOfBox: data?.colorOfBox || "#fffff",
      mapTitle: data?.mapTitle || "#fffff",
      useRoundedCorners: data?.useRoundedCorners || false,
    });
    if (data?.logoUrl) {
      setFileUri(data?.logoUrl);
    }
    setColorOfBox(data?.colorOfBox);
    setFontColor(data?.mapTitle);
  }, [data, setEdits]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (mapTitle?.hex) {
      if (`#${mapTitle?.hex}` !== edits?.mapTitle) {
        setEdit("mapTitle", `#${mapTitle?.hex}`);
      }
    } else {
      setEdit("mapTitle", mapTitle);
    }
    if (colorOfBox?.hex) {
      if (`#${colorOfBox?.hex}` !== edits?.colorOfBox) {
        setEdit("colorOfBox", `#${colorOfBox?.hex}`);
      }
    } else {
      setEdit("colorOfBox", colorOfBox);
    }
  }, [colorOfBox, mapTitle]);

  const uploadLogo = useCallback(() => {
    setLoadingLogo(true);

    const formData = new FormData();
    formData.append("picture", DataURIToBlob(file));

    query
      .post(endpoints.modules.updateLogo({ id: moduleId }), formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        showSnackbar("Uploaded file", { variant: "success", autoHideDuration: 2000 });
        setLoadingLogo(false);
      })
      .catch((err) => {
        setLoadingLogo(false);
        showSnackbar("Could not upload file", { variant: "error", autoHideDuration: 2000 });
        console.log(err); // eslint-disable-line
      });
  }, [file, setLoadingLogo]);

  return (
    <Card className="p-6">
      <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
        OVERVIEW
      </MDTypography>

      <Grid className="grid gap-6">
        <Grid display="flex">
          <Grid item xs={4} className="grid gap-6">
            <FormField
              label="Menu Title*"
              placeholder="eg. OVERVIEW"
              value={edits?.label || ""}
              onChange={(e) => setEdit("label", e.target.value)}
            />
          </Grid>
          <Grid item xs={4} className="specialmarigin centeralignalways">
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" className="text-sm text-gray-500">
                  Display in Menu?
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
        </Grid>

        <FormField
          type="text"
          label="Header"
          value={edits?.overviewHeading || ""}
          onChange={(e) => handleTextChange("overviewHeading", e.target.value)}
        />
        <FormField
          type="text"
          label="Subheader"
          value={edits?.overviewSubheading || ""}
          onChange={(e) => handleTextChange("overviewSubheading", e.target.value)}
        />
        <FormField
          type="text"
          label="Content"
          multiline
          minRows={4}
          value={edits?.overviewContent || ""}
          onChange={(e) => handleTextChange("overviewContent", e.target.value)}
        />

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>
              <h1 className="text-sm text-gray-500 mb-2 "> Text Color</h1>
              <MuiColorPicker color={mapTitle} setColor={setFontColor} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="overviwbuttons">
              <h1 className="text-sm text-gray-500 mb-2  mt-2.5">Use Logo Frame?</h1>
              <Switch checked={edits?.useLogoBox || false} onChange={handleUseLogoBox} />
            </div>
          </Grid>
          {edits?.useLogoBox && (
            <Grid item xs={3}>
              <div>
                <h1 className="text-sm text-gray-500 mb-2">Logo Frame Color</h1>
                <MuiColorPicker
                  color={createColor(edits?.colorOfBox || "#fff")}
                  setColor={setColorOfBox}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={3}>
            <div className="overviwbuttons">
              <h1 className="text-sm text-gray-500 mb-2 mt-2.5">Use Rounded Corners?</h1>
              <Switch
                checked={edits?.useRoundedCorners || false}
                onChange={handleUseRoundedCorners}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <LogoCropper
          logo={file}
          setLogo={setFile}
          serverImage={serverImage || ""}
          setDeleteImage={setDeleteImage}
        />

        <Grid item xs={9}>
          <MDBox>
            <ClickAlert link={getModuleRoute(data)} />
          </MDBox>
        </Grid>
        <br />
        <Grid className="centermodulebuttons">
          <Grid display="flex" justify-content="center" className="customgap">
            <DeleteModule id={data?.id} />
            <MDButton
              color="info"
              size="medium"
              className="mx-auto w-full sm:w-1/4 md:w-auto"
              onClick={() => {
                save();
                if (file) {
                  if (file?.size > 10e6) {
                    showSnackbar("File too large", { variant: "error", autoHideDuration: 3000 });
                    setFile("");
                  } else {
                    uploadLogo();
                  }
                }
              }}
              disabled={loading}
            >
              {loading || loadingLogo ? "Updating..." : "UPDATE"}
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default function OverviewModule() {
  return <ModuleWrapper component={Overview} />;
}
