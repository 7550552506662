/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Alert, Card, CircularProgress, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import getPhotosRoutine, {
  addPhotoFolderRoutine,
  addPhotoImageRoutine,
  removePhotoFolderRoutine,
  sortPhotoFoldersRoutine,
  updatePhotoFolderRoutine,
} from "utils/accessor/folders/photos/routines";

import { endpoints, query } from "api";

import MDButton from "@core/components/MDButton";
import DeleteModule from "components/delete-module";
import ModuleControls from "components/module-controls";
import { usePageRerender } from "contexts/rerender";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useSnackbar } from "notistack";
import { getModuleRoute } from "utils/availableModules";
import LoadingModule from "../components/loading";
import PhotoCard from "./components/folder-card";
import Header from "./components/header";

function PhotoGallery({
  photos,
  removePhotoFolder,
  addPhotoImage,
  updatePhotoFolder,
  sortPhotoFolders,
  photosLoading,
  getPhotos,
}) {
  const [draggedFolderId, setDraggedFolderId] = useState(undefined);
  const [newTargetFolderId, setNewTargetFolderId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });

  const error = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    getPhotos(moduleId);
  }, [moduleId]);

  const removeFolder = (id) => {
    setIsLoading(true);
    removePhotoFolder(photos, id);
    query
      .delete(endpoints.photoGalleryFolders.deleteOne({ id }))
      .then(() => {
        setIsLoading(false);
        success("Folder removed");
        getPhotos(moduleId);
      })
      .catch((err) => {
        setIsLoading(false);
        error(err?.response?.data.message || "Could not remove folder");
      });
  };

  const addFolder = (title, base64File) => {
    setIsLoading(true);

    query
      .post(endpoints.photoGalleryFolders.create(), {
        viewerId: moduleId,
        name: title,
      })
      .then((res) => {
        // eslint-disable-line

        const data = new FormData();
        data.append("picture", base64File);
        query
          .post(endpoints.photoGalleryFolders.uploadThumbnail({ id: res.data.id }), data, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            setIsLoading(false);
            success("Created folder");
            getPhotos(moduleId);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err); // eslint-disable-line
            error(err?.response?.data.message || "Could not upload thumbnail");
          });
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not create folder");
      });
  };

  const handleDragStart = (id) => {
    setDraggedFolderId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (id) => {
    setNewTargetFolderId(id);
  };

  const handleDragEnd = () => {
    setDraggedFolderId(undefined);
    setNewTargetFolderId(undefined);
  };
  const addImage = (values) => {
    addPhotoImage(photos, values);
  };

  const updateFolder = (values) => {
    updatePhotoFolder(photos, values);
  };

  // const viewerId = localStorage.getItem("viewerId");
  const sortFolders = () => {
    if (!draggedFolderId || !newTargetFolderId) return;

    sortPhotoFolders(photos, draggedFolderId, newTargetFolderId, moduleId);
  };

  const handleDrop = () => {
    sortFolders();
  };
  const [data, setData] = useState({});
  const [label, setLabel] = useState(data?.label);
  const [loading, setLoading] = useState(false);
  const [displayInLeftMenu, setIsDisabled] = useState(data?.displayInLeftMenu);
  const { fetchModules } = useViewer();

  const [colorOfBox, setColorOfBox] = useState("#FFFFFF");
  const [controlsColor, setControlsColor] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("#FFFFFF");
  const [useLogoBox, setUseLogoBox] = useState(false);
  const [useRoundedCorners, setUseRoundedCorners] = useState(false);
  const [displayFolder, setDisplayFolder] = useState(false);

  useEffect(() => {
    setColorOfBox(data?.colorOfBox || "#FFFFFF");
    setUseLogoBox(data?.useLogoBox);
    setControlsColor(data?.controlsColor || "#FFFFFF");
    setTextColor(data?.textColor || "#FFFFFF");
    setUseRoundedCorners(data?.useRoundedCorners);
    setDisplayFolder(data?.displayFolder);
  }, [data, moduleId]);

  useEffect(() => {
    query
      .get(endpoints.modules.findOne({ id: moduleId }))
      .then((res) => {
        setLabel(res.data.label);
        setIsDisabled(res.data.displayInLeftMenu);
        setData(res.data);
      })
      .catch(() => {});
    window.scrollTo(0, 0);
  }, [moduleId]);

  const handleDisplayInLeftMenu = (e) => {
    setIsDisabled(e.target.checked);
  };
  const { forceRerender } = usePageRerender();

  if (photosLoading || isLoading) {
    return <LoadingModule />;
  }

  return (
    <>
      <Card className="p-6 overflow-auto">
        <Grid className="grid gap-6">
          <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
            PHOTO GALLERY
          </MDTypography>
          <Grid display="flex">
            <Grid item xs={4} className="grid gap-6 ">
              <FormField
                label="Menu Title*"
                placeholder="eg. PHOTO GALLERY"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4} className="specialmarigin centeralignalways">
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch checked={displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography variant="button" className="text-sm text-gray-500">
                    Display in Menu?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>

          <ModuleControls
            setUseRoundedCorners={setUseRoundedCorners}
            useRoundedCorners={useRoundedCorners}
            setColorOfBox={setColorOfBox}
            colorOfBox={colorOfBox}
            useLogoBox={useLogoBox}
            setUseLogoBox={setUseLogoBox}
            setControlsColor={setControlsColor}
            controlsColor={controlsColor}
            setTextColor={setTextColor}
            textColor={textColor}
            displayFolder={displayFolder}
            setDisplayFolder={setDisplayFolder}
            galleryCount={photos?.length}
          />

          <Grid item xs={9}>
            <MDBox>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
          <br />
          <div className="centermodulebuttons">
            <Grid display="flex" justify-content="center" className="customgap">
              <DeleteModule id={data?.id} />
              <MDButton
                color="info"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto"
                disabled={loading}
                onClick={() => {
                  if (label === "") {
                    enqueueSnackbar("Menu title cannot be empty", {
                      variant: "error",
                      autoHideDuration: 2000,
                    });
                    return;
                  }
                  setLoading(true);
                  query
                    .patch(endpoints.modules.updateOne({ id: moduleId }), {
                      label,
                      displayInLeftMenu,
                      useLogoBox,
                      useRoundedCorners,
                      colorOfBox,
                      controlsColor,
                      textColor,
                      displayFolder,
                    })
                    .then(() => {
                      setLoading(false);
                      enqueueSnackbar("Module updated", {
                        variant: "success",
                        autoHideDuration: 2000,
                      });
                      forceRerender();
                      fetchModules();
                    })
                    .catch((e) => {
                      setLoading(false);
                      enqueueSnackbar("Module not updated", {
                        variant: "error",
                        autoHideDuration: 2000,
                      });
                    });
                }}
              >
                {loading ? "Saving..." : "Update"}
              </MDButton>
            </Grid>
          </div>
        </Grid>
      </Card>

      {photosLoading || isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null}

      <MDBox display="flex" justifyContent="flex-start" className="mx-4 mt-5">
        <Header addFolder={addFolder} />
      </MDBox>

      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-5">
        {photos.map((folder) => (
          <PhotoCard
            removeFolder={removeFolder}
            addImage={addImage}
            updateFolder={updateFolder}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            handleDrop={handleDrop}
            key={folder?.id}
            folder={folder}
            getPhotos={getPhotos}
          />
        ))}
      </div>

      {photos.length === 0 && (
        <MDTypography variant="h6" fontWeight="bold" className="text-center mb-12">
          Jump in and Create a Gallery!
        </MDTypography>
      )}

      <br />
      <Card className="p-6 overflow-auto">
        <Alert
          severity="info"
          className="text-md py-3 md:text-sm text-center"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div className="text-center mx-auto">
            <h3 className="font-bold"> Need new professional still photos?</h3>
            Let us take care of that for you!
            <br />
            We provide professional Marketing Photography services globally.
            <br />
            <a href="https://www.viewlio.com/photography/" target="_blank" rel="noreferrer">
              <MDButton
                size="medium"
                variant="outlined"
                className="mx-auto w-full sm:w-1/4 md:w-auto mt-2 specialcolor"
              >
                Learn more
              </MDButton>
            </a>
          </div>
        </Alert>
      </Card>
    </>
  );
}

PhotoGallery.defaultProps = {
  photos: [],
};

PhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  removePhotoFolder: PropTypes.func.isRequired,
  addPhotoImage: PropTypes.func.isRequired,
  updatePhotoFolder: PropTypes.func.isRequired,
  sortPhotoFolders: PropTypes.func.isRequired,
  getPhotos: PropTypes.func.isRequired,
  photosLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    photos: state.getIn(["photos", "photos"]),
    photosLoading: state.getIn(["photos", "photosLoading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removePhotoFolder: (photos, id) =>
      dispatch(
        removePhotoFolderRoutine.trigger({
          photos,
          id,
        })
      ),
    addPhotoFolder: (photos, title, base64File) =>
      dispatch(
        addPhotoFolderRoutine.trigger({
          photos,
          title,
          base64File,
        })
      ),
    addPhotoImage: (photos, values) =>
      dispatch(
        addPhotoImageRoutine.trigger({
          photos,
          values,
        })
      ),
    updatePhotoFolder: (photos, values) =>
      dispatch(
        updatePhotoFolderRoutine.trigger({
          photos,
          values,
        })
      ),
    sortPhotoFolders: (photos, draggedFolderId, newTargetFolderId, moduleId) =>
      dispatch(
        sortPhotoFoldersRoutine.trigger({
          photos,
          draggedFolderId,
          newTargetFolderId,
          moduleId,
        })
      ),
    getPhotos: (moduleId) => dispatch(getPhotosRoutine.trigger({ moduleId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);
