/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import "cropperjs/dist/cropper.css";

import { FormControlLabel, Grid, Switch } from "@mui/material";
import MuiColorPicker from "components/color-picker";
import { createColor } from "material-ui-color";

export default function ModuleControls({
  setUseRoundedCorners,
  useRoundedCorners,
  setUseLogoBox,
  colorOfBox,
  useLogoBox,
  setColorOfBox,
  setControlsColor,
  controlsColor,
  setTextColor,
  textColor,
  setDisplayFolder,
  displayFolder,
  galleryCount,
}) {
  // eslint-disable-next-line no-unused-vars

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} className="nowrapalways" display="flex" alignItems="center">
          <span className="text-sm text-gray-500">Controls Color </span>{" "}
          <MuiColorPicker
            color={createColor(controlsColor)}
            setColor={(e) => {
              setControlsColor(`#${e.hex}`);
            }}
          />
        </Grid>
        <Grid item xs={4} className="nowrapalways" display="flex" alignItems="center">
          <span className="text-sm text-gray-500">Text Color </span>{" "}
          <MuiColorPicker
            color={createColor(textColor)}
            setColor={(e) => {
              setTextColor(`#${e.hex}`);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label="Use Image Frames?"
            control={
              <Switch
                onChange={(event) => {
                  setUseLogoBox(event.target.checked);
                }}
                checked={useLogoBox}
              />
            }
          />{" "}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} className="nowrapalways" display="flex" alignItems="center">
          <span className="text-sm text-gray-500">Frame Color </span>{" "}
          <MuiColorPicker
            color={createColor(colorOfBox)}
            setColor={(e) => {
              setColorOfBox(`#${e.hex}`);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label="Use Rounded Corners?"
            control={
              <Switch
                onChange={(event) => {
                  setUseRoundedCorners(event.target.checked);
                }}
                checked={useRoundedCorners}
              />
            }
          />
        </Grid>
        {galleryCount === 1 && (
          <Grid item xs={4}>
            <FormControlLabel
              label="Display Folder?"
              control={
                <Switch
                  onChange={(event) => {
                    setDisplayFolder(event.target.checked);
                  }}
                  checked={displayFolder}
                />
              }
            />{" "}
          </Grid>
        )}
      </Grid>
    </>
  );
}
