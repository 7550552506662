// react-router-dom components

// @mui icons

// Images
import bgImage from "@core/assets/images/bg-sign-in-basic.jpeg";
import GuestLayout from "layouts/guest-layout";
import NewUser from "./new-user";

function SignUp() {
  return (
    <GuestLayout image={bgImage} isSignUp>
      <NewUser />
    </GuestLayout>
  );
}

export default SignUp;
