/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import SwitchToggle from "components/switch";
import { useViewer } from "contexts/viewer";
// import { moduleTypes } from "mock-data/modules";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { addNewModules, availableModules } from "utils/availableModules";

function AddModuleContent({ close, parent }) {
  const { enqueueSnackbar } = useSnackbar();
  const { viewer, modules, fetchModules } = useViewer();
  const [displayToggle, setDisplayToggle] = useState(true);
  const [moduleType, setModuleType] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const save = () => {
    if (!moduleType) {
      enqueueSnackbar("Select module type", { variant: "error", autoHideDuration: 2000 });
      return;
    }

    setLoading(true);
    query
      .post(endpoints.modules.create({ id: viewer.id.toString() }), {
        label: moduleName,
        name: moduleName?.toLowerCase().replace(" ", "-"),
        type: moduleName?.toLowerCase().replace(" ", "-"),
        viewerId: viewer.id.toString(),
        parent: "",
        order: modules.length + 1,
        menuTitle: moduleType.value,
        displayInLeftMenu: displayToggle,

        // label: "Floor Plans",
        // name: "floor-plans",
        // type: "floor-plans",
        // viewerId: viewer.id.toString(),
        // order: 11,
        // displayInLeftMenu: displayToggle,
      })
      .then((res) => {
        setLoading(false);
        close();
        enqueueSnackbar("Module created", { variant: "success", autoHideDuration: 2000 });
        fetchModules();
      })
      .catch((err) => {
        setError(err?.response?.data?.message || "Something went wrong");
        setLoading(false);
      });
  };

  // const moduleTypes = useMemo(
  //   () =>
  //     (modules || [])
  //       .filter((x) => !x.parent && availableModules.includes(x.type))
  //       .map((item) => ({
  //         label: item.label,
  //         value: item.type,
  //         id: item?.modules?.[0]?.id ? item?.modules?.[0]?.id : item?.id,
  //       })),
  //   [modules]
  // );

  // useEffect(() => {
  //   moduleTypes.push({
  //     label: "CUSTOM",
  //     value: "custom",
  //     id: "",
  //   });
  // }, []);

  useEffect(() => {
    if (parent) {
      setModuleType({ label: parent.label, value: parent.type });
    }
  }, [parent]);

  return (
    <MDBox style={{ padding: "12px" }} component="form">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            label="Title"
            placeholder=""
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div>
            <h1 className="text-xs text-gray-500">Display module in left menu</h1>
            <SwitchToggle checked={displayToggle} setChecked={setDisplayToggle} />
          </div>
        </Grid>

        <Grid item xs={12} className="py-2">
          <span className="text-xs">Select Module Type</span>

          <Autocomplete
            // defaultValue={moduleTypes[0]}
            options={addNewModules.map((module)=>({
                ...module,
                label: module.label.toLocaleUpperCase()
              }))}
            value={moduleType}
            onChange={(e, newValue) => {
              setModuleType(newValue);
            }}
            disableClearable
            renderInput={(params) => <MDInput {...params} variant="standard" />}
            placeholder="Module type"
          />
        </Grid>

        {error && (
          <Grid item xs={12} className="py-2">
            <p className="text-red-500 text-xs text-center">{error}</p>
          </Grid>
        )}

        <MDBox ml="auto" mt={2}>
          <MDButton color="info" size="small" disabled={loading} onClick={save}>
            {loading ? "Saving..." : "Add Module"}
          </MDButton>
        </MDBox>
      </Grid>
    </MDBox>
  );
}

export default AddModuleContent;
