/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { connect } from "react-redux";
import { useUrl } from "contexts/url";
import getVideosRoutine from "./routines";

function Videos(props) {
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { getVideos } = props;
    getVideos(moduleId);
  }, [moduleId]);
  return null;
}

Videos.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getVideos: (moduleId) => dispatch(getVideosRoutine.trigger({ moduleId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
