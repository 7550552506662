/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import { useEffect, useState } from "react";
import "./stripe.css";

import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Link, Switch, TextField } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { baseUrl } from "api";
import paymentImage from "assets/payment.png";
import axios from "axios";
import { useViewer } from "contexts/viewer";
import { subscriptionsData } from "data";
import keys from "data/keys";

export default function CheckoutForm({
  loading,
  viewerError,
  setLoadingMessage,
  subscriptionRows,
  totalAmount,
  plan,
  submitForm,
  submitValues,
  activeStep,
  handleBack,
  removeOneviewerLogo,
  setupExtras,
  loadingMessage,
  paymentStatus,
  setErrorMessage,
  errorMessage,
  setIsLoading,
  isLoading,
  viewerId,
  customerDefaultPaymentMethod,
}) {
  const { viewer } = useViewer();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [message, setMessage] = useState(null);
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
  const [defaultPaymentMethod, setPaymentMethod] = useState(false);
  const [useDefaultCard, setUseDefaultCard] = useState(true);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [couponData, setCouponData] = useState(false);

  const userId = localStorage.getItem("$id");

  useEffect(() => {
    if (customerDefaultPaymentMethod) {
      setPaymentMethod(customerDefaultPaymentMethod);
    } else {
      setIsPaymentMethodLoading(true);
      try {
        fetch(`${baseUrl}/users/${userId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json", token: localStorage.getItem("$token") },
        })
          .then((res) => res.json())
          .then((data) => {
            setIsPaymentMethodLoading(false);
            if (data.statusCode > 201) {
              setUseDefaultCard(false);
              setIsPaymentMethodLoading(false);
            } else if (data?.cardNumber !== "") {
              setPaymentMethod(data?.cardNumber);
            } else {
              setUseDefaultCard(false);
              setPaymentMethod(null);
            }
          });
      } catch (e) {
        setUseDefaultCard(false);
        setIsPaymentMethodLoading(false);
      }
    }
  }, []);

  const transformedData = Object.keys(subscriptionsData)?.reduce((acc, key) => {
    acc[subscriptionsData[key].price] = { amount: subscriptionsData[key].amount };
    return acc;
  }, {});

  const finalArray = subscriptionRows?.recurring?.map((item) => ({
    ...item,
    amount: transformedData[item.price]?.amount || 0,
  }));
  const recurringAmount = finalArray?.reduce((total, item) => total + item.amount, 0);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(keys);

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    console.log("THIS ONE");
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (localStorage.getItem("$role") === "superadmin") {
      setIsLoading(true);
      setCouponError("");
      setLoadingMessage("Creating gallery.....");
      submitForm(submitValues, {
        name,
        couponCode,
        email,
        subscriptionRows,
        totalAmount,
        paymentMethodId: "",
      });
      return;
    }

    if (!useDefaultCard && name === "" && email === "") {
      setIsLoading(false);
      setErrorMessage("Details cannot be empty");
      return;
    }

    // Create a payment method using the card element
    let paymentMethodId;
    if (!useDefaultCard) {
      const cardElement = elements.getElement(CardElement);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      paymentMethodId = paymentMethod.id;
    } else {
      paymentMethodId = defaultPaymentMethod;
    }

    if (paymentMethodId) {
      setErrorMessage("");
      setIsLoading(true);

      if (paymentStatus) {
        setLoadingMessage("Processing payment.....");
        await axios
          .post(`${baseUrl}/billing/subscription`, {
            name,
            couponCode,
            email,
            subscriptionRows,
            totalAmount,
            paymentMethod: paymentMethodId,
            viewerId: viewer.id,
            userId: localStorage.getItem("$id"),
            // expiringDate: string,
          })
          .then(async (res) => {
            const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const daysInMonth = getDaysInMonth(year, month);
            const newFutureDate = new Date(currentDate);
            newFutureDate.setDate(newFutureDate.getDate() + daysInMonth);

            setIsLoading(false);
            submitForm(
              {
                paynentId: res?.data?.subscriptions?.viewerSubscription?.id
                  ? res?.data?.subscriptions?.viewerSubscription?.id
                  : "",
                removeLogoSubscriptionId: res?.data?.subscriptions?.removeLogoSubscription?.id
                  ? res?.data?.subscriptions?.removeLogoSubscription?.id
                  : "",
              },
              res.data
            );
          })
          .catch((err) => {
            setIsLoading(false);
            setErrorMessage(err.response.data.message);
            setLoadingMessage("Purchase");
            if (err.response.status === 401) {
              window.location.href = "/";
            }
          });
      } else {
        submitForm(submitValues, {
          name,
          couponCode,
          email,
          subscriptionRows,
          totalAmount,
          paymentMethodId,
        });
      }
    } else {
      setErrorMessage("");
      setIsLoading(true);
      setErrorMessage("Details cannot be empty");
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"SF-Pro-Rounded"',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form id="payment-form">
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e?.target?.value)}
      /> */}
      <br />
      <center>
        <img src={paymentImage} alt="" width="65%" />
      </center>
      <br />
      {localStorage.getItem("$role") !== "superadmin" && (
        <>
          {isPaymentMethodLoading ? (
            <MDTypography variant="caption" fontWeight="regular" color="text">
              Loading....
            </MDTypography>
          ) : (
            <>
              {" "}
              {!!defaultPaymentMethod && (
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch
                      checked={useDefaultCard}
                      onChange={() => setUseDefaultCard((prevState) => !prevState)}
                    />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button">Use default card</MDTypography>
                  </MDBox>
                </MDBox>
              )}
              {elements && !useDefaultCard ? (
                <>
                  {" "}
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-2">
                    <div className="flex flex-col gap-y-1">
                      <div className="text-sm text-gray-500">Name On Card</div>
                      <TextField type="text" onChange={(e) => setName(e.target.value)} fullWidth />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="text-sm text-gray-500">Email</div>
                      <TextField type="text" onChange={(e) => setEmail(e.target.value)} fullWidth />
                    </div>
                  </div>
                  <div style={{ marginTop: "12px" }} className="flex flex-col gap-y-1">
                    <div className="text-sm text-gray-500">Card Details</div>
                    <CardElement id="payment-element" options={paymentElementOptions} />
                  </div>
                </>
              ) : null}
            </>
          )}
          {errorMessage && (
            <div id="error-message" style={{ color: "red" }}>
              <br /> {errorMessage}
            </div>
          )}
          <center>
            <div style={{ marginTop: "12px" }}>
              <div className="text-sm text-gray-500">Promo Code</div>
              <TextField type="text" onChange={(e) => setCouponCode(e.target.value)} />

              <MDButton
                style={{ marginLeft: "12px", marginTop: "2px" }}
                type="button"
                onClick={() => {
                  if (couponCode === "") {
                    setCouponError("Coupon cannot be empty");
                    return;
                  }

                  setIsCouponLoading(true);
                  fetch(`${baseUrl}/billing/coupon/${couponCode}`, {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      token: localStorage.getItem("$token"),
                    },
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      setIsCouponLoading(false);
                      if (data.message === "success") {
                        if (!data.data.valid) {
                          setCouponError("Invalid coupon");
                          return;
                        }
                        setCouponError("");
                        const charge = data.data.percent_off
                          ? data.data.percent_off
                          : data.data.amount_off / 100;

                        const isPercentage = !!data.data.percent_off;

                        let onceOffTotal = 0;
                        if (subscriptionRows.onceoff) {
                          onceOffTotal = subscriptionRows.onceoff.amount - charge;
                        }
                        let totalRecurring = recurringAmount - charge;

                        if (isPercentage) {
                          const percentage = charge / 100;
                          if (subscriptionRows.onceoff) {
                            onceOffTotal = percentage * subscriptionRows.onceoff.amount;
                            onceOffTotal = subscriptionRows.onceoff.amount - onceOffTotal;
                          }

                          totalRecurring = percentage * recurringAmount;
                          totalRecurring = recurringAmount - totalRecurring;
                        }
                        const couponInfo = {
                          isPercentage: !!data.data.percent_off,
                          charge,
                          symbol: data.data.percent_off ? "%" : "$",
                          onceOffTotal,
                          totalRecurring,
                        };
                        setCouponData(couponInfo);
                      } else {
                        setCouponError(data.message);
                        setIsCouponLoading(false);
                      }
                    })
                    .catch((e) => {
                      setCouponError(e.message);
                      setIsCouponLoading(false);
                    });
                }}
                color="dark"
              >
                {isCouponLoading ? "Applying..." : "Apply"}
              </MDButton>
            </div>
            {couponError && (
              <div id="error-message" style={{ color: "red" }}>
                {couponError}
              </div>
            )}
          </center>
          <h2 style={{ marginTop: "18px" }} className="text-sm text-gray-500 mt-3 font-bold">
            For subscriptions, your card will be charged at the end of the 14-day trial period, and
            billing will recur automatically per your subscription choice. You may cancel anytime to
            avoid future billings. Setup Support services purchased will be charged immediately.
            There are no refunds, pro-rated or full, for already processed billings. You can update
            your Credit Card on file and access the Invoices for each purchase in your Billing
            Settings.
          </h2>
          <h2 style={{ marginTop: "18px" }} className="text-sm text-gray-500 mt-3 font-bold">
            By purchasing, you accept these Payment Terms and our{" "}
            <MDTypography
              component={Link}
              href="https://www.viewlio.com/terms"
              variant="a"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              target="_blank"
            >
              Terms of Use,
            </MDTypography>{" "}
            and you acknowledge reading the{" "}
            <MDTypography
              component={Link}
              href="https://www.viewlio.com/privacy"
              variant="a"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              target="_blank"
            >
              Privacy Policy.
            </MDTypography>{" "}
            You also agree to auto renewal of your subscription, which can be disabled at any time
            through your account.
          </h2>
        </>
      )}

      {recurringAmount && (
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          className="flex-col gap-y-2 md:flex-row"
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          <p
            style={{ display: "inline-flex", justifyContent: "left" }}
            className="text-sm text-gray-500 mt-4 font-bold"
          >
            {" "}
            Total to be charged after 14-day trial{" "}
            {couponData && (
              <b>
                (
                {couponData.isPercentage
                  ? `${couponData.charge}${couponData.symbol}`
                  : `${couponData.symbol}${couponData.charge}`}{" "}
                DISCOUNT )
              </b>
            )}
            :{" "}
          </p>
          {viewerError && <p className="text-sm text-red-600 text-center">{viewerError}</p>}
          <p
            className="text-sm text-gray-500 mt-4 font-bold"
            style={{ display: "inline-flex", justifyContent: "right" }}
          >
            ${couponData ? couponData.totalRecurring.toFixed(2) : recurringAmount}
          </p>
        </MDBox>
      )}
      {subscriptionRows?.onceoff && (
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          className="flex-col gap-y-2 md:flex-row"
          style={{ marginTop: recurringAmount ? "-12px" : "" }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          <p
            style={{ display: "inline-flex", justifyContent: "left" }}
            className="text-sm text-gray-500 mt-4 font-bold"
          >
            {" "}
            Total to be charged immediately{" "}
            {couponData && (
              <b style={{ marginLeft: "3px" }}>
                (
                {couponData.isPercentage
                  ? `${couponData.charge}${couponData.symbol}`
                  : `${couponData.symbol}${couponData.charge}`}{" "}
                DISCOUNT)
              </b>
            )}
            :{" "}
          </p>
          {viewerError && <p className="text-sm text-red-600 text-center">{viewerError}</p>}
          <p
            className="text-sm text-gray-500 mt-4 font-bold"
            style={{ display: "inline-flex", justifyContent: "right" }}
          >
            ${couponData ? couponData.onceOffTotal.toFixed(2) : subscriptionRows?.onceoff?.amount}
          </p>
        </MDBox>
      )}

      <MDBox
        mt={2}
        width="100%"
        display="flex"
        justifyContent="space-between"
        className="flex-col gap-y-2 md:flex-row"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {activeStep === 0 ? <MDBox /> : null}
        <MDButton
          disabled={isLoading || !stripe || !elements || isPaymentMethodLoading}
          color="light"
          onClick={handleBack}
        >
          back
        </MDButton>
        {viewerError && <p className="text-sm text-red-600 text-center">{viewerError}</p>}
        {localStorage.getItem("$role") === "superadmin" ? (
          <div className="css-processingpaymentbutton">
            <MDButton disabled={isLoading} color="dark" onClick={handleSubmit}>
              {loading && ""}
              {isLoading ? loadingMessage : "Create Gallery"}
            </MDButton>
          </div>
        ) : (
          <MDButton
            disabled={isLoading || !stripe || !elements || isPaymentMethodLoading}
            color="dark"
            className="PurchaseandCreateViewer"
            onClick={handleSubmit}
          >
            {loading && ""}
            {(isLoading ? loadingMessage : paymentStatus) ||
              (isLoading ? loadingMessage : "PURCHASE AND CREATE GALLERY")}
          </MDButton>
        )}
      </MDBox>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
