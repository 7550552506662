/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import { useViewer } from "contexts/viewer";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AgentInfo from "./components/agent-info";
import Design from "./components/design";
import Location from "./components/location";
import Sidenav from "./components/sidenav";
import Socials from "./components/socials";
import TransferViewer from "./components/transfer-viewer";

function ViewerSettings(props) {
  const { setViewer } = useViewer();

  const { name: viewerName } = useParams();

  const getViewerData = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName })).then((res) => {
      if (res.data.length) {
        setViewer(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getViewerData();
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <MDBox mt={0}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3} className="w-full md:max-w-[260px]">
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <Header />
              </Grid> */}
              <Grid item xs={12}>
                <Location snack={enqueueSnackbar} />
              </Grid>
              <Grid item xs={12}>
                <Design snack={enqueueSnackbar} />
              </Grid>
              <Grid item xs={12}>
                <AgentInfo snack={enqueueSnackbar} />
              </Grid>
              <Grid item xs={12}>
                <Socials snack={enqueueSnackbar} />
              </Grid>
              {localStorage.getItem("$role") === "superadmin" && (
                <Grid item xs={12}>
                  <TransferViewer snack={enqueueSnackbar} />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ViewerSettings;
