// eslint-disable-next-line import/no-extraneous-dependencies
import { fromJS } from "immutable";

import getVideosRoutine, {
  removeVideoFolderRoutine,
  addVideoFolderRoutine,
  sortVideoFoldersRoutine,
} from "./routines";

const initialState = fromJS({});

export default function videoReducer(state = initialState, action) {
  switch (action.type) {
    case getVideosRoutine.TRIGGER:
      return state.merge({
        videosLoading: true,
      });

    case getVideosRoutine.SUCCESS:
      return state.merge({
        videos: action.payload.sort((a, b) => a.order - b.order),
        videosLoading: false,
      });

    case getVideosRoutine.FAILURE:
      return state.merge({
        videosLoading: false,
      });

    case removeVideoFolderRoutine.SUCCESS:
      return state.merge({
        videos: action.payload,
      });

    case addVideoFolderRoutine.SUCCESS:
      return state.merge({
        videos: action.payload,
      });

    case sortVideoFoldersRoutine.TRIGGER:
      return state.merge({
        videosLoading: true,
      });
    case sortVideoFoldersRoutine.SUCCESS:
      return state.merge({
        videos: action.payload.sort((a, b) => a.order - b.order),
        videosLoading: false,
      });
    case sortVideoFoldersRoutine.FAILURE:
      return state.merge({
        videosLoading: false,
      });

    default:
      return state;
  }
}
