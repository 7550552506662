/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/users/new-user/components/FormField";
import Grid from "@mui/material/Grid";
import MuiColorPicker from "components/color-picker";
import FontSelector from "components/font-selector";
import SwitchToggle from "components/switch";
import { createColor } from "material-ui-color";
import PropTypes from "prop-types";
import { useState } from "react";

function Profile({ formData }) {
  const { formField, values } = formData;
  const { publicEmail, bio } = formField;
  const { publicEmail: publicEmailV, bio: bioV } = values;
  const [frameColor, setFrameColor] = useState(createColor("#000"));
  const [windowColor, setWindowColor] = useState(createColor("#fff"));
  const [menuColor, setMenuColor] = useState(createColor("#2e2e32"));
  const [menuHoverColor, setMenuHoverColor] = useState(createColor("#626268"));
  const [fontColor, setFontColor] = useState(createColor("#fff"));

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Design
      </MDTypography>
      <MDBox mt={1.625}>
        <p className="apply-font text-sm mb-5 text-gray-500">
          The selected font will be applied to this form.
        </p>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 apply-font">
          <div>
            <h1 className="text-sm text-gray-500 mb-2">Gallery Frame Background Color</h1>
            <MuiColorPicker color={frameColor} setColor={setFrameColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Gallery Window Background Color</h1>
            <MuiColorPicker color={windowColor} setColor={setWindowColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Menu Color</h1>
            <MuiColorPicker color={menuColor} setColor={setMenuColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Menu background</h1>
            <MuiColorPicker color={menuHoverColor} setColor={setMenuHoverColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Text Color</h1>
            <MuiColorPicker color={fontColor} setColor={setFontColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Font Selector</h1>
            <FontSelector />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">
              Do you want to use icons for main menu items?
            </h1>
            <SwitchToggle initialState />
          </div>
        </div>

        <Grid container spacing={1} className="hidden">
          <Grid item xs={12}>
            <FormField
              type={publicEmail.type}
              label={publicEmail.label}
              name={publicEmail.name}
              value={publicEmailV}
              // placeholder={publicEmail.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={bio.type}
              label={bio.label}
              name={bio.name}
              value={bioV}
              // placeholder={bio.placeholder}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Profile.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Profile;
