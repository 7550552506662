/* eslint-disable import/no-unresolved */
import breakpoints from "@core/assets/theme/base/breakpoints";
import MDBox from "@core/components/MDBox";
import Footer from "@core/examples/Footer";
import DashboardLayout from "@core/examples/LayoutContainers/DashboardLayout";
import { UserContextProvider } from "contexts/user";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DashboardNavbar from "./DashboardNavbar";

function BaseLayout({ children, pageName, action }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <DashboardLayout>
      <UserContextProvider>
        {/* <RequireAuthentication /> */}
        <DashboardNavbar pageName={pageName} action={action}/>
        <MDBox mt={0} className="min-h-[80vh]">
          {children}
        </MDBox>
        <Footer />
      </UserContextProvider>
    </DashboardLayout>
  );
}

BaseLayout.defaultProps = {
  pageName: "",
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
};

export default BaseLayout;
