/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-import-module-exports */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Alert, Card, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useEffect } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function VirtualTour({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setEdits({
      virtualTourUrl: data?.virtualTourUrl,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
    });
  }, [data, setEdits]);

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };

  function isEmbeddedHtmlCode(inputString) {
    // Define the regular expression pattern for HTML tags
    const htmlTagPattern = /<[^>]*>/;

    // Test the input string against the regular expression
    return htmlTagPattern.test(inputString);
  }

  return (
    <>
      <Card className="p-6 overflow-auto">
        <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
          VIRTUAL TOUR
        </MDTypography>
        <Grid display="flex">
          <Grid item xs={4} className="grid gap-6 ">
            <FormField
              label="Menu Title*"
              placeholder="eg. VIRTUAL TOUR"
              value={edits?.label || ""}
              onChange={(e) => setEdit("label", e.target.value)}
            />
          </Grid>

          <Grid item xs={4} className="specialmarigin centeralignalways">
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" className="text-sm text-gray-500">
                  Display in Menu?
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
        </Grid>

        <br />

        <FormField
          type="text"
          label="Virtual Tour URL"
          value={edits?.virtualTourUrl || ""}
          onChange={(e) => {
            let url = e.target.value;
            if (isEmbeddedHtmlCode(e.target.value)) {
              const startIndex = url.indexOf('src="') + 5; // Find the start of the src attribute value
              const endIndex = url.indexOf('"', startIndex); // Find the end of the src attribute value
              url = url.substring(startIndex, endIndex); // Extract the URL
            }
            setEdit("virtualTourUrl", url);
          }}
          placeholder="Please enter the entire URL of the hosted Virtual Tour"
        />
        <div className="text-sm">
          <MDTypography variant="button" fontWeight="regular" color="text">
            You can add URLs from any type of Virtual Tour, such as from Google Maps, Matterport,
            Panowalks, GoThru, CloudPano, YouVisit, and many others.
          </MDTypography>
          <br />
          <br />
          <div className="font-bold mb-1 specialcolor">
            How to get a URL from Google Maps Virtual Tours
          </div>
          <ol type="1">
            <li>
              1. Open{" "}
              <MDButton
                as="a"
                href="https://www.google.com/maps"
                rel="noreferrer"
                target="_blank"
                style={{ margin: 0, padding: 0 }}
              >
                Google Maps
              </MDButton>
              .
            </li>
            <li>2. Go to the Virtual Tour you’d like to add.</li>
            <li>3. Click the 3 vertical dots.</li>
            <li>
              4. Click <span className="font-bold">Share or embed image</span>.
            </li>
            <li>
              5. Click <span className="font-bold">COPY HTML</span>.
            </li>
            <li>6. Paste the code into the Virtual Tour URL field above for this module.</li>
          </ol>
        </div>

        <Grid item xs={9}>
          <MDBox>
            <ClickAlert link={getModuleRoute(data)} />
          </MDBox>
        </Grid>
        <br />
        <div className="centermodulebuttons">
          <Grid display="flex" justify-content="center" className="customgap">
            <DeleteModule id={data?.id} />
            <MDButton color="info" size="medium" onClick={save} disabled={loading}>
              {loading ? "Updating..." : "UPDATE"}
            </MDButton>
          </Grid>
        </div>
      </Card>
      <br />
      <Card className="p-6 overflow-auto">
        <Alert
          severity="info"
          className="text-sm md:text-md text-center"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div className="text-center mx-auto">
            <h2 className="font-bold"> Don&apos;t have a Virtual Tour? </h2>
            No problem! Let us create a custom one for you.
            <br /> We provide professional 360º Virtual Tour photography services globally.
            <br />
            <br /> Click here to learn more and get started!
            <br />
            <a href="https://www.viewlio.com/virtual-tours/" target="_blank" rel="noreferrer">
              <MDButton
                variant="outlined"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto mt-2 specialcolor"
              >
                Learn more
              </MDButton>
            </a>
          </div>
        </Alert>
      </Card>
    </>
  );
}

export default function VirtualTourModule() {
  return <ModuleWrapper component={VirtualTour} />;
}
