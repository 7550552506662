import { processDates } from "./formatUnixDate";

export const getPropertyList = (list, property) => list?.map((item) => item[property]);

export const organizeData = (data) => {
  const organizedData = {
    day: [],
    month: [],
    year: [],
  };

  // Organize data by day
  organizedData.day = data
    .map((entry) => {
      const year = entry.date.slice(0, 4);
      const month = entry.date.slice(4, 6);
      const day = entry.date.slice(6, 8);
      return {
        date: `${year}/${month}/${day}`,
        visits: entry.visits,
      };
    })
    .sort((a, b) => a.date.localeCompare(b.date));

  // Organize data by month
  const monthlyData = {};
  data.forEach((entry) => {
    const month = entry.date.slice(4, 6);
    if (!monthlyData[month]) {
      monthlyData[month] = {
        date: entry.date,
        visits: 0,
      };
    }
    monthlyData[month].visits += entry.visits;
  });
  organizedData.month = Object.values(monthlyData)
    .sort((a, b) => a.date.localeCompare(b.date))
    .map((monthEntry) => ({
      date: processDates([monthEntry.date], "month")[0],
      visits: monthEntry.visits,
    }));

  // Organize data by year
  const yearlyData = {};
  data.forEach((entry) => {
    const year = entry.date.slice(0, 4);
    if (!yearlyData[year]) {
      yearlyData[year] = {
        date: year,
        visits: 0,
      };
    }
    yearlyData[year].visits += entry.visits;
  });
  organizedData.year = Object.values(yearlyData);

  return organizedData;
};
