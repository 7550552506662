/* eslint-disable import/no-unresolved */
import { useMediaQuery } from "@mui/material";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { EyeOpen, StatisticUp } from "akar-icons";
import { endpoints, host, query } from "api";
import SwitchToggle from "components/switch";
import { usePageRerender } from "contexts/rerender";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import { FiDatabase } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PATHS from "routes/paths";
import { getModuleRoute } from "utils/availableModules";

export default function ViewerHeader({ showMenuIconSwitch }) {
  const { viewer, setViewer } = useViewer();
  const [showMenuIcons, setShowMenuIcons] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [loading, setLoading] = useState();
  const [savingResult, setSavingResult] = useState();
  const { tab: currentTab, name: viewerName } = useParams();
  const { search: searchString } = useLocation();
  const navigate = useNavigate();
  const isMobile = !useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleOpenTab = (tab) => {
    navigate(`${PATHS.viewer.replace(":name", viewerName).replace(":tab", tab)}${searchString}`);
  };

  const [data, setData] = useState({});

  const getTabUrl = () => `${host}/${viewer.slug}/${getModuleRoute(data)}`;

  const handleOpenTabzs = (tab) => {
    const tabUrl = getTabUrl();
    const tabId = `viewer_tab_${tab}`;

    // Check if the tab is already opened
    const existingTab = window.open(tabUrl, tabId);

    if (existingTab) {
      // If the tab is already opened, focus on it and reload
      existingTab.focus();
      existingTab.location.reload();
    } else {
      // If the tab is not opened, open it in a new tab
      window.open(tabUrl, "_blank");
    }
  };

  const handleUseIconsForMainMenuItemsChange = (value) => {
    setLoading(true);
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
        useIconsForMainMenuItems: value,
      })
      .then((res) => {
        setViewer(res.data);
        setLoading(false);
        setSavingResult({ success: "updated" });
      })
      .catch((err) => {
        setSavingResult({ error: "failed. Please retry" });
        setLoading(false);
      });
  };

  const handleUseForMainMenuItemsChange = (value) => {
    setLoading(true);
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
        showMenu: value,
      })
      .then((res) => {
        setViewer(res.data);
        setLoading(false);
        setSavingResult({ success: "updated" });
      })
      .catch((err) => {
        setSavingResult({ error: "failed. Please retry" });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (viewer) {
      setShowMenuIcons(viewer.useIconsForMainMenuItems);
      setShowMenu(viewer.showMenu);
    }
  }, [viewer]);

  useEffect(() => {
    handleOpenTab(currentTab || "builder");
    setSavingResult();
  }, []);

  useEffect(() => {
    if (savingResult) {
      setTimeout(() => setSavingResult(), 3000);
    }
  }, [savingResult]);

  const { getSearchParam } = useUrl();

  const moduleId = getSearchParam("moduleId");
  const { rerenderCount } = usePageRerender();

  useEffect(() => {
    query
      .get(endpoints.modules.findOne({ id: moduleId }))
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {});
  }, [moduleId, rerenderCount, viewerName]);

  // const [toDate, setToDate] = useState("");
  // const [fromDate, setFromDate] = useState("");
  return (
    <div className="flex flex-col md:flex-row justify-between w-full gap-y-3">
      {/* <DateRangeSelector
        toDate={toDate}
        setToDate={setToDate}
        fromDate={fromDate}
        setFromDate={setFromDate}
      /> */}
      {!showMenuIconSwitch && <div />}
      {showMenuIconSwitch ? (
        <div className="flex items-center">
          <h1 className="text-xs text-gray-500">Show Menu</h1>
          <SwitchToggle
            classes="ml-0"
            checked={showMenu}
            setChecked={(callback) => {
              const isChecked = showMenu;
              setShowMenu(callback);
              handleUseForMainMenuItemsChange(!isChecked);
            }}
          />
          <h1 className="text-xs text-gray-500">Show Icons</h1>
          <SwitchToggle
            classes="ml-0"
            checked={showMenuIcons}
            setChecked={(callback) => {
              const isChecked = showMenuIcons;
              setShowMenuIcons(callback);
              handleUseIconsForMainMenuItemsChange(!isChecked);
            }}
          />
          {loading ? <span className="text-sm">saving...</span> : ""}
          {(savingResult &&
            (savingResult.error ? (
              <span className="text-red-500 text-small">{savingResult.error}</span>
            ) : (
              <span className="text-green-500 text-sm">{savingResult.success}</span>
            ))) ||
            ""}
          <div style={{ marginLeft: 15 }}>
            <p className="text-sm text-gray-500 nameofviwerstyle">
              {" "}
              {viewerName.toLocaleUpperCase()}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <Tabs orientation={isMobile ? "vertical" : "horizontal"} value={currentTab} className="px-2">
        {viewer?.isActive && data?.displayInLeftMenu && (
          <Tab
            onClick={handleOpenTabzs}
            label="Gallery"
            icon={<EyeOpen />}
            value="analytics"
            className="justify-start md:justify-center md:ml-2 newviewerbutton"
          />
        )}
        {/* ... (add more tabs as needed) */}

        {viewer.isActive && (
          <Tab
            label="Modules"
            icon={<FiDatabase />}
            value="builder"
            onClick={() => handleOpenTab("builder")}
            className="justify-start md:justify-center md:ml-2"
          />
        )}
        {viewer.isActive || localStorage.getItem("$role") === "superadmin" ? (
          <Tab
            label="Settings"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                settings
              </Icon>
            }
            value="settings"
            onClick={() => handleOpenTab("settings")}
            className="justify-start md:justify-center md:ml-2"
          />
        ) : (
          ""
        )}

        {viewer.isActive && (
          <Tab
            label="Analytics"
            icon={<StatisticUp />}
            value="analytics"
            onClick={() => handleOpenTab("analytics")}
            className="justify-start md:justify-center md:ml-2"
          />
        )}

        <Tab
          label="Billing"
          icon={
            <Icon fontSize="small" sx={{ mt: -0.25 }}>
              money
            </Icon>
          }
          value="billing"
          onClick={() => handleOpenTab("billing")}
          className="justify-start md:justify-center md:ml-2"
        />
      </Tabs>
    </div>
  );
}
