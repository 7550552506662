/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Box, Modal, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import { baseUrl, endpoints, query } from "api";
import SwitchToggle from "components/switch";
import { useViewer } from "contexts/viewer";
import { subscriptionsData } from "data";
import { useState } from "react";
import formatUnixDate from "utils/formatUnixDate";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function Extras({
  removeOneviewerLogo,
  setRemoveOneviewerLogo,
  setupExtras,
  setSetupExtras,
  setPlan,
  plan,
  subscriptions,
  subscriptionsItems,
}) {
  const [isLoading, setIsLoading] = useState(false);
  function findLineItemByPlanId(planId) {
    for (const subscription of subscriptions) {
      const items = subscription.subscription.items.data;
      for (const item of items) {
        if (item.plan.id === planId) {
          return item;
        }
      }
    }
    return null; // Return null if no matching item is found
  }

  const planData = subscriptions.filter((subscription) => {
    const subscriptionItem = subscription.subscription.items.data[0];
    return (subscriptionItem.plan.id === plan) === "monthly"
      ? subscriptionsData.monthlyPlan.price
      : subscriptionsData.yearlyPlan.price;
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { viewer } = useViewer();

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold" className="centermobile">
        Subscription Plan
      </MDTypography>
      <MDBox>
        <div className="flex flex-col gap-y-2 justify-center">
          <div className="flex items-center justify-center gap-x-3">
            <h1 className="text-sm text-gray-500 mr-3">Monthly</h1>
            <SwitchToggle
              initialState
              checked={plan === "yearly"}
              setChecked={() => setPlan(plan === "monthly" ? "yearly" : "monthly")}
            />
            <h1 className="text-sm text-gray-500">Annually</h1>
          </div>
          <div className="text-center text-md">
            {plan === "monthly"
              ? `$${subscriptionsData.monthlyPlan.amount} Monthly`
              : `$${subscriptionsData.yearlyPlan.amount} Annually`}
          </div>
          {plan === "yearly" ? (
            <div className="text-sm text-center text-gray-500">
              If you pay annually, you receive 2 months free. A savings of $198/year!
            </div>
          ) : (
            ""
          )}
          {planData.length > 0 ? (
            <div className="text-sm text-center text-gray-500">
              Next billing date {formatUnixDate(planData[0].subscription.current_period_end)}
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
      </MDBox>

      <MDTypography variant="h5" fontWeight="bold">
        Extras
      </MDTypography>
      <MDBox mt={1.625}>
        <div className="mb-7">
          <MDTypography variant="h6" fontWeight="" className="uppercase font-[600] text-[14px]">
            Setup support
          </MDTypography>
          <RadioGroup value={setupExtras} onChange={(e) => setSetupExtras(e.target.value)}>
            <div className="flex gap-x-2 items-center">
              <Radio value="none" />
              <h1 className="text-sm text-gray-500">None</h1>
            </div>
            <div className="flex gap-x-2 items-center">
              <Radio value="setupInstruction" />
              <h1 className="text-sm text-gray-500">
                Concierge 1-Hour Setup Walkthrough Instruction via Zoom + $
                {subscriptionsData.setupWalkthrough.amount}.
              </h1>
            </div>
            <div className="flex gap-x-2 items-center">
              <Radio value="completeViewer" />
              <h1 className="text-sm text-gray-500">
                Concierge Complete Gallery Built for You + $
                {subscriptionsData.completeViewer.amount}.
              </h1>
            </div>
          </RadioGroup>
          <h1 className="text-m text-gray-500">
            If one of these options is selected, we will reach out to you to schedule a Zoom
            meeting.
            <br />
            <span className="text-sm">
              These extra services are not included in the 14-day free trial, and are immediately
              billed upon purchase.
            </span>
          </h1>
        </div>
        <div>
          <MDTypography variant="h6" fontWeight="" className="uppercase font-[600] text-[14px]">
            Logo Removal
          </MDTypography>
          <div className="flex gap-x-2 items-center">
            <Switch
              checked={removeOneviewerLogo}
              value={removeOneviewerLogo}
              onChange={(e) => {
                if (!e.target.checked && viewer?.removeOneviewerLogo) {
                  setOpen(true);
                } else {
                  setRemoveOneviewerLogo(e.target.checked);
                }
              }}
            />
            <h1 className="text-sm text-gray-500">
              Remove Viewlio Logo from Gallery Menu + ${subscriptionsData.removeLogoMontlhy.amount}
              /month or ${subscriptionsData.removeLogoYearly.amount}/year (depending on your
              subscription).
            </h1>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="rounded-xl">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Cancel Subscription
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "16px" }}>
              Are you sure you want to cancel this subscription?
            </Typography>
            <br />
            <div className="flex flex-col md:flex-row gap-x-2 gap-y-2 items-center mt-4">
              <MDButton
                color="error"
                onClick={() => {
                  setIsLoading(true);
                  fetch(
                    `${baseUrl}/billing/cancel-subscriptions/${
                      subscriptions.length > 0 && subscriptions[0]?.removeLogoSubscriptionId
                    }`,
                    {
                      method: "GET",
                      headers: { "Content-Type": "application/json" },
                    }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      setIsLoading(false);
                      query.patch(endpoints.viewers.updateOne({ id: viewer.id }), {
                        removeOneviewerLogo: false,
                      });
                      handleClose();
                      window.location.reload();
                    })
                    .catch(() => {
                      setIsLoading(false);
                    });
                }}
              >
                {isLoading ? "Processing" : "Yes"}
              </MDButton>{" "}
              <MDButton variant="outlined" color="info" onClick={handleClose}>
                No
              </MDButton>
            </div>
          </Box>
        </Modal>
      </MDBox>
    </MDBox>
  );
}

Extras.propTypes = {
  subscriptions: [],
};

export default Extras;
