/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Card, Grid } from "@mui/material";
import { endpoints, query } from "api";
import MuiColorPicker from "components/color-picker";
import FontSelector from "components/font-selector";
import LogoCropper from "components/logoCropper";
import SwitchToggle from "components/switch";
import { useViewer } from "contexts/viewer";
import { DataURIToBlob } from "helpers";
import { createColor } from "material-ui-color";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Design({ snack }) {
  const { viewer, setViewer } = useViewer();
  const [frameColor, setFrameColor] = useState(createColor("#42424A"));
  const [windowColor, setWindowColor] = useState(createColor("#eee"));
  const [menuColor, setMenuColor] = useState(createColor("#42424a"));
  const [menuHoverColor, setMenuHoverColor] = useState(createColor("#626268"));
  const [viewerControlsColor, setViewerControlsColor] = useState(createColor("#626268"));

  const [fontColor, setFontColor] = useState(createColor("#EEEEEE"));
  const [font, setFont] = useState(viewer?.font);
  const [useIconsForMainMenuItems, setUseIconsForMainMenuItems] = useState(
    viewer?.useIconsForMainMenuItems
  );
  const [useColorGradientOnMenu, setUseColorGradientOnMenu] = useState(false);
  const [menuColorGradientSecondColor, setMenuColorGradientSecondColor] = useState("#191919");
  const [file, setFile] = useState("");
  const [deleteImage, setDeleteImage] = useState("");
  const [serverImage, setServerImage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  // create hex by adding '#' to given color
  const createHex = (color) => {
    if (!color) {
      return null;
    }

    if (color.startsWith("#")) {
      return color;
    }

    return `#${color}`;
  };

  useEffect(() => {
    setError();
    setFrameColor(createColor(createHex(viewer?.viewerFrameBackgroundColor)));
    setWindowColor(createColor(createHex(viewer?.windowColor)));
    setMenuColor(createColor(createHex(`#${viewer?.menuColor}`)));
    setMenuHoverColor(createColor(createHex(`#${viewer?.menuBackgroundHoverAndActiveState}`)));
    setFontColor(createColor(createHex(viewer?.fontColor)));
    setViewerControlsColor(createColor(createHex(viewer?.viewerControlsColor)));
    setFont(viewer?.font);
    setUseIconsForMainMenuItems(viewer?.useIconsForMainMenuItems);
    setUseColorGradientOnMenu(viewer?.useColorGradientOnMenu);
    setMenuColorGradientSecondColor(createColor(viewer?.menuColorGradientSecondColor));
    setServerImage(viewer?.logoUrl);
  }, [viewer]);

  const { name: viewerName } = useParams();

  const getViewerData = () => {
    setLoading(true);

    query.get(endpoints.viewers.getAll({}, { name: viewerName, isActive: true })).then((res) => {
      if (res.data.length) {
        setLoading(false);
        setViewer(res.data[0]);
      }
    });
  };

  const save = () => {
    setLoading(true);
    setError();
    let checkLogo = {};
    if (viewer.logoUrl && deleteImage === "yes") {
      checkLogo = { logoUrl: "" };
    }

    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
        viewerFrameBackgroundColor: frameColor?.hex || frameColor,
        windowColor: windowColor?.hex || windowColor,
        menuColor: menuColor?.hex || menuColor,
        menuBackgroundHoverAndActiveState: menuHoverColor?.hex || menuHoverColor,
        fontColor: fontColor?.hex || fontColor,
        viewerControlsColor: viewerControlsColor?.hex || viewerControlsColor,
        font,
        useColorGradientOnMenu,
        useIconsForMainMenuItems,
        menuColorGradientSecondColor:
          createHex(menuColorGradientSecondColor?.hex) || menuColorGradientSecondColor,
        ...checkLogo,
      })
      .then((res) => {
        setLoading(false);
        setViewer(res.data);
        snack("Updated", { variant: "success", autoHideDuration: 2000 });
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Something went wrong");
        snack("Could not update", { variant: "error", autoHideDuration: 2000 });
      });

    if (file) {
      setLoading(true);

      const data = new FormData();
      data.append("picture", DataURIToBlob(file));

      query
        .post(endpoints.viewers.updateLogo({ id: viewer.id }), data, {
          "Content-Type": "multipart/form-data",
        })
        .then(() => {
          getViewerData();
          setLoading(false);
          setFile("");
          snack("Updated logo", { variant: "success", autoHideDuration: 2000 });
        })
        .catch((err) => {
          setFile("");
          snack("Could not update logo", { variant: "error", autoHideDuration: 2000 });
        });
    }
  };

  return (
    <Card id="design" className="p-6">
      <MDBox>
        <MDTypography variant="h5" fontWeight="bold">
          Design
        </MDTypography>
        <MDBox mt={1.625}>
          <div className="grid sm:grid-cols-2 gap-5">
            <div>
              <h1 className="text-sm text-gray-500 mb-2">Frame Color</h1>
              <MuiColorPicker color={windowColor} setColor={setWindowColor} />
            </div>

            <div>
              <h1 className="text-sm text-gray-500 mb-2">Content Window Color</h1>
              <MuiColorPicker color={frameColor} setColor={setFrameColor} />
            </div>

            <div>
              <h1 className="text-sm text-gray-500 mb-2">Menu Color</h1>
              <MuiColorPicker color={menuColor} setColor={setMenuColor} />
            </div>
            {/* {useColorGradientOnMenu && ( */}
            <div>
              <h1 className="text-sm text-gray-500 mb-2">Menu Gradient Second Color</h1>
              <MuiColorPicker
                color={menuColorGradientSecondColor}
                setColor={setMenuColorGradientSecondColor}
              />
            </div>
            {/* )} */}

            <div>
              <h1 className="text-sm text-gray-500 mb-2">Menu Highlight Color</h1>
              <MuiColorPicker color={menuHoverColor} setColor={setMenuHoverColor} />
            </div>
            <div className="flex gap-x-1 items-center">
              <SwitchToggle
                initialState
                checked={useColorGradientOnMenu}
                setChecked={setUseColorGradientOnMenu}
              />

              <h1 className="text-sm text-gray-500">Use Color Gradient on Menu?</h1>
            </div>
            <div>
              <h1 className="text-sm text-gray-500 mb-2">Menu Text and Icon Color</h1>
              <MuiColorPicker color={fontColor} setColor={setFontColor} />
            </div>

            <div>
              <h1 className="text-sm text-gray-500 mb-2">Font Selector</h1>
              <FontSelector setFont={setFont} font={font} />
            </div>
            <div>
              <div>
                <h1 className="text-sm text-gray-500 mb-2">Controls Color</h1>
                <MuiColorPicker color={viewerControlsColor} setColor={setViewerControlsColor} />
              </div>
            </div>
            {/* <div className="flex gap-x-1 items-center">
              <SwitchToggle
                checked={useIconsForMainMenuItems}
                setChecked={setUseIconsForMainMenuItems}
              />
              <h1 className="text-sm text-gray-500">
                Show menu icons?
              </h1>
            </div> */}

            <div className="hidden md:block" />
          </div>
          <br />
          <Grid item xs={9}>
            <div className="logouploadcustom">
              <p className="logouploadlabellabel">Menu Logo</p>
              <LogoCropper
                logo={file}
                setLogo={setFile}
                setDeleteImage={setDeleteImage}
                text="Upload Logo"
                serverImage={serverImage}
              />
            </div>
            <br />
          </Grid>
        </MDBox>
      </MDBox>
      <center>
        <MDButton
          color="dark"
          size="small"
          className="max-w-fit ml-auto specialbackgroundcolor"
          type="submit"
          disabled={loading}
          onClick={() => {
            setFrameColor(createColor("#42424A"));
            setWindowColor(createColor("#EEEEEE"));
            setMenuColor(createColor("#42424a"));
            setMenuHoverColor(createColor("#626268"));
            setViewerControlsColor(createColor("#626268"));
            setMenuColorGradientSecondColor(createColor("#191919"));
            setFontColor(createColor("#EEEEEE"));
            setFont("Nunito");
            setUseColorGradientOnMenu(true);
            setUseIconsForMainMenuItems(true);
          }}
        >
          RESET TO DEFAULTS
        </MDButton>

        <MDButton
          color="dark"
          size="small"
          className="max-w-fit ml-auto specialmarigin2"
          type="submit"
          disabled={loading}
          onClick={save}
        >
          {loading ? "Updating..." : "Update"}
        </MDButton>
      </center>
    </Card>
  );
}
