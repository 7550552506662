import Switch from "@mui/material/Switch";

export default function SwitchToggle({ checked, setChecked, classes }) {
  return (
    <Switch
      checked={checked}
      value={checked}
      onChange={() => setChecked((prevState) => !prevState)}
      className={`-ml-[0.75rem] ${classes}`}
    />
  );
}
