/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import { endpoints, query } from "api";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
// eslint-disable-next-line import/no-extraneous-dependencies
import heic2any from "heic2any";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { blobToFile } from "utils/convertHeic2Jpg";
import { isFileAllowed } from "utils/isFileAllowed";
import { isHeicFile } from "utils/isHeicFile";

export default function EditFolderForm({ folder, cancel, getFloorPlans }) {
  const { viewer } = useViewer();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState(folder.name);
  const [thumbnail, setThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  const handleSubmit = async () => {
    setIsLoading(true);
    const { id } = folder;
    query
      .patch(`/floor-plans-folders/${id}`, { name: title })
      .then((res) => {
        setIsLoading(false);

        getFloorPlans(moduleId);
        cancel();
        success("Updated folder");
      })
      .catch((err) => {
        setIsLoading(false);

        // eslint-disable-line
        error(err?.response?.data.message || "Could not update folder");
      });
    if (thumbnail) {
      // setIsLoading(true);
      // uploadThumbnail(thumbnail, folder.id);

      if (isFileAllowed(thumbnail)) {
        if (isHeicFile(thumbnail)) {
          await heic2any({
            blob: thumbnail,
            toType: "image/jpeg",
            quality: 0.7,
          }).then(
            (blob) => {
              const fileName = "viewlio.txt";
              const mimeType = "text/plain";
              const newFile = blobToFile(blob, fileName, mimeType);

              const data = new FormData();
              data.append("picture", newFile);

              query
                .post(endpoints.floorPlansFolders.uploadThumbnail({ id: folder.id }), data, {
                  "Content-Type": "multipart/form-data",
                })
                .then(() => {
                  getFloorPlans(moduleId);
                })
                .catch((err) => {});
            },
            () => {
              error("Failed to process your file");
            }
          );
        } else {
          const data = new FormData();
          data.append("picture", thumbnail);

          query
            .post(endpoints.floorPlansFolders.uploadThumbnail({ id: folder.id }), data, {
              "Content-Type": "multipart/form-data",
            })
            .then(() => {
              getFloorPlans(moduleId);
            })
            .catch((err) => {});
        }
      } else {
        error("File type not allowed");
      }
      // setIsLoading(false);
    }
  };

  return (
    <MDBox component="form" pb={3} pt={3}>
      <div className="">
        <MDInput
          label="Title"
          fullWidth
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          value={title}
        />
      </div>
      {/* {uploadingThumbnail ? (
        <div className="flex flex-start text-left mt-3">
          <Alert severity="info" className="flex-grow">
            <div className="text-sm">Updating thumbnail...</div>
          </Alert>
        </div>
      ) : (
        ""
      )} */}
      <br />
      <div className="flex flex-col gap-y-2 my-6">
        <div className="text-sm">
          Drag and drop your thumbnail or click here to upload thumbnail
        </div>
        <input
          type="file"
          name="submit"
          onChange={(e) => {
            setThumbnail(e.target.files[0]);
          }}
          className="text-sm"
        />
      </div>
      <br />
      <MDBox className="mt-2 gap-x-1 flex justify-between rightalignalways">
        <MDButton onClick={cancel} color="secondary" disabled={isLoading}>
          cancel
        </MDButton>
        <MDButton
          className="specialmarigin"
          style={{ marginRight: "12px" }}
          onClick={handleSubmit}
          color="info"
          disabled={isLoading}
        >
          {isLoading ? "updating..." : "update"}
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

EditFolderForm.propTypes = {
  getFloorPlans: PropTypes.func,
  cancel: PropTypes.func,
  folder: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};
