/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Button, Chip, Modal } from "@mui/material";
import { endpoints, query } from "api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Check, Close } from "@mui/icons-material";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

// eslint-disable-next-line
export default function StatusField({ row, fetchViewers }) {
  const { id, isActive } = row.original;

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [activateModal, setActivateModal] = useState(false);
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });

  const errorMessage = (message) =>
    enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const toolTipTitle = isActive ? "Deactivate" : "Activate";

  return (
    <div className="flex items-center  text-white">
      {row?.original?.isActive ? (
        <Chip
          label={row?.original?.payment_status}
          color="success"
          onClick={() => {
            if (localStorage.getItem("$role") === "superadmin") {
              if (row?.original?.payment_status !== "cancelled") {
                setActivateModal(true);
              } else {
                errorMessage("This gallery subscription was cancelled");
              }
            }
          }}
          icon={<Check />}
          style={{ color: "white" }}
        />
      ) : (
        <Chip
          onClick={() => {
            if (localStorage.getItem("$role") === "superadmin") {
              if (row?.original?.payment_status !== "cancelled") {
                setActivateModal(true);
              } else {
                errorMessage("This gallery subscription was cancelled");
              }
            }
          }}
          label={row?.original?.payment_status || "Inactive"}
          color="error"
          icon={<Close />}
          style={{ color: "white" }}
        />
      )}
      <Modal
        open={activateModal}
        onClose={() => {
          setActivateModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            bgcolor: "background.paper",
            // width: "40%",
            p: 4,
          }}
          className="transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <MDBox mb={1}>
            <MDTypography variant="h5" fontWeight="bold">
              Are you sure you want to {isActive ? "deactivate" : "activate"}?
            </MDTypography>
          </MDBox>

          {error && (
            <MDBox mb={1}>
              <MDTypography variant="body1" color="error" style={{ fontSize: "15px" }}>
                {error}
              </MDTypography>
            </MDBox>
          )}

          <MDBox mt={2} display="flex" width="100%" className="w-full ">
            <Button
              className="w-1/2 m-auto text-white"
              variant="contained"
              color="primary"
              onClick={() => {
                setLoading(true);
                query
                  .patch(endpoints.viewers.updateOne({ id }), {
                    isActive: !isActive,
                  })
                  .then(() => {
                    fetchViewers(1, 1000);
                    success(`Gallery ${toolTipTitle}d`);
                    setLoading(false);
                  })
                  .catch(() => {
                    setError(toolTipTitle);
                    setLoading(false);
                  });
              }}
              sx={{
                color: "#fff",
                marginRight: "8px",
              }}
              disabled={loading}
            >
              {loading ? "Processing..." : toolTipTitle}
            </Button>
            <Button
              className="w-1/2 m-auto text-white"
              variant="contained"
              color="secondary"
              onClick={() => {
                setActivateModal(false);
              }}
              sx={{
                color: "#fff",
              }}
            >
              Cancel
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
    </div>
  );
}
