export const availableModules = [
  "photo-gallery",
  "overview",
  "virtual-tour",
  "floor-plans",
  "video-gallery",
  "photo-gallery",
  "virtual-staging",
  "sales-deck",
  "contact-form",
  "custom",
  "directions",
  "instagram",
  "3d-model",
  "website",
];

export const addNewModules = [
  {
    label: "Custom",
    value: "custom",
    id: "",
  },
  {
    label: "Virtual Tour",
    value: "virtual-tour",
    id: "",
  },
  {
    label: "Photo Gallery",
    value: "photo-gallery",
    id: "",
  },
  {
    label: "Video Gallery",
    value: "video-gallery",
    id: "",
  },
  {
    label: "3d Model",
    value: "3d-model",
    id: "",
  },
  {
    label: "Website",
    value: "website",
    id: "",
  },
  {
    label: "Virtual Staging",
    value: "virtual-staging",
    id: "",
  },
  {
    label: "Floor Plans",
    value: "floor-plans",
    id: "",
  },
  {
    label: "Sales Deck",
    value: "sales-deck",
    id: "",
  },
  {
    label: "Instagram",
    value: "instagram",
    id: "",
  },
  {
    label: "Overview",
    value: "overview",
    id: "",
  },
  {
    label: "Contact Form",
    value: "contact-form",
    id: "",
  },
  {
    label: "Directions",
    value: "directions",
    id: "",
  },
];

export const getModuleRoute = (data) => {
  let route = data?.type;
  if (availableModules.includes(data?.menuTitle)) {
    route = `${data?.menuTitle}/${data?.name}`;
  } else {
    route = `${data?.menuTitle?.replaceAll(" ", "-")?.toLowerCase()}/${data?.name}`;
  }
  if (availableModules.includes(data?.type)) {
    route = data?.type;
  }

  return route;
};
