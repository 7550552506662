import { InfoOutlined } from "@mui/icons-material";
import {
  ArrowCycle,
  Calendar,
  CodepenFill,
  File,
  Globe,
  Image,
  InstagramFill,
  Location,
  ShippingBoxV1,
  Video,
} from "akar-icons";

export const moduleTypes = [
  {
    icon: <ArrowCycle className="text-[#344767]" />,
    name: "virtual-tour",
    label: "VIRTUAL TOUR",
    canHaveChildren: true,
  },
  {
    icon: <Image className="text-[#344767]" />,
    label: "PHOTO GALLERY",
    name: "photo-gallery",
    canHaveChildren: false,
  },
  {
    icon: <Video className="text-[#344767]" />,
    label: "VIDEO GALLERY",
    name: "video-gallery",
    canHaveChildren: false,
  },
  {
    icon: <Globe className="text-[#344767]" />,
    label: "3D MODEL",
    name: "3d-model",
    canHaveChildren: true,
  },
  {
    icon: <Globe className="text-[#344767]" />,
    label: "WEBSITE",
    name: "website",
    canHaveChildren: true,
  },
  {
    icon: <ShippingBoxV1 className="text-[#344767]" />,
    label: "VIRTUAL STAGING",
    name: "virtual-staging",
    canHaveChildren: true,
  },
  {
    icon: <Image className="text-[#344767]" />,
    label: "FLOOR PLANS",
    name: "floor-plans",
    canHaveChildren: false,
  },
  {
    icon: <Calendar className="text-[#344767]" />,
    label: "SALES DECK",
    name: "sales-deck",
    canHaveChildren: false,
  },
  {
    icon: <InstagramFill className="text-[#344767]" />,
    label: "INSTAGRAM",
    name: "instagram",
    canHaveChildren: false,
  },
  {
    icon: <InfoOutlined className="text-[#344767] w-5 h-5" />,
    label: "OVERVIEW",
    name: "overview",
    canHaveChildren: false,
  },
  {
    icon: <Calendar className="text-[#344767]" />,
    label: "CONTACT FORM",
    name: "contact-form",
    canHaveChildren: false,
  },
  {
    icon: <Location className="text-[#344767]" />,
    label: "MAP / DIRECTIONS",
    name: "directions",
    canHaveChildren: false,
  },
  // {
  //   icon: <CodepenFill className="text-[#344767]" />,
  //   label: "CUSTOM",
  //   name: "custom",
  //   canHaveChildren: false,
  // },
];
