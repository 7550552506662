const form = {
  formId: "new-viewer-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Phone Number",
      type: "tel",
      errorMsg: "Phone Number is required.",
    },
    linkedInProfile: {
      name: "linkedInProfile",
      label: "LinkedIn Profile",
      type: "text",
      errorMsg: "LinkedIn Profile is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    locationName: {
      name: "locationName",
      label: "Name",
      type: "text",
      errorMsg: "Location name is required.",
    },
    twitter: {
      name: "twitter",
      label: "X Handle",
      type: "text",
      errorMsg: "X profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "Facebook Account",
      type: "text",
    },
    instagram: {
      name: "instagram",
      label: "Instagram Account",
      type: "text",
    },
    youtube: {
      name: "youtube",
      label: "Youtube Account",
      type: "text",
    },
    website: {
      name: "website",
      label: "Website Link",
      type: "text",
    },
    publicEmail: {
      name: "publicEmail",
      label: "Public Email",
      type: "email",
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
  },
};

export default form;
