import { createRoutine } from "redux-saga-routines";

const getFloorPlansRoutine = createRoutine("GET_FLOORPLANS");
export default getFloorPlansRoutine;

export const removeFloorPlanFolderRoutine = createRoutine("REMOVE_FLOORPLAN_FOLDER");
export const addFloorPlanFolderRoutine = createRoutine("ADD_FLOORPLAN_FOLDER");
export const addFloorPlanPlanRoutine = createRoutine("ADD_FLOORPLAN_PLAN_FOLDER");
export const updateFloorPlanFolderRoutine = createRoutine("UPDATE_FLOORPLAN_PLAN_FOLDER");
export const sortFloorPlanFoldersRoutine = createRoutine("SORT_FLOORPLAN_PLAN_FOLDER");
export const getPlansRoutine = createRoutine("GET_PLANS");
export const sortPlansRoutine = createRoutine("SORT_PLANS");
export const removeFloorPlanPlanRoutine = createRoutine("REMOVE_PLANS");
