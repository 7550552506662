/* eslint-disable import/no-unresolved */
import { combineReducers } from "redux-immutable";
import photoReducer from "utils/accessor/folders/photos/reducer";
import videoReducer from "utils/accessor/folders/videos/reducer";
import floorPlanReducer from "utils/accessor/folders/floor-plans/reducer";

/**
 * Create root reducer, containing
 * all features of the application
 */
const rootReducer = combineReducers({
  photos: photoReducer,
  videos: videoReducer,
  floorPlans: floorPlanReducer,
});

export default rootReducer;
