/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import MDButton from "@core/components/MDButton";
import "cropperjs/dist/cropper.css";
import { useCallback, useEffect, useRef, useState } from "react";

import Cropper from "react-easy-crop";
import "./image-cropper.css";

export default function ImageCropper({
  file,
  setFile,
  zoom,
  setZoom,
  setCroppedAreaPixels,
  setCropperModal,
  filepickEvent,
  image,
  setImage,
  type,
}) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onChange = (e) => {
    setFile("");
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const cropperRef = useRef(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setCropperModal(false);
    }
  };

  useEffect(() => {
    if (filepickEvent) {
      onChange(filepickEvent);
    }
  }, [filepickEvent]);

  return (
    <div style={{ width: "100%", height: "200px" }}>
      {image !== "" && file === "" && (
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          showGrid={false}
          aspect={4 / 4}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      )}
    </div>
  );
}
