const form = {
  formId: "new-viewer-form",
  formField: {
    name: {
      name: "name",
      label: "Enter your Gallery Name (must be unique and not already taken)",
      type: "text",
      errorMsg: "Gallery name is required.",
      placeholder: "Gallery Name",
    },
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Phone",
      type: "tel",
      errorMsg: "Phone Number is required.",
    },
    linkedInProfile: {
      name: "linkedInProfile",
      label: "LinkedIn",
      type: "text",
      errorMsg: "LinkedIn Profile is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    logo: {
      name: "logo",
      label: "Logo",
      type: "file",
      errorMsg: "Logo is required.",
    },
    twitter: {
      name: "twitter",
      label: "X",
      type: "text",
      errorMsg: "Twitter profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "Facebook",
      type: "text",
    },
    instagram: {
      name: "instagram",
      label: "Instagram",
      type: "text",
    },
    threads: {
      name: "threads",
      label: "Youtube",
      type: "text",
    },
    youtube: {
      name: "youtube",
      label: "Youtube",
      type: "text",
    },
    website: {
      name: "website",
      label: "Website",
      type: "text",
    },
    publicEmail: {
      name: "publicEmail",
      label: "Public Email",
      type: "email",
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
  },
};

export default form;
