import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import ImageCropper from "components/image-cropper";
import Modal from "components/modals/modal";
import PropTypes from "prop-types";
import { useState } from "react";
import FormField from "../form-field";

function AgentInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, title, phoneNumber, linkedInProfile, email } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    title: titleV,
    email: emailV,
    phoneNumber: phoneNumberV,
    linkedInProfile: linkedInProfileV,
  } = values;
  const [cropperModal, setCropperModal] = useState(false);
  const [file, setFile] = useState("");

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Agent/Contact Info</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              // placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              // placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={title.type}
              label={title.label}
              name={title.name}
              value={titleV}
              // placeholder={title.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              // placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phoneNumber.type}
              label={phoneNumber.label}
              name={phoneNumber.name}
              value={phoneNumberV}
              // placeholder={phoneNumber.placeholder}
              error={errors.phoneNumber && touched.phoneNumber}
              success={phoneNumberV.length > 0 && !errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={linkedInProfile.type}
              label={linkedInProfile.label}
              name={linkedInProfile.name}
              value={linkedInProfileV}
              // placeholder={linkedInProfile.placeholder}
              error={errors.linkedInProfile && touched.linkedInProfile}
              success={linkedInProfileV.length > 0 && !errors.linkedInProfile}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="flex space-x-5 justify-center 2xl:w-1/4 mx-auto">
              <Modal
                open={cropperModal}
                setOpen={() => {
                  setCropperModal(true);
                }}
                content={
                  <ImageCropper
                    file={file}
                    setFile={setFile}
                    setCropperModal={setCropperModal}
                    aspectRatio={1}
                    maxWidth={500}
                    maxHeight={500}
                  />
                }
              />

              <button
                type="button"
                className="w-full border-2 border-dashed border-gray-400 bg-gray-100 text-sm text-gray-500 rounded-lg font-normal px-5 py-3"
                onClick={() => setCropperModal(true)}
              >
                {file !== "" ? "Change Profile Picture" : "Select Profile Picture"}
              </button>

              {file !== "" && <img className="max-w-[100px] rounded-lg" src={file} alt="cropped" />}
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

AgentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AgentInfo;
