/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useUrl } from "contexts/url";

export default function ImageCount({ count, id }) {
  const { setSearchParam } = useUrl();
  return (
    <Tooltip title="View Images">
      <a
        href
        onClick={() => {
          setSearchParam("openFolder", id);
        }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center" color="text">
            <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
              <Icon onClick={() => {}} sx={{ fontWeight: "bold" }}>
                photo
              </Icon>
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{count}
            </MDTypography>
          </MDBox>
        </MDBox>
      </a>
    </Tooltip>
  );
}

ImageCount.propType = {
  count: PropTypes.number,
  id: PropTypes.string,
};
