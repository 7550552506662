/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import HorizontalBarChart from "@core/examples/Charts/BarCharts/HorizontalBarChart";
import DefaultDoughnutChart from "@core/examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DefaultLineChart from "@core/examples/Charts/LineCharts/DefaultLineChart";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPropertyList, organizeData } from "utils/constructChartObject";
import DateRangeSelector from "./components/date-range";
import CountryMapView from "./components/map-view-per-country";
import TimeAndBounceOnModule from "./components/time-and-bounce-on-module";
import TrafficSource from "./components/traffic-source";
import ViewsPerCities from "./components/views-per-cities";
import ViewsPerCountry from "./components/views-per-country";
import ViewPerModule from "./components/views-per-module";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import hyphenToSentence from "utils/hyphenToSentence";
import { usePageRerender } from "contexts/rerender";

function ViewerAnalytics() {
  const { name: viewerName } = useParams();
  const specificUrl = viewerName.toLowerCase().replaceAll(" ", "-");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [operatingSystemsData, setOperatingSystemsData] = useState({});
  const [viewsPerModule, setViewsPerModule] = useState({});
  const [topDevices, setTopDevices] = useState({});
  const [browserViews, setBrowserViews] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [trafficOverTimeData, setTrafficOverTimeData] = useState({});
  const [trafficInterval, setTrafficInterval] = useState("day");

  const [osData, setOsData] = useState([]);
  const [timeOnModuleData, setTimeOnModuleData] = useState([]);
  const [topDevicesData, setTopDevicesData] = useState([]);
  const [countryCityData, setCountryCityData] = useState([]);
  const [browserData, setBrowserData] = useState([]);
  const [trafficData, setTrafficData] = useState([]);
  const [intervalData, setIntervalData] = useState([]);

  const filterIntervalArray = (data) =>
    data?.filter((item) => (item.url.replaceAll("/", "") === specificUrl ? item : null))[0]
      .data;
      const { forceRerender } = usePageRerender();

  useEffect(() => {
    forceRerender()

    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const startDate = fromDate ? fromDate?.format("YYYY-MM-DD") : "";
    const endDate = toDate ? toDate?.format("YYYY-MM-DD") : "";

    fetch(
      `https://nigelreign.pythonanywhere.com?start_date=${startDate}&end_date=${endDate}&specific_url=/${specificUrl}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.views_by_operating_systems_data.length > 0) {
          setOsData(data.views_by_operating_systems_data[0]?.operating_systems);
        }
        if (data.time_on_module_and_bounce_rate.length > 0) {
          setTimeOnModuleData(
            data.time_on_module_and_bounce_rate[0]?.data.map((module) => ({
              ...module,
              url: hyphenToSentence(module.url),
            }))
          );
        }
        if (data.top_devices_by_visits_data.length > 0) {
          setTopDevicesData(
            data.top_devices_by_visits_data[0]?.devices.map((device) => ({
              ...device,
              device_category: capitalizeFirstLetter(device.device_category),
            }))
          );
        }
        if (data.views_by_browsers_data.length > 0) {
          setBrowserData(data.views_by_browsers_data[0]?.browsers);
        }
        if (data.traffic_sources_by_data.length > 0) {
          setTrafficData(data.traffic_sources_by_data[0]?.sources);
        }
        if (data.traffic_sources_by_data.length > 0) {
          setCountryCityData(data.views_per_country_and_city_data?.data);
        }
        if (data.visits_per_url_by_time_interval_data.length > 0) {
          setIntervalData(filterIntervalArray(data.visits_per_url_by_time_interval_data));
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [toDate, fromDate]);

  function getDataByPeriod(data, period) {
    return data[period] || null;
  }

  const colors = ["#69A3AE", "#9B2226", "dark", "#828E9D"];
  useEffect(() => {
    setOperatingSystemsData({
      labels: getPropertyList(osData, "os_name"),
      datasets: {
        label: "",
        backgroundColors: colors,
        data: getPropertyList(osData, "views"),
      },
    });

    setViewsPerModule({
      labels: getPropertyList(timeOnModuleData, "url"),
      datasets: [
        {
          label: "Views",
          color: "dark",
          data: getPropertyList(timeOnModuleData, "views"),
        },
      ],
    });

    setTopDevices({
      labels: getPropertyList(topDevicesData, "device_category"),
      datasets: [
        {
          label: "Users",
          color: "dark",
          data: getPropertyList(topDevicesData, "visits"),
        },
      ],
    });

    setBrowserViews({
      labels: getPropertyList(browserData, "browser_name"),
      datasets: {
        label: "Users",
        backgroundColors: colors,
        data: getPropertyList(browserData, "views"),
      },
    });
  }, [osData, timeOnModuleData, topDevicesData, browserData]);

  useEffect(() => {
    // Fetch the data for the specified time interval (day, month, or year)
    const dataForInterval = getDataByPeriod(organizeData(intervalData), trafficInterval);

    // Extract the dates and visits from the fetched data
    const dates = getPropertyList(dataForInterval, "date");
    const visits = getPropertyList(dataForInterval, "visits");

    // Set the state for the traffic over time chart
    setTrafficOverTimeData({
      labels: dates, // Convert date strings based on the selected interval
      datasets: [
        {
          label: "Views",
          color: "info",
          data: visits,
        },
      ],
    });
  }, [trafficInterval, intervalData]);
  return (
    <MDBox py={3}>
      <div className="flex items-center justify-start w-full">
        <DateRangeSelector
          toDate={toDate}
          setToDate={setToDate}
          fromDate={fromDate}
          setFromDate={setFromDate}
        />
      </div>

      {isLoading && (
        <center>
          <br />
          <CircularProgress />
        </center>
      )}
      <div>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MDBox mb={3}>
                <DefaultDoughnutChart
                  icon=""
                  title="Operating Systems"
                  description=""
                  chart={operatingSystemsData}
                  browserData={osData}
                  isLoading={isLoading}
                  labels={getPropertyList(osData, "os_name")}
                  data={getPropertyList(osData, "views")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultDoughnutChart
                icon=""
                title="Views Per Browser"
                description=""
                isLoading={isLoading}
                chart={browserViews}
                labels={getPropertyList(browserData, "browser_name")}
                data={getPropertyList(browserData, "views")}
                browserData={browserData}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <HorizontalBarChart
                icon=""
                title="Devices"
                description=""
                chart={topDevices}
                isLoading={isLoading}
                topDevicesData={topDevicesData}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <MDBox mb={3}>
                <DefaultLineChart
                  icon=""
                  title="Traffic Over Time"
                  description=""
                  isLoading={isLoading}
                  chart={trafficOverTimeData}
                  setTrafficInterval={setTrafficInterval}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={3}>
                <TrafficSource data={trafficData} isLoading={isLoading} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ViewPerModule
                  data={viewsPerModule}
                  timeOnModuleData={timeOnModuleData}
                  isLoading={isLoading}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <TimeAndBounceOnModule data={timeOnModuleData} isLoading={isLoading} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ViewsPerCities data={countryCityData} isLoading={isLoading} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ViewsPerCountry data={countryCityData} isLoading={isLoading} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <CountryMapView data={countryCityData} isLoading={isLoading} />
            </Grid>
          </Grid>
        </MDBox>
      </div>
    </MDBox>
  );
}

export default ViewerAnalytics;
