/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Alert, Card, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useEffect } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function StagingOptions({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setEdits({
      stagingOptionsUrl: data?.stagingOptionsUrl,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
    });
  }, [data, setEdits]);

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };

  return (
    <>
      <Card className="p-6">
        <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
          VIRTUAL STAGING
        </MDTypography>

        <Grid>
          <Grid display="flex">
            <Grid item xs={4}>
              <FormField
                label="Menu Title*"
                placeholder="eg. VIRTUAL STAGING"
                value={edits?.label || ""}
                onChange={(e) => setEdit("label", e.target.value)}
              />
            </Grid>
            <Grid item xs={4} className="specialmarigin centeralignalways">
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography variant="button" className="text-sm text-gray-500">
                    Display in Menu?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
                <ClickAlert link={getModuleRoute(data)} />
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <FormField
            type="text"
            label="Virtual Staging URL"
            onChange={(e) => setEdit("stagingOptionsUrl", e.target.value)}
            value={edits?.stagingOptionsUrl}
            placeholder="Please enter the entire URL of the hosted Virtual Staging"
          />

          <Grid item xs={9}>
            <MDBox>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
          <br />
          <div className="centermodulebuttons">
            <Grid display="flex" justify-content="center" className="customgap">
              <DeleteModule id={data?.id} />
              <MDButton
                color="info"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto"
                onClick={save}
                disabled={loading}
              >
                {loading ? "Updating..." : "UPDATE"}
              </MDButton>
            </Grid>
          </div>
        </Grid>
      </Card>
      <br />
      <Card className="p-6 overflow-auto">
        <Alert
          severity="info"
          className="text-sm md:text-md"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div className="text-center ">
            <h3 className="font-bold"> Don&apos;t have Virtual Staging?</h3>
            No problem! Let us create custom ones for you.
            <br />
            We provide professional Virtual Staging services globally.
            <br />
            <br />
            Click here to learn more and get started!
            <br />
            <a href="https://www.viewlio.com/virtual-staging/" target="_blank" rel="noreferrer">
              <MDButton
                variant="outlined"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto mt-2 specialcolor"
              >
                Learn more
              </MDButton>
            </a>
          </div>
        </Alert>
      </Card>
    </>
  );
}

export default function StagingOptionsModule() {
  return <ModuleWrapper component={StagingOptions} />;
}
