/* eslint-disable react/require-default-props */
/* eslint-disable import/no-unresolved */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";

function SalesTableCell({ title, content, image, noBorder, ...rest }) {
  return (
    <TableCell {...rest} align="center" sx={{ border: noBorder && 0 }}>
      <MDBox display="flex">
        <MDTypography
          style={{ marginRight: "40px" }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
        >
          {content}
        </MDTypography>
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
        >
          {content}
        </MDTypography>
      </MDBox>
    </TableCell>
  );
}

// Setting default values for the props of SalesTableCell
SalesTableCell.defaultProps = {
  image: "",
  noBorder: false,
};

// Typechecking props for the SalesTableCell
SalesTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default SalesTableCell;
