// export const host = "http://localhost:3001";
// export const host = "https://clownfish-app-btyb7.ondigitalocean.app";

import envConfigs from "envConfigs";

let url = "https://clownfish-app-btyb7.ondigitalocean.app";

if (envConfigs.enviroment === "live") {
  url = "https://app.viewlio.com";
}
if (envConfigs.enviroment === "local") {
  url = "http://localhost:3001";
}

export const host = url;

const version = "/api/v1.0";
if (host.includes("localhost")) {
  // version = "/v1.0";
}

export const baseUrl = `${host}${version}`;
// export const baseUrl = "http://localhost:3001/v1.0";
