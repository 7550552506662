/* eslint-disable import/no-unresolved */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";

// Images
import pattern from "@core/assets/images/illustrations/pattern-tree.svg";
import amexLogo from "@core/assets/images/logos/amex.png";
import masterCardLogo from "@core/assets/images/logos/mastercard.png";
import visaLogo from "@core/assets/images/logos/visa.png";
import discoverLogo from "@core/assets/images/logos/discover.png";

function BankingCard({ color, number, holder, expires, brand }) {
  const num1 = "****";
  const num2 = num1;
  const num3 = num2;
  const num4 = number?.substring(number.length - 4);

  let logo = null;
  if (brand === "visa") {
    logo = visaLogo;
  } else if (brand === "mastercard") {
    logo = masterCardLogo;
  } else if (brand === "amex") {
    logo = amexLogo;
  } else if (brand === "discover") {
    logo = discoverLogo;
  }
  return (
    <Card
      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative",
        aspectRatio: "3 / 1  !important",
        maxHeight: "12vh",
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.2}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <MDBox position="relative" zIndex={2} p={2}>
        <MDBox color="white" p={1} lineHeight={0} display="inline-block">
          <Icon fontSize="default">wifi</Icon>
        </MDBox>
        <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 1, mb: 1, pb: 1 }}>
          {num1}&nbsp;&nbsp;&nbsp;{num2}&nbsp;&nbsp;&nbsp;{num3}&nbsp;&nbsp;&nbsp;{num4}
        </MDTypography>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox mr={3} lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Card Holder
              </MDTypography>
              <MDTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {holder}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Expires
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {expires}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" width="20%">
            {logo && <MDBox component="img" src={logo} alt="master card" width="130%" mt={1} />}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

BankingCard.defaultProps = {
  color: "dark",
};

BankingCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  number: PropTypes.string.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

export default BankingCard;
