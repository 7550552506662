/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";

import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Card, Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { baseUrl, endpoints, query } from "api";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function PdfViewer({
  data,
  saving: loading,
  edits,
  setEdits,
  setSaving: setLoading,
  moduleId,
  showSnackbar,
}) {
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fetchModules } = useViewer();
  const [label, setLabel] = useState(data?.label);
  const [displayInLeftMenu, setIsDisabled] = useState(data?.displayInLeftMenu);

  const uploadPdf = () => {
    setIsLoading(true);
    query
      .patch(endpoints.modules.updateOne({ id: moduleId }), { label, displayInLeftMenu })
      .then(() => {
        setLoading(false);
        showSnackbar("Module updated", {
          variant: "success",
          autoHideDuration: 2000,
        });
        setIsLoading(false);

        fetchModules();
      })
      .catch((e) => {
        setLoading(false);
        showSnackbar("Module not updated", {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
    if (file) {
      if (file?.size > 100e6) {
        showSnackbar("File too large", { variant: "error", autoHideDuration: 3000 });
      } else {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("picture", file);
        if (file) {
          query
            .post(endpoints.modules.updateSalesDeck({ id: moduleId }), formData, {
              "Content-Type": "multipart/form-data",
            })
            .then(() => {
              showSnackbar("Uploaded file", { variant: "success", autoHideDuration: 2000 });
              setIsLoading(false);

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((err) => {
              setIsLoading(false);
              showSnackbar("Could not upload file", { variant: "error", autoHideDuration: 2000 });
              console.log(err); // eslint-disable-line
            });
        }
      }
    }
  };

  useEffect(() => {
    setEdits({
      displayInLeftMenu: data?.displayInLeftMenu,
    });
  }, [data, setEdits]);

  const handleDisplayInLeftMenu = (e) => {
    setEdits("displayInLeftMenu", e.target.checked);
    setIsDisabled(e.target.checked);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card className="p-6">
      <MDTypography variant="h5" fontWeight="bold" className="text-center mb-6">
        SALES DECK
      </MDTypography>

      <div className="grid gap-6">
        <Grid display="flex">
          <Grid item xs={4}>
            <FormField
              label="Menu Title*"
              placeholder="eg. SALES DECK"
              value={label || ""}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} className="specialmarigin centeralignalways">
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" className="text-sm text-gray-500">
                  Display in Menu?
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox>
          <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Upload PDF
            </MDTypography>
          </MDBox>
          <div className="file-input-container">
            <input
              type="file"
              name="submit"
              id="fileInput"
              className="text-sm md:text-md cheatcode"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <label htmlFor="fileInput" className="custom-file-label">
              {" "}
              Upload File{" "}
            </label>
          </div>
        </MDBox>
        {data?.salesDeck && (
          <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <MDTypography
              style={{ marginRight: "10px" }}
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              There is a PDF uploaded
            </MDTypography>
            {/* <a
              href={data?.salesDeck}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "12px" }}
            >
              <MDButton color="success" size="medium" className="mx-auto w-full sm:w-1/4 md:w-auto">
                View Uploaded PDF
              </MDButton>
            </a> */}

            <MDButton
              iconOnly
              onClick={() => {
                setIsLoading(true);
                fetch(`${baseUrl}/viewer-modules/delete/file/${moduleId}`, {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => {
                    setIsLoading(false);

                    showSnackbar("Module updated", {
                      variant: "success",
                      autoHideDuration: 2000,
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch(() => {
                    setIsLoading(false);

                    showSnackbar("Module not updated", {
                      variant: "error",
                      autoHideDuration: 2000,
                    });
                  });
              }}
              className="bg-red-600 text-white"
            >
              <FiTrash2 />
            </MDButton>
          </MDBox>
        )}

        <Grid item xs={9}>
          <MDBox>
            <ClickAlert link={getModuleRoute(data)} />
          </MDBox>
        </Grid>
        <br />
        <Grid className="centermodulebuttons">
          <Grid display="flex" justify-content="center" className="customgap">
            <DeleteModule id={data?.id} />
            <MDButton
              color="info"
              size="medium"
              className="mx-auto w-full sm:w-1/4 md:w-auto"
              onClick={uploadPdf}
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "UPDATE"}
            </MDButton>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}

export default function SalesDeckModule() {
  return <ModuleWrapper component={PdfViewer} />;
}
