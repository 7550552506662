/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-bind */
// @react-jvectormap components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Card } from "@mui/material";
import AnalyticsDataTable from "components/analytics-datatable";
import NoAnalyticsData from "components/no-analytics-data";
import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies

function TimeAndBounceOnModule({ data, isLoading }) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCities(
      data?.flatMap((country) =>
        country.regions.flatMap((region) => {
          if (region.cities && region.cities.length > 0) {
            return region.cities.map((city) => ({
              ...city,
              country: country.name,
              region: region.name,
            }));
          }
          // If region has no cities, include the region without cities
          return {
            country: country.name,
            region: region.name,
            views: region.views,
            active_users: region.active_users,
            new_users: region.new_users,
          };
        })
      )
    );
  }, [data]);

  return (
    <Card sx={{ width: "100%", height: "23.8rem" }}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={1} pt={1}>
          <MDBox style={{ color: "#051D3A" }}>
            <MDTypography variant="h6">Views & Users By Area</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <div style={{ maxHeight: "18rem", overflowY: "auto" }}>
            {data.length > 0 && !isLoading ? (
              <AnalyticsDataTable
                theme="solarized"
                columns={[
                  {
                    name: "Country",
                    selector: (row) => row.country,
                    sortable: true,
                  },
                  {
                    name: "Region",
                    selector: (row) => row.region,
                    sortable: true,
                  },
                  {
                    name: "City",
                    selector: (row) => row.name,
                    sortable: true,
                  },
                  {
                    name: "Users",
                    selector: (row) => row.active_users,
                    sortable: true,
                  },
                  {
                    name: "Views",
                    selector: (row) => row.views,
                    sortable: true,
                  },
                ]}
                data={cities}
              />
            ) : (
              <NoAnalyticsData isLoading={isLoading} />
            )}
          </div>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default TimeAndBounceOnModule;
