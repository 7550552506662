/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-bind */
// @react-jvectormap components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Card, Table, TableBody, TableRow } from "@mui/material";
import AnalyticsDataTable from "components/analytics-datatable";
import NoAnalyticsData from "components/no-analytics-data";

function ViewsPerCountry({ data, isLoading }) {
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  const countries = data?.map((country) => {
    let totalNewUsers = 0;
    let totalViews = 0;
    let totalActiveUsers = 0;
    for (const region of country.regions) {
      if (region.cities.length > 0) {
        for (const city of region.cities) {
          totalNewUsers += city.new_users;
          totalActiveUsers += city.active_users;
          totalViews += city.views;
        }
      } else {
        totalNewUsers += region.new_users;
        totalActiveUsers += region.active_users;
        totalViews += region.views;
      }
    }

    return {
      ...country,
      totalNewUsers,
      totalActiveUsers,
      totalViews,
    };
  });
  const columns = [
    {
      name: "Country",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) => row.totalActiveUsers,
      sortable: true,
    },
    {
      name: "Views",
      selector: (row) => row.totalViews,
      sortable: true,
    },
  ];

  return (
    <Card sx={{ width: "100%", height: "23.8rem" }}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={1} pt={1}>
          <MDBox style={{ color: "#051D3A" }}>
            <MDTypography variant="h6">Views & Users By Country</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <div style={{ maxHeight: "18rem", overflowY: "auto" }}>
            {data.length > 0 && !isLoading ? (
              <AnalyticsDataTable theme="solarized" columns={columns} data={countries} />
            ) : (
              <NoAnalyticsData isLoading={isLoading} />
            )}
          </div>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ViewsPerCountry;
