/* eslint-disable import/no-extraneous-dependencies */
import DataTable, { createTheme } from "react-data-table-component";

function AnalyticsDataTable({ data, columns }) {
  createTheme(
    "solarized",
    {
      text: {
        primary: "#456374",
      },
    },
    "light"
  );

  const customStyles = {
    headCells: {
      style: {
        color: "#456374",
      },
    },
    rows: {
      style: {
        color: "#456374",
      },
    },
    cells: {
      style: {
        color: "#456374",
      },
    },
  };

  return <DataTable theme="solarized" columns={columns} noDataComponent="" data={data} customStyles={customStyles} />;
}

export default AnalyticsDataTable;
