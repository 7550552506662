import form from "pages/viewers/new-viewer/schemas/form";
import * as Yup from "yup";

const {
  formField: {
    name,
    firstName,
    lastName,
    email,
    title,
    phoneNumber,
    linkedInProfile,
    password,
    repeatPassword,
  },
} = form;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string()
      .required(name.errorMsg)
      .matches(/^[ aA-zZ0-9_/-]+$/, "Please make sure this name is following the guideline below!"),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string(),
    [lastName.name]: Yup.string(),
    [email.name]: Yup.string().email(email.invalidMsg),
    [title.name]: Yup.string(),
    [phoneNumber.name]: Yup.string(),
    [linkedInProfile.name]: Yup.string(),
  }),
];

export default validations;
