import axios from "axios";
import { buildUrl } from "./urlBuilder";

export default {
  get: (url, params, headers) =>
    axios.get(buildUrl(url), {
      params,
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  post: (url, data, headers) =>
    axios.post(buildUrl(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  put: (url, data, headers) =>
    axios.put(buildUrl(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  patch: (url, data, headers) =>
    axios.patch(buildUrl(url), data, {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
  delete: (url, headers) =>
    axios.delete(buildUrl(url), {
      headers: {
        ...headers,
        token: localStorage.getItem("$token"),
      },
    }),
};
