const PATHS = {
  dashboard: "/",
  // Viewer
  viewer: "/galleries/:name/:tab",
  viewerSettings: "/galleries/:name/gallery-settings",
  sampleViewer: "/sample-gallery",
  // Viewers
  viewers: "/galleries",
  createViewer: "/create/gallery",
  // Support
  supportTutorials: "support",
  supportFAQs: "support",
  supportContactUs: "/support/contact-us",
  // User Profile
  userProfile: "/user/profile",
  userSettings: "/user/settings",
  userBilling: "/user/billing",

  // Modules
  overview: "/galleries/:name/overview",
  photoLibrary: "/galleries/:name/gallery/photos/library",
  photoGallery: "/galleries/:name/photo-gallery",
  floorPlan: "/galleries/:name/floor-plan",
  videoGallery: "/galleries/:name/video-gallery",
  models: "/galleries/:name/3d-models",
  website: "/galleries/:name/website",
  stagingOptions: "/galleries/:name/staging-options",
  pdfViewer: "/galleries/:name/pdf-viewer",
  eventEnquiryForm: "/galleries/:name/event-enquiries-form",
  instagram: "/galleries/:name/instagram",
  mapsAndDirections: "/galleries/:name/maps-and-directions",
  codeEmbed: "/galleries/:name/code-embed",

  signIn: "/sign-in",
  signUp: "/sign-up",
  verifyEmail: "/verify-email",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
};

export default PATHS;
