/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Card, CircularProgress, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import getFloorPlansRoutine, {
  addFloorPlanFolderRoutine,
  addFloorPlanPlanRoutine,
  removeFloorPlanFolderRoutine,
  sortFloorPlanFoldersRoutine,
  updateFloorPlanFolderRoutine,
} from "utils/accessor/folders/floor-plans/routines";

import { endpoints, query } from "api";
import { useViewer } from "contexts/viewer";

import MDButton from "@core/components/MDButton";
import DeleteModule from "components/delete-module";
import ModuleControls from "components/module-controls";
import { usePageRerender } from "contexts/rerender";
import { useUrl } from "contexts/url";
import { useSnackbar } from "notistack";
import { getModuleRoute } from "utils/availableModules";
import LoadingModule from "../components/loading";
import FloorPlanCard from "./components/folder-card";
import Header from "./components/header";

function FloorPlanGallery({
  floorPlans,
  removeFloorPlanFolder,
  addFloorPlanPlan,
  updateFloorPlanFolder,
  sortFloorPlanFolders,
  floorPlansLoading,
  getFloorPlans,
}) {
  const [draggedFolderId, setDraggedFolderId] = useState(undefined);
  const [newTargetFolderId, setNewTargetFolderId] = useState(undefined);
  const { viewer } = useViewer();
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });

  const error = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    getFloorPlans(moduleId);
  }, [moduleId]);

  const removeFolder = (id) => {
    setIsLoading(true);
    removeFloorPlanFolder(floorPlans, id);
    query
      .delete(endpoints.floorPlansFolders.deleteOne({ id }))
      .then(() => {
        setIsLoading(false);
        success("Folder removed");
        getFloorPlans(moduleId);
      })
      .catch((err) => {
        setIsLoading(false);
        error(err?.response?.data.message || "Could not remove folder");
      });
  };

  const addFolder = (title, base64File) => {
    setIsLoading(true);

    query
      .post(endpoints.floorPlansFolders.create(), {
        viewerId: moduleId,
        name: title,
      })
      .then((res) => {
        // eslint-disable-line

        const data = new FormData();
        data.append("picture", base64File);
        query
          .post(endpoints.floorPlansFolders.uploadThumbnail({ id: res.data.id }), data, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            setIsLoading(false);
            success("Created folder");
            getFloorPlans(moduleId);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err); // eslint-disable-line
            error(err?.response?.data.message || "Could not upload thumbnail");
          });
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not create Folder");
      });
  };

  const handleDragStart = (id) => {
    setDraggedFolderId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (id) => {
    setNewTargetFolderId(id);
  };

  const handleDragEnd = () => {
    setDraggedFolderId(undefined);
    setNewTargetFolderId(undefined);
  };
  const addPlan = (values) => {
    addFloorPlanPlan(floorPlans, values);
    getFloorPlans(moduleId);
  };

  const updateFolder = (values) => {
    updateFloorPlanFolder(floorPlans, values);
  };

  // const viewerId = localStorage.getItem("viewerId");
  const sortFolders = () => {
    if (!draggedFolderId || !newTargetFolderId) return;

    sortFloorPlanFolders(floorPlans, draggedFolderId, newTargetFolderId, moduleId);
  };

  const handleDrop = () => {
    sortFolders();
  };
  const [data, setData] = useState({});
  const [label, setLabel] = useState(data?.label);
  const [loading, setLoading] = useState(false);
  const [displayInLeftMenu, setIsDisabled] = useState(data?.displayInLeftMenu);
  const { fetchModules } = useViewer();

  const [colorOfBox, setColorOfBox] = useState("#FFFFFF");
  const [useLogoBox, setUseLogoBox] = useState(false);
  const [useRoundedCorners, setUseRoundedCorners] = useState(false);
  const [controlsColor, setControlsColor] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("#FFFFFF");
  const [displayFolder, setDisplayFolder] = useState(false);

  useEffect(() => {
    setColorOfBox(data?.colorOfBox || "#FFFFFF");
    setUseLogoBox(data?.useLogoBox);
    setControlsColor(data?.controlsColor);
    setDisplayFolder(data?.displayFolder);
    setTextColor(data?.textColor);
    setUseRoundedCorners(data?.useRoundedCorners);
  }, [data, moduleId]);

  useEffect(() => {
    query
      .get(endpoints.modules.findOne({ id: moduleId }))
      .then((res) => {
        setLabel(res.data.label);
        setIsDisabled(res.data.displayInLeftMenu);
        setData(res.data);
      })
      .catch(() => {});
    window.scrollTo(0, 0);
  }, [moduleId]);

  const handleDisplayInLeftMenu = (e) => {
    setIsDisabled(e.target.checked);
  };
  const { forceRerender } = usePageRerender();

  if (floorPlansLoading || isLoading) {
    return <LoadingModule />;
  }

  return (
    <>
      <Card className="p-6 overflow-auto">
        <Grid className="grid gap-6">
          <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
            FLOOR PLANS
          </MDTypography>
          <Grid display="flex">
            <Grid item xs={4} className="grid gap-6">
              <FormField
                label="Menu Title*"
                placeholder="eg. FLOOR PLAN"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4} className="specialmarigin centeralignalways">
              <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                <MDBox mt={0.5}>
                  <Switch checked={displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                </MDBox>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography variant="button" className="text-sm text-gray-500">
                    Display in Menu?
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>

          <ModuleControls
            setUseRoundedCorners={setUseRoundedCorners}
            useRoundedCorners={useRoundedCorners}
            setColorOfBox={setColorOfBox}
            colorOfBox={colorOfBox}
            useLogoBox={useLogoBox}
            setUseLogoBox={setUseLogoBox}
            setControlsColor={setControlsColor}
            controlsColor={controlsColor}
            setTextColor={setTextColor}
            textColor={textColor}
            displayFolder={displayFolder}
            setDisplayFolder={setDisplayFolder}
            galleryCount={floorPlans?.length}
          />

          <Grid item xs={9}>
            <MDBox>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
          <br />
          <Grid className="centermodulebuttons">
            <Grid display="flex" justify-content="center" className="customgap">
              <DeleteModule id={data?.id} />
              <MDButton
                color="info"
                size="medium"
                className="mx-auto w-full sm:w-1/4 md:w-auto"
                disabled={loading}
                onClick={() => {
                  if (label === "") {
                    enqueueSnackbar("Menu title cannot be empty", {
                      variant: "error",
                      autoHideDuration: 2000,
                    });
                    return;
                  }
                  setLoading(true);
                  query
                    .patch(endpoints.modules.updateOne({ id: moduleId }), {
                      label,
                      displayInLeftMenu,
                      useLogoBox,
                      useRoundedCorners,
                      colorOfBox,
                      controlsColor,
                      textColor,
                      displayFolder,
                    })
                    .then(() => {
                      setLoading(false);
                      enqueueSnackbar("Module updated", {
                        variant: "success",
                        autoHideDuration: 2000,
                      });
                      forceRerender();
                      fetchModules();
                    })
                    .catch((e) => {
                      setLoading(false);
                      enqueueSnackbar("Module not updated", {
                        variant: "error",
                        autoHideDuration: 2000,
                      });
                    });
                }}
              >
                {loading ? "Saving..." : "Update"}
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {floorPlansLoading || isLoading ? (
        <center>
          <br />
          <CircularProgress />
        </center>
      ) : null}

      <MDBox display="flex" justifyContent="flex-start" className="mx-4 mt-5">
        <Header addFolder={addFolder} />
      </MDBox>

      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-5">
        {floorPlans.map((folder) => (
          <FloorPlanCard
            removeFolder={removeFolder}
            addPlan={addPlan}
            updateFolder={updateFolder}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            handleDragOver={handleDragOver}
            handleDragEnd={handleDragEnd}
            handleDrop={handleDrop}
            key={folder?.id}
            folder={folder}
            getFloorPlans={getFloorPlans}
          />
        ))}
      </div>
      {floorPlans.length === 0 && (
        <MDTypography variant="h6" fontWeight="bold" className="text-center mb-12">
          Jump in and Create a Gallery!
        </MDTypography>
      )}
      <br />
    </>
  );
}

FloorPlanGallery.defaultProps = {
  floorPlans: [],
};

FloorPlanGallery.propTypes = {
  floorPlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  removeFloorPlanFolder: PropTypes.func.isRequired,
  addFloorPlanPlan: PropTypes.func.isRequired,
  updateFloorPlanFolder: PropTypes.func.isRequired,
  sortFloorPlanFolders: PropTypes.func.isRequired,
  getFloorPlans: PropTypes.func.isRequired,
  floorPlansLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    floorPlans: state.getIn(["floorPlans", "floorPlans"]),
    floorPlansLoading: state.getIn(["floorPlans", "floorPlansLoading"]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeFloorPlanFolder: (floorPlans, id) =>
      dispatch(
        removeFloorPlanFolderRoutine.trigger({
          floorPlans,
          id,
        })
      ),
    addFloorPlanFolder: (floorPlans, title, base64File) =>
      dispatch(
        addFloorPlanFolderRoutine.trigger({
          floorPlans,
          title,
          base64File,
        })
      ),
    addFloorPlanPlan: (floorPlans, values) =>
      dispatch(
        addFloorPlanPlanRoutine.trigger({
          floorPlans,
          values,
        })
      ),
    updateFloorPlanFolder: (floorPlans, values) =>
      dispatch(
        updateFloorPlanFolderRoutine.trigger({
          floorPlans,
          values,
        })
      ),
    sortFloorPlanFolders: (floorPlans, draggedFolderId, newTargetFolderId, moduleId) =>
      dispatch(
        sortFloorPlanFoldersRoutine.trigger({
          floorPlans,
          draggedFolderId,
          newTargetFolderId,
          moduleId,
        })
      ),
    getFloorPlans: (moduleId) => dispatch(getFloorPlansRoutine.trigger({ moduleId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanGallery);
