/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import Grid from "@mui/material/Grid";
import BasicModal from "components/modals/modal";
import PhotoGalleryContextProvider from "contexts/photo-gallery";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import AddModuleContent from "./add-module-content";
import ViewerBuilderContent from "./content";
import ViewerPlaceholders from "./placeholders";
import SelectBaseModuleContent from "./select-base-module-content";
import Sidenav from "./sidenav";

export default function ViewerBuilderTab() {
  const { fetchingViewer, viewer } = useViewer();
  const { getSearchParam, setSearchParam } = useUrl();
  const [addModal, setAddModal] = useState(false);
  const [baseModuleModal, setBaseModuleModal] = useState(false);

  useEffect(() => {
    if (!(getSearchParam("builderTab"))) {
      setSearchParam("builderTab", "virtual-tour");
    }
  }, []);

  return (
    <PhotoGalleryContextProvider>
      <Grid item className="w-full md:max-w-[255px]">
        <Sidenav useIcons={viewer?.useIconsForMainMenuItems} add={(parent) => {
          setAddModal(parent || true);
        }}/>
      </Grid>
      <Grid item className="w-full flex-1">
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {fetchingViewer ?
                <ViewerPlaceholders />
                :
                <ViewerBuilderContent />
              }
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      <BasicModal
        open={addModal}
        setOpen={setAddModal}
        content={
          <AddModuleContent
            parent={addModal !== true ? addModal : null}
            close={() => {
              setAddModal();
            }}
          />
        }
        hasHeader
        closable
        title="Add New Module"
      />

      <BasicModal
        open={baseModuleModal}
        setOpen={setBaseModuleModal}
        content={<SelectBaseModuleContent />}
        hasHeader
        closable
        title="Select Base Module"
      />
    </PhotoGalleryContextProvider>
  );
}
