/* eslint-disable import/no-unresolved */
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";
// Custom styles for the MDEditor
import MDEditorRoot from "@core/components/MDEditor/MDEditorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "@core/context";
import EditorToolbar, { formats, modules } from "./EditorToolbar";

function MDEditor({ onChange, value }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          modules={modules}
          formats={formats}
        />
      </div>
    </MDEditorRoot>
  );
}

export default MDEditor;
