/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDEditor from "@core/components/MDEditor";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Card, FormLabel, Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useEffect, useState } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function CodeEmded({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    setEdits({
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
      codeEmbed: data?.codeEmbed || "",
      useLogoBox: data?.useLogoBox || false,
    });
  }, [data, setEdits]);

  const [editorValue, setEditorValue] = useState("");

  useEffect(() => {
    if (editorValue) {
      setEdit("codeEmbed", editorValue);
    }
  }, [editorValue]);

  const [txValue, setTxValue] = useState(data?.codeEmbed);

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };

  const setMenuTitle = (title) => setEdit("label", title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card className="p-6">
      <MDBox>
        <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
          Custom HTML Module
        </MDTypography>

        <Grid>
          <Grid display="flex">
            <Grid item xs={4} className="grid gap-6">
              <FormField
                label="Menu Title*"
                placeholder="Custom"
                value={edits?.label || ""}
                onChange={(e) => setMenuTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} className="specialmarigin centeralignalways">
              <Grid item xs={1}>
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" className="text-sm text-gray-500">
                      Display in Menu?
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
                  <ClickAlert link={getModuleRoute(data)} />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <FormLabel
            component="legend"
            className="text-sm text-gray-500 font-normal"
            style={{ textAlign: "left" }}
          >
            Use the Text Editor below to add your custom content.
          </FormLabel>
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch
                checked={edits?.useLogoBox}
                onChange={(e) => {
                  setEdit("useLogoBox", e.target.checked);
                }}
              />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Switch to plain text paste html
              </MDTypography>
            </MDBox>
          </MDBox>
          {edits?.useLogoBox ? (
            <FormField
              type="text"
              label="Custom HTML"
              onChange={(event) => {
                setTxValue(event.target.value);
                setEdit("codeEmbed", event.target.value);
              }}
              value={txValue}
              multiline
              minRows={4}
            />
          ) : (
            <MDEditor value={editorValue || edits?.codeEmbed || ""} onChange={setEditorValue} />
          )}
          <br /> <br />
          <Grid className="centermodulebuttons">
            <Grid>
              <MDBox>
                <ClickAlert link={getModuleRoute(data)} />
              </MDBox>
            </Grid>

            <Grid className="centermodulebuttons">
              <Grid display="flex" justify-content="center" className="customgap">
                <DeleteModule id={data?.id} />
                <MDButton
                  color="info"
                  size="medium"
                  className="mx-auto w-full sm:w-1/4 md:w-auto"
                  onClick={save}
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update"}
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default function CodeEmdedModule() {
  return <ModuleWrapper component={CodeEmded} />;
}
