import { ColorPicker } from "material-ui-color";

export default function MuiColorPicker({ color, setColor }) {
  const handleChange = (value) => {
    setColor(value);
  };

  return (
    <div className="-ml-1">
      <ColorPicker className="w-full" value={color} onChange={handleChange} />
    </div>
  );
}
