/* eslint-disable import/no-unresolved */
import { endpoints, query } from "api";
import { moduleTypes } from "constants/modules";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const ViewerContext = createContext();
export function useViewer() {
  return useContext(ViewerContext);
}

export default function ViewerContextProvider({ children }) {
  const [viewer, setViewer] = useState({});
  const [activeModule, setActiveModule] = useState();
  const [fetchingModules, setFetchingModules] = useState(false);
  const [fetchingViewer, setFetchingViewer] = useState(false);
  const [reordering, setReordering] = useState(false);
  const [modules, setModules] = useState([]);
  const { pathname } = useLocation();

  const fetchModules = useCallback(() => {
    if (viewer.id) {
      const viewerId = viewer.id;
      setFetchingModules(true);

      query
        .get(endpoints.modules.getAll({}, { viewerId }))
        .then((res) => {
          setFetchingModules(false);

          const { data } = res;
          const orderedModule = data
            .sort((a, b) => a.order - b.order)
            .filter((item) => !item.parent);

          const stuff = orderedModule.map((item) => {
            const module = modules.find((m) => Number(m.id) === Number(item.key));
            return {
              ...module,
              text: item.name || module.text,
              href: "",
              ...item,
            };
          });

          setModules(
            stuff.map((item) => ({
              ...item,
              children: data
                .filter((child) => child.parent === item.id)
                .sort((a, b) => a.order - b.order)
                .map((child) => {
                  const localModule = modules.find((m) => Number(m.id) === Number(child.key));
                  return {
                    ...localModule,
                    ...child,
                    text: child.name || localModule.active.text,
                    href: "",
                  };
                }),
            }))
          );
        })
        .catch(() => setFetchingModules(false));
    }
  }, [pathname, viewer?.id]);

  const reorderModules = useCallback((newOrder) => {
    setReordering(true);

    const flattened = [];

    for (let i = 0; i < newOrder.length; i += 1) {
      flattened.push({
        id: newOrder[i].id,
        parent: null,
        order: i + 1,
      });

      for (let j = 0; j < newOrder[i].children.length; j += 1) {
        flattened.push({
          id: newOrder[i].children[j].id,
          parent: newOrder[i].id,
          order: j + 1,
        });
      }
    }

    const viewerId = localStorage.getItem("viewerId");
    query
      .post(endpoints.viewers.reorderModules({ id: viewerId }), { modules: flattened })
      .then((res) => {
        setReordering(false);
        fetchModules();
      })
      .catch((err) => {
        setReordering(false);
      });

    setModules(newOrder);
  }, []);

  const value = useMemo(
    () => ({
      viewer,
      setViewer,
      activeModule,
      setActiveModule,
      fetchingModules,
      setFetchingModules,
      fetchingViewer,
      setFetchingViewer,
      setModules,
      modules,
      fetchModules,
      reordering,
      setReordering,
      reorderModules,
    }),
    [
      viewer,
      setViewer,
      activeModule,
      setActiveModule,
      fetchingModules,
      setFetchingModules,
      fetchingViewer,
      setFetchingViewer,
      setModules,
      modules,
      fetchModules,
      reordering,
      setReordering,
      reorderModules,
    ]
  );

  useEffect(() => {
    fetchModules();
  }, [viewer?.id]);

  return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>;
}
