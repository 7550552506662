/* eslint-disable import/no-unresolved */
import MDButton from "@core/components/MDButton";
import { Grid } from "@mui/material";
import { endpoints, query } from "api";
import { Form, useForm } from "components/form-input/form";
import ProfileCropper from "components/profileCropper";
import SwitchToggle from "components/switch";
import { useViewer } from "contexts/viewer";
import { dataURLtoBlob } from "helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import formatURL from "utils/formatUrl";
import FormField from "../../form-field";
import { schema } from "./schema";

export function AgentInfoForm({ snack, ...props }) {
  const { viewer, setViewer } = useViewer();
  const form = useForm({
    schema,
  });

  // const [file, setFile] = useState("");

  const [agentFirstName, setAgentFirstName] = useState(viewer?.agentFirstName);
  const [agentLastName, setAgentLastName] = useState(viewer?.agentLastName);
  const [agentTitle, setAgentTitle] = useState(viewer?.agentTitle);
  const [agentPhoneNumber, setAgentPhoneNumber] = useState(viewer?.agentPhoneNumber);
  const [agentEmailAddress, setAgentEmailAddress] = useState(viewer?.agentEmailAddress);
  const [websiteLink, setWebsiteLink] = useState(viewer?.websiteLink);
  const [loading, setLoading] = useState(false);
  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [error, setError] = useState();
  const [logo, setLogo] = useState();
  useEffect(() => {
    setError();
    setAgentFirstName(viewer?.agentFirstName);
    setAgentLastName(viewer?.agentLastName);
    setAgentTitle(viewer?.agentTitle);
    setAgentPhoneNumber(viewer?.agentPhoneNumber);
    setAgentEmailAddress(viewer?.agentEmailAddress);
    setWebsiteLink(viewer?.websiteLink);
    setShowAgentInfo(viewer?.showAgentInfo);
  }, [viewer]);

  const fieldsChanged = () => {
    const newVals = {
      agentFirstName,
      agentLastName,
      agentTitle,
      agentPhoneNumber,
      agentEmailAddress,
      websiteLink,
    };

    const fields = [
      "agentFirstName",
      "agentLastName",
      "agentTitle",
      "agentPhoneNumber",
      "agentEmailAddress",
      "websiteLink",
    ];

    for (let i = 0; i < fields.length; i += 1) {
      if (newVals[fields[i]] !== viewer[fields[i]]) {
        return true;
      }
    }

    return false;
  };
  const { name: viewerName } = useParams();

  const getViewerData = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName, isActive: true })).then((res) => {
      if (res.data.length) {
        setViewer(res.data[0]);
      }
    });
  };

  const save = () => {
    setLoading(true);
    setError();
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
        agentFirstName,
        agentLastName,
        agentTitle,
        agentPhoneNumber,
        agentEmailAddress,
        websiteLink: websiteLink ? formatURL(websiteLink) : "",
        showAgentInfo,
      })
      .then((res) => {
        snack("Updated", { variant: "success", autoHideDuration: 2000 });
        if (logo) {
          const data = new FormData();
          data.append("picture", dataURLtoBlob(logo));

          query
            .post(endpoints.viewers.updateAgentProfilePicture({ id: viewer.id }), data, {
              "Content-Type": "multipart/form-data",
            })
            .then(() => {
              setLoading(false);
              setLogo("");
              getViewerData();
              snack("Profile image updated successfully", {
                variant: "success",
                autoHideDuration: 2000,
              });
            })
            .catch((err) => {
              setLogo("");
              snack("Could not update image", { variant: "error", autoHideDuration: 2000 });
              console.log(err); // eslint-disable-line
            });
        }
        setViewer(res.data);

        setLoading(false);
        return res;
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Something went wrong");
        snack("Could not update", { variant: "error", autoHideDuration: 2000 });
      });
  };

  return (
    <div>
      <Form form={form} onSubmit={save}>
        <div className="flex flex-col space-y-5">
          <h1 className="text-sm text-gray-500">
            Any information entered here will appear at the bottom of your Gallery Menu in the Agent
            Section. Leave any field blank if you do not want anything to appear for that field.
          </h1>
          <div>
            <div className="flex gap-x-2 items-center">
              <SwitchToggle checked={!showAgentInfo} setChecked={setShowAgentInfo} />
              <h1 className="text-sm text-gray-500">Display in Menu?</h1>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 gap-5">
            <FormField
              label="First Name"
              type="text"
              {...form.register("firstName")}
              value={agentFirstName}
              onChange={(e) => setAgentFirstName(e.target.value)}
            />

            <FormField
              defaultValue="Doe"
              label="Last Name"
              type="text"
              {...form.register("lastName")}
              value={agentLastName}
              onChange={(e) => setAgentLastName(e.target.value)}
            />

            <FormField
              defaultValue="Test Gallery"
              label="Title"
              type="text"
              {...form.register("title")}
              value={agentTitle}
              onChange={(e) => setAgentTitle(e.target.value)}
            />

            <FormField
              defaultValue="jdoe@gmail.com"
              label="Email"
              type="email"
              {...form.register("email")}
              value={agentEmailAddress}
              onChange={(e) => setAgentEmailAddress(e.target.value)}
            />

            <FormField
              defaultValue="+27 889 889 009"
              label="Phone"
              type="tel"
              {...form.register("phone")}
              value={agentPhoneNumber}
              onChange={(e) => setAgentPhoneNumber(e.target.value)}
            />

            <FormField
              defaultValue=""
              label="Website"
              type="text"
              {...form.register("websiteLink")}
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
            />

            <Grid item xs={3}>
              <p className="agentphotouploadlabel">Agent Photo</p>

              <ProfileCropper
                profilePicture={logo}
                setProfilePicture={setLogo}
                serverImage={viewer?.agentProfilePicture}
                text="Upload Photo"
              />
            </Grid>
          </div>

          <center>
            <MDButton
              color="dark"
              size="small"
              className="max-w-fit ml-auto"
              type="submit"
              disabled={loading}
              onClick={save}
            >
              {loading ? "Updating..." : "Update"}
            </MDButton>
          </center>
        </div>
      </Form>
    </div>
  );
}
