import MDTypography from "@core/components/MDTypography";
import { usePageRerender } from "contexts/rerender";
import { useEffect, useState } from "react";

function NoAnalyticsData({ isLoading }) {
  const { rerenderCount } = usePageRerender();

  const [status, setStatus] = useState("Loading...");
  useEffect(() => {
    setTimeout(() => {
      if (!isLoading) {
        setStatus("No data at the moment");
      }else{
        setStatus("Loading.....")
      }
    }, 5000);
  }, [isLoading, rerenderCount]);
  return (
    <center>
      <MDTypography variant="caption" fontWeight="regular" color="#456374">
        {status}
      </MDTypography>
    </center>
  );
}
export default NoAnalyticsData;
