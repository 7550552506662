/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */

import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import heic2any from "heic2any";
import { query } from "api";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { isFileAllowed } from "utils/isFileAllowed";
import { isHeicFile } from "utils/isHeicFile";
import { blobToFile } from "utils/convertHeic2Jpg";

export default function AddPhotoForm({ getFloorPlans, cancel, id, moduleId }) {
  const [isSnackVisible, setIsSnackVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const [file, setFile] = useState(null);

  const submitFile = async (uploadFile, i) => {
    const data = new FormData();
    data.append("picture", uploadFile);
    try {
      await query.post(`/floor-plans-folders/${id}/upload`, data, {
        "Content-Type": "multipart/form-data",
      });
      success(`Image ${i + 1} added successfully`);
    } catch (err) {
      error(`Failed to upload image ${i + 1}.`);
      console.log(err); // eslint-disable-line
    }
  };

  const handleSubmit = async () => {
    if (file.length > 10) {
      error("You can only upload up to 10 images.");
      return;
    }

    setIsLoading(true);

    for (let i = 0; i < file.length; i++) {
      const uploadFile = file[i];

      if (isFileAllowed(uploadFile)) {
        if (isHeicFile(uploadFile)) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const blob = await heic2any({
              blob: uploadFile,
              toType: "image/jpeg",
              quality: 0.7,
            });

            const fileName = "viewlio.txt";
            const mimeType = "text/plain";
            const newFile = blobToFile(blob, fileName, mimeType);

            // eslint-disable-next-line no-await-in-loop
            await submitFile(newFile, i);
          } catch (err) {
            error("Failed to process your file");
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          await submitFile(uploadFile, i);
        }
      } else {
        error(`Invalid file type. Image ${i + 1} not uploaded.`);
      }
    }

    success(`Finished uploading images`);
    getFloorPlans(moduleId);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    cancel();
  };

  return (
    <MDBox component="form" pb={3} px={0}>
      <div className="flex flex-col gap-y-2 my-6 ">
        <div className="text-sm text-left">
          Upload a maximum of <b>10</b> photos at a time. Files types allowed are jpg, jpeg, png,
          and heic.
        </div>
        <MDBox className="mt-2 flex gap-x-1 w-full justify-center">
          <div className="file-input-container">
            <input
              type="file"
              name="submit"
              id="fileInput"
              onChange={(e) => {
                setFile(e.target.files);
              }}
              multiple
              className="text-sm"
            />
            <label htmlFor="fileInput" className="custom-file-label">
              {" "}
              Upload File{" "}
            </label>
          </div>
        </MDBox>
      </div>
      <MDBox className="mt-2 flex gap-x-1 w-full justify-center">
        <MDButton onClick={cancel} color="secondary" disabled={isLoading}>
          cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info" disabled={isLoading}>
          {isLoading ? "adding..." : "add"}
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

AddPhotoForm.propTypes = {
  cancel: PropTypes.func,
  getFloorPlans: PropTypes.func,
  id: PropTypes.string,
};
