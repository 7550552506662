/* eslint-disable import/no-unresolved */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// eslint-disable-next-line import/no-unresolved
// import data from "pages/viewer/builder/content/modules/video-gallery/data";
import { baseUrl } from "api";
import axios from "axios";
import getVideosRoutine, {
  addVideoFolderRoutine,
  removeVideoFolderRoutine,
  sortVideoFoldersRoutine,
} from "./routines";

/**
 * ============================================================
 * @param {Get Services}
 */
function* getVideos(action) {
  const { moduleId } = action.payload;

  try {
    // trigger request action
    yield put(getVideosRoutine.request());
    const response = yield call(axios.get, `${baseUrl}/video-gallery-folders?viewerId=${moduleId}`);

    yield put(getVideosRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

function* foldersSaga() {
  // run fetchDataFromServer on every trigger action
  yield takeEvery(getVideosRoutine.TRIGGER, getVideos);
}

export default foldersSaga;

function* removeVideoFolder(action) {
  const { videos, id } = action.payload;

  const newFolders = videos.filter((folder) => folder.id !== id);
  try {
    // trigger request action
    yield put(removeVideoFolderRoutine.request());

    yield put(removeVideoFolderRoutine.success(newFolders));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* removeVideoFolderSaga() {
  yield takeLatest([removeVideoFolderRoutine.TRIGGER], removeVideoFolder);
}

function* addVideoFolder(action) {
  const { videos, values } = action.payload;

  // https://img.youtube.com/vi/R-Xr4bxWWaE/4.jpg

  let videoID;
  let thumbnailUrl;
  if (values?.url?.includes("youtube.com")) {
    // eslint-disable-next-line prefer-destructuring
    videoID = values.url.split("?v=")[1];
    videoID = videoID.substring(0, videoID.indexOf("&"));

    thumbnailUrl = `https://img.youtube.com/vi/${videoID.replace(" ", "")}/0.jpg`;
  }

  if (values.url.includes("vimeo.com")) {
    // eslint-disable-next-line prefer-destructuring
    videoID = values.url.split("vimeo.com/")[1];
    thumbnailUrl = `https://vumbnail.com/${videoID}.jpg`;
  }

  const thumbnail = values.hasThumbnail
    ? thumbnailUrl
    : `https://viewlio.b-cdn.net/gallery/photo/${values.title}.png`;

  const newFolder = {
    title: values.title,
    url: values.url,
    id: videos.length + 2,
    thumbnail,
  };
  try {
    // trigger request action
    yield put(addVideoFolderRoutine.request());

    yield put(addVideoFolderRoutine.success([newFolder, ...videos]));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* addVideoFolderSaga() {
  yield takeLatest([addVideoFolderRoutine.TRIGGER], addVideoFolder);
}

function* sortVideoFolders(action) {
  const { videos, draggedFolderId, newTargetFolderId, moduleId } = action.payload;

  const folderPositions = videos.map((folder, i) => ({ id: folder.id, index: i }));
  const draggedFolder = folderPositions.find((folder) => folder.id === draggedFolderId);
  const newTargetFolder = folderPositions.find((folder) => folder.id === newTargetFolderId);

  const sortedFolders = [...videos];
  sortedFolders.splice(draggedFolder.index, 1);
  sortedFolders.splice(newTargetFolder.index, 0, videos[draggedFolder.index]);

  for (let i = 0; i < sortedFolders.length; i += 1) {
    sortedFolders[i].order = i + 1;
  }

  const response = yield call(
    axios.post,
    `${baseUrl}/video-gallery-folders/reorder/${moduleId}`,
    sortedFolders,
    {
      headers: {
        accept: "application/json",
        token: localStorage.getItem("$token"),
      },
    }
  );
  try {
    // trigger request action
    yield put(sortVideoFoldersRoutine.request());

    yield put(sortVideoFoldersRoutine.success(response.data));
  } catch (error) {
    // if request failed
  } finally {
    // trigger fulfill action
  }
}

export function* sortVideoFoldersSaga() {
  yield takeLatest([sortVideoFoldersRoutine.TRIGGER], sortVideoFolders);
}
