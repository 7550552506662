import MDButton from "@core/components/MDButton";
import { Slider } from "@mui/material";
import "cropperjs/dist/cropper.css";
import { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "./image-cropper.css";
import MDTypography from "@core/components/MDTypography";

export default function LogoImageCropper({
  file,
  setFile,
  aspectRatio,
  maxWidth,
  maxHeight,
  setCropperModal,
  filepickEvent,
  setFilepickEvent,
}) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const [zoom, setZoom] = useState(1);
  const onChange = (e) => {
    setFile("");
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const cropperRef = useRef(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());

      setCropperModal(false);
      setFilepickEvent();
    }
  };

  useEffect(() => {
    if (filepickEvent) {
      onChange(filepickEvent);
    }
  }, [filepickEvent]);

  return (
    <div style={{ width: "100%" }}>
      {image !== "" && (
        <div>
          <Cropper
            ref={cropperRef}
            style={{ height: 350, width: "100%" }}
            dragMode="move"
            aspectRatio={1600 / 500}
            zoomable
            preview=".img-preview"
            src={image}
            zoomTo={zoom}
            autoCropArea={1.5}
            restore={false}
            guides={false}
            center={false}
            zoomOnWheel={false}
            highlight={false}
            cropBoxMovable
            cropBoxResizable={false}
            toggleDragModeOnDblclick={false}
            format="png"
          />
          <center>
            <MDTypography variant="button" fontWeight="medium" color="text">
              Make sure your logo fits in the rectangle section
            </MDTypography>
          </center>
          <div style={{ padding: "12px", backgroundColor: "white" }}>
            <Slider
              defaultValue={zoom}
              min={0.1}
              max={3}
              step={0.01}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
            />
            <MDButton className="w-full mt-5" color="info" onClick={() => getCropData()}>
              Crop Image
            </MDButton>
            <MDButton
              className="w-full mt-5"
              color="light"
              onClick={() => {
                setCropperModal(false);
                setFilepickEvent();
              }}
            >
              Cancel
            </MDButton>
          </div>
        </div>
      )}
    </div>
  );
}
