import form from "pages/viewers/new-viewer/schemas/form";

const {
  formField: {
    name,
    firstName,
    lastName,
    email,
    title,
    linkedInProfile,
    phoneNumber,
    password,
    repeatPassword,
    logo,
    twitter,
    facebook,
    instagram,
    threads,
    publicEmail,
    bio,
    website,
  },
} = form;

const initialValues = {
  [name.name]: "",
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [title.name]: "",
  [linkedInProfile.name]: "",
  [phoneNumber.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [logo.name]: "",
  [threads.name]: "",
  [twitter.name]: "",
  [facebook.name]: "",
  [instagram.name]: "",
  [publicEmail.name]: "",
  [bio.name]: "",
  [website.name]: "",
};

export default initialValues;
