/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Card, Grid, Switch } from "@mui/material";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useEffect } from "react";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function MapsAndDirections({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setEdits({
      googleMapCodeEmbed: data?.googleMapCodeEmbed,
      displayInLeftMenu: data?.displayInLeftMenu,
      mapTitle: data?.mapTitle,
      label: data?.label,
    });
  }, [data, setEdits]);

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };

  return (
    <Card className="p-6">
      <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
        MAPS / DIRECTIONS
      </MDTypography>
      <Grid display="flex">
        <Grid item xs={4} className="grid gap-6">
          <FormField
            label="Menu Title*"
            placeholder="eg. MAP / DIRECTIONS"
            value={edits?.label || ""}
            onChange={(e) => setEdit("label", e.target.value)}
          />
        </Grid>
        <Grid item xs={4} className="specialmarigin centeralignalways">
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
            </MDBox>
            <MDBox width="80%" ml={0.5}>
              <MDTypography variant="button" className="text-sm text-gray-500">
                Display in Menu?
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
            <ClickAlert link={getModuleRoute(data)} />
          </MDBox>
        </Grid>
      </Grid>
      <br />

      <div className="text-sm">
        <FormField
          label="Map URL"
          type="text"
          onChange={(e) => setEdit("googleMapCodeEmbed", e.target.value)}
          value={edits?.googleMapCodeEmbed || ""}
          placeholder="Please enter the entire URL of the Map here."
        />
        <br />
        <br />
        <div className="font-bold mb-1 specialcolor">How to get a URL from Google Maps</div>
        <ol type="1">
          <li>
            1. Open{" "}
            <MDButton
              as="a"
              href="https://www.google.com/maps"
              rel="noreferrer"
              target="_blank"
              style={{ margin: 0, padding: 0 }}
            >
              Google Maps
            </MDButton>
            .
          </li>
          <li>2. Go to the directions, map, or Street View image you&apos;d like to embed.</li>
          <li>
            3. In the top left, click Menu{" "}
            <img
              className="inline"
              src="https://lh5.ggpht.com/gnm-ty6mnE6vkedDflD8UzuuSYpoeaGMx1Am3m0zH0OkEAkqv3jGJV3cnjkqH75mrrqn=w36-h36"
              alt="icon"
            />
            .
          </li>
          <li>
            4. Click <span className="font-bold">Share or embed map</span>.
          </li>
          <li>
            5. Click <span className="font-bold">Embed map</span>.
          </li>
          <li>6. Paste the code into the Map URL field above for this module.</li>
        </ol>
      </div>

      <Grid item xs={9}>
        <MDBox>
          <ClickAlert link={getModuleRoute(data)} />
        </MDBox>
      </Grid>
      <br />
      <Grid className="centermodulebuttons">
        <Grid display="flex" justify-content="center" className="customgap">
          <DeleteModule id={data?.id} />
          <MDButton
            color="info"
            size="medium"
            className="mx-auto w-full sm:w-1/4 md:w-auto"
            onClick={save}
            disabled={loading}
          >
            {loading ? "Updating..." : "UPDATE"}
          </MDButton>
        </Grid>
      </Grid>
    </Card>
  );
}

export default function MapsModule() {
  return <ModuleWrapper component={MapsAndDirections} />;
}
