/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { query } from "api";
import { useSnackbar } from "notistack";
import { useState } from "react";

function EditUserContent({ data, getUserDetails, isLoading, setIsLoading, setEditModal }) {
  const [fullName, setfullName] = useState(data.fullName);
  const [email, setEmail] = useState(data.email);
  const [cellphone, setCellPhone] = useState(data.cellphone);
  const [title, setTitle] = useState(data.title);
  const [role, setRole] = useState(data.role);
  const [company, setCompany] = useState(data.companyName);
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  return (
    <Card id="general" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">General</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Full Name"
              value={fullName}
              onChange={(event) => {
                setfullName(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormField
              label="Email"
              value={email}
              placeholder=""
              inputProps={{ type: "email" }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              placeholder=""
              // inputProps={{ type: "number" }}
              value={cellphone}
              onChange={(event) => {
                setCellPhone(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Company"
              value={company}
              onChange={(event) => {
                setCompany(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="industry-select-label">Select Role</InputLabel>
              <Select
                labelId="industry-select-label"
                id="industry-select"
                value={role}
                label="Select Industry"
                onChange={(event) => {
                  setRole(event.target.value);
                }}
                style={{
                  height: "30px",
                }}
              >
                <MenuItem value="root" key="root">
                  Admin
                </MenuItem>
                <MenuItem value="user" key="user">
                  User
                </MenuItem>
                {localStorage.getItem("$role") === "superadmin" && (
                  <MenuItem value="superadmin" key="superadmin">
                    Super Admin
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <MDBox ml="auto" mt={2}>
            <br />
            <br />
            <MDButton
              onClick={() => {
                if (
                  fullName === "" ||
                  email === "" ||
                  cellphone === "" ||
                  title === "" ||
                  role === "" ||
                  company === ""
                ) {
                  error("Fields cannot be empty");
                  return;
                }
                setIsLoading(true);
                query
                  .patch(`/users/${data.id}`, {
                    fullName,
                    email,
                    cellphone,
                    title,
                    role,
                    companyName: company,
                  })
                  .then((res) => {
                    success("Updated successfully");
                    setIsLoading(false);
                    getUserDetails();
                    setEditModal();
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    error("Failed to update");
                  });
              }}
              color="dark"
              size="small"
              // component={Link}
            >
              {isLoading ? "updating info...." : "update info"}
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default EditUserContent;
