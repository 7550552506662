/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { endpoints, query } from "api";
import { Form, useForm } from "components/form-input/form";
import SwitchToggle from "components/switch";
import { usePageRerender } from "contexts/rerender";
import { useViewer } from "contexts/viewer";
import countries from "data/countries";
import states from "data/states";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PATHS from "routes/paths";
import { availableModules } from "utils/availableModules";
import FormField from "../../form-field";
import { schema } from "./schema";

export function LocationForm({ snack }) {
  const { viewer, setViewer } = useViewer();
  const { name: viewerName } = useParams();

  const { modules } = useViewer();
  const [location, setLocation] = useState(viewer?.location);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedModuleType, setSelectedModuleType] = useState(viewer.baseModule);
  const [usePassword, setUsePassword] = useState(viewer.usePassword);
  const [address, setAddress] = useState(viewer.address);
  const [country, setCountry] = useState(viewer.country);
  const [province, setProvince] = useState(viewer.province);
  const [city, setCity] = useState(viewer.city);
  const [bio, setBio] = useState(viewer.bio);
  const [cvv, setCvv] = useState(viewer.cvv);
  const [password, setPassword] = useState(viewer.city);
  const [password2, setPassword2] = useState(viewer.city);
  const navigate = useNavigate();

  const getViewerData = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName, isActive: true })).then((res) => {
      if (res.data.length) {
        setViewer(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getViewerData();
  }, []);

  const moduleTypes = useMemo(
    () =>
      (modules || [])
        .filter((x) => !x.parent && availableModules.includes(x.type))
        .map((item) => ({
          label: item.label,
          value: item.name,
        })),
    [modules]
  );

  const form = useForm({
    schema,
  });

  useEffect(() => {
    setError();
    setLocation(viewer?.name);
    setUsePassword(viewer.usePassword);
    setSelectedModuleType(viewer?.baseModule);
  }, [viewer]);

  const { forceRerender } = usePageRerender();

  const save = () => {
    const payload = {};
    if (location === "") {
      snack("Gallery Name cannot be empty", { variant: "error", autoHideDuration: 2000 });
      return;
    }
    if (location !== viewer?.name) {
      payload.name = location;
    }
    if (selectedModuleType !== "") {
      payload.baseModule = selectedModuleType?.value;
    }
    if (address !== "") {
      payload.address = address;
    }
    if (country !== "") {
      payload.country = country;
    }
    if (province !== "") {
      payload.province = province;
    }
    if (city !== "") {
      payload.city = city;
    }
    if (bio !== "") {
      payload.bio = bio;
    }

    if (bio !== "") {
      payload.cvv = cvv;
    }

    if (password !== "" || password2 !== "") {
      if (usePassword) {
        if (password === password2) {
          payload.password = password;
          payload.repeatPassword = password2;
          payload.usePassword = usePassword;
        } else {
          snack("Passwords dont match", { variant: "error", autoHideDuration: 2000 });

          return;
        }
      }
    }
    payload.usePassword = usePassword;
    setLoading(true);
    setError();
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), payload)
      .then(() => {
        getViewerData();
        forceRerender();
        setLoading(false);
        snack("Updated", { variant: "success", autoHideDuration: 2000 });
        if (location !== viewer?.name) {
          navigate(PATHS.viewer.replace(":name", location).replace(":tab", "builder"));
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Something went wrong");
        snack("Could not update", { variant: "error", autoHideDuration: 2000 });
      });
  };

  return (
    <div>
      <Form form={form} onSubmit={save}>
        <div className="flex flex-col space-y-5">
          <FormField
            label="Gallery Name* (must be unique and not already taken)"
            type="text"
            {...form.register("name")}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <Grid item xs={12} sm={6} className="py-2">
            <span className="text-xs text-gray-500">
              When clicking on your menu logo this module will display:
            </span>

            <Autocomplete
              options={moduleTypes}
              value={selectedModuleType}
              onChange={(e, newValue) => setSelectedModuleType(newValue)}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
          <h1 className="text-sm text-gray-500">
            The following information will not be displayed publicly:
          </h1>
          <FormField
            label="Business Name"
            type="text"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
          />

          <Grid item xs={12} className="py-2">
            {/** factor these out */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
              <FormField
                className="mt-2"
                type="text"
                label="Address"
                name="address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
              <FormField
                className="mt-2"
                type="text"
                label="City"
                name="city"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
            </div>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl
                variant="standard"
                style={{
                  marginTop: "7px",
                }}
                fullWidth
              >
                <InputLabel id="country-select-label">Select Country</InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-select"
                  value={country}
                  label="Select Country"
                  // onChange={handleChange}
                  style={{
                    height: "30px",
                  }}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  {countries.map((option) => (
                    <MenuItem value={option.name} key={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              {country === "United States of America" ? (
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{
                    marginTop: "7px",
                  }}
                >
                  <InputLabel id="state-select-label">Select State</InputLabel>
                  <Select
                    labelId="country-select-label"
                    id="state-select"
                    value={province}
                    label="Select State"
                    // onChange={handleChange}
                    style={{
                      height: "30px",
                    }}
                    onChange={(e) => {
                      setProvince(e.target.value);
                    }}
                  >
                    {states.map((option) => (
                      <MenuItem value={option.name} key={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormField
                  className="mt-2"
                  type="text"
                  label="State/Province"
                  name="province"
                  value={province}
                  fullWidth={false}
                  onChange={(e) => {
                    setProvince(e.target.value);
                  }}
                  // error={errors.name && touched.name}
                  // success={nameV.length > 0 && !errors.name}
                />
              )}
            </Grid>
            <Grid item xs>
              <FormField
                className="mt-1"
                type="text"
                label="Zip/Postal Code"
                name="bio"
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="py-2">
            <div>
              <MDTypography variant="h6" fontWeight="" className="uppercase font-[600] text-[14px]">
                Password Protection
              </MDTypography>
              <div className="flex gap-x-2 items-center">
                <SwitchToggle checked={usePassword} setChecked={setUsePassword} />
                <h1 className="text-sm a1a1a6color">
                  Would you like to protect this entire Gallery with a password?
                </h1>
              </div>
            </div>
            {usePassword && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                <input autoComplete="false" name="hidden" type="text" style={{ display: "none" }} />

                <FormField
                  className="mt-2"
                  type="password"
                  label="Repeat Password"
                  name="repeatPpassword"
                  // value={nameV}
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                  // error={errors.name && touched.name}
                  // success={nameV.length > 0 && !errors.name}
                />
                <FormField
                  className="mt-2"
                  type="password"
                  label="Password"
                  name="password"
                  // value={nameV}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  // error={errors.name && touched.name}
                  // success={nameV.length > 0 && !errors.name}
                />
              </div>
            )}
          </Grid>
          <div className="flex justify-center">
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
          <center>
            <MDButton
              color="dark"
              size="small"
              className="max-w-fit ml-auto "
              type="submit"
              // onClick={save}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update"}
            </MDButton>
          </center>
        </div>
      </Form>
    </div>
  );
}
