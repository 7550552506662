/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import BaseLayout from "layouts/base-layout";
import ChangePassword from "pages/user/settings/components/change-password";
import Sidenav from "pages/user/settings/components/sidenav";
import BasicInfo from "./components/basic-info";
import UserAccounts from "./components/user-accounts";

function UserSettings() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("lg"));
  return (
    <BaseLayout>
      <MDBox mt={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3} style={{ maxWidth: isMobile ? "" : "250px"}}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <Header />
                </Grid> */}
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  {/* <UserAccounts /> */}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default UserSettings;
