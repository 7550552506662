const removeHiphen = (input) =>
  input
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const hyphenToSentence = (input) =>
  removeHiphen(
    input
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );

export default hyphenToSentence;
