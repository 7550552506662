import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import { Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";

function SelectBaseModuleContent() {
  const {viewer, setViewer} = useViewer();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  const save = () => {
    setLoading("Saving...");
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id.toString() }), {
        baseModule: selectedModule,
      })
      .then((res) => {
        setLoading();
        setViewer(res.data);
      })
      .catch((err) => {
        setLoading();
      });
  };

  useEffect(() => {
    setLoading("Loading modules...");
    query
      .get(endpoints.modules.getAll({}, { viewerId: viewer.id }))
      .then((res) => {
        setModules(res.data);
        setSelectedModule(res.data.length ? res.data[0].name : "");
        setLoading();
      })
      .catch(() => {
        setLoading();
      });
  }, []);

  return (
    <MDBox component="form">
      <Grid container spacing={3}>
        <Grid item xs={12} className="py-2">
          <span className="text-xs">Select Base Module</span>
          <Autocomplete
            value={loading || selectedModule}
            onChange={(e, newValue) => setSelectedModule(newValue)}
            options={modules.map((module) => module.name)}
            renderInput={(params) => <MDInput {...params} variant="standard" />}
            disabled={loading}
            className="max-w-md"
          />
        </Grid>

        <MDBox ml="auto" mt={2}>
          <MDButton  color="info" size="small" disabled={loading} onClick={save}>
            {loading || "Save"}
          </MDButton>
        </MDBox>
      </Grid>
    </MDBox>
  );
}

export default SelectBaseModuleContent;
