import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormField from "../form-field";

function Socials({ formData }) {
  const { formField, values } = formData;
  const { twitter, facebook, instagram, youtube, website } = formField;
  const {
    twitter: twitterV,
    facebook: facebookV,
    instagram: instagramV,
    youtube: youtubeV,
    website: websiteV,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Socials
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormField
              type={twitter.type}
              label={twitter.label}
              name={twitter.name}
              value={twitterV}
              // placeholder={twitter.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={facebook.type}
              label={facebook.label}
              name={facebook.name}
              value={facebookV}
              // placeholder={facebook.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={instagram.type}
              label={instagram.label}
              name={instagram.name}
              value={instagramV}
              // placeholder={instagram.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={youtube.type}
              label={youtube.label}
              name={youtube.name}
              value={youtubeV}
              // placeholder={youtube.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={website.type}
              label={website.label}
              name={website.name}
              value={websiteV}
              // placeholder={website.placeholder}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Socials.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Socials;
