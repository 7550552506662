import { createContext, useCallback, useContext, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const UrlContext = createContext();
export function useUrl() {
  return useContext(UrlContext);
}

export default function UrlProvider({ children }) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const actualParams = useMemo(() => {
    const params = {};
    searchParams?.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);

  const setSearchParam = useCallback(
    (name, value) => setSearchParams({ ...actualParams, [name]: value}),
    [actualParams, setSearchParams]
  );

  const setMultipleSearchParams = useCallback(
    (stuff) => setSearchParams({ ...actualParams, ...stuff}),
    [actualParams, setSearchParams]
  );

  const ssetSearchParams = useCallback(
    (stuff) => setSearchParams({ ...actualParams, ...stuff}),
    [actualParams, setSearchParams]
  );

  const getSearchParam = useCallback((name) => searchParams.get(name), [searchParams]);
  const removeSearchParam = useCallback((name) => {
    const temp = {...actualParams};
    delete temp[name];
    setSearchParams(temp);
  }, [actualParams, setSearchParams]);

  const value = useMemo(() => ({
    location,
    searchParams: actualParams,
    setSearchParam,
    setSearchParams: ssetSearchParams,
    getSearchParam,
    removeSearchParam,
    setMultipleSearchParams,
  }), [
    location,
    actualParams,
    setSearchParam,
    getSearchParam,
    ssetSearchParams,
    removeSearchParam,
    setMultipleSearchParams,
  ]);

  return (
    <UrlContext.Provider value={value}>
      {children}
    </UrlContext.Provider>
  )
}
