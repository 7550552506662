// @react-jvectormap components

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import VerticalBarChart from "@core/examples/Charts/BarCharts/VerticalBarChart";
import { Grid } from "@mui/material";
import NoAnalyticsData from "components/no-analytics-data";

function ViewPerModule({ data, timeOnModuleData }) {
  return (
    <MDBox mb={6}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <VerticalBarChart
            icon=""
            title="Views Per Module"
            description=""
            chart={data}
            timeOnModuleData={timeOnModuleData}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ViewPerModule;
