// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SF-Pro-Rounded-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SF-Pro-Rounded-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* fonts.css */\n@font-face {\n  font-family: \"SF-Pro-Rounded\";\n  src: local(\"SF-Pro-Rounded-Regular\"), local(\"SF-Pro-Rounded-Regular\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"SF-Pro-Rounded\";\n  src: local(\"SF-Pro-Rounded-Bold\"), local(\"SF-Pro-Rounded-Bold\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n/* Add more @font-face rules for different font styles if needed */\n", "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,6BAA6B;EAC7B;8DAC8D;EAC9D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B;8DAC2D;EAC3D,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA,kEAAkE","sourcesContent":["/* fonts.css */\n@font-face {\n  font-family: \"SF-Pro-Rounded\";\n  src: local(\"SF-Pro-Rounded-Regular\"), local(\"SF-Pro-Rounded-Regular\"),\n    url(\"./fonts/SF-Pro-Rounded-Regular.otf\") format(\"opentype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"SF-Pro-Rounded\";\n  src: local(\"SF-Pro-Rounded-Bold\"), local(\"SF-Pro-Rounded-Bold\"),\n    url(\"./fonts/SF-Pro-Rounded-Bold.otf\") format(\"opentype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n/* Add more @font-face rules for different font styles if needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
