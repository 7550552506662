/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import TableCell from "@core/layouts/pages/account/settings/components/TableCell";
import { CircularProgress, Link } from "@mui/material";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import BasicModal from "components/modals/modal";
// import { userAccounts } from "mock-data/user-accounts";
import { query } from "api";
import { useEffect, useState } from "react";
import UserAccountRow from "./account-row";
import AddUserContent from "./add-user-content";

function UserAccounts() {
  const [addModal, setAddModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getUserDetails = () => {
    setIsLoading(true);
    let url = `/users/mine?id=${localStorage.getItem("$id")}`;
    if (localStorage.getItem("$role") === "superadmin") {
      url = "/users/";
    }
    query
      .get(url)
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  return (
    <>
      <Card id="user-accounts">
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Editor Accounts</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={3} pb={3} lineHeight={1} className="sm:flex justify-between">
          {/* <MDBox px={3} pb={3} lineHeight={1} className="grid grid-cols-[auto_100px]"> */}
          <div>
            <MDTypography variant="button" color="text">
              You can add unlimited editors to this Gallery. There is an additional fee of $9/month
              or $90/year per Editor/per Gallery depending on the subscription model you have chosen
              for this Gallery.
            </MDTypography>
          </div>

          <div className="flex items-center">
            <MDButton
              component={Link}
              color="dark"
              size="small"
              className="h-max mt-4 sm:mt-0"
              onClick={() => setAddModal(true)}
            >
              Add Editor
            </MDButton>
          </div>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDBox minWidth="auto" className="overflow-scroll md:overflow-hidden">
            <Table sx={{ minWidth: "36rem" }}>
              <MDBox component="thead">
                <TableRow>
                  <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                    Editors
                  </TableCell>
                  <TableCell align="center" padding={[1.5, 6, 1.5, 6]} />
                </TableRow>
              </MDBox>
              {isLoading ? (
                <center>
                  <br />
                  <CircularProgress />
                </center>
              ) : null}
              <TableBody>
                {users.map((row, index) => (
                  <UserAccountRow
                    row={row}
                    index={index}
                    getUserDetails={getUserDetails}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ))}
              </TableBody>
            </Table>
          </MDBox>
        </MDBox>
      </Card>

      <BasicModal
        open={addModal}
        setOpen={setAddModal}
        content={
          <AddUserContent
            setAddModal={setAddModal}
            getUserDetails={getUserDetails}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        }
        hasHeader
        closable
        title="Add New Editor"
      />
    </>
  );
}

export default UserAccounts;
