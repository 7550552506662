/* eslint-disable import/no-unresolved */
import { endpoints, query } from "api";
import { useSnackbar } from "notistack";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useViewer } from "./viewer";
import { useUrl } from "./url";

export const PhotoGalleryContext = createContext();
export function usePhotoGallery() {
  return useContext(PhotoGalleryContext);
}

export default function PhotoGalleryContextProvider({ children }) {
  const { viewer } = useViewer();
  const { enqueueSnackbar } = useSnackbar();

  // status states
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [uploadingThumbnail, setUploadingThumbnail] = useState(false);
  const [fetchingFolders, setFetchingFolders] = useState(false);
  const [removingFolder, setRemovingFolder] = useState(false);
  const [updatingFolder, setUpdatingFolder] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  // helper states
  const [createdFolder, setCreatedFolder] = useState();
  const [createdFolderThumbnail, setCreatedFolderThumbnail] = useState();
  const [error, setError] = useState();
  const [folders, setFolders] = useState([]);
  const [lastFetchedFolders, setLastFetchedFolders] = useState();

  // helper functions
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const { getSearchParam } = useUrl();

  // const viewerId = localStorage.getItem("viewerId");
  const moduleId = getSearchParam("moduleId");
  // functions
  const fetchFolders = useCallback(() => {
    setError();
    if (viewer?.id) {
      setFetchingFolders(true);
      query
        .get(endpoints.photoGalleryFolders.getAll({}, { viewerId: moduleId }))
        .then((res) => {
          setFetchingFolders(false);
          if (res.data.length) {
            setFolders(res.data);
          } else {
            setFolders([]);
          }
        })
        .catch(() => setFetchingFolders(false));
    }
  }, [viewer?.id]); // eslint-disable-line

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  const createFolder = useCallback(
    (title) => {
      setCreatedFolder();
      setError();

      if (title?.trim() === "") {
        setError("Title required");
        return;
      }

      setCreatingFolder(true);
      query
        .post(endpoints.photoGalleryFolders.create(), {
          viewerId: viewer.id,
          name: title,
        })
        .then((res) => {
          // eslint-disable-line
          setCreatedFolder(res.data);
          setCreatingFolder(false);
          success("Created folder");
          if (!createdFolderThumbnail) {
            fetchFolders();
            setCreatedFolder();
          }
        })
        .catch((err) => {
          // eslint-disable-line
          setError(err?.response?.data.message || "Could not create Folder");
          setCreatingFolder(false);
        });
    },
    [viewer?.id, fetchFolders]
  );

  const updateFolder = useCallback(
    (id, updates) => {
      setError();
      setUpdatingFolder(true);
      query
        .patch(endpoints.photoGalleryFolders.updateOne({ id }), {
          ...updates,
        })
        .then((res) => {
          // eslint-disable-line
          setUpdatingFolder(false);
          fetchFolders();
          success("Updated folder");
        })
        .catch((err) => {
          // eslint-disable-line
          setError(err?.response?.data.message || "Could not update folder");
          setUpdatingFolder(false);
        });
    },
    [fetchFolders]
  );

  const uploadThumbnail = useCallback(
    (file, folderId) => {
      setError();
      setUploadingThumbnail(true);
      if (!file) {
        fetchFolders();
        setUploadingThumbnail(false);
        return;
      }

      const data = new FormData();
      data.append("picture", file);

      query
        .post(endpoints.photoGalleryFolders.uploadThumbnail({ id: folderId }), data, {
          "Content-Type": "multipart/form-data",
        })
        .then(() => {
          setUploadingThumbnail(false);
          setCreatedFolderThumbnail();
          fetchFolders();
          setCreatedFolder();
          success("Uploaded thumbnail");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          setUploadingThumbnail(false);
          setCreatedFolderThumbnail();
          setError(err?.response?.data.message || "Could not upload thumbnail");
        });
    },
    [fetchFolders]
  ); // eslint-disable-line

  useEffect(() => {
    if (createdFolderThumbnail && createdFolder) {
      uploadThumbnail(createdFolderThumbnail, createdFolder.id);
    }
  }, [createdFolder]);

  const removeFolder = useCallback(
    (id) => {
      setError();
      setRemovingFolder(id);
      query
        .delete(endpoints.photoGalleryFolders.deleteOne({ id }))
        .then(() => {
          setRemovingFolder(false);
          fetchFolders();
          success("Folder removed");
        })
        .catch((err) => {
          setRemovingFolder(false);
          setError(err?.response?.data.message || "Could not remove folder");
        });
    },
    [fetchFolders]
  ); // eslint-disable-line

  const uploadImage = useCallback(
    (file, folderId) => {
      setError();
      setUploadingImage(true);

      if (!file) {
        setUploadingImage(false);
        return;
      }

      const data = new FormData();
      data.append("picture", file);

      query
        .post(endpoints.photoGalleryFolders.uploadImage({ id: folderId }), data, {
          "Content-Type": "multipart/form-data",
        })
        .then(() => {
          setUploadingImage(false);
          fetchFolders();
          success("Image uploaded");
        })
        .catch((err) => {
          setUploadingImage(false);
          setError(err?.response?.data.message || "Could not upload image");
        });
    },
    [fetchFolders]
  );

  // get a folder
  // remove image from folder

  const value = useMemo(
    () => ({
      folders,
      fetchFolders,
      createFolder,
      removeFolder,
      updateFolder,
      uploadThumbnail,
      uploadImage,
      uploadingImage,
      createdFolderThumbnail,
      setCreatedFolderThumbnail,
      creatingFolder,
      uploadingThumbnail,
      fetchingFolders,
      removingFolder,
      updatingFolder,
    }),
    [
      folders,
      fetchFolders,
      createFolder,
      removeFolder,
      updateFolder,
      uploadThumbnail,
      uploadImage,
      uploadingImage,
      createdFolderThumbnail,
      setCreatedFolderThumbnail,
      creatingFolder,
      uploadingThumbnail,
      fetchingFolders,
      removingFolder,
      updatingFolder,
    ]
  );

  // util useEffects
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error", autoHideDuration: 3000 });
    }
  }, [error]);

  return <PhotoGalleryContext.Provider value={value}>{children}</PhotoGalleryContext.Provider>;
}
