/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Box, Modal } from "@mui/material";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { usePhotoGallery } from "contexts/photo-gallery";
import { useUrl } from "contexts/url";
import PropTypes from "prop-types";
import { useState } from "react";
import AddPhotoForm from "../forms/AddPhotoForm";
import EditFolderForm from "../forms/EditFolderForm";
import ImageCount from "../util/ImageCount";

export default function FloorPlanCard({
  folder,
  removeFolder,
  updateFolder,
  addImage,
  handleDragStart,
  handleDragEnter,
  handleDragOver,
  handleDragEnd,
  handleDrop,
  getFloorPlans,
}) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const { setSearchParam } = useUrl();

  const { removingFolder, updatingFolder, fetchingFolders } = usePhotoGallery();
  const disableButtons = removingFolder || updatingFolder || fetchingFolders;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "#ffff",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Card
      draggable
      onDragStart={() => handleDragStart(folder.id)}
      onDragEnter={() => handleDragEnter(folder.id)}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
        maxWidth: 345,
      }}
      className="hover:cursor-pointer mt-5"
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <center>
          <MDBox
            component="img"
            src={folder.thumbnail}
            borderRadius="lg"
            shadow="sm"
            height="180px"
            // width="100%"
            position="relative"
            zIndex={10}
            mb={2}
          />
        </center>
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-10}
          position="relative"
          zIndex={1}
        >
          <MDBox mr={1}>
            <MDButton
              onClick={() => {
                if (showEditForm) {
                  setShowEditForm(false);
                } else {
                  setShowEditForm(true);
                }
              }}
              color="info"
              size="small"
              disabled={disableButtons}
            >
              edit
            </MDButton>
          </MDBox>
          <MDButton
            onClick={() => removeFolder(folder.id)}
            variant="outlined"
            color="dark"
            size="small"
            disabled={disableButtons}
          >
            {removingFolder === folder.actualId ? "removing..." : "remove"}
          </MDButton>
        </MDBox>
        {/* <Link to={`${PATHS.photoLibrary}/${folder.id}`}> */}
        <MDTypography
          variant="h5"
          fontWeight="regular"
          sx={{ mt: 4 }}
          onClick={() => {
            setSearchParam("openFolder", folder.id);
          }}
        >
          {folder.name}
        </MDTypography>
        {/* </Link> */}

        {/* {showEditForm && (
          <EditFolderForm
            updateFolder={updateFolder}
            folder={folder}
            getFloorPlans={getFloorPlans}
            cancel={() => setShowEditForm(false)}
          />
        )} */}
        <Modal
          open={showEditForm}
          onClose={() => {
            setShowEditForm(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDBox sx={{ mt: 2 }} display="flex" justifyContent="space-between">
              <EditFolderForm
                updateFolder={updateFolder}
                folder={folder}
                getFloorPlans={getFloorPlans}
                cancel={() => setShowEditForm(false)}
              />
            </MDBox>
          </Box>
        </Modal>
        <MDBox
          sx={{ mt: 2, ml: 0, mr: 0, padding: 0 }}
          display="flex"
          justifyContent="space-between"
        >
          {!showAddForm && <ImageCount count={folder?.files?.length} id={folder.id} />}
          {!showAddForm && (
            <Tooltip title="Add Images">
              <MDButton
                onClick={() => setShowAddForm(true)}
                size="small"
                color="info"
                iconOnly
                disabled={disableButtons}
              >
                <Icon>add</Icon>
              </MDButton>
            </Tooltip>
          )}
        </MDBox>
        {showAddForm && (
          <AddPhotoForm
            addImage={addImage}
            getFloorPlans={getFloorPlans}
            id={folder.id}
            cancel={() => setShowAddForm(false)}
          />
        )}
      </MDBox>
    </Card>
  );
}
FloorPlanCard.defaultProps = {
  folder: {},
};

FloorPlanCard.propTypes = {
  folder: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    imageCount: PropTypes.number,
    handleDragStart: PropTypes.func,
    updateFolder: PropTypes.func,
    handleDragOver: PropTypes.func,
    handleDragEnter: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleDrop: PropTypes.func,
  }),
};
