/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import "cropperjs/dist/cropper.css";

import MDButton from "@core/components/MDButton";
import { Box, Modal, Typography } from "@mui/material";
import { endpoints, query } from "api";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useSnackbar } from "notistack";
import { useState } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function DeleteModule({ id }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { modules, fetchModules } = useViewer();
  const { setMultipleSearchParams } = useUrl();

  return (
    <>
      <MDButton color="error" size="medium" onClick={handleOpen} disabled={loading}>
        Delete
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="rounded-xl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Module
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "16px" }}>
            Are you sure you want to delete?
          </Typography>
          <br />
          <div className="flex flex-col md:flex-row gap-x-6 gap-y-8 items-center mt-4 centeraddvideobutton customgap">
            <MDButton variant="outlined" color="info" onClick={handleClose}>
              No
            </MDButton>
            <MDButton
              color="error"
              onClick={() => {
                setLoading(true);
                query
                  .delete(endpoints.modules.deleteOne({ id }))
                  .then((res) => {
                    fetchModules();
                    success("Module delete successfully");
                    handleClose();
                    setTimeout(() => {
                      setMultipleSearchParams({
                        builderTab: modules[1].type,
                        moduleId: modules[1].id,
                      });
                    }, 1000);
                  })
                  .catch((err) => {
                    error("Failed to delete module");
                  });
              }}
            >
              {loading ? "Deleting" : "Yes"}
            </MDButton>{" "}
          </div>
        </Box>
      </Modal>
    </>
  );
}
