/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Select } from "@mui/material";
import Card from "@mui/material/Card";
import { endpoints, query } from "api";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function TransferViewer({ snack }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [isLoading, setIsLoading] = useState();
  const { viewer, setViewer } = useViewer();

  const getUserDetails = () => {
    setIsLoading(true);
    let url = ``;
    if (localStorage.getItem("$role") === "superadmin") {
      url = "/users/";
    }
    query
      .get(url)
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const { name: viewerName } = useParams();

  const getViewerData = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName, isActive: true })).then((res) => {
      if (res.data.length) {
        setViewer(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getViewerData();
  }, []);

  return (
    <Card id="transfer-viewer" sx={{ overflow: "visible" }} className="rounded-lg">
      <div className="p-6">
        <MDTypography variant="h5">TRANSFER GALLERY</MDTypography>
      </div>
      <div className="p-6 pt-0">
        <div>
          <div className="flex flex-col space-y-5">
            <MDBox mb={2}>
              <MDTypography
                sx={{
                  fontSize: "12px",
                  fontWeight: "semibold",
                  lineHeight: "20px",
                  color: "#7b809a",
                  marginBottom: "4px",
                }}
                className="font-light text-sm my-2"
              >
                {/* Select an Editor to Gallery this Viewer to. That Editor will then become the new
                Admin for this Viewer, and your Admin account will be downgraded to an Editor
                account. The new Admin will then be responsible for the Viewer Billing and must have
                a valid credit card on file to keep the Viewer active. */}
                Select an Admin to transfer this Gallery to
              </MDTypography>

              <Select
                sx={{
                  width: "100%",
                }}
                native
                inputProps={{
                  name: "type",
                  id: "uncontrolled-native",
                }}
                className="w-full my-2"
                onChange={(event) => {
                  setSelectedUser(event.target.value);
                }}
                name="user"
              >
                <option value="">Select Admin</option>
                {users.map((user) => (
                  <option value={user.id}>{user.fullName}</option>
                ))}
              </Select>
            </MDBox>
            <center>
              <MDButton
                color="dark"
                size="small"
                className="max-w-fit ml-auto"
                type="submit"
                onClick={() => {
                  setIsLoading(true);
                  query
                    .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
                      owner: selectedUser,
                      isActive: false,
                      expirationDate: "transfer",
                    })
                    .then(() => {
                      getViewerData();

                      setIsLoading(false);
                      snack("Gallery transferred successfully", {
                        variant: "success",
                        autoHideDuration: 2000,
                      });
                    })
                    .catch((err) => {
                      snack("Failed to transfer", { variant: "error", autoHideDuration: 2000 });
                    });
                }}
              >
                {isLoading ? "Transferring..." : "Transfer"}
              </MDButton>
            </center>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransferViewer;
