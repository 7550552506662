/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Card } from "@mui/material";
import { baseUrl, endpoints, query } from "api";
import paymentImage from "assets/payment.png";
import { useViewer } from "contexts/viewer";
import { subscriptionsData } from "data";
import PaymentMethod from "pages/user/billing/components/payment-method";
import { useEffect, useMemo, useState } from "react";

import Invoice from "@core/layouts/pages/account/billing/components/Invoice";
import axios from "axios";
import { useSnackbar } from "notistack";
import Billing from "pages/viewers/new-viewer/components/billing";
import SummaryPage from "pages/viewers/new-viewer/components/summary";
import { useParams } from "react-router-dom";
import filterArrayByField from "utils/filterArrayByField";
import Extras from "./extras";
import PaymentCardModal from "./options/components/payment-card";
import ViewerSubscription from "./subscription";

export default function ViewerBillingPage() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("$id");
  const [editCard, setEditCard] = useState(false);
  const { viewer, setViewer } = useViewer();

  const [removeOneviewerLogo, setRemoveOneviewerLogo] = useState(false);
  const [subscriptionsItems, setSubscriptionsItems] = useState([]);
  const [setupExtras, setSetupExtras] = useState("none");
  const [loadingMessage, setLoadingMessage] = useState("Purchase");
  const [plan, setPlan] = useState("monthly");

  useEffect(async () => {
    if (subscriptions?.subscriptions && viewer) {
      setPlan(viewer?.plan);
      setSetupExtras(viewer?.setupExtras);
      setRemoveOneviewerLogo(viewer?.removeOneviewerLogo);
    }
  }, [viewer, subscriptions]);

  const getSubscriptionDetails = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/billing/subscriptions/viewer/${viewer?.id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setSubscriptions(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const { name: viewerName } = useParams();

  const getViewerData = () => {
    query.get(endpoints.viewers.getAll({}, { name: viewerName })).then((res) => {
      if (res.data.length) {
        setViewer(res.data[0]);
      }
    });
  };

  useEffect(() => {
    getViewerData();
  }, []);
  useEffect(async () => {
    getSubscriptionDetails();
  }, [viewer]);

  const rows = useMemo(
    () =>
      [
        // subscriptions?.subscriptions && viewer?.removeOneviewerLogo !== removeOneviewerLogo
        viewer?.removeOneviewerLogo !== removeOneviewerLogo
          ? {
              id: 1,
              item: "Remove Viewlio Logo from Gallery Menu",
              cost:
                plan === "monthly"
                  ? subscriptionsData.removeLogoMontlhy.amount
                  : subscriptionsData.removeLogoYearly.amount,
              data:
                plan === "monthly"
                  ? subscriptionsData.removeLogoMontlhy
                  : subscriptionsData.removeLogoYearly,
            }
          : null,
        viewer.setupExtras !== setupExtras
          ? setupExtras !== "none"
            ? {
                id: 2,
                item:
                  setupExtras === "completeViewer"
                    ? "Concierge Complete Gallery Built for You"
                    : "Concierge 1-Hour Set-Up Walkthrough Instruction",
                cost:
                  setupExtras === "completeViewer"
                    ? subscriptionsData.completeViewer.amount
                    : subscriptionsData.setupWalkthrough.amount,
                data:
                  setupExtras === "completeViewer"
                    ? subscriptionsData.completeViewer
                    : subscriptionsData.setupWalkthrough,
              }
            : null
          : null,
        viewer?.plan !== plan
          ? {
              id: 3,
              item: `Plan: ${plan?.toUpperCase()}`,
              cost:
                plan === "monthly"
                  ? subscriptionsData.monthlyPlan.amount
                  : subscriptionsData.yearlyPlan.amount,
              data:
                plan === "monthly" ? subscriptionsData.monthlyPlan : subscriptionsData.yearlyPlan,
            }
          : null,
      ].filter((x) => x),
    [removeOneviewerLogo, setupExtras, plan]
  );

  useEffect(() => {
    const data = rows.map((x) => x.data);
    const recreatedArray = data?.reduce((acc, obj) => {
      const { type, amount, description, ...rest } = obj;
      if (!acc[type]) {
        acc[type] = type === "onceoff" ? { amount: 0, description: "" } : [];
      }

      if (type === "onceoff") {
        acc[type].amount += amount;
        acc[type].description = description || acc[type].description;
      } else {
        acc[type].push(rest);
      }
      return acc;
    }, {});

    setSubscriptionsItems(recreatedArray);
  }, [rows, plan, setupExtras]);

  const [isViewerCancelled, setIsViewerCancelled] = useState(false);

  useEffect(() => {
    const filteredSubscription = filterArrayByField(
      subscriptions?.subscriptions || [],
      "description",
      viewer.name
    );
    if (filteredSubscription.length > 0) {
      setIsViewerCancelled(filteredSubscription[0]?.subscription?.cancel_at_period_end);
    }
  }, [subscriptions?.subscriptions]);

  const [activeStep, setActiveStep] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [error, setError] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const errorMessage = (message) =>
    enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  return (
    <div className="flex flex-col gap-y-4" style={{ fontSize: "16px" }}>
      <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-y-2 gap-x-4">
        <div className="flex flex-col gap-y-2">
          {subscriptions?.subscriptions && (
            <ViewerSubscription
              subscriptions={subscriptions?.subscriptions || []}
              isLoading={isLoading}
            />
          )}
          {/* this is where i will add the logic */}
          <Card sx={{ width: "100%" }}>
            <MDBox p={2} className="flex flex-col gap-y-3">
              {isLoading ? (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Loading....
                </MDTypography>
              ) : (
                <>
                  {!isViewerCancelled && (
                    <>
                      {activeStep === 0 && (
                        <>
                          <Extras
                            removeOneviewerLogo={removeOneviewerLogo}
                            setRemoveOneviewerLogo={setRemoveOneviewerLogo}
                            setupExtras={setupExtras}
                            setSetupExtras={setSetupExtras}
                            plan={plan}
                            setPlan={setPlan}
                            subscriptions={subscriptions.subscriptions || []}
                          />
                          <br />
                          <center>
                            <img src={paymentImage} alt="" width={300} />
                          </center>
                          <br />
                          <div className="mt-3 ">
                            <center>
                              <MDButton
                                className="PurchaseandCreateViewer"
                                color="info"
                                onClick={() => setActiveStep(1)}
                              >
                                Purchase
                              </MDButton>
                            </center>
                          </div>
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                          <MDBox p={3}>
                            <MDBox>
                              <SummaryPage
                                removeOneviewerLogo={removeOneviewerLogo}
                                setupExtras={setupExtras}
                                plan={plan}
                                purchaseRows={rows}
                                setTotalAmount={setTotalAmount}
                                setSubscriptionsItems={setSubscriptionsItems}
                              />
                              <br />

                              <MDBox
                                mt={2}
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                                className="flex-col gap-y-2 md:flex-row"
                              >
                                <MDButton
                                  color="light"
                                  onClick={() => {
                                    setActiveStep(0);
                                  }}
                                >
                                  back
                                </MDButton>
                                <MDButton
                                  onClick={() => {
                                    setActiveStep(2);
                                  }}
                                  disabled={loading}
                                  type="submit"
                                  color="dark"
                                >
                                  {loading && "Initializing..."}
                                  NEXT
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </>
                      )}
                      {activeStep === 2 && (
                        <>
                          <MDBox p={3}>
                            <MDBox>
                              <Billing
                                activeStep={activeStep}
                                handleBack={() => {
                                  setActiveStep(1);
                                }}
                                paymentStatus={loadingMessage}
                                setLoadingMessage={setLoadingMessage}
                                loading={loading}
                                errorMessage={errorMsg}
                                setErrorMessage={setErrorMessage}
                                setIsLoading={setIsPaymentLoading}
                                isLoading={isPaymentLoading}
                                error={error}
                                totalAmount={totalAmount}
                                viewer={viewer}
                                plan={plan}
                                removeOneviewerLogo={removeOneviewerLogo}
                                setupExtras={setupExtras}
                                setError={setError}
                                setActiveStep={setActiveStep}
                                subscriptionRows={subscriptionsItems}
                                customerDefaultPaymentMethod={
                                  subscriptions?.customer?.invoice_settings?.default_payment_method
                                }
                                submitForm={(values, paymentDetails) => {
                                  if (subscriptions.subscriptions) {
                                    const updatedValues = {};
                                    if (values?.paynentId) {
                                      fetch(
                                        `${baseUrl}/billing/cancel-subscriptions/${subscriptions?.subscriptions[0]?.paynentId}`,
                                        {
                                          method: "GET",
                                          headers: { "Content-Type": "application/json" },
                                        }
                                      )
                                        .then((res) => res.json())
                                        .then((data) => {})
                                        .catch(() => {
                                          setIsLoading(false);
                                        });

                                      updatedValues.paynentId = values?.paynentId;
                                    }
                                    if (values?.removeLogoSubscriptionId) {
                                      fetch(
                                        `${baseUrl}/billing/cancel-subscriptions/${subscriptions?.subscriptions[0]?.removeLogoSubscriptionId}`,
                                        {
                                          method: "GET",
                                          headers: { "Content-Type": "application/json" },
                                        }
                                      )
                                        .then((res) => res.json())
                                        .then((data) => {})
                                        .catch(() => {
                                          setIsLoading(false);
                                        });
                                      updatedValues.removeLogoSubscriptionId =
                                        values?.removeLogoSubscriptionId;
                                    }
                                    query
                                      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
                                        removeOneviewerLogo,
                                        setupExtras,
                                        plan,
                                        isActive: true,
                                        status: "1",
                                      })
                                      .then(() => {
                                        axios
                                          .patch(
                                            `${baseUrl}/billing/${subscriptions?.subscriptions[0]?.id}`,
                                            updatedValues
                                          )
                                          .then(() => {})
                                          .catch(() => {});

                                        setActiveStep(0);
                                        getSubscriptionDetails();
                                        getViewerData();
                                        setIsLoading(false);
                                        setLoadingMessage("Purchase");
                                        success("Payment made successful");
                                        // setTimeout(() => {
                                        //   window.location.reload();
                                        // }, 2000);
                                      })
                                      .catch((err) => {
                                        setIsLoading(false);
                                        setLoadingMessage("Purchase");

                                        errorMessage(err.response.data.message);
                                      });
                                  } else {
                                    const getDaysInMonth = (year, month) =>
                                      new Date(year, month + 1, 0).getDate();
                                    const currentDate = new Date();
                                    const year = currentDate.getFullYear();
                                    const month = currentDate.getMonth();
                                    const daysInMonth = getDaysInMonth(year, month);
                                    const newFutureDate = new Date(currentDate);
                                    newFutureDate.setDate(newFutureDate.getDate() + daysInMonth);

                                    axios
                                      .post(`${baseUrl}/billing`, {
                                        description: viewer.name,
                                        amount: totalAmount,
                                        customerId: paymentDetails.customerId,
                                        email: paymentDetails.email,
                                        paymentStatus: "success",
                                        viewerId: viewer.id,
                                        paynentId: values?.paynentId,
                                        removeLogoSubscriptionId: values?.removeLogoSubscriptionId,
                                        userId: localStorage.getItem("$id"),
                                        dateCreated: new Date(),

                                        expiringDate: newFutureDate,
                                      })
                                      .then((billingResponse) => {
                                        setLoadingMessage("SETTING UP YOUR GALLERY...");

                                        query
                                          .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
                                            removeOneviewerLogo,
                                            setupExtras,
                                            plan,
                                            isActive: true,
                                            status: "1",
                                          })
                                          .then(() => {})
                                          .catch((err) => {});

                                        query
                                          .patch(
                                            endpoints.users.updateOne({
                                              id: localStorage.getItem("$id"),
                                            }),
                                            {
                                              cardNumber: paymentDetails.paymentMethodId,
                                            }
                                          )
                                          .then(() => {});

                                        setActiveStep(0);
                                        getSubscriptionDetails();
                                        getViewerData();
                                        setIsLoading(false);
                                        setLoadingMessage("Purchase");
                                        success("Payment made successful");
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 2000);
                                        
                                      })
                                      .catch((err) => {
                                        setErrorMessage("Failed to sync payment and gallery.");
                                        setIsLoading(false);
                                        // setErrorMessage(
                                        //   "Payment was successful but failed to create gallery. Please contact admin"
                                        // );
                                      });
                                  }
                                }}
                                submitValues={{}}
                              />
                              <br />

                              <MDBox
                                mt={2}
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                                className="flex-col gap-y-2 md:flex-row"
                              >
                                {activeStep === 0 || activeStep === 2 ? (
                                  <MDBox />
                                ) : (
                                  <MDButton
                                    color="light"
                                    onClick={() => {
                                      setActiveStep(0);
                                    }}
                                  >
                                    back
                                  </MDButton>
                                )}
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </MDBox>
          </Card>
        </div>
        <div>
          {/* <Payments payments={subscriptions?.payments} isLoading={isLoading} /> */}
          <PaymentMethod
            paymentMethod={subscriptions?.paymentMethod}
            customer={subscriptions.customer}
            getSubscriptionDetails={getSubscriptionDetails}
            isLoading={isLoading}
          />
          <br />
          <Card sx={{ hight: "100%" }}>
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                Invoices
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {isLoading ? (
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    Loading....
                  </MDTypography>
                ) : (
                  <>
                    {subscriptions?.charges?.length > 0 ? (
                      <>
                        {subscriptions?.charges?.map((line) => (
                          <Invoice
                            date={line?.created}
                            invoicePdf={`${line.receipt_url}`}
                            price={`${line?.currency.toUpperCase()}${(line.amount / 100).toFixed(
                              2
                            )}`}
                            id={line?.description}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                          No invoices found
                        </MDTypography>
                      </>
                    )}
                  </>
                )}
              </MDBox>
            </MDBox>
          </Card>
        </div>
      </div>
      <PaymentCardModal
        open={editCard}
        setOpen={setEditCard}
        fetch={false}
        customer={subscriptions.customer}
        getSubscriptionDetails={getSubscriptionDetails}
        subscriptionsItems={subscriptionsItems}
        setupExtras={setupExtras}
        removeOneviewerLogo={removeOneviewerLogo}
        plan={plan}
        billingRows={rows || []}
      />
      {/* <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-y-2 gap-x-4">
        <div>
          <PaymentMethod
            paymentMethod={subscriptions?.paymentMethod}
            customer={subscriptions.customer}
            getSubscriptionDetails={getSubscriptionDetails}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div>
        <h1 className="font-bold">Terms and Conditions</h1>
        Accounts and viewers that are inactive are deleted after 1 year of inactivity, accounts 2
        years, with 3 warning emails and reactivation discount, otherwise deletion is imminent
      </div> */}
    </div>
  );
}
