/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import { endpoints, query } from "api";
import { useState } from "react";

export default function useDeleteUser({ getUserDetails }) {
  const [modal, setModal] = useState({
    visibility: false,
    item: {},
  });

  // eslint-disable-next-line no-unused-vars
  function deleteUser(id) {
    return query
      .delete(`/users/${modal?.item?.id}`)
      .then(() => {
        query.get(`${endpoints.viewers.deleteUserViewers({ id: modal?.item?.id })}`).then(() => {});
        getUserDetails();
        setModal((prevState) => ({
          ...prevState,
          visibility: false,
        }));
      })
      .catch((err) => {
        setModal((prevState) => ({
          ...prevState,
          visibility: false,
        }));
      });
  }

  function handleDeleteClick(item) {
    setModal((prevState) => ({
      ...prevState,
      visibility: true,
      item,
    }));
  }

  return { modal, setModal, deleteUser, handleDeleteClick };
}
