/* eslint-disable import/no-unresolved */
import App from "App";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./preflight.css";

import { MaterialUIControllerProvider } from "@core/context";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { PageRerenderProvider } from "contexts/rerender";
import UrlProvider from "contexts/url";
import ViewerContextProvider from "contexts/viewer";
import { SnackbarProvider } from "notistack";
import withReduxFeatures from "redux/withReduxFeatures";

const WrappedApp = withReduxFeatures(App);

ReactDOM.render(
  <BrowserRouter basename="/">
    <MaterialUIControllerProvider>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider>
            <UrlProvider>
              <ViewerContextProvider>
                <PageRerenderProvider>
                  <WrappedApp />
                </PageRerenderProvider>
              </ViewerContextProvider>
            </UrlProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
