/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import TableCell from "@core/layouts/pages/account/settings/components/TableCell";
import TableRow from "@mui/material/TableRow";
import ActionModal from "components/modals/action-modal";
import Modal from "components/modals/modal";
import { useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { query } from "api";
import { useSnackbar } from "notistack";
import EditUserContent from "./edit-user-content";
import useDeleteUser from "./hooks/use-delete-user";

export default function UserAccountRow({ row, index, getUserDetails, isLoading, setIsLoading }) {
  const [editModal, setEditModal] = useState(false);
  const { modal, setModal, deleteUser, handleDeleteClick } = useDeleteUser({ getUserDetails });

  function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join("-");
  }
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  return (
    <>
      <TableRow key={index}>
        <TableCell padding={[1, 1, 1, 0.5]}>
          <MDBox lineHeight={1.4}>
            <MDTypography display="block" variant="button" fontWeight="regular">
              {row?.fullName}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              Added {formatDate(row?.createdAt)}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
          <div className="flex space-x-2">
            <MDButton color="success" iconOnly onClick={() => setEditModal(true)}>
              <FiEdit3 size={15} />
            </MDButton>
            <MDButton color="error" iconOnly onClick={() => handleDeleteClick(row)}>
              <FiTrash2 size={15} />
            </MDButton>
          </div>
        </TableCell>
      </TableRow>

      <Modal
        open={editModal}
        setOpen={setEditModal}
        content={
          <EditUserContent
            data={row}
            getUserDetails={getUserDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setEditModal={setEditModal}
          />
        }
        hasHeader
        closable
        title="Edit Editor Info"
      />

      <ActionModal
        action="Delete"
        id={modal?.item?.id}
        actionFuction={deleteUser}
        message={`You want to delete ${modal?.item?.fullName}?`}
        open={modal.visibility}
        setOpen={setModal}
      />
    </>
  );
}
