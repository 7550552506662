/* eslint-disable import/no-unresolved */
import { Alert, Snackbar } from "@mui/material";
import { host } from "api";
import { useViewer } from "contexts/viewer";
import { useState } from "react";

export default function ClickAlert({ link }) {
  const [isSnackVisible, setIsSnackVisible] = useState(false);
  const { viewer } = useViewer();

  const moduleUrl = `${host}/${viewer.slug}/${link}`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(moduleUrl);
      setIsSnackVisible(true);
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
  };
  return (
    <>
      <Snackbar
        open={isSnackVisible}
        autoHideDuration={3000}
        onClose={() => {
          setIsSnackVisible(false);
        }}
      >
        <Alert sx={{ width: "100%" }} severity="success">
          Url copied successfully!
        </Alert>
      </Snackbar>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    </>
  );
}
