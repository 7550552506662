import * as Yup from "yup";
import form from "./form";

const {
  formField: {
    fullName,
    email,
    title,
    cellphone,
    password,
    repeatPassword,
    companyName,
    companyWebsiteUrl,
    nameOnCard,
    cardNumber,
    expirationDate,
    cvv,
    billingAddress,
  },
} = form;

const validations = [
  Yup.object().shape({
    [cellphone.name]: Yup.string(),
    [fullName.name]: Yup.string().required(fullName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  Yup.object().shape({
    [title.name]: Yup.string(),
    [companyName.name]: Yup.string(),
    [companyWebsiteUrl.name]: Yup.string(),
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    [nameOnCard.name]: Yup.string().required(nameOnCard.errorMsg),
    [cardNumber.name]: Yup.string().required(cardNumber.errorMsg),
    [expirationDate.name]: Yup.string().required(expirationDate.errorMsg),
    [cvv.name]: Yup.string().required(cvv.errorMsg),
    [billingAddress.name]: Yup.string().required(billingAddress.errorMsg),
  }),
];

export default validations;
