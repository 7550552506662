/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Card from "@mui/material/Card";
import { useViewer } from "contexts/viewer";
import { LocationForm } from "./form";

function Location(props) {
  const {viewer, setViewer} = useViewer();
  return (
    <Card id="location" sx={{ overflow: "visible" }} className="rounded-lg">
      <MDBox p={3}>
        <MDTypography variant="h5">General</MDTypography>
      </MDBox>
      <div className="p-6 pt-0">
        <LocationForm viewer={viewer} setViewer={setViewer} {...props} />
      </div>
    </Card>
  );
}

export default Location;
