import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as api from "api";
import { Form, Formik } from "formik";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PATHS from "routes/paths";
import CompanyInfo from "./components/company-info";
import Terms from "./components/terms";
import UserInfo from "./components/user-info";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

function NewUser() {
  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function getSteps() {
    return ["User", "Company", "Terms"];
  }

  const navigate = useNavigate();

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <UserInfo formData={formData} />;
      case 1:
        return <CompanyInfo formData={formData} />;
      case 2:
        return <Terms formData={formData} />;
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    // eslint-disable-next-line no-unused-vars
    const { repeatPassword, ...accountDetails } = values;

    actions.setSubmitting(true);
    setLoading(true);
    setError("");

    api.query
      .post(api.endpoints.users.register, { ...accountDetails, plan: selectedPlan })
      .then(async (res) => {
        const token = res.data.tokens.accessToken;

        const decodedToken = jwtDecode(token);
        localStorage.setItem("$token", token);
        localStorage.setItem("$fullName", decodedToken.fullName);
        localStorage.setItem("$email", decodedToken.email);
        localStorage.setItem("$id", decodedToken.sub);
        localStorage.setItem("$token", res.data.tokens.accessToken);
        
        setLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        navigate(PATHS.createViewer);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError("Something went wrong");
        }
        actions.setSubmitting(false);
      });
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox py={3} mb={20} height="65vh">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 4, maxWidth: "900px", mx: "auto" }}
      >
        <Grid item xs={12} lg={9}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      style={{ background: "#245C84" }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>

                  <MDBox p={3}>
                    {error && <p className="text-sm text-red-600 text-center mb-5">{error}</p>}
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton color="light" onClick={handleBack}>
                            back
                          </MDButton>
                        )}

                        <MDButton disabled={isSubmitting} type="submit" color="dark">
                          {loading && "Registering..."}
                          {!loading && (isLastStep ? "Submit" : "Next")}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={1} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                      >
                        Sign in
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewUser;
