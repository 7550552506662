/* eslint-disable import/no-unresolved */
import { Menu, Transition } from "@headlessui/react";
import PanToolIcon from "@mui/icons-material/PanTool";
import { Tooltip } from "@mui/material";

export default function PanToolMenu() {
  return (
    <Menu as="div" className="ml-3 relative">
      <div className="relative">
        <Menu.Button className="max-w-xs md:px-1 flex items-center text-sm focus:outline-none bg-transparent hover:bg-gray-100 focus:bg-gray-100 p-2 opacity-0 hidden group-hover:block group-hover:opacity-100 group-focus-within:opacity-100 rounded-lg text-gray-500 absolute top-[-18px] right-[0px]">
          <Tooltip title="Drag And Drop">
            <PanToolIcon color="info" />
          </Tooltip>
        </Menu.Button>
      </div>
    </Menu>
  );
}
