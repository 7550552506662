/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import { Alert } from "@mui/material";
import { query } from "api";
import { usePhotoGallery } from "contexts/photo-gallery";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { blobToFile } from "utils/convertHeic2Jpg";
// eslint-disable-next-line import/no-extraneous-dependencies
import heic2any from "heic2any";
import { isFileAllowed } from "utils/isFileAllowed";
import { isHeicFile } from "utils/isHeicFile";

export default function EditFolderForm({ folder, cancel, getPhotos }) {
  const { viewer } = useViewer();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState(folder.name);
  const [thumbnail, setThumbnail] = useState();
  const { uploadThumbnail, uploadingThumbnail, updatingFolder } = usePhotoGallery();
  const [isLoading, setIsLoading] = useState(false);

  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");
  const handleSubmit = () => {
    const { id } = folder;
    setIsLoading(true);
    query
      .patch(`/photo-gallery-folders/${id}`, { name: title })
      .then((res) => {
        getPhotos(moduleId);
        cancel();
        setIsLoading(false);
        success("Updated folder");
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not update folder");
      });
  };

  useEffect(async () => {
    if (thumbnail) {
      setIsLoading(true);

      if (isFileAllowed(thumbnail)) {
        if (isHeicFile(thumbnail)) {
          await heic2any({
            blob: thumbnail,
            toType: "image/jpeg",
            quality: 0.7,
          }).then(
            (blob) => {
              const fileName = "viewlio.txt";
              const mimeType = "text/plain";
              const newFile = blobToFile(blob, fileName, mimeType);

              uploadThumbnail(newFile, folder.id);
              getPhotos(moduleId);
              setIsLoading(false);
            },
            () => {
              error("Failed to process your file");
              setIsLoading(false);
            }
          );
        } else {
          uploadThumbnail(thumbnail, folder.id);
          getPhotos(moduleId);
          setIsLoading(false);
        }
      } else {
        error("File type not allowed");
        setIsLoading(false);
      }
    }
  }, [thumbnail]);

  return (
    <MDBox component="form" pb={3} pt={3}>
      <div className="">
        <MDInput
          label="Title"
          fullWidth
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          value={title}
        />
      </div>
      {uploadingThumbnail && isLoading ? (
        <div className="flex flex-start text-left mt-3">
          <Alert severity="info" className="flex-grow">
            <div className="text-sm">Updating thumbnail...</div>
          </Alert>
        </div>
      ) : (
        ""
      )}
      <br />
      <div className="flex flex-col gap-y-2 my-6">
        <small>Drag and drop your thumbnail or click here to upload thumbnail</small>
        <div className="file-input-container">
          <input
            type="file"
            name="submit"
            accept=".heic, .png, .jpg, .jpeg"
            onChange={(e) => {
              setThumbnail(e.target.files[0]);
            }}
            className="text-sm"
          />
          <label htmlFor="fileInput" className="custom-file-label">
            {" "}
            Upload File{" "}
          </label>
        </div>
      </div>
      <br />
      <MDBox className="mt-2 gap-x-1 flex justify-between rightalignalways">
        <MDButton onClick={cancel} color="secondary" disabled={updatingFolder}>
          cancel
        </MDButton>
        <MDButton
          className="specialmarigin"
          style={{ marginRight: "12px" }}
          onClick={handleSubmit}
          color="info"
          disabled={updatingFolder}
        >
          {updatingFolder || isLoading ? "updating..." : "update"}
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

EditFolderForm.propTypes = {
  getPhotos: PropTypes.func,
  cancel: PropTypes.func,
  folder: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};
