import { endpoints, query } from "api";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const userContext = createContext({});

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    query.get(endpoints.auth.user).then((res) => {
      setUser(res.data);
    }).catch((err) => {
      if (err?.response?.data) {
        console.error("error fetching current user");
        navigate("/sign-in");
      }
      else {
        console.warn("refreshed in the middle of a request");
      }
    });
  }, []);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <userContext.Provider value={value}>
      {children}
    </userContext.Provider>
  );
}

export function useUser() {
  return useContext(userContext);
}