import envConfigs from "envConfigs";

let key =
  "pk_test_51JUIwgARRlrXWO139iDgMqjL1tdbohUfNa4KSXvKq7tpFcg8PmY9X4hmanbvOwiJGhOAEq9tL6CExFyf1vpsyyGm00h5J5gCb5";

if (envConfigs.enviroment === "live") {
  key =
    "pk_live_51JUIwgARRlrXWO13f7vdoBmEePhw77x3Vl3Vc4CkA9EUSg8GBrw6RxypQ799dz5QEVyn7vrHtQfaF1TcWDePHq6c003hR892sv";
}

const keys = {
  pushibleKey: key,
};
export default keys;
