/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Box, Card, Modal, Typography } from "@mui/material";
import { baseUrl } from "api";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import filterArrayByField from "utils/filterArrayByField";
import formatUnixDate from "utils/formatUnixDate";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ViewerSubscription({ subscriptions, isLoading }) {
  const [subscriptionId, setSubscriptionId] = useState("month");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [isViewerCancelled, setIsViewerCancelled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { viewer } = useViewer();

  useEffect(() => {
    const filteredSubscription = filterArrayByField(subscriptions, "description", viewer.name);
    if (filteredSubscription.length > 0) {
      setSubscriptionId(filteredSubscription[0]?.subscription?.id);
      setIsViewerCancelled(filteredSubscription[0]?.subscription?.cancel_at_period_end);
      const formattedDate = formatUnixDate(filteredSubscription[0].subscription.cancel_at);
      setNextBillingDate(formattedDate);
    }
  }, [subscriptions]);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDTypography variant="h4" sx={{ mt: 2, mb: 1, ml: 2 }}>
          Subscription {viewer.name && `(${viewer.name})`}
        </MDTypography>
      </MDBox>
      <MDBox p={2} className="flex flex-col gap-y-3">
        {isLoading ? (
          <MDTypography variant="caption" fontWeight="regular" color="text">
            Loading....
          </MDTypography>
        ) : (
          <>
            {/* <div>
              <span className="font-bold">Next billing date</span>: {nextBillingDate}
            </div> */}
            {isViewerCancelled ? (
              <div className="text-sm" style={{ color: "red" }}>
                This viewer subscription will cancel on {nextBillingDate}{" "}
              </div>
            ) : (
              <div className="flex flex-col md:flex-row items-center gap-x-3 gap-y-2 cancelsubscription">
                <MDButton color="error" onClick={handleOpen}>
                  Cancel Subscription
                </MDButton>
              </div>
            )}
            <div className="cancelsubscription">
              {" "}
              <p>Note: will take effect on next billing date</p>
            </div>
          </>
        )}
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="rounded-xl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cancel Subscription
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "16px" }}>
            Are you sure you want to deactivate this Gallery? Once the subscription is canceled,
            your Gallery will no longer be publicly visible once your purchased subscription time
            ends.
          </Typography>
          <br />
          <center className="flex flex-col md:flex-row gap-x-2 gap-y-2 items-center mt-4 flexcustomgap">
            <MDButton variant="outlined" color="info" onClick={handleClose}>
              No
            </MDButton>
            <MDButton
              color="error"
              onClick={() => {
                fetch(`${baseUrl}/billing/cancel-subscriptions/${subscriptionId}`, {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
                  .then((data) => {
                    handleClose();
                  })
                  .catch(() => {});
              }}
            >
              Yes
            </MDButton>{" "}
          </center>
        </Box>
      </Modal>
    </Card>
  );
}
