/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import { CircularProgress, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import AddFolderForm from "../forms/AddForm";

export default function Header({
  addFolder,
  isLoading,
  videosLoading,
  isAddFolderVisible,
  setIsAddFolderVisible,
}) {
  return (
    <Grid item xs={12} lg={12}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="centeraddvideobutton">
            <MDBox pl={1} className="flex gap-3">
              {isAddFolderVisible ? (
                <MDButton
                  onClick={() => {
                    setIsAddFolderVisible(false);
                  }}
                  color="info"
                  variant="outlined"
                >
                  Close
                </MDButton>
              ) : (
                <Tooltip title="Add Folder">
                  <MDButton
                    onClick={() => {
                      if (isAddFolderVisible) {
                        setIsAddFolderVisible(false);
                      } else {
                        setIsAddFolderVisible(true);
                      }
                    }}
                    color="info"
                    variant="outlined"
                  >
                    {isAddFolderVisible ? "Close" : "Add Video"}
                  </MDButton>
                </Tooltip>
              )}
              {isLoading ? (
                <>
                  <br /> <br /> <CircularProgress color="info" />
                </>
              ) : null}
            </MDBox>
          </Grid>
          {isAddFolderVisible ? <AddFolderForm addFolder={addFolder} /> : null}
        </Grid>
      </MDBox>
    </Grid>
  );
}
