/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/users/new-user/components/FormField";
import Grid from "@mui/material/Grid";
import MuiColorPicker from "components/color-picker";
import FontSelector from "components/font-selector";
import LogoCropper from "components/logoCropper";
import SwitchToggle from "components/switch";
import PropTypes from "prop-types";
import { useState } from "react";

function Profile({
  formData,
  frameColor,
  setFrameColor,
  windowColor,
  setWindowColor,
  menuColor,
  setMenuColor,
  menuHoverColor,
  setMenuHoverColor,
  font,
  setFont,
  useIconsForMainMenuItems,
  setUseIconsForMainMenuItems,

  menuFontAndIconColor,
  setMenuFontAndIconColor,
  windowFontAndIconColor,
  setWindowFontAndIconColor,

  useColorGradientOnMenu,
  setUseColorGradientOnMenu,
  menuColorGradientSecondColor,
  setMenuColorGradientSecondColor,
  viewerControlsColor,
  setViewerControlsColor,
  logo,
  setLogo,
}) {
  const { formField, values } = formData;
  const { publicEmail, bio } = formField;
  const { publicEmail: publicEmailV, bio: bioV } = values;
  const [deleteImage, setDeleteImage] = useState("");

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Design
      </MDTypography>
      <MDBox mt={1.625}>
        <div className="grid sm:grid-cols-2 gap-5 apply-font">
          <div>
            <h1 className="text-sm text-gray-500 mb-2">Frame Color</h1>
            <MuiColorPicker color={windowColor} setColor={setWindowColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Content Window Color</h1>
            <MuiColorPicker color={frameColor} setColor={setFrameColor} />
          </div>
          <div>
            <h1 className="text-sm text-gray-500 mb-2">Menu Color</h1>
            <MuiColorPicker color={menuColor} setColor={setMenuColor} />
          </div>

          <div>
            {/* {useColorGradientOnMenu && ( */}
            <div>
              <h1 className="text-sm text-gray-500 mb-2">Menu Gradient Second Color</h1>
              <MuiColorPicker
                color={menuColorGradientSecondColor}
                setColor={setMenuColorGradientSecondColor}
              />
            </div>
            {/* )} */}
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Menu Highlight Color</h1>
            <MuiColorPicker color={menuHoverColor} setColor={setMenuHoverColor} />
          </div>
          <div className="flex gap-x-1 items-center">
            <SwitchToggle
              initialState
              checked={useColorGradientOnMenu}
              setChecked={setUseColorGradientOnMenu}
            />

            <h1 className="text-sm text-gray-500">Use Color Gradient on Menu?</h1>
          </div>
          <div>
            <h1 className="text-sm text-gray-500 mb-2">Menu Text and Icon Color</h1>
            <MuiColorPicker color={menuFontAndIconColor} setColor={setMenuFontAndIconColor} />
          </div>

          <div>
            <h1 className="text-sm text-gray-500 mb-2">Font Selector</h1>
            <FontSelector font={font} setFont={setFont} />
          </div>
          <div>
            <div>
              <h1 className="text-sm text-gray-500 mb-2">Controls Color</h1>
              <MuiColorPicker color={viewerControlsColor} setColor={setViewerControlsColor} />
            </div>
          </div>

          {/* <div className="flex gap-x-1 items-center">
            <SwitchToggle
              initialState
              checked={useIconsForMainMenuItems}
              setChecked={setUseIconsForMainMenuItems}
            />

            <h1 className="text-sm text-gray-500">
              Show menu icons?
            </h1>
          </div> */}

          <div className="hidden md:block" />
        </div>

        <div>
          <br />
          <LogoCropper
            logo={logo}
            setLogo={setLogo}
            serverImage={logo}
            setDeleteImage={setDeleteImage}
          />
        </div>
        <Grid container spacing={1} className="hidden">
          <Grid item xs={12}>
            <FormField
              type={publicEmail.type}
              label={publicEmail.label}
              name={publicEmail.name}
              value={publicEmailV}
              placeholder={publicEmail.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={bio.type}
              label={bio.label}
              name={bio.name}
              value={bioV}
              placeholder={bio.placeholder}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Profile.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Profile;
