import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormField from "../form-field";

function Address({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { locationName } = formField;
  const { locationName: locationNameV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Location
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={locationName.type}
              label={locationName.label}
              name={locationName.name}
              value={locationNameV}
              // placeholder={locationName.placeholder}
              error={errors.locationName && touched.locationName}
              success={locationNameV.length > 0 && !errors.locationName}
            />

            <div className="flex">
              <p className="text-sm">
                <strong>NB</strong> All views/links, should have URLS in the following structure:
                The first part of the main URL, the property, then the view type, with hyphens
                between multiple words. <br />
                Examples: https://www.viewlio.com/the-westin-new-orleans-canal-place/3D-Model or
                https://app.viewlio.com/westin-new-orleans-canal-place/video-gallery/overview
              </p>
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
