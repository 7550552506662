/* eslint-disable react/jsx-props-no-spreading */
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm as useHookForm, useFormContext } from "react-hook-form";

export const useForm = ({ schema, ...formConfig }) =>
  useHookForm({
    ...formConfig,
    resolver: zodResolver(schema),
  });

export function Form({ form, onSubmit, children, ...props }) {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} {...props}>
        <fieldset disabled={form.formState.isSubmitting}>{children}</fieldset>
      </form>
    </FormProvider>
  );
}

export function FieldError({ name }) {
  const {
    formState: { errors },
  } = useFormContext();

  if (!name) return null;

  const error = errors[name];

  if (!error) return null;

  return <span className="text-red-500 text-sm">{error.message}</span>;
}
