/* eslint-disable import/no-unresolved */
import MDTypography from "@core/components/MDTypography";
import DataTable from "@core/examples/Tables/DataTable";
import { Alert } from "@mui/material";
import * as api from "api";
import query from "api/query";
import { useViewer } from "contexts/viewer";
import BaseLayout from "layouts/base-layout";
import { useCallback, useEffect, useState } from "react";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import filterArrayByField from "utils/filterArrayByField";
import StatusField from "./status-field";
import ViewersTableRow from "./viewers-table-row";

export default function Viewers() {
  const [viewers, setViewers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setViewer } = useViewer();

  const makeRequestUrl = (page, limit, isActive, status) => {
    const url = `${api.endpoints.viewers.getMine(
      {},
      {
        page,
        limit,
        select:
          "createdAt, plan, user, baseModule, status, name, slug, owner, address, city, country, isActive, id&x=x",
      }
    )}`;
    let parameters = { page, limit, status };
    if (localStorage.getItem("$role") === "superadmin") {
      parameters = { page, limit };
    }
    const params = new URLSearchParams(parameters);
    return `${url}?${params.toString()}`;
  };

  const fetchViewers = useCallback(async (page, limit) => {
    setLoading(true);
    query
      .get(makeRequestUrl(page, limit, true, "1"))
      .then((res) => {
        // eslint-disable-line
        setLoading(false);
        setViewers(res.data || []);
      })
      .catch((err) => {
        // eslint-disable-line
        setLoading(false);
        setError("Could not fetch viewers");
      });
  }, []);

  useEffect(() => {
    fetchViewers(1, 1000);
  }, [fetchViewers]);

  useEffect(() => {
    setViewer({});
  }, []);

  const filteredArray = filterArrayByField(viewers, "isActive", false);
  return (
    <BaseLayout
      pageName="All Galleries"
      // action={{
      //   label: "Create Gallery",
      //   command: () => navigate(PATHS.createViewer),
      // }}
    >
      {filteredArray?.length > 0 && localStorage.getItem("$role") !== "superadmin" && (
        <Alert
          severity="info"
          className="text-sm md:text-md"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div className="text-center">
            In order to make a Gallery Active, you must go to the Billing settings for that Gallery,
            purchase a subscription, and have an updated and valid credit card on file.
          </div>
        </Alert>
      )}

      <DataTable
        canSearch
        loading={loading}
        table={{
          columns: [
            { Header: "Admin", accessor: "user" },
            { Header: "GALLERY", accessor: "name" },
            {
              Header: "address",
              accessor: "address",
            },
            {
              Header: "city",
              accessor: "city",
            },
            // {
            //   Header: "country",
            //   accessor: "country",
            // },
            { Header: "Date created", accessor: "createdAt" },
            { Header: "Subscription", accessor: "plan" },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ row }) => StatusField({ row, fetchViewers }),
            },
            {
              Header: "Actions",
              accessor: "actions",
              width: "20%",
              Cell: ({ row }) => ViewersTableRow({ row, fetchViewers }),
            },
          ],
          rows: viewers
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((item) => ({
              createdAt: new Date(item.createdAt).toDateString().split(" ").slice(1).join(" "),
              name: item.name,
              slug: item.slug,
              address: item.address,
              city: item.city,
              // country: item.country,
              id: item.id,
              isActive: item.isActive,
              baseModule: item.baseModule,
              plan: `${capitalizeFirstLetter(item.plan)}`,
              user: `${item.status === "9" ? "Deleted User" : item.user}`,
              payment_status: capitalizeFirstLetter(item.payment_status),
            })),
        }}
      />
      {viewers?.length === 0 && loading === false && (
        <>
          <br />
          <MDTypography variant="h6" fontWeight="bold" className="text-center mb-12">
            Jump in and Create a Gallery!
          </MDTypography>
        </>
      )}
    </BaseLayout>
  );
}
