/* eslint-disable import/no-unresolved */
import { useMemo } from "react";

// prop-types is a library for typechecking of props
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";

// @mui material components
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";

// Material Dashboard 2 PRO React examples
import SalesTableCell from "@core/examples/Tables/SalesTable/SalesTableCell";
import { ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
}));

const columns = [
  { field: "city", headerName: "City" },
  { field: "count", headerName: "Count", flex: 1 },
];

function createData(id, city, count) {
  return { id, city, count };
}

const rowss = [
  createData(1, "Seattle", 123),
  createData(2, "Austin", 89),
  createData(3, "Portland", 100),
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    border: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  padding: 0,
  margin: 0,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: 0,
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function SalesTable({ title, rows, shadow }) {
  const classes = useStyles();

  return (
    <TableContainer sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <Table>
        <TableBody>
          {" "}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
              classes={{ content: classes.content }}
            >
              <TableContainer
                sx={{ margin: 0, padding: 0, height: "100%", boxShadow: !shadow && "none" }}
              >
                  <SalesTableCell key="nigel" title="tttt" content="ddsads" image="asdas" />
              </TableContainer>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox p={2} style={{ width: "100%", overflow: "auto" }}>
                <DataGrid
                  rows={rowss}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  density="compact"
                  autoHeight
                  hideFooter
                  sx={{
                    boxShadow: 0,
                    border: 0,
                    borderColor: "primary.light",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// Setting default values for the props of SalesTable
SalesTable.defaultProps = {
  title: "",
  rows: [{}],
  shadow: true,
};

// Typechecking props for the SalesTable
SalesTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
};

export default SalesTable;
