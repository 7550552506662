/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Button, Modal } from "@mui/material";
import { baseUrl, endpoints, host, query } from "api";
import { useState } from "react";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import PATHS from "routes/paths";

export default function ViewersTableRow({ row, fetchViewers }) {
  const { id, baseModule, isActive } = row.original;
  let linkTo = PATHS.viewer.replaceAll(":name", row.original.name).replaceAll(":tab", "builder");

  if (!isActive) {
    linkTo = `${row.original.name}/billing?builderTab=virtual-tour&moduleId=${id}`;
  }
  if (!isActive && localStorage.getItem("$role") === "superadmin") {
    linkTo = `${row.original.name}/settings?builderTab=virtual-tour&moduleId=${id}`;
  }
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteViewer = () => {
    setLoading(true);
    if (localStorage.getItem("$role") === "superadmin") {
      query
        .delete(endpoints.viewers.deleteOne({ id }))
        .then(() => {
          fetch(`${baseUrl}/billing/subscriptions/viewer/${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((data) => {
              fetch(
                `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.paynentId}`,
                {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                }
              )
                .then((res) => res.json())
                .then(() => {})
                .catch(() => {});
              if (data?.subscriptions[0]?.removeLogoSubscriptionId) {
                fetch(
                  `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.removeLogoSubscriptionId}`,
                  {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                  }
                )
                  .then((res) => res.json())
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(() => {});
          setLoading(false);

          fetchViewers(1, 1000);
          setTimeout(closeModal, 1000);
        })
        .catch(() => {
          setError("Could not delete viewer");
          setLoading(false);
        });
    } else {
      query
        .get(`${endpoints.viewers.deactivateViewer({ id })}`)
        .then(() => {
          setLoading(false);

          fetchViewers(1, 1000);
          setTimeout(closeModal, 1000);
        })
        .catch(() => {
          setError("Could not delete viewer");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex space-x-5 justify-center">
      {isActive && (
        <a
          href
          onClick={async () => {
            const viewerBaseLink = `${host}/${row.original.slug || row.original.name}`;
            if (baseModule) {
              window.open(`${viewerBaseLink}/${baseModule}`, "_blank");
            } else {
              await query
                .get(endpoints.modules.getAll({}, { viewerId: id }))
                .then((res) => {
                  const orderedModule = res.data
                    .sort((a, b) => a.order - b.order)
                    .filter((item) => !item.parent);

                  window.open(`${viewerBaseLink}/${orderedModule[0]?.type}`, "_blank");
                })
                .catch(() => {
                  // window.open(`${viewerBaseLink}/virtual-tour`, "_blank");
                });
            }
          }}
          target="_blank"
          rel="noreferrer"
          className=""
        >
          <MDButton iconOnly color="success">
            <FiEye />
          </MDButton>
        </a>
      )}

      {row?.original?.payment_status !== "cancelled" && (
        <>
          {" "}
          <Link to={linkTo} className="">
            <MDButton iconOnly color="info">
              <FiEdit />
            </MDButton>
          </Link>
          <MDButton iconOnly onClick={openModal} className="bg-red-600 text-white">
            <FiTrash2 />
          </MDButton>
        </>
      )}

      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            bgcolor: "background.paper",
            // width: "40%",
            p: 4,
          }}
          className="transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <MDBox mb={1}>
            <MDTypography variant="h5" fontWeight="bold">
              Are you sure? There is no recovery option. Alternatively, you can transfer a Gallery
              to another Admin and they will be responsible for continuing a subscription payment in
              order to keep the Gallery active. Contact{" "}
              <a
                href="https://www.viewlio.com/support"
                className="supporttextcolor"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>{" "}
              to have Galleries transferred to another Admin.
            </MDTypography>
          </MDBox>

          {error && (
            <MDBox mb={1}>
              <MDTypography variant="body1" color="error" style={{ fontSize: "15px" }}>
                {error}
              </MDTypography>
            </MDBox>
          )}

          <MDBox
            mt={2}
            display="flex"
            width="100%"
            className="w-full "
            style={{ justifyContent: "center" }}
            gap="8px"
          >
            <Button
              className="w-1/2 m-auto text-white"
              variant="contained"
              color="primary"
              onClick={closeModal}
              sx={{
                color: "#fff",
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-1/2 m-auto text-white"
              variant="contained"
              color="primary"
              onClick={deleteViewer}
              style={{ backgroundColor: "#9b2226" }}
              sx={{
                color: "#fff",
                marginRight: "8px",
              }}
              disabled={loading}
            >
              {loading ? "Deleting..." : "Delete"}
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
    </div>
  );
}
