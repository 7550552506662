/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import { Card, FormControlLabel, FormGroup, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
// eslint-disable-next-line import/no-extraneous-dependencies
import heic2any from "heic2any";

import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { blobToFile } from "utils/convertHeic2Jpg";
import { isFileAllowed } from "utils/isFileAllowed";
import { isHeicFile } from "utils/isHeicFile";

export default function EditFolderForm({ updateFolder, folder, isLoading, cancel }) {
  const [isAddFolderVisible, setIsAddFolderVisible] = useState(false);
  const [title, setTitle] = useState(folder.name);
  const [url, setURL] = useState(folder.videoUrl);
  const [hasThumbnail, setHasThumbnail] = useState(folder.hasThumbnail !== "false");
  const [colorPickerVisible, setColorPickerVisible] = useState(folder.borderColor !== "");
  const [isDropZoneVisible, setIsDropZoneVisible] = useState(folder.hasThumbnail !== "false");
  const [base64File, setBase64File] = useState(false);
  const [hasImageBorders, setImageBorders] = useState(folder.hasImageBorders !== "false");
  const [useRoundedCorners, setUseRoundedCorners] = useState(folder.hasRoundedCorners !== "false");
  const [color, setColor] = useState(folder.borderColor === "" ? "#000000" : folder.borderColor);
  const { enqueueSnackbar } = useSnackbar();
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const handleSubmit = async () => {
    if (title === "" || url === "") {
      error("Please make sure fields are not empty");
      return;
    }

    if (url?.includes("youtube.com") || url.includes("vimeo.com")) {
      /* empty */
    } else {
      error("Please upload a video from Youtube or Vimeo");
      return;
    }

    let urlData = "";
    if (hasThumbnail) {
      let videoID;
      if (url?.includes("youtube.com")) {
        // eslint-disable-next-line prefer-destructuring

        const youtubeUrl = new URL(url);
        videoID = youtubeUrl.searchParams.get("v") || youtubeUrl.pathname.substring(1);

        urlData = `https://img.youtube.com/vi/${videoID}/0.jpg`;
      } else if (url.includes("vimeo.com")) {
        try {
          videoID = url.split("vimeo.com/")[1];
        } catch (e) {
          const path = new URL(url);
          videoID = path.pathname.split("/")[2];
        }
        // eslint-disable-next-line prefer-destructuring
        urlData = `https://vumbnail.com/${videoID}.jpg`;
      } else {
        error("Please enter a URL from Youtube or Vimeo");
        return;
      }
    }
    let colorData = "";
    if (colorPickerVisible) {
      colorData = color;
    }

    const payload = {
      name: title,
      thumbnailUrl: urlData,
      hasRoundedCorners: useRoundedCorners,
      hasImageBorders,
      hasThumbnail,
      videoUrl: url,
      borderColor: colorData,
      id: folder.id,
    };

    if (base64File) {
      if (isFileAllowed(base64File)) {
        if (isHeicFile(base64File)) {
          await heic2any({
            blob: base64File,
            toType: "image/jpeg",
            quality: 0.7,
          }).then(
            (blob) => {
              const fileName = "viewlio.txt";
              const mimeType = "text/plain";
              const newFile = blobToFile(blob, fileName, mimeType);

              if (base64File) {
                payload.thumbnail = newFile;
              }
              setBase64File(false);
              updateFolder(payload);
            },
            () => {
              error("Failed to process your file");
            }
          );
        } else {
          console.log("****siyangena la?");
          if (base64File) {
            payload.thumbnail = base64File;
          }
          setBase64File(false);
          updateFolder(payload);
        }
      } else {
        error("File type not allowed");
      }
    } else {
      setBase64File(false);
      updateFolder(payload);
    }
  };

  return (
    <Grid item xs={12}>
      <Card>
        <MDBox component="form" pb={3} px={3}>
          <Grid container p={1} spacing={3}>
            <Grid item xs={12}>
              <MDInput
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                value={title}
                fullWidth
                label="Title"
              />
            </Grid>
          </Grid>
          <Grid container p={1} spacing={3}>
            <Grid item xs={12}>
              <MDInput
                onChange={(event) => {
                  setURL(event.target.value);
                }}
                value={url}
                fullWidth
                label="URL of Vimeo or YouTube Video"
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label="Do you want to use a thumbnail from Vimeo or YouTube? If not, add a thumbnail image below."
                control={
                  <Switch
                    checked={hasThumbnail}
                    onChange={(event) => {
                      setHasThumbnail(event.target.checked);
                      if (event.target.checked) {
                        setIsDropZoneVisible(true);
                      } else {
                        setIsDropZoneVisible(false);
                      }
                    }}
                  />
                }
              />
              {/* {isDropZoneVisible ? (
                        <></>
                      ) : // <Grid container p={1} spacing={3}>
                      //   <Grid item xs={12}>
                      //     <MDInput
                      //       onChange={(event) => {
                      //         setURL(event.target.value);
                      //       }}
                      //       fullWidth
                      //       label="URL of Vimeo or YouTube Video"
                      //     />
                      //   </Grid>
                      // </Grid>
                      null} */}
              <FormGroup>
                {!isDropZoneVisible ? (
                  <>
                    {" "}
                    <div className="flex flex-col gap-y-2" style={{ marginTop: "12px" }}>
                      <div className="text-sm">
                        <b> Upload a photo for the folder cover image.</b>
                      </div>
                      <input
                        type="file"
                        name="submit"
                        className="text-sm md:text-md"
                        accept=".heic, .png, .jpg, .jpeg"
                        onChange={(e) => {
                          setBase64File(e.target.files[0]);
                        }}
                      />
                    </div>
                  </>
                ) : null}
                {/* <FormControlLabel
                  style={{ marginTop: "12px" }}
                  label="Use Image Borders"
                  control={
                    <Switch
                      checked={hasImageBorders}
                      onChange={(event) => {
                        setImageBorders(event.target.checked);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="Select Border Color"
                  control={
                    <Switch
                      checked={colorPickerVisible}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setColorPickerVisible(true);
                        } else {
                          setColorPickerVisible(false);
                        }
                      }}
                    />
                  }
                />
                {colorPickerVisible ? (
                  <ColorPicker
                    name="color"
                    defaultValue={color}
                    value={color}
                    // value={this.state.color} - for controlled component
                    onChange={(_color) => {
                      if (_color === undefined) {
                        console.log("");
                      } else {
                        setColor(_color);
                      }
                    }}
                  />
                ) : null}
                <FormControlLabel
                  label="Use Rounded Corners?"
                  control={
                    <Switch
                      checked={useRoundedCorners}
                      onChange={(event) => {
                        setUseRoundedCorners(event.target.checked);
                      }}
                    />
                  }
                /> */}
              </FormGroup>
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="center" gap="8px">
            <MDButton onClick={cancel} color="secondary" disabled={isLoading}>
              cancel
            </MDButton>
            <MDButton style={{ marginRight: "12px" }} onClick={handleSubmit} color="info">
              {isLoading ? "updating..." : "update"}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

EditFolderForm.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};
