/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import PanToolIcon from "@mui/icons-material/PanTool";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

export default function ImageCard({
  image,
  removeImage,
  handleDragStart,
  handleDragEnter,
  handleDragOver,
  handleDragEnd,
  handleDrop,
  isLoading,
}) {
  return (
    <Card
      draggable
      onDragStart={() => handleDragStart(image)}
      onDragEnter={() => handleDragEnter(image)}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      sx={{
        // "&:hover .card-header": {
        //   transform: "translate3d(0, -50px, 0)",
        // },
        maxWidth: 345,
      }}
      className="hover:cursor-pointer mt-9"
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <center>
          <MDBox
            component="img"
            src={image}
            borderRadius="lg"
            shadow="sm"
            height="180px"
            width="100%"
            position="relative"
            zIndex={10}
            mb={2}
            style={{ objectFit: "cover" }}
          />
        </center>
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDButton
          onClick={() => {
            removeImage(image);
          }}
          variant="outlined"
          color="dark"
          size="small"
        >
          {isLoading ? "removing..." : "remove"}
        </MDButton>
        <MDBox textAlign="right" style={{ paddingRight: "20px", marginTop: "-37px" }}>
          <Tooltip title="Drag And Drop">
            <PanToolIcon color="info" />
          </Tooltip>
        </MDBox>
      </MDBox>
    </Card>
  );
}
ImageCard.defaultProps = {
  image: "",
};

ImageCard.propTypes = {
  image: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    imageCount: PropTypes.number,
    handleDragStart: PropTypes.func,
    updateFolder: PropTypes.func,
    handleDragOver: PropTypes.func,
    handleDragEnter: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleDrop: PropTypes.func,
  }),
};
