/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */

export const blobToFile = (blob, fileName, mimeType)  => {
  const options = {
    type: mimeType || blob.type,
  };

  if (typeof blob.arrayBuffer === "function") {
    // Use ArrayBuffer to create the File
    return new File([blob], fileName, options);
  }
  // For browsers that do not support blob.arrayBuffer
  const binaryString = window.atob(blob);
  const { length } = binaryString;
  const buffer = new ArrayBuffer(length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < length; i++) {
    array[i] = binaryString.charCodeAt(i);
  }

  return new File([array], fileName, options);
}

