/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Button, Card, Grid, Modal, Select, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import Reorderable from "components/reorderable";
import { useEffect, useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import { endpoints, query } from "api";
import ClickAlert from "components/common/clickAlert";
import DeleteModule from "components/delete-module";
import { useViewer } from "contexts/viewer";
import { getModuleRoute } from "utils/availableModules";
import ModuleWrapper from "../components/wrapper";

function EventEnquires({ data, saving, moduleId, showSnackbar, edits, setEdit, setEdits }) {
  // const [editorValue, setEditorValue] = useState("");
  const [isTypeRequired, setIsTypeRequired] = useState("");

  useEffect(() => {
    setEdits({
      eventEnquiryFormTitle: data?.eventEnquiryFormTitle,
      eventEnquiryFormEmail: data?.eventEnquiryFormEmail,
      eventEnquiryFormEmailCopy: data?.eventEnquiryFormEmailCopy,
      eventEnquiryFormCustomHtml: data?.eventEnquiryFormCustomHtml,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
    });
    // setEditorValue(
    //   data?.eventEnquiryFormCustomHtml ||
    // "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
    // );
  }, [data, setEdits]);

  const setEventEnquiryFormTitle = (value) => setEdit("eventEnquiryFormTitle", value);
  const setEventEnquiryFormEmail = (value) => setEdit("eventEnquiryFormEmail", value);
  const setEventEnquiryFormEmailCopy = (value) => setEdit("eventEnquiryFormEmailCopy", value);

  // eslint-disable-next-line
  const [checked, setChecked] = useState(true);
  const [state, setState] = useState({
    fields: [],
    newFieldDetails: {},
  });

  const clearNewFieldDetails = () => {
    setState((oldState) => ({
      ...oldState,
      newFieldDetails: {
        type: "text",
      },
    }));
  };

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingFieldAtIndex, setEditingFieldIndex] = useState(-1);

  const handleDisplayInLeftMenu = (e) => {
    setEdit("displayInLeftMenu", e.target.checked);
  };

  const submitData = {
    ...edits,
    eventEnquiryFormFields: state?.fields?.map((item) => ({
      title: item.title,
      type: item.type,
      isMandatory: item.isMandatory,
      subtitle: item.subtitle,
    })),
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEditingFieldIndex(-1);
    clearNewFieldDetails();
    setOpen(false);
  };

  const saveNewField = () => {
    if (!state.newFieldDetails.title) {
      return;
    }

    setState((oldState) => ({
      ...oldState,
      fields: [...oldState.fields, { ...oldState.newFieldDetails }],
    }));

    clearNewFieldDetails();
  };

  const handleSaveNewField = () => {
    if (editingFieldAtIndex > -1) {
      setState((oldState) => ({
        ...oldState,
        fields: [
          ...oldState.fields.slice(0, editingFieldAtIndex),
          { ...oldState.newFieldDetails },
          ...oldState.fields.slice(editingFieldAtIndex + 1),
        ],
      }));
      clearNewFieldDetails();
    } else {
      saveNewField();
    }

    setEdits(submitData);
    handleClose();
  };

  const setNewFieldValue = (fieldName, fieldValue) => {
    setState((oldState) => ({
      ...oldState,
      newFieldDetails: {
        ...oldState.newFieldDetails,
        [fieldName]: fieldValue,
      },
    }));
  };

  const handleEdit = (fieldIndex) => {
    setEditingFieldIndex(fieldIndex);
    setState((oldState) => ({
      ...oldState,
      newFieldDetails: {
        ...oldState.fields[fieldIndex],
      },
    }));
    handleOpen();
  };

  const handleDelete = (fieldIndex) => {
    setState((oldState) => ({
      ...oldState,
      fields: [...oldState.fields.slice(0, fieldIndex), ...oldState.fields.slice(fieldIndex + 1)],
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setState({
      fields: [...(data?.eventEnquiryFormFields || [])],
      newFieldDetails: {},
    });
  }, [data]);

  const handleInputChange = (isMandatory, index) => {
    const list = [...state.fields];
    list[index].isMandatory = isMandatory;

    setState((oldState) => ({
      ...oldState,
      fields: list,
    }));
  };

  const { fetchModules } = useViewer();
  const handleSave = () => {
    setIsLoading(true);
    query
      .patch(endpoints.modules.updateOne({ id: moduleId }), submitData)
      .then(() => {
        showSnackbar("Module updated", { variant: "success", autoHideDuration: 2000 });
        setIsLoading(false);
        fetchModules();
      })
      .catch((e) => {
        setIsLoading(false);
        showSnackbar("Module not updated", { variant: "error", autoHideDuration: 2000 });
      });
  };

  // useEffect(() => {
  //   if (editorValue) {
  //     setEdit("eventEnquiryFormCustomHtml", editorValue);
  //   }
  // }, [editorValue]);

  return (
    <Card className="p-6">
      <div className="grid gap-6">
        <MDTypography variant="h5" fontWeight="bold" className="text-center">
          CONTACT FORM
        </MDTypography>
        {/* <Alert severity="error">
          <span className="">
          # NOTE (remove this): Saving the fields is causing a cyclic dependency.. that needs solving. For now everything else can be saved though.
          </span>
        </Alert> */}
        <Grid display="flex">
          <Grid item xs={4} className="grid gap-6">
            <FormField
              label="Menu Title*"
              placeholder="Contact Form"
              value={edits?.label || ""}
              onChange={(e) => setEdit("label", e.target.value)}
            />
          </Grid>
          <Grid item xs={4} className="specialmarigin centeralignalways">
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" className="text-sm text-gray-500">
                  Display in Menu?
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
              <ClickAlert link={getModuleRoute(data)} />
            </MDBox>
          </Grid>
        </Grid>

        <div>
          <FormField
            type="text"
            label="Title"
            value={edits?.eventEnquiryFormTitle}
            onChange={(e) => setEventEnquiryFormTitle(e.target.value)}
          />
        </div>

        <div>
          <FormField
            type="email"
            label="Email to send form to"
            value={edits?.eventEnquiryFormEmail}
            onChange={(e) => setEventEnquiryFormEmail(e.target.value)}
          />
        </div>

        {/* <div>
           <FormField
            type="checkbox"
            label="Email copy to sender"
            value={edits?.eventEnquiryFormEmailCopy}
            onChange={(e) => setEventEnquiryFormEmailCopy(e.target.value)}
          /> 

          <MDBox width="80%" ml={0.5} className="flex flex-grow items-center">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email copy to sender{" "}
            </MDTypography>
          </MDBox>
          <MDBox mt={0.5}>
            <Checkbox
              checked={edits?.eventEnquiryFormEmailCopy === "true"}
              onChange={(e) => setEventEnquiryFormEmailCopy(e.target.checked)}
            />
          </MDBox>
        </div> */}

        {state?.fields?.length > 0 && (
          <MDTypography className="font-bold text-xl">Form Fields</MDTypography>
        )}
        <p className="text-sm">Drag and drop fields to change their order.</p>

        <Reorderable items={state?.fields} setItems={(x) => setState((s) => ({ ...s, fields: x }))}>
          {state?.fields?.map((field, index) => (
            <div key={field?.title} className="my-2">
              <div className="flex space-x-3">
                <TextField
                  disabled
                  className="w-full"
                  {...field}
                  label={field?.type === "date" ? "" : field?.title}
                />
                {/* <div>
                  <MDBox mb={0.5} ml={-1.5} className="flex gap-x-2 justify-start">
                    <MDBox mt={0.5}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.isMandatory}
                            // value={}
                            onChange={(event) => {
                              handleInputChange(event.target.checked, index);
                            }}
                            className="-ml-[0.75rem] ml-2"
                          />
                        }
                        style={{ fontWeight: 400 }}
                      />
                    </MDBox>
                    <MDBox
                      width="10%"
                      ml={0.5}
                      style={{ marginLeft: "-20px" }}
                      className="flex flex-grow items-center"
                    >
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        Required
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </div> */}
                <MDButton color="success" iconOnly onClick={() => handleEdit(index)}>
                  <FiEdit3 />
                </MDButton>
                <MDButton color="error" iconOnly onClick={() => handleDelete(index)}>
                  <FiTrash2 />
                </MDButton>
              </div>
            </div>
          ))}
        </Reorderable>
        {/*
        {state.fields.map((field, index) => (
          <div key={field.title}>
            <div className="flex space-x-3">
              <TextField disabled className="w-full" {...field} label={field.title} />
              <MDButton

                color="success"
                iconOnly
                onClick={() => handleEdit(index)}
              >
                <FiEdit3 />
              </MDButton>
              <MDButton

                color="error"
                iconOnly
                onClick={() => handleDelete(index)}
              >
                <FiTrash2 />
              </MDButton>
            </div>
          </div>
        ))} */}
        <center>
          <MDButton color="light" size="medium" onClick={handleOpen}>
            Add Field
          </MDButton>
        </center>
        <Grid item xs={9}>
          <MDBox>
            <ClickAlert link={getModuleRoute(data)} />
          </MDBox>
        </Grid>
        <br />
        <Grid className="centermodulebuttons">
          <Grid display="flex" justify-content="center" className="customgap">
            <DeleteModule id={data?.id} />
            <MDButton
              color="info"
              className="w-full sm:w-1/4 mx-auto md:w-auto"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "UPDATE"}
            </MDButton>
          </Grid>
        </Grid>

        {/* mui modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "10px",
              bgcolor: "background.paper",
              // width: "40%",
              p: 4,
            }}
            className="transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full sm:my-8 sm:p-6"
          >
            <MDBox mb={1}>
              <MDTypography variant="h5" fontWeight="bold">
                Enter Field Details
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <FormField
                type="text"
                label="Title"
                onChange={(event) => {
                  setNewFieldValue("isMandatory", false);
                  setNewFieldValue(event.target.name, event.target.value);
                }}
                name="title"
                value={state?.newFieldDetails?.title}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDTypography
                sx={{
                  fontSize: "12px",
                  fontWeight: "semibold",
                  lineHeight: "20px",
                  color: "#7b809a",
                  marginBottom: "4px",
                }}
                className="font-light text-sm my-2"
              >
                Select the type of field you want to add
              </MDTypography>
              {isTypeRequired === "check" ? (
                <small style={{ color: "red" }}>Please select</small>
              ) : null}
              <Select
                sx={{
                  width: "100%",
                }}
                native
                inputProps={{
                  name: "type",
                  id: "uncontrolled-native",
                }}
                className="w-full my-2"
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setNewFieldValue("isMandatory", false);
                    setNewFieldValue(event.target.name, event.target.value);
                    setIsTypeRequired("proceed");
                  } else {
                    setIsTypeRequired("check");
                  }
                }}
                name="type"
                value={state.newFieldDetails.type}
              >
                <option value="">Select</option>
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="date">Date</option>
                <option value="textarea">Text Area</option>
              </Select>
            </MDBox>
            {/* <MDBox mb={2}>
              <FormGroup>
                <FormControlLabel
                  onChange={(event) => {
                    setNewFieldValue("isMandatory", event.target.checked);
                  }}
                  control={<Checkbox checked={state.newFieldDetails.isMandatory} />}
                  label="Is mandatory"
                  style={{ fontWeight: 400 }}
                />
              </FormGroup>
            </MDBox> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                justifyItems: "center",
                gap: "7px",
              }}
            >
              <div>
                <Button
                  className=" text-white"
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Cancel
                </Button>
              </div>

              <div>
                <Button
                  className=" text-white"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (isTypeRequired === "proceed") {
                      handleSaveNewField();
                    } else {
                      setIsTypeRequired("check");
                    }
                  }}
                  sx={{
                    color: "#fff",
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          </MDBox>
        </Modal>
      </div>
    </Card>
  );
}

export default function EventEnquiresModule() {
  return <ModuleWrapper component={EventEnquires} />;
}
