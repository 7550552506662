/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";
import { Card, FormControlLabel, FormGroup, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";

import heic2any from "heic2any";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { blobToFile } from "utils/convertHeic2Jpg";
import { isFileAllowed } from "utils/isFileAllowed";
import { isHeicFile } from "utils/isHeicFile";

export default function AddFolderForm({ addFolder }) {
  const [isAddFolderVisible, setIsAddFolderVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [buttonText, setButtonText] = useState("create");
  const [url, setURL] = useState("");
  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [isDropZoneVisible, setIsDropZoneVisible] = useState(false);
  const [base64File, setBase64File] = useState(false);
  const [hasImageBorders, setImageBorders] = useState(false);
  const [useRoundedCorners, setUseRoundedCorners] = useState(false);
  const [color, setColor] = useState("#000000");
  const { enqueueSnackbar } = useSnackbar();
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const handleSubmit = async () => {
    if (title === "" || url === "") {
      error("Please make sure fields are not empty");
      return;
    }

    if (url?.includes("youtu") || url.includes("vimeo.com")) {
      /* empty */
    } else {
      error("Please upload a video from Youtube or Vimeo");
      return;
    }

    let urlData = "";
    if (hasThumbnail) {
      let videoID;
      if (url?.includes("youtu")) {
        // eslint-disable-next-line prefer-destructuring

        const youtubeUrl = new URL(url);
        videoID = youtubeUrl.searchParams.get("v") || youtubeUrl.pathname.substring(1);

        urlData = `https://img.youtube.com/vi/${videoID.replace(" ", "")}/0.jpg`;
      } else if (url.includes("vimeo.com")) {
        // eslint-disable-next-line prefer-destructuring
        videoID = url.split("vimeo.com/")[1];
        urlData = `https://vumbnail.com/${videoID}.jpg`;
      } else {
        error("Please enter a URL from Youtube or Vimeo");
        return;
      }
    }
    let colorData = "";
    if (colorPickerVisible) {
      colorData = color;
    }

    if (!hasThumbnail) {
      if (isFileAllowed(base64File)) {
        if (isHeicFile(base64File)) {
          setButtonText("Processing file...");
          await heic2any({
            blob: base64File,
            toType: "image/jpeg",
            quality: 0.7,
          }).then(
            (blob) => {
              const fileName = "viewlio.txt";
              const mimeType = "text/plain";
              const newFile = blobToFile(blob, fileName, mimeType);
              const payload = {
                name: title,
                thumbnailUrl: urlData,
                hasRoundedCorners: useRoundedCorners,
                thumbnail: newFile,
                hasImageBorders,
                hasThumbnail,
                videoUrl: url,
                borderColor: colorData,
              };
              setButtonText("create");
              setURL("");
              setTitle("");
              setHasThumbnail(false);
              setUseRoundedCorners(false);
              setColorPickerVisible(false);
              setHasThumbnail(false);
              setIsAddFolderVisible(false);
              addFolder(payload);
            },
            () => {
              error("Failed to process your file");
            }
          );
        } else {
          const payload = {
            name: title,
            thumbnailUrl: urlData,
            hasRoundedCorners: useRoundedCorners,
            thumbnail: base64File,
            hasImageBorders,
            hasThumbnail,
            videoUrl: url,
            borderColor: colorData,
          };

          setURL("");
          setTitle("");
          setHasThumbnail(false);
          setUseRoundedCorners(false);
          setColorPickerVisible(false);
          setHasThumbnail(false);
          setIsAddFolderVisible(false);
          addFolder(payload);
        }
      } else {
        error("File type not allowed");
      }
    } else {
      const payload = {
        name: title,
        thumbnailUrl: urlData,
        hasRoundedCorners: useRoundedCorners,
        thumbnail: base64File,
        hasImageBorders,
        hasThumbnail,
        videoUrl: url,
        borderColor: colorData,
      };

      setURL("");
      setTitle("");
      setHasThumbnail(false);
      setUseRoundedCorners(false);
      setColorPickerVisible(false);
      setHasThumbnail(false);
      setIsAddFolderVisible(false);
      addFolder(payload);
    }
  };

  return (
    <Grid item xs={12}>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">Create Folder </MDTypography>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container p={1} spacing={3}>
            <Grid item xs={12}>
              <MDInput
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                fullWidth
                label="Title"
              />
            </Grid>
          </Grid>
          <Grid container p={1} spacing={3}>
            <Grid item xs={12}>
              <MDInput
                onChange={(event) => {
                  setURL(event.target.value);
                }}
                fullWidth
                label="URL of Vimeo or YouTube Video"
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                label="Do you want to use a thumbnail from Vimeo or YouTube? If not, add a thumbnail image below."
                control={
                  <Switch
                    onChange={(event) => {
                      setHasThumbnail(event.target.checked);
                      if (event.target.checked) {
                        setIsDropZoneVisible(true);
                      } else {
                        setIsDropZoneVisible(false);
                      }
                    }}
                  />
                }
              />
              {/* {isDropZoneVisible ? (
                        <></>
                      ) : // <Grid container p={1} spacing={3}>
                      //   <Grid item xs={12}>
                      //     <MDInput
                      //       onChange={(event) => {
                      //         setURL(event.target.value);
                      //       }}
                      //       fullWidth
                      //       label="URL of Vimeo or YouTube Video"
                      //     />
                      //   </Grid>
                      // </Grid>
                      null} */}
              <FormGroup>
                {!isDropZoneVisible ? (
                  <>
                    {" "}
                    <div className="flex flex-col gap-y-2" style={{ marginTop: "12px" }}>
                      <div className="text-sm">
                        <b> Upload a photo for the video thumbnail image.</b>
                      </div>
                      <div className="file-input-container">
                        <input
                          type="file"
                          name="submit"
                          id="fileInput"
                          className="text-sm md:text-md cheatcode"
                          accept=".heic, .png, .jpg, .jpeg"
                          onChange={(e) => {
                            setBase64File(e.target.files[0]);
                          }}
                        />
                        <label htmlFor="fileInput" className="custom-file-label">
                          {" "}
                          Upload File{" "}
                        </label>
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <FormControlLabel
                  style={{ marginTop: "12px" }}
                  label="Use Image Borders"
                  control={
                    <Switch
                      onChange={(event) => {
                        setImageBorders(event.target.checked);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="Select Border Color"
                  control={
                    <Switch
                      onChange={(event) => {
                        if (event.target.checked) {
                          setColorPickerVisible(true);
                        } else {
                          setColorPickerVisible(false);
                        }
                      }}
                    />
                  }
                />
                {colorPickerVisible ? (
                  <ColorPicker
                    name="color"
                    defaultValue={color}
                    value={color}
                    // value={this.state.color} - for controlled component
                    onChange={(_color) => {
                      if (_color === undefined) {
                        console.log("");
                      } else {
                        setColor(_color);
                      }
                    }}
                  />
                ) : null}
                <FormControlLabel
                  label="Use Rounded Corners?"
                  control={
                    <Switch
                      onChange={(event) => {
                        setUseRoundedCorners(event.target.checked);
                      }}
                    />
                  }
                /> */}
              </FormGroup>
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="flex-end" className="centeraddvideobutton">
            <MDButton onClick={handleSubmit} color="info" disabled={buttonText !== "create"}>
              {buttonText}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

AddFolderForm.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};
