import { Skeleton } from "@mui/material";

export default function LoadingModule() {
  return (
    <div className="flex flex-col gap-y-2 bg-white shadow-md rounded-md p-4">
      <Skeleton
        className="w-[50%] mx-auto h-[45px]"
      />
      <Skeleton
        className="w-full flex-grow h-[200px]"
      />
    </div>
  )
}