/* eslint-disable import/no-unresolved */
import MDButton from "@core/components/MDButton";
import { endpoints, query } from "api";
import { Form, useForm } from "components/form-input/form";
import SwitchToggle from "components/switch";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";
import formatURL from "utils/formatUrl";
import FormField from "../../form-field";
import { schema } from "./schema";

export function SocialsForm({ snack }) {
  const { viewer, setViewer } = useViewer();
  const form = useForm({
    schema,
  });

  const [twitterHandle, setTwitterHandle] = useState(viewer?.twitterHandle);
  const [facebookAccount, setFacebookAccount] = useState(viewer?.facebookAccount);
  const [instagramAccount, setInstagramAccount] = useState(viewer?.instagramAccount);
  const [threadAccount, setThreadAccount] = useState(viewer?.threadAccount);
  const [agentLinkedinProfile, setAgentLinkedinProfile] = useState(viewer?.agentLinkedinProfile);
  const [loading, setLoading] = useState(false);
  const [showSocials, setShowSocials] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setError();
    setTwitterHandle(viewer?.twitterHandle);
    setFacebookAccount(viewer?.facebookAccount);
    setInstagramAccount(viewer?.instagramAccount);
    setThreadAccount(viewer?.threadAccount);
    setAgentLinkedinProfile(viewer?.agentLinkedinProfile);
    setShowSocials(viewer?.showSocials);
  }, [viewer]);

  const save = () => {
    setLoading(true);
    setError();
    query
      .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
        twitterHandle: twitterHandle ? formatURL(twitterHandle) : "",
        facebookAccount: facebookAccount ? formatURL(facebookAccount) : "",
        instagramAccount: instagramAccount ? formatURL(instagramAccount) : "",
        threadAccount: threadAccount ? formatURL(threadAccount) : "",
        agentLinkedinProfile: agentLinkedinProfile ? formatURL(agentLinkedinProfile) : "",
        showSocials,
      })
      .then((res) => {
        setLoading(false);
        setViewer(res.data);
        snack("Updated", { variant: "success", autoHideDuration: 2000 });
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Something went wrong");
        snack("Could not update", { variant: "error", autoHideDuration: 2000 });
      });
  };

  return (
    <div>
      <h1 className="text-sm text-gray-500 mb-4">
        Any information entered here will appear at the bottom of your Gallery Menu in the Social
        Media Section. Leave any field blank if you do not want anything to appear for that field.
      </h1>
      <div>
        <div className="flex gap-x-2 items-center">
          <SwitchToggle checked={!showSocials} setChecked={setShowSocials} />
          <h1 className="text-sm text-gray-500">Display in Menu?</h1>
        </div>
      </div>
      <br />
      <Form form={form} onSubmit={save}>
        <div className="flex flex-col space-y-5">
          <div className="grid sm:grid-cols-2 gap-5">
            <FormField
              defaultValue="https://www.facebook.com/john.doe"
              label="Facebook"
              type="text"
              {...form.register("facebook")}
              value={facebookAccount}
              onChange={(e) => setFacebookAccount(e.target.value)}
            />

            <FormField
              defaultValue="https://www.instagram.com/johndoe/"
              label="Instagram"
              type="text"
              {...form.register("instagram")}
              value={instagramAccount}
              onChange={(e) => setInstagramAccount(e.target.value)}
            />

            <FormField
              defaultValue=""
              label="YouTube"
              type="text"
              {...form.register("thread")}
              value={threadAccount}
              onChange={(e) => setThreadAccount(e.target.value)}
            />
            
            <FormField
              defaultValue="https://twitter.com/johndoe"
              label="X"
              type="text"
              {...form.register("twitter")}
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
            />

            <FormField
              defaultValue="linkedin.com/john-doe"
              label="LinkedIn"
              type="text"
              {...form.register("linkedIn")}
              value={agentLinkedinProfile}
              onChange={(e) => setAgentLinkedinProfile(e.target.value)}
            />
          </div>
          <center>
            <MDButton
              color="dark"
              size="small"
              className="max-w-fit ml-auto"
              type="submit"
              disabled={loading}
              onClick={save}
            >
              {loading ? "Updating..." : "Update"}
            </MDButton>
          </center>
        </div>
      </Form>
    </div>
  );
}
