/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import { DataGrid } from "@mui/x-data-grid";
import { subscriptionsData } from "data";
import { useEffect, useMemo } from "react";

const columns = [
  { field: "item", headerName: "Item", flex: 1, minWidth: 300 },
  { field: "cost", headerName: "Cost", width: 100 },
];

export default function SummaryPage({
  removeOneviewerLogo,
  setupExtras,
  plan,
  setTotalAmount,
  setSubscriptionsItems,
  purchaseRows,
  formData,
}) {
  let rows = [];
  console.log("*****", formData);


  if (purchaseRows) {
    rows = purchaseRows;
  } else {
    rows = useMemo(
      () =>
        [
          removeOneviewerLogo
            ? {
                id: 1,
                item: "Remove Viewlio Logo from Gallery Menu?",
                cost:
                  plan === "monthly"
                    ? subscriptionsData.removeLogoMontlhy.amount
                    : subscriptionsData.removeLogoYearly.amount,
                data:
                  plan === "monthly"
                    ? subscriptionsData.removeLogoMontlhy
                    : subscriptionsData.removeLogoYearly,
              }
            : null,
          setupExtras !== "none"
            ? {
                id: 2,
                item:
                  setupExtras === "completeViewer"
                    ? "Concierge Complete Gallery Built for You"
                    : "Concierge 1-Hour Set-Up Walkthrough Instruction",
                cost:
                  setupExtras === "completeViewer"
                    ? subscriptionsData.completeViewer.amount
                    : subscriptionsData.setupWalkthrough.amount,
                data:
                  setupExtras === "completeViewer"
                    ? subscriptionsData.completeViewer
                    : subscriptionsData.setupWalkthrough,
              }
            : null,
          {
            id: 3,
            item: `Plan: ${[plan[0]?.toUpperCase(), plan?.substring(1)].join("")}`,
            cost:
              plan === "monthly"
                ? subscriptionsData.monthlyPlan.amount
                : subscriptionsData.yearlyPlan.amount,
            data: plan === "monthly" ? subscriptionsData.monthlyPlan : subscriptionsData.yearlyPlan,
          },
        ].filter((x) => x),
      [removeOneviewerLogo, setupExtras, plan]
    );
  }

  const totalCost = useMemo(() => {
    if (rows) {
      let total = 0;
      for (let i = 0; i < rows.length; i += 1) {
        total += rows[i].cost;
      }
      return total;
    }
    return 0;
  }, [rows]);

  useEffect(() => {
    setTotalAmount(totalCost);
  }, [totalCost]);

  useEffect(() => {
    const data = rows.map((x) => x.data);
    const recreatedArray = data.reduce((acc, obj) => {
      const { type, amount, description, ...rest } = obj;
      if (!acc[type]) {
        acc[type] = type === "onceoff" ? { amount: 0, description: "" } : [];
      }

      if (type === "onceoff") {
        acc[type].amount += amount;
        acc[type].description = description || acc[type].description;
      } else {
        acc[type].push(rest);
      }
      return acc;
    }, {});
    setSubscriptionsItems(recreatedArray);
  }, [rows]);

  return (
    <div>
      <MDBox p={2} style={{ height: 300, width: "100%", overflow: "auto", fontSize: "16px" }}>
        <DataGrid
          rows={rows.map((r) => ({
            ...r,
            cost: `$${r.cost}`,
          }))}
          columns={columns}
          pageSize={5}
          hideFooter
          rowsPerPageOptions={[5]}
          density="compact"
          style={{
            fontSize: "14px",
            fontWeight: 300,
          }}
        />
      </MDBox>
      <MDBox
        mt={2}
        width="100%"
        display="flex"
        justifyContent="space-between"
        className="flex-col gap-y-2 md:flex-row"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        <p
          style={{ display: "inline-flex", justifyContent: "left" }}
          className="text-sm text-gray-500 mt-4 font-bold"
        >
          {" "}
        </p>
        <p style={{ fontSize: "16px", display: "inline-flex", justifyContent: "right" }}>
          <span className="font-bold">Total</span>: <span>${totalCost}</span>
        </p>
      </MDBox>
    </div>
  );
}
