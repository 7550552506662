/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Card } from "@mui/material";
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";
import { useEffect, useState } from "react";

const getMapViewData = (countries) => {
  const mapData = {};
  const mapData1 = {};

  for (const country of countries) {
    if (country.code) {
      let totalNewUsers = 0;
      let totalViews = 0;
      let totalActiveUsers = 0;
      for (const region of country.regions) {
        if (region.cities.length > 0) {
          for (const city of region.cities) {
            totalNewUsers += city.new_users;
            totalActiveUsers += city.active_users;
            totalViews += city.views;
          }
        } else {
          totalNewUsers += region.new_users;
          totalActiveUsers += region.active_users;
          totalViews += region.views;
        }
      }

      mapData1[country.code] = totalViews;
      mapData[country.code] = {
        views: totalViews,
        name: country.name,
        new_users: totalNewUsers,
        active_users: totalActiveUsers,
      };
    }
  }

  return { mapData, mapData1 };
};

function CountryMapView({ data, isLoading }) {
  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    if (data.length > 0) {
      const { mapData, mapData1 } = getMapViewData(data);
      setMapData({ mapData, mapData1 });
    }
  }, [data, isLoading]);

  return (
    <Card sx={{ width: "100%", height: "30.8rem" }}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={1} pt={1}>
          <MDBox style={{ color: "#051D3A" }}>
            <MDTypography variant="h6">Map View</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          {/* Render other content */}
          {/* ... */}
          {/* Conditionally render the VectorMap if mapData is available */}
          {mapData && (
            <VectorMap
              style={{ height: "400px" }}
              map={worldMerc}
              zoomOnScroll={false}
              zoomButtons={false}
              markersSelectable
              backgroundColor="transparent"
              regionStyle={{
                initial: {
                  fill: "#dee2e7",
                  "fill-opacity": 1,
                  stroke: "none",
                  "stroke-width": 0,
                  "stroke-opacity": 0,
                },
              }}
              series={{
                regions: [
                  {
                    scale: ["#69A3AE", "#97CADB"],
                    attribute: "fill",
                    values: mapData.mapData1,
                    normalizeFunction: undefined,
                    min: 1,
                    max: 100,
                  },
                ],
              }}
              onRegionTipShow={function (event, label, code) {
                label.html(
                  `<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black"; padding-left: 10px>` +
                    `<p>` +
                    `<b>${label.html()}</b>` +
                    `</p>` +
                    `<p>` +
                    `Views: ` +
                    `<b>${mapData.mapData[code]?.views ? mapData.mapData[code]?.views : 0}</b>` +
                    `</p>` +
                    `Active Users: ` +
                    `<b>${
                      mapData.mapData[code]?.new_users ? mapData.mapData[code]?.new_users : 0
                    }</b>` +
                    `</p>` +
                    `Active Users: ` +
                    `<b>${
                      mapData.mapData[code]?.active_users ? mapData.mapData[code]?.active_users : 0
                    }</b>` +
                    `</p>` +
                    `</div>`
                );
              }}
            />
          )}

          {!isLoading && data.length === 0 && (
            <VectorMap
              style={{ height: "400px" }}
              map={worldMerc}
              zoomOnScroll={false}
              zoomButtons={false}
              markersSelectable
              backgroundColor="transparent"
              regionStyle={{
                initial: {
                  fill: "#dee2e7",
                  "fill-opacity": 1,
                  stroke: "none",
                  "stroke-width": 0,
                  "stroke-opacity": 0,
                },
              }}
              series={{
                regions: [
                  {
                    scale: ["#DEEBF7", "#08519C"],
                    attribute: "fill",
                    values: {},
                    normalizeFunction: "polynomial",
                    min: 1,
                    max: 100,
                  },
                ],
              }}
              onRegionTipShow={function (event, label, code) {
                label.html(
                  `<div style="background-color: white; border: 1px solid white; outline: 10px solid white; border-radius: 6px; min-height: 70px; width: 150px; color: black"; padding-left: 10px>` +
                    `<p>` +
                    `<b>${label.html()}</b>` +
                    `</p>` +
                    `</div>`
                );
              }}
            />
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CountryMapView;
