/* eslint-disable no-unsafe-optional-chaining */
// @react-jvectormap components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { Card } from "@mui/material";
import AnalyticsDataTable from "components/analytics-datatable";
import NoAnalyticsData from "components/no-analytics-data";
import hyphenToSentence from "utils/hyphenToSentence";

function TimeAndBounceOnModule({ data, isLoading }) {
  function convertSecondsToTimeFormat(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;

    // Add leading zeros if needed
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <Card sx={{ width: "100%", height: "23.8rem" }}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={1} pt={1}>
          <MDBox style={{ color: "#051D3A" }}>
            <MDTypography variant="h6">Module Overview</MDTypography>
          </MDBox>
        </MDBox>
        <div style={{ maxHeight: "18rem", overflowY: "auto" }}>
          {data.length > 0 && !isLoading ? (
            <AnalyticsDataTable
              theme="solarized"
              columns={[
                {
                  name: "Module Name",
                  selector: (row) => row.url,
                  sortable: true,
                },
                {
                  name: "Views",
                  selector: (row) => row.views,
                  sortable: true,
                },
                {
                  name: "Time On Module",
                  selector: (row) => row.time,
                  sortable: true,
                },
                {
                  name: "Bounce Rate",
                  selector: (row) => row.bounceRate,
                  sortable: true,
                },
              ]}
              data={data.map((item) => {
                const time = convertSecondsToTimeFormat(item?.time_on_module);
                const bouncePercentage = parseFloat(item?.bounce_rate) * 100 || 0;
                const formattedBounce = Number.isInteger(bouncePercentage)
                  ? bouncePercentage
                  : bouncePercentage.toFixed(2);
                return {
                  ...item,
                  bounceRate: formattedBounce,
                  time,
                  url: hyphenToSentence(item?.url),
                };
              })}
            />
          ) : (
            <NoAnalyticsData isLoading={isLoading} />
          )}
        </div>
      </MDBox>
    </Card>
  );
}

export default TimeAndBounceOnModule;
