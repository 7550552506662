// eslint-disable-next-line import/no-extraneous-dependencies
import { fromJS } from "immutable";

import getFloorPlansRoutine, {
  removeFloorPlanFolderRoutine,
  addFloorPlanFolderRoutine,
  addFloorPlanPlanRoutine,
  getPlansRoutine,
  sortPlansRoutine,
  sortFloorPlanFoldersRoutine,
  removeFloorPlanPlanRoutine,
} from "./routines";

const initialState = fromJS({});

export default function floorPlanReducer(state = initialState, action) {
  switch (action.type) {
    case getFloorPlansRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });
    case getFloorPlansRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case removeFloorPlanFolderRoutine.SUCCESS:
      return state.merge({
        floorPlans: action.payload.sort((a, b) => a.order - b.order),
        floorPlansLoading: false,
      });

    case getFloorPlansRoutine.SUCCESS:
      return state.merge({
        floorPlans: action.payload.sort((a, b) => a.order - b.order),
        floorPlansLoading: false,
      });

    case removeFloorPlanFolderRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });
    case removeFloorPlanFolderRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case addFloorPlanFolderRoutine.SUCCESS:
      return state.merge({
        floorPlans: action.payload.sort((a, b) => a.order - b.order),
        floorPlansLoading: false,
      });

    case getPlansRoutine.SUCCESS:
      return state.merge({
        plans: action.payload.sort((a, b) => a.order - b.order),
      });

    case addFloorPlanFolderRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });

    case addFloorPlanFolderRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case addFloorPlanPlanRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });

    case addFloorPlanPlanRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case addFloorPlanPlanRoutine.SUCCESS:
      return state.merge({
        floorPlans: action.payload.sort((a, b) => a.order - b.order),
        floorPlansLoading: false,
      });
    case sortFloorPlanFoldersRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });

    case sortFloorPlanFoldersRoutine.SUCCESS:
      return state.merge({
        floorPlans: action.payload.sort((a, b) => a.order - b.order),
        floorPlansLoading: false,
      });
    case sortFloorPlanFoldersRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case getPlansRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });

    case getPlansRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case sortPlansRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });
    case sortPlansRoutine.SUCCESS:
      return state.merge({
        floorPlansLoading: false,
        plans: action.payload,
      });
    case sortPlansRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    case removeFloorPlanPlanRoutine.TRIGGER:
      return state.merge({
        floorPlansLoading: true,
      });
    case removeFloorPlanPlanRoutine.SUCCESS:
      return state.merge({
        plans: action.payload.sort((a, b) => a.order - b.order),
      });
    case removeFloorPlanPlanRoutine.FAILURE:
      return state.merge({
        floorPlansLoading: false,
      });

    default:
      return state;
  }
}
