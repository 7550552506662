/* eslint-disable import/no-unresolved */
import { Skeleton } from "@mui/material";
import { useUrl } from "contexts/url";
import { useEffect, useMemo } from "react";
import ModelsModule from "./modules/3d-models";
import Website from "./modules/website";
import CodeEmdedModule from "./modules/code-embed";
import EventEnquiresModule from "./modules/event-enquiries";
import InstagramModule from "./modules/instagram";
import MapsModule from "./modules/maps";
import OverviewModule from "./modules/overview";
import SalesDeckModule from "./modules/pdf-viewer";
import PhotoGallery from "./modules/photo-gallery";
import PhotoLibrary from "./modules/photo-gallery/library";
import FloorPlanLibrary from "./modules/floor-plans/library";
import StagingOptionsModule from "./modules/staging-options";
import VirtualTourModule from "./modules/virtual-tour";
import VideoGallery from "./modules/video-gallery";
import FloorPlans from "./modules/floor-plans";

export default function ViewerBuilderContent() {
  const { getSearchParam, removeSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");
  const openFolder = getSearchParam("openFolder");
  const builderTab = getSearchParam("builderTab");

  useEffect(() => {
    removeSearchParam("openFolder");
  }, [builderTab]);

  const activeTabContent = useMemo(() => {
    switch (builderTab) {
      case "virtual-tour":
        return <VirtualTourModule />;
      case "overview":
        return <OverviewModule />;
      case "3d-model":
        return <ModelsModule />;
      case "website":
        return <Website />;
      case "virtual-staging":
        return <StagingOptionsModule />;
      case "instagram":
        return <InstagramModule />;
      case "custom":
        return <CodeEmdedModule />;
      case "directions":
        return <MapsModule />;
      case "sales-deck":
        return <SalesDeckModule />;
      case "video-gallery":
        // return (
        //   <div>
        //     <div>[CURRENTLY BEING UPDATED]</div>{" "}
        //     <div className="text-sm italic max-w-sm">
        //       (I had already began integrating with the backend)
        //     </div>
        //   </div>
        // );
        return <VideoGallery />;
      case "contact-form":
        return <EventEnquiresModule />;
      case "photo-gallery":
        if (openFolder) {
          return <PhotoLibrary />;
        }
        return <PhotoGallery />;
      case "floor-plans":
        if (openFolder) {
          return <FloorPlanLibrary />;
        }
        return <FloorPlans />;
      default:
        return (
          <div className="flex flex-col gap-y-2 bg-white shadow-md rounded-md p-4">
            <Skeleton className="w-[50%] mx-auto h-[45px]" />
            <Skeleton className="w-full flex-grow h-[200px]" />
          </div>
        );
    }
  }, [builderTab, moduleId, openFolder]);

  return activeTabContent;
}
