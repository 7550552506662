/* eslint-disable jsx-a11y/click-events-have-key-events */

import MDButton from "@core/components/MDButton";
import { useState } from "react";

/* eslint-disable jsx-a11y/no-static-element-interactions */
export default function ActionModal({ open, setOpen, message, actionFuction, action, id }) {
  const [loading, setLoading] = useState(false);

  return open ? (
    <>
      <div
        onClick={() =>
          setOpen((prevState) => ({
            ...prevState,
            visibility: false,
          }))
        }
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none backdrop-blur-sm mx-3"
      >
        <div
          className="relative w-auto my-6 mx-auto max-w-3xl"
          onClick={(e) => e.stopPropagation()}
        >
          {/* ---- CONTENT ---- */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[95vh] overflow-hidden hover:overflow-auto">
            <div className="text-center p-10">
              <div className="mb-5 text-center">
                <h1 className="font-semibold text-2xl text-gray-700">Are you sure?</h1>
                <h6 className="font-normal text-sm text-gray-700">{message}</h6>
              </div>
              <MDButton
                color="light"
                
                onClick={() =>
                  setOpen((prevState) => ({
                    ...prevState,
                    visibility: false,
                  }))
                }
                className="btn bg-secondary font-semibold text-gray-700 hover:text-gray-700/60"
              >
                Discard
              </MDButton>

              <MDButton
                color="error"
                
                onClick={() => {
                  setLoading(true);
                  actionFuction(id)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch(() => {
                      setLoading(false);
                    });
                }}
                className="btn bg-primary text-dark font-semibold ml-5 hover:text-dark/60"
                disabled={loading}
              >
                {loading ? "Hold on..." : action}
              </MDButton>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  ) : null;
}
