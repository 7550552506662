/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { baseUrl, endpoints, query } from "api";
import axios from "axios";
import { moduleTypes } from "constants/modules";
import { Form, Formik } from "formik";
import { DataURIToBlob, dataURLtoBlob } from "helpers";
import BaseLayout from "layouts/base-layout";
import { createColor } from "material-ui-color";
import { useSnackbar } from "notistack";
import Address from "pages/viewers/new-viewer/components/address";
import AgentInfo from "pages/viewers/new-viewer/components/agent-info";
import Profile from "pages/viewers/new-viewer/components/profile";
import Socials from "pages/viewers/new-viewer/components/socials";
import form from "pages/viewers/new-viewer/schemas/form";
import initialValues from "pages/viewers/new-viewer/schemas/initialValues";
import validations from "pages/viewers/new-viewer/schemas/validations";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "routes/paths";
import formatURL from "utils/formatUrl";
import Billing from "./components/billing";
import Extras from "./components/extras";
import SummaryPage from "./components/summary";
import validationsWithoutPwd from "./schemas/validations-without-pwd";

function NewViewer() {
  function getSteps() {
    return ["General", "Design", "Agent", "Socials", "BILLING", "Summary"];
  }

  const { enqueueSnackbar } = useSnackbar();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAgentInfo, setShowAgentInfo] = useState(true);
  const [showSocials, setShowSocials] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Processing payment.....");
  const [errorMessage, setErrorMessage] = useState(null);

  const [error, setError] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [frameColor, setFrameColor] = useState(createColor("#42424a"));
  const [windowColor, setWindowColor] = useState(createColor("#eee"));
  const [menuColor, setMenuColor] = useState(createColor("#42424a"));
  const [menuHoverColor, setMenuHoverColor] = useState(createColor("#626268"));
  const [viewerControlsColor, setViewerControlsColor] = useState(createColor("#626268"));
  const [fontColor, setFontColor] = useState(createColor("#fff"));
  const [menuFontAndIconColor, setMenuFontAndIconColor] = useState(createColor("#EEEEEE"));
  const [windowFontAndIconColor, setWindowFontAndIconColor] = useState(createColor("#2e2e32"));
  const [font, setFont] = useState("Nunito");
  const [useIconsForMainMenuItems, setUseIconsForMainMenuItems] = useState(true);
  const [removeOneviewerLogo, setRemoveOneviewerLogo] = useState(false);
  const [setupExtras, setSetupExtras] = useState("none");
  const [plan, setPlan] = useState("monthly");
  const [cardInfo, setCardInfo] = useState({});
  const [subscriptionRows, setSubscriptionsItems] = useState([]);
  const [submitValues, setSubmitValues] = useState();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const [useColorGradientOnMenu, setUseColorGradientOnMenu] = useState(true);
  const [menuColorGradientSecondColor, setMenuColorGradientSecondColor] = useState("#191919");

  // eslint-disable-next-line
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logo, setLogo] = useState("");

  // eslint-disable-next-line
  const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [viewer, setViewer] = useState("");
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = checked ? validations[activeStep] : validationsWithoutPwd[activeStep];
  const isConfirmationStep = activeStep === steps.length;

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, paymentDetails) => {
    // eslint-disable-next-line
    const { promoCode, ...actualCardInfo } = cardInfo;

    const fields = {
      ...values,
      frameColor: frameColor?.hex || frameColor,
      windowColor: windowColor?.hex || windowColor,
      menuColor: menuColor?.hex || menuColor,
      fontColor: fontColor?.hex || fontColor,
      menuHoverColor: menuHoverColor?.hex || menuHoverColor,
      font,
      useIconsForMainMenuItems,
      menuFontAndIconColor: menuFontAndIconColor?.hex || menuFontAndIconColor,
      // windowFontAndIconColor: windowFontAndIconColor.hex,
      removeOneviewerLogo,
      setupExtras,
      plan: localStorage.getItem("$role") === "superadmin" ? "no-billing" : plan,
      useColorGradientOnMenu,
      menuColorGradientSecondColor:
        menuColorGradientSecondColor?.hex || menuColorGradientSecondColor,
      viewerControlsColor: viewerControlsColor?.hex || viewerControlsColor,
      ...actualCardInfo,
    };
    if (fields?.name === "") {
      setError("Name cannot be empty");
      return;
    }
    // eslint-disable-line
    setLoading(true);
    // actions.setSubmitting(true);

    query
      .post(endpoints.viewers.create(), {
        name: fields?.name,
        address: fields?.address,
        industry: fields?.industry,
        city: fields?.city,
        password: fields.password,
        province: fields.province,
        cvv: fields.businessName,
        country: fields.country,
        repeatPassword: fields.repeatPassword,
        agentFirstName: fields.firstName,
        agentLastName: fields.lastName,
        agentTitle: fields.title,
        agentEmailAddress: fields.email,
        agentPhoneNumber: fields.phoneNumber,
        agentLinkedinProfile: fields.linkedInProfile ? formatURL(fields.linkedInProfile) : "",
        bio: fields.zip,
        twitterHandle: fields.twitter ? formatURL(fields.twitter) : "",
        menuHoverColor: fields.menuHoverColor,
        facebookAccount: fields.facebook ? formatURL(fields.facebook) : "",
        instagramAccount: fields.instagram ? formatURL(fields.instagram) : "",
        threadAccount: fields.threads ? formatURL(fields.threads) : "",
        youtubeAccount: fields.youtube ? formatURL(fields.youtube) : "",
        websiteLink: fields.website ? formatURL(fields.website) : "",
        viewerFrameBackgroundColor: fields.frameColor,
        viewerFrameWindowBackgroundColor: fields.windowColor,
        menuColor: fields.menuColor,
        menuBackgroundHoverAndActiveState: fields.menuHoverColor,
        viewerControlsColor: fields.viewerControlsColor,
        fontColor: fields.fontColor,
        font: fields.font,
        menuFontAndIconColor: fields.menuFontAndIconColor,
        windowFontAndIconColor: fields.windowFontAndIconColor,
        removeOneviewerLogo: fields.removeOneviewerLogo,
        windowColor: fields.windowColor,
        useIconsForMainMenuItems: fields.useIconsForMainMenuItems,
        menuColorGradientSecondColor: fields.menuColorGradientSecondColor,
        useColorGradientOnMenu: fields.useColorGradientOnMenu,
        defaultModules: moduleTypes.map((item, index) => ({
          label: item.label,
          name: item.name,
          order: index,
        })),
        setupExtras: fields.setupExtras,
        plan: fields.plan,
        nameOnCard: fields.nameOnCard,
        cardNumber: fields.cardNumber,
        expirationDate: fields.expirationDate,
        billingAddress: fields.billingAddress,
        isActive: true,
        showAgentInfo,
        showSocials,
      })
      .then(async (res) => {
        setViewer(res);
        if (localStorage.getItem("$role") === "superadmin") {
          success("Congratulations, you’ve created a new Gallery!");

          setTimeout(() => {
            setLoading(false);
            navigate(PATHS.viewer.replaceAll(":name", res.data.name).replaceAll(":tab", "builder"));
          }, 4000);
        } else {
          await axios
            .post(`${baseUrl}/billing/subscription`, {
              name: paymentDetails.name,
              couponCode: paymentDetails.couponCode,
              email: paymentDetails.email,
              subscriptionRows,
              totalAmount,
              viewerId: res.data.id,
              paymentMethod: paymentDetails.paymentMethodId,
              userId: localStorage.getItem("$id"),
              // expiringDate: string,
            })
            .then(async (paymentResponse) => {
              setLoadingMessage("Setting up your subscription....");

              const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
              const currentDate = new Date();
              const year = currentDate.getFullYear();
              const month = currentDate.getMonth();
              const daysInMonth = getDaysInMonth(year, month);
              const newFutureDate = new Date(currentDate);
              newFutureDate.setDate(newFutureDate.getDate() + daysInMonth);

              await axios
                .post(`${baseUrl}/billing`, {
                  description: submitValues.name,
                  amount: totalAmount,
                  customerId: paymentResponse.data.customerId,
                  email: paymentDetails.email,
                  paymentStatus: "success",
                  viewerId: res.data.id,
                  paynentId: paymentResponse?.data?.subscriptions?.viewerSubscription?.id,
                  removeLogoSubscriptionId: paymentResponse?.data?.subscriptions
                    ?.removeLogoSubscription?.id
                    ? paymentResponse?.data?.subscriptions?.removeLogoSubscription?.id
                    : "",
                  userId: localStorage.getItem("$id"),
                  dateCreated: new Date(),
                  expiringDate: newFutureDate,
                })
                .then((billingResponse) => {
                  setLoadingMessage("SETTING UP YOUR GALLERY...");

                  query
                    .patch(endpoints.users.updateOne({ id: localStorage.getItem("$id") }), {
                      cardNumber: paymentDetails.paymentMethodId,
                    })
                    .then(() => {});

                  // eslint-disable-line
                  if (profilePicture) {
                    // upload the profile picture here
                    setUploadingProfilePicture(true);

                    const data = new FormData();
                    data.append("picture", dataURLtoBlob(profilePicture));

                    query
                      .post(
                        endpoints.viewers.updateAgentProfilePicture({ id: res.data.id }),
                        data,
                        {
                          "Content-Type": "multipart/form-data",
                        }
                      )
                      .then(() => setUploadingProfilePicture(false))
                      .catch((err) => {
                        console.log(err); // eslint-disable-line
                        setUploadingProfilePicture(false);
                      });
                  }

                  if (logo) {
                    // upload the logo here
                    setUploadingLogo(true);

                    const data = new FormData();
                    data.append("picture", DataURIToBlob(logo));

                    query
                      .post(endpoints.viewers.updateLogo({ id: res.data.id }), data, {
                        "Content-Type": "multipart/form-data",
                      })
                      .then(() => setUploadingLogo(false))
                      .catch((err) => {
                        console.log(err); // eslint-disable-line
                        setUploadingLogo(false);
                      });
                  }
                  setViewer(res.data);
                  // setActiveStep(activeStep + 1);
                  success("Congratulations, you’ve created a new Gallery!");

                  setTimeout(() => {
                    setLoading(false);
                    navigate(
                      PATHS.viewer.replaceAll(":name", res.data.name).replaceAll(":tab", "builder")
                    );
                  }, 4000);
                })
                .catch((err) => {
                  setErrorMessage("Failed to sync payment and gallery.");
                  query
                    .delete(endpoints.viewers.deleteOne({ id: res.data.id }))
                    .then(() => {
                      fetch(`${baseUrl}/billing/subscriptions/viewer/${res.data.id}`, {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res_) => res_.json())
                        .then((data) => {
                          fetch(
                            `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.paynentId}`,
                            {
                              method: "GET",
                              headers: { "Content-Type": "application/json" },
                            }
                          )
                            .then((resp) => resp.json())
                            .then(() => {})
                            .catch(() => {});
                          if (data?.subscriptions[0]?.removeLogoSubscriptionId) {
                            fetch(
                              `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.removeLogoSubscriptionId}`,
                              {
                                method: "GET",
                                headers: { "Content-Type": "application/json" },
                              }
                            )
                              .then((resp_) => resp_.json())
                              .then(() => {})
                              .catch(() => {});
                          }
                        })
                        .catch(() => {});
                      setLoading(false);
                      setIsLoading(false);
                    })
                    .catch(() => {
                      setLoading(false);
                      setIsLoading(false);
                    });
                  setTimeout(() => {
                    window.location.reload();
                  }, 4000);
                  setIsLoading(false);
                  // setErrorMessage(
                  //   "Payment was successful but failed to create gallery. Please contact admin"
                  // );
                });
            })
            .catch((err) => {
              setLoading(false);
              setIsLoading(false);
              // setErrorMessage(err.response.data.message);
              setErrorMessage(`${err.response.data.message}.`);

              query
                .delete(endpoints.viewers.deleteOne({ id: res.data.id }))
                .then(() => {
                  fetch(`${baseUrl}/billing/subscriptions/viewer/${res.data.id}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                  })
                    .then((res_) => res_.json())
                    .then((data) => {
                      fetch(
                        `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.paynentId}`,
                        {
                          method: "GET",
                          headers: { "Content-Type": "application/json" },
                        }
                      )
                        .then((resp) => resp.json())
                        .then(() => {})
                        .catch(() => {});
                      if (data?.subscriptions[0]?.removeLogoSubscriptionId) {
                        fetch(
                          `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.removeLogoSubscriptionId}`,
                          {
                            method: "GET",
                            headers: { "Content-Type": "application/json" },
                          }
                        )
                          .then((resp_) => resp_.json())
                          .then(() => {})
                          .catch(() => {});
                      }
                    })
                    .catch(() => {});
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
              // setTimeout(() => {
              //   window.location.reload();
              // }, 4000);

              if (err.response.status === 401) {
                window.location.href = "/";
              }
            });
        }
      })
      .catch((err) => {
        // eslint-disable-line
        setLoading(false);
        setIsLoading(false);
        // actions.setSubmitting(false);
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError("Something went wrong");
        }

        query
          .delete(endpoints.viewers.deleteOne({ id: viewer.data.id }))
          .then(() => {
            fetch(`${baseUrl}/billing/subscriptions/viewer/${viewer.data.id}`, {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            })
              .then((res_) => res_.json())
              .then((data) => {
                fetch(
                  `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.paynentId}`,
                  {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                  }
                )
                  .then((resp) => resp.json())
                  .then(() => {})
                  .catch(() => {});
                if (data?.subscriptions[0]?.removeLogoSubscriptionId) {
                  fetch(
                    `${baseUrl}/billing/cancel-subscriptions/${data?.subscriptions[0]?.removeLogoSubscriptionId}`,
                    {
                      method: "GET",
                      headers: { "Content-Type": "application/json" },
                    }
                  )
                    .then((resp_) => resp_.json())
                    .then(() => {})
                    .catch(() => {});
                }
              })
              .catch(() => {});
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
  };

  const handleSubmit = (values, actions) => {
    setError(null);
    // if (activeStep === 5) {
    //   submitForm(values, actions);
    // } else {
    setSubmitValues(values);
    setActiveStep(activeStep + 1);
    actions.setTouched({});
    actions.setSubmitting(false);
    // }
  };

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return (
          <Address
            checked={checked}
            setChecked={setChecked}
            formData={formData}
            setError={setError}
            logo={logo}
            setLogo={setLogo}
          />
        );
      case 1:
        return (
          <Profile
            formData={formData}
            frameColor={frameColor}
            setFrameColor={setFrameColor}
            windowColor={windowColor}
            setWindowColor={setWindowColor}
            menuColor={menuColor}
            setMenuColor={setMenuColor}
            fontColor={fontColor}
            setFontColor={setFontColor}
            menuHoverColor={menuHoverColor}
            setMenuHoverColor={setMenuHoverColor}
            setViewerControlsColor={setViewerControlsColor}
            viewerControlsColor={viewerControlsColor}
            font={font}
            setFont={setFont}
            useIconsForMainMenuItems={useIconsForMainMenuItems}
            setUseIconsForMainMenuItems={setUseIconsForMainMenuItems}
            menuFontAndIconColor={menuFontAndIconColor}
            setMenuFontAndIconColor={setMenuFontAndIconColor}
            windowFontAndIconColor={windowFontAndIconColor}
            setWindowFontAndIconColor={setWindowFontAndIconColor}
            useColorGradientOnMenu={useColorGradientOnMenu}
            setUseColorGradientOnMenu={setUseColorGradientOnMenu}
            menuColorGradientSecondColor={menuColorGradientSecondColor}
            setMenuColorGradientSecondColor={setMenuColorGradientSecondColor}
            logo={logo}
            setLogo={setLogo}
          />
        );
      case 2:
        return (
          <AgentInfo
            formData={formData}
            setError={setError}
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            setShowAgentInfo={setShowAgentInfo}
            showAgentInfo={showAgentInfo}
          />
        );
      case 3:
        return (
          <Socials
            formData={formData}
            setShowSocials={setShowSocials}
            showSocials={showSocials}
            setError={setError}
          />
        );
      case 4:
        return (
          <Extras
            formData={formData}
            removeOneviewerLogo={removeOneviewerLogo}
            setRemoveOneviewerLogo={setRemoveOneviewerLogo}
            setupExtras={setupExtras}
            setSetupExtras={setSetupExtras}
            plan={plan}
            setPlan={setPlan}
          />
        );
      case 5:
        return (
          <SummaryPage
            removeOneviewerLogo={removeOneviewerLogo}
            setupExtras={setupExtras}
            plan={plan}
            setTotalAmount={setTotalAmount}
            setSubscriptionsItems={setSubscriptionsItems}
          />
        );
      case 6:
        return (
          <Billing
            activeStep={activeStep}
            handleBack={handleBack}
            loading={loading}
            error={error}
            totalAmount={totalAmount}
            viewer={viewer}
            plan={plan}
            removeOneviewerLogo={removeOneviewerLogo}
            setupExtras={setupExtras}
            setLoadingMessage={setLoadingMessage}
            setError={setError}
            setActiveStep={setActiveStep}
            subscriptionRows={subscriptionRows}
            submitForm={submitForm}
            submitValues={submitValues}
            loadingMessage={loadingMessage}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  }


  return (
    <BaseLayout pageName="Create Gallery">
      <MDBox py={3} mb={20} height="65vh" maxWidth="800px" mx="auto">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 4 }}>
          <Grid item xs={12} lg={9} className="mt-5 lg:mt-0">
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%", maxWidth: "650px", mx: "auto" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        style={{ background: "#245C84" }}
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          className="flex-col gap-y-2 md:flex-row"
                        >
                          {activeStep === 0 || activeStep === 6 ? (
                            <MDBox />
                          ) : (
                            <MDButton color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          {error && <p className="text-sm text-red-600 text-center">{error}</p>}
                          {!isConfirmationStep ? (
                            <MDButton disabled={isSubmitting} type="submit" color="dark">
                              {loading && "Initializing..."}
                              {!loading &&
                                (isConfirmationStep ? "PURCHASE AND CREATE GALLERY" : "Next")}
                            </MDButton>
                          ) : null}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
            {/* <div className="flex my-5 mx-5">
                <FiHelpCircle className="flex-none" />
                <p className="text-sm ml-2">
                  Check out this{" "}
                  <Link className="text-blue-600" to={PATHS.sampleViewer}>
                    prepopulated sample viewer
                  </Link>{" "}
                  to have an overview of how the create gallery wizard should look like.
                </p>
              </div> */}
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default NewViewer;
