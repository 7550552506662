/* eslint-disable no-useless-return */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSnackbar } from "notistack";
// eslint-disable-next-line import/no-extraneous-dependencies
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import { Link, Switch, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { baseUrl, endpoints, query } from "api";
import axios from "axios";
import { useViewer } from "contexts/viewer";
import { useEffect, useState } from "react";

export default function CardModal({
  customer,
  getSubscriptionDetails,
  hide,
  billingRows,
  subscriptionRows,
  removeOneviewerLogo,
  setupExtras,
  plan,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(customer?.email);
  const [name, setName] = useState(customer?.name);
  const [loadingMessage, setLoadingMessage] = useState("Processing payment.....");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [useDefaultCard, setUseDefaultCard] = useState(
    !!customer?.invoice_settings?.default_payment_method
  );

  const { viewer } = useViewer();
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const paymentElementOptions = {
    layout: "tabs",
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"SF-Pro-Rounded"',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    // Create a payment method using the card element
    let newPaymentMethod;
    if (!useDefaultCard) {
      const cardElement = elements.getElement(CardElement);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      newPaymentMethod = paymentMethod;
    }
    await axios
      .post(`${baseUrl}/billing/subscription`, {
        name,
        email,
        subscriptionRows,
        paymentMethod: useDefaultCard
          ? customer?.invoice_settings?.default_payment_method
          : newPaymentMethod.id,
        userId: localStorage.getItem("$id"),
        // expiringDate: string,
      })
      .then(async (res) => {
        await axios
          .post(`${baseUrl}/billing`, {
            description: viewer.name,
            amount: "",
            customerId: res.data.customerId,
            email,
            paymentStatus: "success",
            viewerId: viewer.id,
            paynentId: res.data.subscriptionId,
            userId: localStorage.getItem("$id"),
            dateCreated: new Date(),
            expiringDate: "",
          })
          .then(() => {
            query
              .patch(endpoints.viewers.updateOne({ id: viewer.id }), {
                removeOneviewerLogo,
                setupExtras,
                plan,
                isActive: true,
                status: "1",
              })
              .then(() => {
                setIsLoading(false);
                success("Payment made successful");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              })
              .catch((err) => {
                setIsLoading(false);
                error(err.response.data.message);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            error(err.response.data.message);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        error(err.response.data.message);
      });
  };

  return (
    <div>
      <MDBox p={2} style={{ height: 200, width: "100%", overflow: "auto", fontSize: "16px" }}>
        <DataGrid
          rows={billingRows.map((r) => ({
            ...r,
            cost: `$${r.cost}`,
          }))}
          columns={[
            { field: "item", headerName: "Item", flex: 1, minWidth: 300 },
            { field: "cost", headerName: "Cost", width: 100 },
          ]}
          pageSize={5}
          hideFooter
          rowsPerPageOptions={[5]}
          density="compact"
          style={{
            fontSize: "14px",
            fontWeight: 300,
          }}
        />
      </MDBox>{" "}
      <MDBox p={2} style={{ height: 400, width: "100%", overflow: "auto", fontSize: "16px" }}>
        <form id="payment-form" onSubmit={handleSubmit}>
          {customer?.invoice_settings?.default_payment_method && (
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  checked={useDefaultCard}
                  onChange={() => setUseDefaultCard((prevState) => !prevState)}
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button">Use default card</MDTypography>
              </MDBox>
            </MDBox>
          )}

          {!useDefaultCard && (
            <>
              {elements ? (
                <>
                  {" "}
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-2">
                    <div className="flex flex-col gap-y-1">
                      <div className="text-sm text-gray-500">Name On Card</div>
                      <TextField
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="text-sm text-gray-500">Email</div>
                      <TextField
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "12px" }} className="flex flex-col gap-y-1">
                    <div className="text-sm text-gray-500">Card Details</div>

                    <CardElement id="payment-element" options={paymentElementOptions} />
                  </div>
                </>
              ) : null}

              {errorMessage && (
                <div id="error-message" style={{ color: "red" }}>
                  <br /> {errorMessage}
                </div>
              )}
            </>
          )}
          <br />
          <center>
            <MDButton disabled={isLoading || !stripe || !elements} type="submit" color="dark">
              {isLoading ? loadingMessage : "Pay"}
            </MDButton>
          </center>
        </form>
        <br />
        <span className="text-sm">
          For subscriptions, your card will be charged at the end of the 14-day trial period, and
          billing will recur automatically per your subscription choice. You may cancel anytime to
          avoid future billings. Setup Support services purchased will be charged immediately. There
          are no refunds, pro-rated or full, for already processed billings. You can update your
          Credit Card on file and access the Invoices for each purchase in your Billing Settings.
        </span>
        <br />
        <br />
        <span className="text-sm">
          By purchasing, you accept these Payment Terms and our{" "}
          <MDTypography
            component={Link}
            href="https://www.viewlio.com/terms"
            variant="a"
            color="info"
            fontWeight="medium"
            cursor="pointer"
            target="_blank"
          >
            Terms of Use,
          </MDTypography>{" "}
           and you acknowledge reading the{" "}
          <MDTypography
            component={Link}
            href="https://www.viewlio.com/privacy"
            variant="a"
            color="info"
            fontWeight="medium"
            cursor="pointer"
            target="_blank"
          >
            Privacy Policy.
          </MDTypography>{" "}
          You also agree to auto renewal of your subscription, which can be disabled at any time
          through your account.
        </span>
      </MDBox>
    </div>
  );
}
