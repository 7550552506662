/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
} from "@core/context";
import NotificationItem from "@core/examples/Items/NotificationItem";
import { navbar, navbarContainer, navbarRow } from "@core/examples/Navbars/DashboardNavbar/styles";
import { useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";

function DashboardNavbar({ absolute, light, isMini, pageName, action }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      // color="inherit"
      // sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      className="z-40 flex xl:hidden items-center justify-start "
      style={{marginLeft: "-35px"}}
    >
      <Toolbar
        sx={(theme) => navbarContainer(theme)}
        className="w-full h-full flex items-stretch justify-start"
      >
        <div className="h-full w-full flex-grow flex items-stretch justify-between">
          <MDBox
            // color="inherit"
            sx={(theme) => navbarRow(theme, { isMini })}
            className="hidden w-full h-full flex-grow"
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              pageName={pageName}
              route={route}
              light={light}
            />
          </MDBox>
          <MDBox display={{ xs: "none", xl: "block" }} />

          {isMini ? null : (
            <MDBox
              sx={(theme) => navbarRow(theme, { isMini })}
              className="w-full h-full flex-grow"
              display={{ xs: "block", xl: "none" }}
            >
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  // sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <FiMenu size={20} className="text-gray-500" />
                </IconButton>
              </MDBox>
            </MDBox>
          )}
          {/* {action ? (
            <MDButton color="primary" onClick={action.command || (() => alert("clicked"))}>
              {action.label}
            </MDButton>
          ) : (
            ""
          )} */}
        </div>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  pageName: "",
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  pageName: PropTypes.string,
};

export default DashboardNavbar;
