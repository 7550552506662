// react-router-dom components
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";

// Authentication layout components
import { useSnackbar } from "notistack";
// Images
import * as api from "api";
import query from "api/query";
import GuestLayout from "layouts/guest-layout";
import { useEffect, useState } from "react";

function SetPassword() {
  const navigate = useNavigate();
  const [details, setDetails] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (details.password === details.password2) {
      query
        .get(api.endpoints.users.getUserByEmail({}, { email: details.email }))
        .then((res) => {
          query
            .patch(`/users/set-password`, {
              id: res.data.id,
              email: details.email,
              password: details.password,
            })
            .then((res_) => {
              success("Password configured successfully. Please login");
              setLoading(false);
              navigate("/sign-in");
            })
            .catch((err) => {
              setLoading(false);
              error("Failed to update");
            });
        })
        .catch((err) => {
          setLoading(false);
          error("Failed to configure password");
        });
    } else {
      setLoading(false);
      error("Passwords don't match");
    }
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  return (
    <GuestLayout>
      <Card sx={{ maxWidth: "400px", mx: "auto" }}>
        <MDBox
          borderRadius="lg"
          coloredShadow="info"
          bgColor="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Configure Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && <p className="text-sm text-red-600 text-center mb-5">{error}</p>}
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth name="email" onChange={handleChange} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Re-enter password"
                fullWidth
                name="password2"
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1} className="flex justify-center items-center">
              <MDButton color="info" onClick={handleSubmit} disabled={loading} sx={{ mx: "auto" }}>
                {loading ? "Configuring ..." : "Configure"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </GuestLayout>
  );
}

export default SetPassword;
