const form = {
  formId: "new-user-form",
  formField: {
    fullName: {
      name: "fullName",
      label: "Full Name",
      type: "text",
      errorMsg: "Full name is required.",
    },
    title: {
      name: "title",
      label: "Job Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    companyName: {
      name: "companyName",
      label: "Company Name",
      type: "text",
      errorMsg: "Company name is required.",
    },
    companyWebsiteUrl: {
      name: "companyWebsiteUrl",
      label: "Company Website URL",
      type: "text",
      errorMsg: "Company website URL is required.",
    },
    cellphone: {
      name: "cellphone",
      label: "Phone",
      type: "tel",
      errorMsg: "Phone is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    nameOnCard: {
      name: "nameOnCard",
      label: "Name on Card",
      type: "text",
      errorMsg: "Name on card is required.",
    },
    cardNumber: {
      name: "cardNumber",
      label: "Card Number",
      type: "text",
      errorMsg: "Card number is required.",
    },
    expirationDate: {
      name: "expirationDate",
      label: "Expiration Date",
      type: "text",
      errorMsg: "Expiration date is required.",
      invalidMsg: "Your expiration date is invalid.",
      helperText: "MM/YY",
    },
    cvv: {
      name: "cvv",
      label: "CVV",
      type: "text",
      errorMsg: "CVV is required.",
      helperText: "3 digits on the back of your card",
    },
    billingAddress: {
      name: "billingAddress",
      label: "Billing Address",
      type: "text",
      errorMsg: "Billing address is required.",
    },
  },
};

export default form;
