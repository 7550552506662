/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import Card from "@mui/material/Card";
import PanToolIcon from "@mui/icons-material/PanTool";
import PropTypes from "prop-types";
import { endpoints, query } from "api";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUrl } from "contexts/url";
import { Box, Modal, Tooltip } from "@mui/material";
import EditFolderForm from "../forms/EditForm";

export default function PhotoCard({
  folder,
  removeFolder,
  handleDragStart,
  handleDragEnter,
  handleDragOver,
  handleDragEnd,
  handleDrop,
  getVideos,
}) {
  const [showEditForm, setShowEditForm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const [isLoading, setIsLoading] = useState(false);
  const { getSearchParam } = useUrl();
  const moduleId = getSearchParam("moduleId");

  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "#ffff",
    boxShadow: 24,
    p: 4,
  };

  const updateFolder = (_data) => {
    setIsLoading(true);

    const payload = {
      borderColor: _data.borderColor ? _data.borderColor : "",
      hasImageBorders: _data.hasImageBorders,
      hasRoundedCorners: _data.hasRoundedCorners,
      name: _data.name,
      videoUrl: _data.videoUrl,
      hasThumbnail: _data.hasThumbnail,
    };

    if (_data.hasThumbnail) {
      payload.thumbnail = _data.thumbnailUrl;
    }

    query
      .patch(`/video-gallery-folders/${_data.id}`, payload)
      .then((res) => {
        // eslint-disable-line
        if (!_data.hasThumbnail) {
          const formData = new FormData();
          formData.append("picture", _data.thumbnail);
          if (_data.thumbnail) {
            query
              .post(endpoints.videoGalleryFolders.uploadThumbnail({ id: res.data.id }), formData, {
                "Content-Type": "multipart/form-data",
              })
              .then(() => {
                setIsLoading(false);
                setShowEditForm(false);
                success("Updated successfully");
                getVideos(moduleId);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err); // eslint-disable-line
                error(err?.response?.data.message || "Could not upload thumbnail");
              });
          } else {
            setIsLoading(false);
            setShowEditForm(false);
            success("Updated successfully");
            getVideos(moduleId);
          }
        } else {
          setIsLoading(false);
          setShowEditForm(false);
          success("Updated successful");
          getVideos(moduleId);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // eslint-disable-line
        error(err?.response?.data.message || "Could not create folder");
      });
  };

  const thumbnailGetter = (src, item) => {
    const playerType = item.videoUrl.includes("youtu") ? "youtube" : "vimo";
    if (playerType === "vimo") {
      return item.thumbnail;
    }
    if (item.customeThumb) {
      return item.thumb;
    }
    const youtubeUrl = new URL(item.videoUrl);
    const videoID = youtubeUrl.searchParams.get("v") || youtubeUrl.pathname.substring(1);
    return `https://img.youtube.com/vi/${videoID}/0.jpg`;
  };

  return (
    <Card
      draggable
      onDragStart={() => handleDragStart(folder.id)}
      onDragEnter={() => handleDragEnter(folder.id)}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
        maxWidth: 345,
      }}
      className="hover:cursor-pointer mt-5"
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <center>
          <MDBox
            component="img"
            src={
              folder.hasThumbnail === "false"
                ? folder.thumbnail
                : thumbnailGetter(folder.videoUrl, folder)
            }
            borderRadius="lg"
            shadow="sm"
            height="180px"
            // width="100%"
            position="relative"
            zIndex={10}
            mb={2}
          />
        </center>
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-10}
          position="relative"
          zIndex={1}
        >
          <MDButton
            onClick={() => {
              if (showEditForm) {
                setShowEditForm(false);
              } else {
                setShowEditForm(true);
              }
            }}
            color="info"
            size="small"
          >
            edit
          </MDButton>
          <MDButton
            onClick={() => removeFolder(folder.id)}
            variant="outlined"
            color="dark"
            size="small"
          >
            remove
          </MDButton>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          {folder.name}

          <MDBox textAlign="right" style={{ paddingRight: "20px", marginTop: "-37px" }}>
            <Tooltip title="Drag And Drop">
              <PanToolIcon color="info" />
            </Tooltip>
          </MDBox>
        </MDTypography>
        {showEditForm && (
          <MDBox sx={{ mt: 2 }} display="flex" justifyContent="space-between">
            <EditFolderForm
              folder={folder}
              updateFolder={updateFolder}
              cancel={() => setShowEditForm(false)}
              isLoading={isLoading}
            />
          </MDBox>
        )}

        <Modal
          open={showEditForm}
          onClose={() => {
            setShowEditForm(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDBox sx={{ mt: 2 }} display="flex" justifyContent="space-between">
              <EditFolderForm
                folder={folder}
                updateFolder={updateFolder}
                cancel={() => setShowEditForm(false)}
                isLoading={isLoading}
              />
            </MDBox>
          </Box>
        </Modal>
      </MDBox>
    </Card>
  );
}
PhotoCard.defaultProps = {
  folder: {},
};

PhotoCard.propTypes = {
  folder: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    imageCount: PropTypes.number,
    handleDragStart: PropTypes.func,
    updateFolder: PropTypes.func,
    handleDragOver: PropTypes.func,
    handleDragEnter: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleDrop: PropTypes.func,
    getVideos: PropTypes.func,
  }),
};
