/* eslint-disable import/no-unresolved */
// prop-types is a library for typechecking of props

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import bg from "assets/bg-01.svg";

// Material Dashboard 2 PRO React examples
import PageLayout from "@core/examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import Footer from "./footer";
import GuestNavbar from "./navbar";

function GuestLayout({ image, children, isSignUp, isSignIn }) {
  const getAction = () => {
    if (isSignUp) {
      // return { route: "/sign-in", label: "Sign In", type: "internal" };
    }
    if (isSignIn) {
      // return { route: "/sign-up", label: "Sign Up", type: "internal" };
    }
    return null;
  };

  return (
    <PageLayout>
      <GuestNavbar transparent light action={getAction()} />
      <MDBox
        position="fixed"
        width="100%"
        height="100vh"
        style={{ backgroundImage: { bg } }}
        sx={{
          backgroundColor: "#1B3642",
          // backgroundImage: "radial-gradient(circle, rgba(65,65,73,1) 0%, rgba(29,29,29,1) 50%)",
          // backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" mx="auto" pt="50px" backgroundColor="red" height="100vh">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          {isSignUp ? (
            <Grid item xs={12} sm={8} md={8}>
              {children}
            </Grid>
          ) : (
            <Grid item xs={12} sm={8} md={6} lg={4}>
              {children}
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer light />
    </PageLayout>
  );
}

export default GuestLayout;
