function formatURL(url) {
  let newUrl = url;
  if (url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      newUrl = `https://${url}`;
    }
  }
  return newUrl;
}

export default formatURL;
