/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { query } from "api";
import { useSnackbar } from "notistack";
import { useState } from "react";

function AddUserContent({ getUserDetails, isLoading, setIsLoading, setAddModal }) {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellPhone] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const success = (message) =>
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  const error = (message) => enqueueSnackbar(message, { variant: "error", autoHideDuration: 2000 });

  return (
    <Card id="general" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">General</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Full Name"
              value={fullName}
              onChange={(event) => {
                setfullName(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormField
              label="Email"
              value={email}
              placeholder=""
              inputProps={{ type: "email" }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              placeholder=""
              // inputProps={{ type: "number" }}
              value={cellphone}
              onChange={(event) => {
                setCellPhone(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Company"
              value={company}
              onChange={(event) => {
                setCompany(event.target.value);
              }}
              placeholder=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="industry-select-label">Select Role</InputLabel>
              <Select
                labelId="industry-select-label"
                id="industry-select"
                // value={age}
                label="Select Industry"
                onChange={(event) => {
                  setRole(event.target.value);
                }}
                style={{
                  height: "30px",
                }}
              >
                <MenuItem value="root" key="root">
                  Admin
                </MenuItem>
                <MenuItem value="user" key="user">
                  User
                </MenuItem>
                {localStorage.getItem("$role") === "superadmin" && (
                  <MenuItem value="superadmin" key="superadmin">
                    Super Admin
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <MDBox ml="auto" mt={2}>
            <br />
            <br />
            <MDButton
              // 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

              onClick={() => {
                // const apiKey =
                //   "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";
                // constmine?id=${localStorage.getItem("$id")} url = "https://api.sendinblue.com/v3/smtp/email";

                // const data = {
                //   sender: {
                //     name: "Sender Alex",
                //     email: "chad@viewlio.com",
                //   },
                //   to: [
                //     {
                //       email: "zulunigelb@gmail.com",
                //       name: "John Doe",
                //     },
                //   ],
                //   subject: "Hello world",
                //   htmlContent:
                //     "<html><head></head><body><p>Hello,</p>This is my first transactional email sent from Sendinblue.</p></body></html>",
                // };

                // const config = {
                //   headers: {
                //     "api-key": apiKey,
                //     "Content-Type": "application/json",
                //     Accept: "application/json",
                //   },
                // };

                // axios
                //   .post(url, data, config)
                //   .then((response) => {
                //     console.log(response.data);
                //   })
                //   .catch((_error) => {
                //     console.log(error);
                //   });

                if (
                  fullName === "" ||
                  email === "" ||
                  cellphone === "" ||
                  title === "" ||
                  role === "" ||
                  company === ""
                ) {
                  error("Fields cannot be empty");
                  return;
                }
                setIsLoading(true);
                query
                  .post(`/users`, {
                    fullName,
                    email,
                    cellphone,
                    title,
                    role,
                    companyName: company,
                    added_by: localStorage.getItem("$id"),
                  })
                  .then((res) => {
                    success("Added successfully");
                    setIsLoading(false);
                    getUserDetails();
                    setAddModal();
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    error("Failed to add");
                  });
              }}
              color="dark"
              size="small"
              // component={Link}
            >
              {isLoading ? "adding info...." : "add info"}
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default AddUserContent;
