/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import BankingCard from "@core/examples/Cards/BankingCard";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState } from "react";
// import AddCardModal from "../add-card";
import EditCardModal from "../edit-card";

function PaymentMethod({ paymentMethod, customer, getSubscriptionDetails, isLoading }) {
  const [editCard, setEditCard] = useState(false);

  return (
    <Card>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Default Payment Method
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <center>
          {/* {paymentMethod?.brand === "mastercard" ? (
            <MasterCard
              number={paymentMethod?.last4}
              holder={customer?.name}
              expires={`${paymentMethod?.exp_month}/${paymentMethod?.exp_year}`}
            />
          ) : null} */}
          <BankingCard
            number={paymentMethod?.last4}
            holder={customer?.name}
            expires={`${paymentMethod?.exp_month || ""}/${paymentMethod?.exp_year || ""}`}
            brand={paymentMethod?.brand}
          />
          <center>
            {isLoading ? (
              <>
                {" "}
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  Loading....
                </MDTypography>
              </>
            ) : (
              !paymentMethod?.brand && (
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  No default card found
                </MDTypography>
              )
            )}
          </center>
          <br />
          {paymentMethod?.brand && (
            <MDButton color="dark" onClick={() => setEditCard(true)}>
              <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              &nbsp;update default card
            </MDButton>
          )}
        </center>
      </MDBox>
      {/* <AddCardModal open={addCard} setOpen={setAddCard} fetch={fetchCards} /> */}
      <EditCardModal
        open={editCard}
        setOpen={setEditCard}
        fetch={false}
        customer={customer}
        getSubscriptionDetails={getSubscriptionDetails}
      />
    </Card>
  );
}

export default PaymentMethod;
