/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
// react-router-dom components
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDInput from "@core/components/MDInput";
import MDTypography from "@core/components/MDTypography";

// Authentication layout components

// Images
import * as api from "api";
import query from "api/query";
import GuestLayout from "layouts/guest-layout";
import { useEffect, useState } from "react";

function SignIn() {
  const navigate = useNavigate();
  const [details, setDetails] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSubmit = (e) => {
    // e.preventDefault();
    setLoading(true);
    query
      .post(api.endpoints.auth.login, {
        ...details,
        sessionExpiresInHours: 365 * 24,
      })
      .then((res) => {
        setLoading(false);
        const token = res.data.tokens.accessToken;
        const { role } = res.data.user;

        const decodedToken = jwtDecode(token);

        localStorage.setItem("$customerId", token);
        localStorage.setItem("$token", token);
        localStorage.setItem("$role", role);
        localStorage.setItem("$fullName", decodedToken.fullName);
        localStorage.setItem("$id", decodedToken.sub);

        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        setError("Login Failed");
      });
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        handleSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const handleChange = (e) => {
    setError("");
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    localStorage.removeItem("$token");
  }, []);

  return (
    <GuestLayout>
      <Card sx={{ maxWidth: "400px", mx: "auto" }}>
        <MDBox
          borderRadius="lg"
          coloredShadow="info"
          bgColor="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {error && <p className="text-sm text-red-600 text-center mb-5">{error}</p>}
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                // label="Email"
                fullWidth
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={details.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                // label="Password"
                fullWidth
                placeholder="Password"
                name="password"
                onChange={handleChange}
                value={details.password}
              />
            </MDBox>
            <MDBox mt={-1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot your password?{" "}
                <MDTypography
                  component={Link}
                  to="/forgot-password"
                  variant="button"
                  className="newcolorlink"
                  fontWeight="medium"
                >
                  Reset it
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1} className="flex justify-center items-center">
              <MDButton color="info" onClick={handleSubmit} disabled={loading} sx={{ mx: "auto" }}>
                {loading ? "Validating ..." : "Sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </GuestLayout>
  );
}

export default SignIn;
