/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import Grid from "@mui/material/Grid";
import SwitchToggle from "components/switch";
import PropTypes from "prop-types";
import FormField from "../form-field";

function Socials({ formData, showSocials, setShowSocials }) {
  const { formField, values } = formData;
  const { twitter, facebook, instagram, youtube, threads, linkedInProfile } = formField;
  const {
    twitter: twitterV,
    facebook: facebookV,
    instagram: instagramV,
    youtube: youtubeV,
    linkedInProfile: linkedInProfileV,
    threads: threadsV,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Socials
      </MDTypography>
      <h1 className="text-sm text-gray-500">
        Any information entered here will appear at the bottom of your Gallery Menu in the Social
        Media Section. Leave any field blank if you do not want anything to appear for that field.
      </h1>
      <MDBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormField
              type={facebook.type}
              label={facebook.label}
              name={facebook.name}
              value={facebookV}
              // placeholder={facebook.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={instagram.type}
              label={instagram.label}
              name={instagram.name}
              value={instagramV}
              // placeholder={instagram.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={threads.type}
              label={threads.label}
              name={threads.name}
              value={threadsV}
              // placeholder={threads.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={twitter.type}
              label={twitter.label}
              name={twitter.name}
              value={twitterV}
              // placeholder={twitter.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={linkedInProfile.type}
              label={linkedInProfile.label}
              name={linkedInProfile.name}
              value={linkedInProfileV}
              // placeholder={linkedInProfile.placeholder}
            />
          </Grid>
          <MDBox
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
            className="flex-col gap-y-2 md:flex-row"
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            <p
              style={{ display: "inline-flex", justifyContent: "left" }}
              className="text-sm text-gray-500 mt-4 font-bold"
            >
              {" "}
            </p>
            <div
              className="flex gap-x-2 items-center"
              style={{ display: "inline-flex", justifyContent: "right" }}
            >
              <SwitchToggle checked={!showSocials} setChecked={setShowSocials} />
              <h1 className="text-sm text-gray-500">Display in Menu?</h1>
            </div>
          </MDBox>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Socials.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Socials;
