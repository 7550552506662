/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import { industries } from "constants/industries";
import countries from "data/countries";
import states from "data/states";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import FormField from "../form-field";

function Address({ formData, logo, setLogo }) {
  const { formField, values, errors, touched } = formData;
  const { name: nameV, industry, address, city, province, country, bio, businessName } = values;

  const { name } = formField;
  const [selectedCountry, setCountry] = useState("");
  const [selectedState, setState] = useState("");

  useEffect(() => {
    values.country = selectedCountry;
  }, [selectedCountry]);

  useEffect(() => {
    values.province = selectedState;
  }, [selectedState]);

  useEffect(() => {
    if (selectedCountry === "") {
      setCountry(country);
    }
    if (selectedState === "") {
      setState(province);
    }
  }, []);

  return (
    <MDBox>
      <center>
        <MDTypography variant="h5" fontWeight="bold">
          CREATE GALLERY
        </MDTypography>
      </center>
      <MDTypography variant="h5" fontWeight="bold">
        General
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className="flex mt-4"
              style={{
                height: "50px",
              }}
            >
              <p className="text-sm">The following information will not be displayed publicly:</p>
            </div>
            <FormControl fullWidth variant="standard">
              <Select
                native
                className="w-full my-2"
                labelId="industry-select-label"
                id="industry-select"
                value={industry}
                label="Select Industry"
                // onChange={handleChange}
                style={{
                  height: "30px",
                  color: "#456374",
                }}
              >
                <option value=""> Select Industry</option>
                {industries.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormField type="text" label="Business Name" name="businessName" value={businessName} />
          </Grid>
          <Grid item xs={12}>
            {/** factor these out */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3">
              <FormField
                className="mt-2"
                type="text"
                label="Address"
                name="address"
                value={address}
                placeholder="Address"
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
              <FormField
                className="mt-2"
                type="text"
                label="City"
                name="city"
                value={city}
                placeholder="City"
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-3">
              <FormControl
                variant="standard"
                style={{
                  marginTop: "7px",
                }}
                fullWidth
              >
                <Select
                  labelId="country-select-label"
                  id="country-select"
                  value={selectedCountry}
                  label="Select Country"
                  native
                  inputProps={{
                    name: "type",
                    id: "uncontrolled-native",
                  }}
                  style={{
                    height: "47px",
                    color: "#456374",
                  }}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  <option value="">Select Country</option>
                  {countries.map((option) => (
                    <option value={option.name} key={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedCountry === "United States of America" ? (
                <FormControl
                  fullWidth
                  variant="standard"
                  style={{
                    marginTop: "7px",
                  }}
                >
                  <Select
                    labelId="country-select-label"
                    id="state-select"
                    native
                    value={selectedState}
                    label="Select State"
                    inputProps={{
                      name: "type",
                      id: "uncontrolled-native",
                    }}
                    style={{
                      height: "47px",
                      color: "#456374",
                    }}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  >
                    <option>Select State</option>
                    {states.map((option) => (
                      <option value={option.name} key={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormField
                  className="mt-2"
                  type="text"
                  label="State/Province"
                  name="province"
                  value={province}
                  placeholder="State/Province"
                  fullWidth={false}

                  // error={errors.name && touched.name}
                  // success={nameV.length > 0 && !errors.name}
                />
              )}
              <FormField
                className="mt-2"
                type="text"
                label="Zip/Postal Code"
                name="bio"
                value={bio}
                placeholder="Zip/Postal Code"
                // error={errors.name && touched.name}
                // success={nameV.length > 0 && !errors.name}
              />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
