import MDBox from "@core/components/MDBox";
import ProfileInfoCard from "@core/examples/Cards/InfoCards/ProfileInfoCard";
import Header from "@core/layouts/pages/profile/components/Header";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { endpoints, query } from "api";
import BaseLayout from "layouts/base-layout";
import { useEffect, useState } from "react";
import PlatformSettings from "./components/platform-settings";

function UserProfile() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    query.get(endpoints.auth.user).then((res) => {
      setLoading(false);
      setUser(res.data);
    }).catch((err) => {
      setLoading(false);
    });
  }, []);

  return (
    <BaseLayout>
      <Header user={user}>
        {
          loading && (
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <p
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#eee",
                      padding: "1rem",
                      borderRadius: "0.5rem",
                      textAlign: "center",
                    }}
                  >
                    Loading...
                  </p>
                </Grid>
              </Grid>
            </MDBox>
          )
        }
        {
          !loading && (
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <PlatformSettings user={user} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <ProfileInfoCard
                    title="profile information"
                    description={user.bio || ""}
                    info={{
                      fullName: user.fullName,
                      mobile: user.cellphone,
                      email: user.email,
                    }}
                    action={{ route: "", tooltip: "Edit Profile" }}
                    shadow={false}
                  />
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                </Grid>
              </Grid>
            </MDBox>
          )
        }
      </Header>
    </BaseLayout>
  );
}

export default UserProfile;
