/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
import LogoImageCropper from "components/image-logo-cropper";
import { filePickButtonMaxWidth } from "constants/variables";
import { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";

const { default: Modal } = require("components/modals/modal");

function LogoCropper({ logo, setLogo, text, serverImage, setDeleteImage }) {
  const [cropperModal, setCropperModal] = useState(false);
  const [filepickEvent, setFilepickEvent] = useState();
  const [displayImage, setDisplayImage] = useState(null);

  useEffect(() => {
    setDisplayImage(serverImage);
  }, [serverImage]);

  useEffect(() => {
    setDisplayImage(logo);
  }, [logo]);
  return (
    <div className="flex space-x-5 justify-center 2xl:w-1/4 mx-auto max-w-[300px] relative marginleftforuploader">
      <Modal
        open={cropperModal}
        setOpen={() => {
          setCropperModal(true);
        }}
        content={
          <LogoImageCropper
            file={logo}
            setFile={setLogo}
            setCropperModal={setCropperModal}
            filepickEvent={filepickEvent}
            setFilepickEvent={setFilepickEvent}
          />
        }
        buttons=""
      />

      {!displayImage ? (
        <div className="flex justify-center items-center w-full ">
          <label
            htmlFor="logoImageCropper"
            className="flex flex-col justify-center items-center w-full h-10 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer  hover:bg-gray-200 stylelogouplodbutton"
            style={{ maxWidth: filePickButtonMaxWidth }}
          >
            <div className="flex flex-col justify-center items-center ">
              <p className="mb-0 text-sm text-gray-500 dark:text-gray-400 font-semibold">
                {displayImage !== "" && displayImage ? "" : "Upload Logo" || "Upload Logo"}
              </p>
            </div>

            <input
              id="logoImageCropper"
              onChange={(e) => {
                setFilepickEvent(e);
                setCropperModal(true);
              }}
              type="file"
              accept="image/*"
              hidden
            />
          </label>
        </div>
      ) : (
        ""
      )}
      {displayImage && <img className="rounded-lg margintheuploadedfile" src={displayImage} alt="cropped" />}
      {displayImage ? (
        <label className="flex flex-col justify-center items-center w-fit h-10 rounded-lg bg-red-500 text-white hover:bg-red-600 cursor-pointer absolute bottom-2 right-2">
          <div className="flex flex-col justify-center items-center">
            <p className="mb-0 text-white text-lg font-semibold p-2 px-3">
              <FiTrash2
                onClick={() => {
                  setDeleteImage("yes");
                  setDisplayImage("");
                }}
              />
            </p>
          </div>
        </label>
      ) : (
        ""
      )}
    </div>
  );
}

export default LogoCropper;
