export const industries = [
  "Actor",
  "Ad Agency",
  "Aerospace",
  "Agriculture",
  "Amusement Park",
  "Architecture",
  "Art Gallery",
  "Artist",
  "Auto Dealership",
  "Bar",
  "Brewery",
  "Club",
  "Conference Center",
  "Corporate",
  "Country Club",
  "Creator",
  "Dental",
  "Design Firm",
  "Education",
  "Event Venue",
  "Events",
  "Farm",
  "Film Location",
  "Film Studio",
  "Filmmaker",
  "Fitness Center",
  "Government",
  "Health Spa",
  "Hospital",
  "Hotel",
  "K-12 School",
  "Landmark",
  "Marketing Agency",
  "Medical",
  "Military",
  "Municipality",
  "Museum",
  "Music Recording",
  "Music Venue",
  "Musician",
  "Office",
  "Online Store",
  "Other",
  "Parks/Recreation",
  "Performing Arts Venue",
  "Photographer",
  "Places of Worship",
  "Ranch",
  "Real Estate - Commercial",
  "Real Estate - Residential",
  "Recovery Center",
  "Recovery Center",
  "Restaurant",
  "Retail",
  "Rural",
  "Salon",
  "Senior Living",
  "Software",
  "Sports Stadium",
  "Technology",
  "Tourism",
  "University",
  "Vacation Rental",
  "Veterinary",
  "Videographer",
  "Vineyard",
  "Wedding Venue",
  "Zoo",
];