// ==================================================
// TEST
// ====================================================

import envConfigs from "envConfigs";

let data = {
  monthlyPlan: {
    price: "price_1Nuzr0ARRlrXWO13ROa9ESwX",
    quantity: 1,
    description: "Monthly Plan",
    amount: 99,
    type: "recurring",
  },
  yearlyPlan: {
    price: "price_1NuzryARRlrXWO13p8FITzCS",
    quantity: 1,
    amount: 990,
    description: "Yearly Plan",
    type: "recurring",
  },
  removeLogoMontlhy: {
    price: "price_1NG30GARRlrXWO13ySkSJC0h",
    quantity: 1,
    amount: 29,
    description: "Remove Logo",
    type: "recurring",
  },
  removeLogoYearly: {
    price: "price_1NG313ARRlrXWO13rT78zkG9",
    quantity: 1,
    amount: 290,
    description: "Remove Logo Yearly Plan",
    type: "recurring",
  },
  setupWalkthrough: {
    price: "",
    quantity: 1,
    amount: 149,
    type: "onceoff",
    description: "Concierge 1-Hour Set-Up Walkthrough Instruction",
  },
  completeViewer: {
    price: "",
    quantity: 1,
    amount: 749,
    type: "onceoff",
    description: "Concierge Complete Gallery Built for You",
  },
};

if (envConfigs.enviroment === "live") {
  data = {
    monthlyPlan: {
      price: "price_1NujDpARRlrXWO13J6FCnsXK",
      quantity: 1,
      description: "Monthly Plan",
      amount: 99,
      type: "recurring",
    },
    yearlyPlan: {
      price: "price_1NujEgARRlrXWO13HEyVvQyf",
      quantity: 1,
      amount: 990,
      description: "Yearly Plan",
      type: "recurring",
    },
    removeLogoMontlhy: {
      price: "price_1Nf2A6ARRlrXWO13EEfGxXgQ",
      quantity: 1,
      amount: 29,
      description: "Remove Logo",
      type: "recurring",
    },
    removeLogoYearly: {
      price: "price_1Nf29wARRlrXWO13rrF50xR3",
      quantity: 1,
      amount: 290,
      description: "Remove Logo Yearly Plan",
      type: "recurring",
    },
    setupWalkthrough: {
      price: "",
      quantity: 1,
      amount: 149,
      type: "onceoff",
      description: "Concierge 1-Hour Set-Up Walkthrough Instruction",
    },
    completeViewer: {
      price: "",
      quantity: 1,
      amount: 749,
      type: "onceoff",
      description: "Concierge Complete Gallery Built for You",
    },
  };
}

export const subscriptionsData = data;
