/* eslint-disable import/no-unresolved */
import { endpoints, query } from "api";
import { useUrl } from "contexts/url";
import { useViewer } from "contexts/viewer";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { usePageRerender } from "contexts/rerender";
import LoadingModule from "../loading";

export default function ModuleWrapper({ component: Component, ...props }) {
  const { getSearchParam } = useUrl();
  const { enqueueSnackbar } = useSnackbar();
  const [edits, setEdits] = useState({});
  const [data, setData] = useState();
  const { fetchModules } = useViewer();
  const { forceRerender } = usePageRerender();
  const moduleId = getSearchParam("moduleId");

  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(true);

  const setEdit = useCallback(
    (name, value) => setEdits((s) => ({ ...s, [name]: value })),
    [setEdits]
  );

  const fetchModule = useCallback(() => {
    if (moduleId) {
      setFetching(true);
      query
        .get(endpoints.modules.findOne({ id: moduleId }))
        .then((res) => {
          setData(res.data);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [setFetching, moduleId]);

  const save = () => {
    if (edits?.label === "") {
      enqueueSnackbar("Menu title cannot be empty", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    setSaving(true);
    query
      .patch(endpoints.modules.updateOne({ id: moduleId }), edits)
      .then(() => {
        enqueueSnackbar("Module updated", { variant: "success", autoHideDuration: 2000 });
        setSaving(false);
        fetchModules();
        forceRerender();
      })
      .catch((e) => {
        console.log("---", e);
        setSaving(false);
        enqueueSnackbar("Module not updated", { variant: "error", autoHideDuration: 2000 });
      });
  };

  useEffect(() => {
    fetchModule();
  }, [fetchModule]);

  if (fetching) {
    return <LoadingModule />;
  }

  return (
    <Component
      save={save}
      saving={saving}
      fetchModule={fetchModule}
      fetching={fetching}
      setSaving={setSaving}
      data={data}
      edits={edits}
      setEdits={setEdits}
      moduleId={moduleId}
      setEdit={setEdit}
      showSnackbar={enqueueSnackbar}
      {...props}
    />
  );
}
