import form from "./form";

const {
  formField: {
    firstName,
    lastName,
    email,
    title,
    linkedInProfile,
    phoneNumber,
    locationName,
    twitter,
    facebook,
    instagram,
    youtube,
    website,
    publicEmail,
    bio,
  },
} = form;

const initialValues = {
  [firstName.name]: "John",
  [lastName.name]: "Doe",
  [email.name]: "jdoe@gmail.com",
  [title.name]: "Sales Director",
  [linkedInProfile.name]: "linkedin.com/john-doe/",
  [phoneNumber.name]: "+254 778 990 876",
  [locationName.name]: "the-westin-new-orleans-canal-place",
  [twitter.name]: "https://twitter.com/johndoe",
  [facebook.name]: "https://www.facebook.com/john.doe",
  [instagram.name]: "https://www.instagram.com/johndoe/",
  [youtube.name]: "https://www.youtube.com/c/Johndoe",
  [website.name]: "https://johndoe.com/",
  [publicEmail.name]: "",
  [bio.name]: "",
};

export default initialValues;
