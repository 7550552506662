/* eslint-disable import/no-unresolved */
import { TextField, useMediaQuery } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useViewer } from "contexts/viewer";
import moment from "moment";
import { useEffect } from "react";

export default function DateRangeSelector({ setToDate, setFromDate, fromDate, toDate }) {
  const { viewer } = useViewer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    setFromDate(moment(viewer?.createdAt));
    setToDate(moment());
  }, [viewer?.createdAt]);

  const renderDateInput = (label, value, setValue) =>
    isMobile ? (
      <MobileDatePicker
        label={label}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        className="custom-date-css"
        style={{ color: "#456374" }}
        fullWidth
        disableFuture
        minDate={moment(viewer?.createdAt)}
        renderInput={(params) => <TextField {...params} fullWidth={isMobile} />}
      />
    ) : (
      <DesktopDatePicker
        label={label}
        value={value}
        fullWidth
        className="custom-date-css"
        style={{ color: "#456374" }}
        disableFuture
        minDate={moment(viewer?.createdAt)}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} fullWidth={isMobile} />}
      />
    );

  return (
    <div className="flex items-center flex-col md:flex-row gap-x-2 gap-y-1 w-full md:w-fit mx-auto md:mx-0">
      {renderDateInput("Start Date", fromDate, setFromDate)}
      <span className="text-sm">to</span>
      {renderDateInput("End Date", toDate, setToDate)}
    </div>
  );
}
