import { createRoutine } from "redux-saga-routines";

const getPhotosRoutine = createRoutine("GET_PHOTOS");
export default getPhotosRoutine;

export const removePhotoFolderRoutine = createRoutine("REMOVE_PHOTO_FOLDER");
export const addPhotoFolderRoutine = createRoutine("ADD_PHOTO_FOLDER");
export const addPhotoImageRoutine = createRoutine("ADD_PHOTO_IMAGE_FOLDER");
export const updatePhotoFolderRoutine = createRoutine("UPDATE_PHOTO_Image_FOLDER");
export const sortPhotoFoldersRoutine = createRoutine("SORT_PHOTO_IMAGE_FOLDER");
export const getImagesRoutine = createRoutine("GET_IMAGES");
export const sortImagesRoutine = createRoutine("SORT_IMAGES");
export const removePhotoImageRoutine = createRoutine("REMOVE_IMAGES");
