/* eslint-disable import/no-unresolved */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "@core/components/MDBox";
import MDTypography from "@core/components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "@core/assets/theme/base/typography";

function Footer({ links }) {
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      className="mt-28"
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="end"
      alignItems="center"
      px={1.5}
    >
      {/* <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()} OneViewer
      </MDBox> */}
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

Footer.defaultProps = {
  links: [
    {
      href: "https://www.viewlio.com",
      name: <>&copy; {new Date().getFullYear()} Viewlio</>,
    },
    {
      name: "|",
    },
    {
      href: "https://www.viewlio.com/privacy",
      name: "Privacy",
    },
    {
      name: "|",
    },
    {
      href: "https://www.viewlio.com/terms",
      name: "Terms",
    },
    {
      name: "|",
    },
    {
      href: "https://www.viewlio.com/support",
      name: "Support",
    },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
