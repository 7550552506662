/* eslint-disable import/no-unresolved */
import MDBox from "@core/components/MDBox";
import MDButton from "@core/components/MDButton";
import MDTypography from "@core/components/MDTypography";
import FormField from "@core/layouts/pages/account/components/FormField";
import { Alert, Card, Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import ModuleWrapper from "../components/wrapper";

function Instagram({ data, saving: loading, save, edits, setEdit, setEdits }) {
  useEffect(() => {
    setEdits({
      overviewContent: data?.overviewContent,
      label: data?.label,
      displayInLeftMenu: data?.displayInLeftMenu,
    });
  }, [data, setEdits]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDisplayInLeftMenu = () => {
    const value = data?.displayInLeftMenu || false;
    setEdit("displayInLeftMenu", !value);
  };

  const setMenuTitle = (title) => setEdit("label", title);
  const setToken = (token) => setEdit("overviewContent", token);

  return (
    <Card className="p-6">
      <MDBox>
        <MDTypography variant="h5" fontWeight="bold" className="text-center mb-12">
          Instagram Feed
        </MDTypography>

        <Grid className="grid gap-6">
          <Grid display="flex">
            <Grid item xs={4} className="grid gap-6">
              <FormField
                label="Menu Title*"
                placeholder="Instagram"
                value={edits?.label || ""}
                onChange={(e) => setMenuTitle(e.target.value)}
              />
            </Grid>
            <Grid xs={5} container spacing={2} className="specialmarigin centeralignalways">
              <Grid item xs={3}>
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                  <MDBox mt={0.5}>
                    <Switch checked={edits?.displayInLeftMenu} onChange={handleDisplayInLeftMenu} />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" className="text-sm text-gray-500">
                      Display in Menu?
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
              {/* <Grid item xs={6}>
              <MDBox mt={0.5} style={{ marginTop: "6px" }} ml={0.5}>
                <ClickAlert link={getModuleRoute(data)} />
              </MDBox>
            </Grid> */}
            </Grid>
          </Grid>
          <br />
          {/* <div className="grid gap-6 mb-3">
            <FormField
              label="Instagram Display Token"
              value={edits?.overviewContent}
              placeholder="Enter your instagram authentication token"
              onChange={(e) => setToken(e.target.value)}
            />
          </div> */}
          {/* 
          <MDButton
            color="info"
            size="medium"
            className="mx-auto w-full sm:w-1/4 md:w-auto"
            onClick={save}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </MDButton> */}
          <Alert
            severity="error"
            className="text-md py-3 md:text-sm text-center"
            style={{ display: "grid", gridTemplateColumns: "1fr" }}
          >
            <div className="text-center mx-auto">
              This functionality is still under construction, we’ll notify you when it is ready.
            </div>
            <br />
            <div className="text-center mx-auto">
              <b>
                {" "}
                In the mean time, if you would like us to add your Instagram feed for you, then
                contact us and we can guide you through the process.
              </b>
            </div>
            <div className="text-center mx-auto">
              <a href="https://www.viewlio.com/contact" target="_blank" rel="noreferrer">
                <MDButton
                  color="info"
                  variant="outlined"
                  size="medium"
                  className="mx-auto w-full sm:w-1/4 md:w-auto mt-2"
                >
                  CONTACT US
                </MDButton>
              </a>
            </div>
          </Alert>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default function InstagramModule() {
  return <ModuleWrapper component={Instagram} />;
}
